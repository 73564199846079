import { usePostApiUserCreateUserMutation } from "../../services/appcenterApi";
import { useUploadFileMutation } from "../../hooks/useUploadFileMutation";

export function useCreateUserMutation() {

  const getUrl = (values) => {
    return values.addUserCommand.profilePicture;
  };
  const transformValues = (values, data) => {
    values.addUserCommand.profilePicture = data[0].key;
    return values;
  };

  return useUploadFileMutation({
    useMutationHook: usePostApiUserCreateUserMutation
    , transformValues, getUrl
  });

  /*
  //TODO: How to get the error from upload file to 'main' form
  //upload files

  const [uploadFile] = usePostApiFileUploadMutation();

  //const []
  const [create, postApiObj] = usePostApiUserCreateUserMutation();

  const combineFunction = async (values) => {

    //console.log('Values:')
    //console.log(values)
    //const imgDataUri = values.addApplicationCommand.iconImageUrl.split(',')

    if (values.addUserCommand.profilePicture == "")
      return create(values)

    const data = await uploadFile({
      'body' : [{
        name: 'not-required',
        data: values.addUserCommand.profilePicture.split(',').pop(),
        type: 'image/png'
      }]
    }).unwrap()

    //console.log('uploaded')
    //console.log(data)
    //delete values.addUserCommand.iconImageUrl

    //Add icon key
    values.addUserCommand.profilePicture = data[0].key
    return create(values)
  }

  return[combineFunction, postApiObj]*/
}