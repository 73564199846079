import { Translation, useTranslation } from "react-i18next";
import { ListApplications } from "../pages/Applications/ListApplications";
import { ListOrganizationAssignRequests, UpdateOrganization, UpdateOrganizationAdministrator, UpdateOrganizationSecurityPolicy } from "shared-library/src/pages/Organizations";
import { ListApiClients } from "shared-library/src/pages/ApiClients/ListApiClients";
import { CreateApiClient } from "shared-library/src/pages/ApiClients/CreateApiClient";
import { UpdateApiClient } from "shared-library/src/pages/ApiClients/UpdateApiClient";
import { ListUsers } from "shared-library/src/pages/Users/ListUsers";
import { UpdateUser } from "shared-library/src/pages/Users/UpdateUser";
import { ResetPassword } from "shared-library/src/pages/Users/ResetPassword";
import { ListGroups } from "shared-library/src/pages/Groups/ListGroups";
import { CreateGroup } from "shared-library/src/pages/Groups/CreateGroup";
import { UpdateGroup } from "shared-library/src/pages/Groups/UpdateGroup";
import { ListGroupAssignments } from "../pages/Group/ListGroupAssignments";
import { ChangePassword } from "shared-library/src/pages/User/ChangePassword";
import { UpdateUserSelf } from "shared-library/src/pages/User/UpdateUserSelf";
import { ApplicationLayout } from "../layouts/ApplicationLayout";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { CxError } from "shared-library/src/components";
import { SideBarLayout } from "../layouts/SideBarLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import OrganizationApprovalRequest from "../pages/Organizations/OrganizationApprovalRequest";
import { CenterLayout } from "../layouts/CenterLayout";
import { SignUp } from "../pages/SignUp/SignUp";
import React from "react";
import { Verify } from "../pages/SignUp/Verify";
import { AddAdditionalInfo } from "../pages/SignUp/AddAdditionalInfo";
import { AddTotp } from "../pages/SignUp/AddTotp";
import { Activated } from "../pages/SignUp/Activated";
import { ChangePasswordExternal } from "../pages/User/ChangePasswordExternal";
import { CreateUser } from "shared-library/src/pages/Users/CreateUser";
import { User } from "oidc-client-ts";
import { ListUserGroupAssignment } from "../pages/Users/ListUserGroupAssignments";
import { ViewAuditEvent } from "../../../shared-library/src/pages/Audit/ViewAuditEvent";
import { ViewLoginActivity } from "../../../shared-library/src/pages/User/ViewLoginActivity";
import { ViewOrganizationAuditEvent } from "../pages/Organizations/ViewOrganizationAuditEvent";
import { ListAffectedUsers } from "shared-library/src/pages/Organizations/ListAffectedUsers";

export class MicroFeApp {
    name: string;
    entry: string;
    container: string = "#container";
    activeRule: string;
    props: any;
}

function ErrorHandler() {
    const error = useRouteError();
    const { t } = useTranslation();
    //const errorCode =    
    console.error(error);
    console.error("isRouteError:" + isRouteErrorResponse(error));

    return (
        <CxError
            code={isRouteErrorResponse(error) ? error.status : error.status ?? "999"}
            description={t("common.errorDescription", {
                context: isRouteErrorResponse(error) ? error.status : error.status ?? ""
            })}
            title={t("common.errorTitle", {
                context: isRouteErrorResponse(error) ? error.status : error.status ?? ""
            })}
        />
    );
}

const applicationRoutes = [{
    path: "applications",
    isMenu: {
        context: "application",
        parent: "application",
    },
    children: [
        {
            index: true,
            element: <ListApplications />,
            loader: async function () { await (new Promise(r => { setTimeout(r, 1); })); return null; }
        },
    ],
},
];

const organizationRoutes = [
    {
        path: "organization",
        isMenu: {
            label: <Translation>{(t) => t("update.title", { context: 'organization' })}</Translation>,
            parent: "organization",
        },
        children: [
            {
                index: true,
                element: <UpdateOrganization />,
            },
            {
                path: "updateSecurityPolicy",
                element: <UpdateOrganizationSecurityPolicy />,
                isMenu: {
                    label: <Translation>{(t) => t("update.title", { context: 'organizationSecurityPolicy' })}</Translation>,
                    parent: "organization",
                },
            },
            {
                path: "updateAdmin",
                element: <UpdateOrganizationAdministrator />,
                isMenu: {
                    label: <Translation>{(t) => t("update.title", { context: 'organizationAdmin' })}</Translation>,
                    parent: "organization",
                },
            },
            {
                path: "listApprovalRequest",
                element: <ListOrganizationAssignRequests />,
                isMenu: {
                    label: <Translation>{(t) => t("update.title", { context: 'organizationAssignRequests' })}</Translation>,
                    parent: "organization",
                },
            },
            {
                path: "viewOrganizationAuditEvent",
                element: <ViewOrganizationAuditEvent />,
                isMenu: {
                  label: <Translation>{(t) => t("list.title", { context: 'organizationAuditEvent'})}</Translation>,
                  parent: "organization",
                },
            },
            {
                path: "listAffectedUsers",
                element: <ListAffectedUsers />,
            }
        ],
    },
];

const apiClientRoutes = [
    {
        path: "apiClients",
        isMenu: {
            context: "apiClient",
            parent: "apiClient",
        },
        handle: {
            crumb: () => ({ key: "menu.apiClient", link: "." }),
        },
        children: [
            {
                index: true,
                element: <ListApiClients />,
            },
            {
                path: "create",
                element: <CreateApiClient />,
                handle: {
                    crumb: () => ({ key: "menu.apiClient.new", link: "." }),
                },
            },
            {
                path: "edit/:id",
                element: <UpdateApiClient />,
            },
        ],
    },
];

const userRoutes = [
    {
        path: "users",
        isMenu: {
            context: "users",
            parent: "users_other",
        },
        children: [
            {
                index: true,
                element: <ListUsers />,
            },
            {
                path: "create",
                element: <CreateUser />,
            },
            {
                path: "edit/:userId",
                element: <UpdateUser />,
            },
            {
                path: "resetUserPassword/:userId/:organizationId",
                element: <ResetPassword />
            },
            {
                path: "viewApplicationGroups/:userId",
                element: <ListUserGroupAssignment />
            }
        ],
    },
    {
        path: "userGroups",
        isMenu: {
            context: "userGroup",
            parent: "users_other",
        },
        children: [
            {
                index: true,
                element: <ListGroups />,
            },
            {
                path: "create",
                element: <CreateGroup />,
            },
            {
                path: "edit/:id",
                element: <UpdateGroup />,
            },
            {
                path: "edit/:id/userAssignment",
                element: <ListGroupAssignments />
            }
        ],
    },
    {
        path: "user",
        children: [
            {
                path: "changePassword",
                element: <ChangePassword />,
            },
            {
                path: "viewLoginActivity",
                element: <ViewLoginActivity />,
              },
            {
                index: true,
                element: <UpdateUserSelf />,
            },
        ],
    },
];

const auditRoutes = [
    {
      path: "audit",
      children: [
        {
          path: ":context/:id/:exclusions?/:userOrganizationId?",
          element: <ViewAuditEvent />,
        }
      ],
    },
  ]

export const routes = [
    {
        //path: "/",
        element: <ApplicationLayout />,
        errorElement: <ErrorHandler />,
        children: [
            {
                path: "apps/*",
                loader: async function () {                             
                    if (window.env.mfeLoaded) {                    
                        console.log('redirect to 404');                    
                        return null;
                    } else {
                        return null;            
                    }
                }
            },
            {
                //path: "dashboard",
                element: <SideBarLayout />,
                children: [
                    {
                        path: "",
                        isMenu: {
                            label: <Translation>{(t) => t("menu.dashboard")}</Translation>
                            //level: 1
                        },
                        element: <Dashboard />,
                        loader: async function () { await (new Promise(r => { setTimeout(r, 1); })); return null; },
                        handle: {
                            crumb: () => ({ key: "menu.dashboard", link: "." }),
                        },
                    },
                    ...organizationRoutes,
                    ...userRoutes,
                    ...apiClientRoutes,
                    ...applicationRoutes,
                    ...auditRoutes
                ],
            },
            {
                path: '/requestapproval/:wfid',
                element: <OrganizationApprovalRequest />
            }
        ],
    },
    {
        element: <CenterLayout />,
        path: "signup",
        children: [
            {
                path: ":setPasswordId",
                element: <SignUp />,
            },
        ],
    },
    {
        element: <CenterLayout />,
        path: "verify",
        children: [
            {
                path: ":setPasswordId",
                element: <Verify />,
            },
        ],
    },
    {
        element: <CenterLayout />,
        path: "addAdditionalInfo",
        children: [
            {
                path: ":setPasswordId",
                element: <AddAdditionalInfo />,
            },
        ],
    },
    {
        element: <CenterLayout />,
        path: "addTotp",
        children: [
            {
                path: ":setPasswordId",
                element: <AddTotp />,
            },
        ],
    },
    {
        element: <CenterLayout />,
        path: "activated",
        children: [
            {
                path: ":setPasswordId",
                element: <Activated />,
            },
        ],
    },
    {
        element: <ChangePasswordExternal />,
        path: "ChangePasswordExt",
    }
];