import { createFormContext } from "@mantine/form";
import { z } from "zod";

import { useCustomZodResolver } from "shared-library/src/hooks/useCustomZodResolver";
import { AddAdditionalInfoCommand } from "shared-library/src/services/appcenterApi"


const AddAdditionalInfoFormSchema = z
  .object({
    

  })

const addAdditionalInfoFormInitialValues: AddAdditionalInfoCommand = {
  staffId: "",
  mobileNumber: ""
};


export const AddAdditionalInfoResponseTransformation = (values) => {

  return {
    email: values.email,
    staffId: values.staffId ?? "",
    mobileNumber: values.mobileNumber ?? ""
  }
}

export const [AddAdditionalInfoFormProvider, useAddAdditionalInfoFormContext, useAddAdditionalInfoNativeForm] =
  createFormContext<AddAdditionalInfoCommand>();

export function useAddAdditionalInfoForm() {
  return useAddAdditionalInfoNativeForm({
    initialValues: addAdditionalInfoFormInitialValues,
    validate: useCustomZodResolver("user", AddAdditionalInfoFormSchema),
  });
}
