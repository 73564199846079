import {
  faKey,
  faPaperPlane,
  faPenToSquare,
  faUnlock,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";

import { BadgeList, HoverPopoverWithBadgeList } from "../../components";
import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import { openConfirmationModal } from "../../hooks/openConfirmationModal";
import { openDeleteModal } from "../../hooks/openDeleteModal";
import {
  useDeleteApiUserDeleteUserMutation,
  usePatchApiUserResendActiveEmailMutation,
  usePatchApiUserResendVerifyEmailMutation,
  usePatchApiUserSendResetPasswordMutation,
  useGetApiUserGetOwnProfileQuery,
  usePatchApiUserUpdateUserInactivityLockStatusMutation,
} from "../../services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { Badge, Flex, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as dayjs from "dayjs";

export function UsersDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationId = useAppSelector((state) => state.user?.organization.id);
  const { data : profileData } = useGetApiUserGetOwnProfileQuery(organizationId !== "" ? {'organizationId': organizationId} : skipToken);

  const i18Prefix = "users";

  const columns = [
    {
      accessor: "userFullName",
      title: t("user.userFullName"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "staffId",
      title: t("user.staffId"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "email",
      title: t("user.email"),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: "userRole",
      title: t("user.roles"),
      width: 230,
      render: ({ userRole }) => {
        if (!userRole) {
          return <></>;
        }

        let userRoleArr = userRole.split(",").filter((x) => x !== "");

        if (userRoleArr.length == 0) {
          return <></>;
        }

        if (userRoleArr.length <= 3) {
          return <BadgeList values={userRoleArr} />;
        }

        return (
          <HoverPopoverWithBadgeList
            display={
              <>
                <BadgeList values={userRoleArr.slice(0, 3)} /> ...
              </>
            }
            popoverDisplay={userRoleArr}
            popover={{
              position: "bottom-start",
              width: 300,
              shadow: "0 0 20px -8px",
              withArrow: true,
            }}
          />
        );
      },
    },
    {
      accessor: "endDate",
      title: t("user.endDate"),
      sortable: true,
      ellipsis: true,
      width: 120,
      render: ({ endDate }) => {
        const dateTime = dayjs.default(endDate).utc(false);
        // if end Date is null, return empty string
        if (endDate == null) {
          return <></>;
        }
        return <>{dateTime.local().format("DD/MM/YYYY")}</>;
      },
    },
    {
      accessor: "status",
      title: t("user.status"),
      sortable: true,
      ellipsis: true,
      width: 180,
      render: ({ status, isUserInactivityLockOn,isExpired }) => {
        if (isUserInactivityLockOn) {
            return (
              <Flex align="center" justify="space-between">
                <Tooltip label={status}>
                  <span style={{ marginRight: 8 }}>{status}</span>
                </Tooltip>                
                <Tooltip  label={t("user.inactivityLockStatus_locked")}>
                  <FontAwesomeIcon icon={faLock} />
                </Tooltip>
              </Flex>
            );
          }else {
            return (
              <>
                <Tooltip label={status}>
                  <span>{status}</span>
                </Tooltip>
              </>
            );
          }
        }
    }
  ];
  const computeResetPasswordHasEmail = function (r) {
    if (r.email) {
      return true;
    } else {
      return false;
    }
  };
  const rowContextMenu = {
    items: (record) => {
      let ctxMenu = createStandardRowContextMenu({
        i18Context: i18Prefix,
        auditExclusion: "LoginSession",
        record: { ...record, name: record.userFullName },
        useDeleteMutation: useDeleteApiUserDeleteUserMutation,
        editOverride: {
          onClick: () => {
            navigate(
              generatePath("/users/edit/:userId", { userId: record.id })
            );
          },
        },
        deleteOverride: {
          onClick: () => {
            const deleteData = {
              userId: record.id,
              organizationId: organizationId,
              name: record.userFullName,
            };
            openDeleteModal({
              confirmationValue: deleteData.name,
              recordId: deleteData.userId,
              data: deleteData,
              i18prefix: i18Prefix,
              useDeleteMutation: useDeleteApiUserDeleteUserMutation,
              customDeleteRecord: deleteData,
            });
          },
        },
      }).toSpliced(
        2,
        0,

        {
          key: "resendVerification",
          title: t("list.resendVerification"),
          icon: faPaperPlane,
          disabled: record.status != "Activation",
          onClick: () => {            
            openConfirmationModal({
              data: record,
              i18prefix: "resendVerification",
              useMutationHook: usePatchApiUserResendVerifyEmailMutation,
            });
          },
        },
        {
          key: "resendActivation",
          title: t("list.resendActivation"),
          icon: faPaperPlane,
          disabled: record.status != "PendingActivation",
          onClick: () => {            
            openConfirmationModal({
              data: record,
              i18prefix: "resendActivation",
              useMutationHook: usePatchApiUserResendActiveEmailMutation,
            });
          },
        },        
        {
          key: "resetPassword",
          title: computeResetPasswordHasEmail(record)
            ? t("list.resetPassword")
            : t("list.resetPasswordNoEmail"),
          icon: faKey,
          onClick: () => {
            if (computeResetPasswordHasEmail(record)) {
              openConfirmationModal({
                data: record,
                i18prefix: "resetPassword",
                useMutationHook: usePatchApiUserSendResetPasswordMutation,
              });
            } else {
              navigate(
                generatePath("resetUserPassword/:id/:organizationId", {
                  id: record.id,
                  organizationId: organizationId,
                })
              );
            }
          },
        },
        {
          key: "unlockInactiveUser",
          title: t("list.unlockInactiveUser"),
          icon: faUnlock,
          disabled: !record.isUserInactivityLockOn,
          onClick: () => {
            openConfirmationModal({
              data: record,
              i18prefix: "unlockInactiveUser",
              useMutationHook: usePatchApiUserUpdateUserInactivityLockStatusMutation,
              customConfirmRecord:{
                userId: record.id, 
                organizationId: organizationId, 
                isUserInactivityLockOn: !record.isUserInactivityLockOn
              }
            });
          },
        }
      );
      if (!APPCENTER) {
        //<- APPCENTER variable is a webpack variable. This code is included or excluded during transpile time//
        ctxMenu = ctxMenu.toSpliced(2, 0, {
          key: "editUserAssignment",
          title: t("list.editUserAssignment"),
          icon: faPenToSquare,
          onClick: () => {
            //const path =
            navigate(
              generatePath("editUserAssignment/:userId", { userId: record.id })
            );
          },
        }
          );
      }

      if (APPCENTER) {
        ctxMenu = ctxMenu.toSpliced(2, 0, {
          key: "viewApplicationGroups",
          title: t("list.userGroup"),
          icon: faPaperPlane,
          onClick: () => {
            //const path =
            //navigate(generatePath("edit/:id", { id: record.id }));
            //confirmSendVerificationEmail(record);
            navigate(
              generatePath("viewApplicationGroups/:userId", {
                userId: record.id
              })
            );
          },
        },);
      }
      return ctxMenu;
    },
  };

  return (
    <>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
