import { InputSharedProps, InputWrapperProps, MultiSelect, useInputProps } from "@mantine/core";
import * as React from "react";
import { useEffect, useState } from "react";

interface MultiTextInputProps extends Partial<InputSharedProps>, Partial<InputWrapperProps> {

  value: string[];  
  onSearchRecommendation: (val: string, list: object[], setList: any) => void;
  recommendationGroupKey: string;
  //label: any,
  //form: any,

  //formAccessor: string,
  //required?: boolean
}

export function MultiTextInput(props: MultiTextInputProps) {


  const { value, inputProps, wrapperProps, ...others } = useInputProps("MultiTextInput", {}, props);

  const data = value ? value.map((x) => ({value: x, label: x})) : []

  const [list, setList] = useState(data)  
  //Set the form for submission
  const onCreate = (query) => {
    console.log('onCreate: ', query)
    const item = { value: query, label: query };
    setList((current) => [...current, query]);
    //others.onChange((current) => [...current, query])
    return item;
  };

  useEffect(() => {

    if (value) {
      setList(value?.map((x) => ({value: x, label: x})))
    }

  }, [value])

  const onChange = (data) => {
    console.log('onChange: ', data)    
    others.onChange(data);    
  }
  
  const defaultRecommendationGroupKey = "Recommendations";

  const onSearchChange = function (val) { 
    if (others.onSearchRecommendation != null && typeof others['onSearchRecommendation'] == 'function') {           
      let key = defaultRecommendationGroupKey;
      if (others.recommendationGroupKey) {
        key = others.recommendationGroupKey;
      }
      let newList = list.filter((val) => {
        if (val.hasOwnProperty('group')
          && !!val.group
          && val.group === key)
        {          
          return false;
        }
        return true;
      });
      others.onSearchRecommendation(val, newList, setList);
    }
  } 

  const clearRecommendation = function () {    

    if (others.onSearchRecommendation == null) {
      return;
    }
    let key = defaultRecommendationGroupKey;
    if (others.recommendationGroupKey) {
      key = others.recommendationGroupKey
    }
    let newList = list.filter((val) => {
      if (val.hasOwnProperty('group') 
        && !!val.group
        && val.group === key
      )
      {
        return false;
      }
      return true;
    });
    setList(newList);
  }

  const getCreateLabel = (query) => "+ : " + query;  

  return (
    <>
      <MultiSelect
        onBlur={clearRecommendation}
        {...wrapperProps}
        {...others}
        data={list}
        onChange={onChange}
        value={value}
        creatable clearable searchable
        onSearchChange={onSearchChange}
        //value={data}
        getCreateLabel={getCreateLabel}
        onCreate={onCreate}

      />
    </>
  );
}