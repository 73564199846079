import { createFormContext } from "@mantine/form";
import { z } from "zod";

import { useCustomZodResolver } from "shared-library/src/hooks/useCustomZodResolver";
import { VerifyUserCommand } from "shared-library/src/services/appcenterApi"


const VerifyUserFormSchema = z
  .object({
    userFullName: z.string().trim().min(1),
    email: z.string().email().optional().nullable().or(z.literal("")),
    password: z.string().trim().optional().nullable(), //.min(1),
    confirmPassword: z.string().trim().optional().nullable(),

  })

const verifyUserFormInitialValues: VerifyUserCommand = {
  otpCode: ""
};


export const VerifyUserResponseTransformation = (values) => {

  return {
    email: values.email,
    userFullName: values.userFullName
  }
}

export const [VerifyUserFormProvider, useVerifyUserFormContext, useVerifyUserNativeForm] =
  createFormContext<VerifyUserCommand>();

export function useVerifyUserForm() {
  return useVerifyUserNativeForm({
    initialValues: verifyUserFormInitialValues,
    validate: useCustomZodResolver("user", VerifyUserFormSchema),
  });
}
