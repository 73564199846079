import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

function normalizeResponse(response: any): any {
  //console.log('rawResponse:')
  //console.log(response)

  //if (response && response.status )
  //

  if (response && response.data && response.data.status == "Success") {
    //console.log('Normalized: ')
    //console.log(response.data.data)
    return { data: response.data.data };
  }

  if (response && response.data && response.data.status == "Failed") {
    //console.log('Normalized error ')
    //console.log(response.data)
    //return {error: response.data.data}
    return {
      error: {
        status: "400",
        statusText: "Bad Request",
        data: response.data.message
          ? response.data.message
          : response.data.Data,
      },
    };
  }

  console.log("not formatted rawResponse:");
  return response;
}

const baseQuery = (args, api, extraOptions) => {
  const appUrl = () => window.env.appCenterApiUrl;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: appUrl(),
  });

  return new Promise<any>((resolve, reject) => {
    rawBaseQuery(args, api, extraOptions).then((response) => {
      resolve(normalizeResponse(response));
    });
  });
};
export const activationApi = createApi({
  reducerPath: "activationApi",
  baseQuery: baseQuery,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserByActivationId: builder.query({
      query: (queryArg) => ({
        url: "/api/User/GetUserByActivationId",
        params: { setPasswordId: queryArg.setPasswordId },
      }),
      providesTags: ["User"],
    }),
    postSignUpUser: builder.mutation({
      query: (queryArg) => ({
        url: `/api/User/SignUpUser`,
        method: "POST",
        body: queryArg.signUpUserCommand,
      }),
      invalidatesTags: ["User"],
    }),
    postVerifyUser: builder.mutation({
      query: (queryArg) => ({
        url: `/api/User/VerifyUser`,
        method: "POST",
        body: queryArg.verifyUserCommand,
      }),
      invalidatesTags: ["User"],
    }),
    postAddAdditionalInfo: builder.mutation({
      query: (queryArg) => ({
        url: `/api/User/AddAdditionalInfo`,
        method: "POST",
        body: queryArg.addAdditionalInfoCommand,
      }),
      invalidatesTags: ["User"],
    }),
    postAddTotp: builder.mutation({
      query: (queryArg) => ({
        url: `/api/User/AddTotp`,
        method: "POST",
        body: queryArg.addTotpCommand,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserByActivationIdQuery,
  usePostSignUpUserMutation,
  usePostVerifyUserMutation,
  usePostAddAdditionalInfoMutation,
  usePostAddTotpMutation
} = activationApi;
