import { usePostApiFileUploadMutation } from "../services/appcenterApi";

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

interface useUploadFileMutationProps {
  useMutationHook: any;
  transformValues: any;
  getUrl: any;
  transformExistingValues?: any;
}
export function useUploadFileMutation({
  useMutationHook,
  transformValues,
  getUrl,
  transformExistingValues,
}: useUploadFileMutationProps) {
  //TODO: How to get the error from upload file to 'main' form
  //upload files

  const [uploadFile] = usePostApiFileUploadMutation();

  //const []
  const [create, postApiObj] = useMutationHook();

  const combineFunction = async (values) => {
    console.log("Values:");
    console.log(values);

    const urlVal = getUrl(values);

    //Existing Image
    if (isValidHttpUrl(urlVal)) {
      return create(
        transformExistingValues ? transformExistingValues(values) : values
      );
    }

    if (urlVal == "" || !urlVal) return create(values);

    //const imgDataUri = values.addApplicationCommand.iconImageUrl.split(',')

    const data = await uploadFile({
      body: [
        {
          name: "not-required",
          data: urlVal.split(",").pop(),
          type: "image/png",
        },
      ],
    }).unwrap();

    //console.log('uploaded')
    //console.log(data)

    //delete values.updateApplicationCommand.iconImageUrl

    //Add icon key
    /*
    values.updateApplicationCommand["icon"] = {
      key: data[0].key,
      url: data[0].url
    }*/

    //values = transformValues(values, data)

    return create(transformValues(values, data));
  };

  return [combineFunction, postApiObj];
}
