import { createFormContext } from "@mantine/form";
import { z } from "zod";
import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";


interface ApiClientFormValues {
  clientName: string,
  clientId: string,
  clientSecrets: string,
  allowedGrantType: string,
  redirectUris: string[],
  postLogoutRedirectUris: string[],
  allowedScopes: string[],
  allowedCorsOrigins: string[],
  appHomePage: string,
  privacyPolicyLink: string,
  termOfServiceLink: string,
  supportEmail: string,
  developerEmail: string,
  frontChannelLogoutSessionRequired: boolean,
  frontChannelLogoutUri: string[],
  backChannelLogoutSessionRequired: boolean,
  backChannelLogoutUri: string[],
  allowOfflineAccess: boolean,
  allowAccessTokensViaBrowser: boolean,
  requireClientSecret: boolean,
  organizationId: string,
  accessTokenLifetime: number,

  logoUri: string,
  logoUriKey: string,
  backgroundImage: string,
  backgroundImageKey: string
}

const AllowedGrantTypes = ["authorization_code", "client_credentials"] as const;

const AuthCodeGrant = z.object({
  redirectUris: z.array(z.string())
})

const ApiClientFormSchema = z.object({
  clientName: z.string().trim().min(1),
  clientId: z.string().trim().min(1),
  allowedGrantType: z.enum(AllowedGrantTypes),
  //grant: z.discriminatedUnion("allowedGrantType", [
  //  z.object({ allowedGrantType: z.literal("authorization_code"), redirectUris: z.array(z.string()).nonempty() }),
  //  //z.object({ status: z.literal("client_credentials"), error: z.instanceof(Error) }),
  //])
});

const DifferentApiSchema = z.discriminatedUnion("allowedGrantType", [
  z.object({
      //...ApiClientFormSchema,
      clientName: z.string().trim().min(1),
      clientId: z.string().trim().min(1),
      allowedGrantType: z.literal("authorization_code"),
      redirectUris: z.array(z.string()).nonempty()
    }
  ),
  z.object({
      //...ApiClientFormSchema,
    clientName: z.string().trim().min(1),
    clientId: z.string().trim().min(1),
      allowedGrantType: z.literal("client_credentials"),
      //redirectUris: z.array(z.string()).nonempty()
    }
  ),
])

const apiClientFormInitialValues: ApiClientFormValues = {
  allowAccessTokensViaBrowser: false,
  allowOfflineAccess: false,
  allowedCorsOrigins: [],
  allowedGrantType: "authorization_code",
  allowedScopes: [],
  appHomePage: "",
  backChannelLogoutSessionRequired: false,
  backChannelLogoutUri: [],
  clientId: "",
  clientName: "",
  clientSecrets: undefined,
  developerEmail: "",
  frontChannelLogoutSessionRequired: false,
  frontChannelLogoutUri: [],
  organizationId: "",
  postLogoutRedirectUris: [],
  privacyPolicyLink: "",
  redirectUris: [],
  requireClientSecret: false,
  supportEmail: "",
  termOfServiceLink: "",
  accessTokenLifetime: 3600,
  logoUri: "",
  logoUriKey: "",
  backgroundImage: "",
  backgroundImageKey: ""

};

export const [ApiClientFormProvider, useApiClientFormContext, useApiClientNativeForm] = createFormContext<ApiClientFormValues>();

const ApiClientFormTransformation = (values) => {

  return {
    ...values,
    redirectUris: values.redirectUris.toString(),
    frontChannelLogoutSessionRequired: values.frontChannelLogoutUri.length > 0,
    frontChannelLogoutUri: values.frontChannelLogoutUri.toString(),
    backChannelLogoutSessionRequired: values.backChannelLogoutUri.length > 0,
    backChannelLogoutUri: values.backChannelLogoutUri.toString(),
    allowedCorsOrigins: values.allowedCorsOrigins.toString(),
    postLogoutRedirectUris: values.postLogoutRedirectUris.toString(),
    allowedScopes: values.allowedScopes.toString(),
    requireClientSecret: values.allowedGrantType !== 'authorization_code',
    //backgroundImage : ""

  }
}

export const ApiClientResponseTransformation = (values) => {

  console.log('ApiClientResponseTransformation values: ')
  console.log(values)

  return {
    ...values,
    allowedScopes: values.allowedScopes ? values.allowedScopes.split(",") : [],
    redirectUris: values.redirectUris ? values.redirectUris.split(",") : [],
    frontChannelLogoutUri: values.frontChannelLogoutUri ? values.frontChannelLogoutUri.split(",") : [],
    postLogoutRedirectUris: values.postLogoutRedirectUris ? values.postLogoutRedirectUris.split(",") : [],
    allowedCorsOrigins: values.allowedCorsOrigins ? values.allowedCorsOrigins.split(",") : [],
    backChannelLogoutUri: values.backChannelLogoutUri ? values.backChannelLogoutUri.split(",") : [],
    //code: values.organizationCode
    logoUri: values.logoUri.url ?? "",
    logoUriKey: values.logoUri.key ?? "",
    backgroundImage: values.backgroundImage.url ?? "",
    backgroundImageKey: values.backgroundImage.key ?? "",
  }
}

export function useApiClientForm() {
  return useApiClientNativeForm({
    initialValues: apiClientFormInitialValues,
    validate: useCustomZodResolver("apiClient", DifferentApiSchema),
    //validate: useCustomZodResolver("apiClient", ApiClientFormSchema),
    transformValues: ApiClientFormTransformation
    //validate: uploadImagesAfterValidation(useCustomZodResolver("apiClient", ApiClientFormSchema))
  });
}



