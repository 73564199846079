import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightToBracket,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Stack,
  Text,
  createStyles,
} from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";
import {
  PostApiApplicationSubcribeApplicationApiArg,
  PostApiApplicationUnsubcribeApplicationApiArg,
  usePostApiApplicationSubcribeApplicationMutation,
  usePostApiApplicationUnsubcribeApplicationMutation,
} from "shared-library/src/services/appcenterApi";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: 8,
  },

  lead: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1,
  },

  inner: {
    display: "flex",

    [theme.fn.smallerThan(350)]: {
      flexDirection: "column",
    },
    padding: theme.spacing.md,
  },

  ring: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",

    [theme.fn.smallerThan(350)]: {
      justifyContent: "center",
      marginTop: theme.spacing.md,
    },
  },
  section: {
    padding: theme.spacing.md,
    paddingBottom: 0,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  sectionTop: {
    padding: theme.spacing.md,
  },
}));

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function ApplicationCard({ item }) {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organizationId = useAppSelector((state) => state.user?.organization.id);

  const [
    subscribeApplication,
    {
      error: subscribeApplicationError,
      isLoading: subscribeApplicationIsLoading,
      isSuccess: subscribeApplicationIsSuccess,
      isError: subscribeApplicationIsError,
    },
  ] = usePostApiApplicationSubcribeApplicationMutation();

  const [
    unsubscribeApplication,
    {
      error: unsubscribeApplicationError,
      isLoading: unsubscribeApplicationIsLoading,
      isSuccess: unsubscribeApplicationIsSuccess,
      isError: unsubscribeApplicationIsError,
    },
  ] = usePostApiApplicationUnsubcribeApplicationMutation();

  const handleSubmit = async function (obj) {
    let promises: Promise<any>[] = [];
    if (obj.subcribed) {
      let payload: PostApiApplicationUnsubcribeApplicationApiArg = {
        unsubscribeAppCommand: {
          organizationId: organizationId,
          appId: obj.id,
        },
      };
      promises.push(unsubscribeApplication(payload));
    } else {
      let payload: PostApiApplicationSubcribeApplicationApiArg = {
        subscribeAppCommand: {
          organizationId: organizationId,
          appId: obj.id,
        },
      };
      promises.push(subscribeApplication(payload));
    }
    if (promises.length > 0) {
      await Promise.all(promises);
    }
  };

  return (
    <>
      <Card withBorder p="md" radius="md" className={classes.card}>
        <Stack justify={"space-between"} spacing={0} style={{ height: "100%" }}>
          <Grid mb={16}>
            <Grid.Col span={"auto"}>
              <Stack spacing={8}>
                <Text size="xl" className={classes.label}>
                  {item.name}
                </Text>

                <Text lineClamp={5} size="xs" color="dimmed">
                  {item.description}
                </Text>

                <Group position={"left"}>
                  {!item.enabled && (
                    <Badge color="grey" variant="outline">
                      {t("application.list.badge.disabled")}
                    </Badge>
                  )}
                  {item.preview && (
                    <Badge variant="outline">
                      {t("application.list.badge.preview")}
                    </Badge>
                  )}
                </Group>
              </Stack>
            </Grid.Col>
            <Grid.Col span={"content"}>
              <Image
                height={80}
                width={80}
                src={item.icon.url}
                alt={item.name}
              />
            </Grid.Col>
          </Grid>

          <Card.Section className={classes.section}>
            <Group spacing={16}>
              <Button
                disabled={!isValidHttpUrl(item.documentUrl)}
                variant={"subtle"}
                onClick={() => {
                  window.location.href = item.documentUrl
                    .replace(/\{orgid\}/gi, organizationId)
                    .replace(/\{organizationid\}/gi, organizationId);
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  style={{ marginRight: 5 }}
                />
                {t("application.card.learnMore")}
              </Button>

              {item.subcribed && (
                <Button
                  disabled={!isValidHttpUrl(item.appUrl)}
                  variant={"subtle"}
                  onClick={() => {
                    window.location.href = item.appUrl
                      .replace(/\{orgid\}/gi, organizationId)
                      .replace(/\{organizationid\}/gi, organizationId);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightToBracket}
                    style={{ marginRight: 5 }}
                  />
                  {t("application.card.goToApp")}
                </Button>
              )}

              <Button
                variant={item.subcribed ? "default" : "filled"}
                radius="xl"
                style={{ flex: 1 }}
                onClick={() => handleSubmit(item)}
              >
                {item.subcribed
                  ? t("application.card.unsubscribe")
                  : t("application.card.subscribe")}
              </Button>
            </Group>
          </Card.Section>
        </Stack>
      </Card>
    </>
  );
}
