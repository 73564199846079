import React, { useEffect, useRef, useState } from "react";
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import {
  Button,
  Center,
  Group,
  Image,
  Input,
  InputSharedProps,
  InputWrapperProps,
  Modal,
  Text,
  useInputProps
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { SliderWithButtons } from "./SliderWithButtons";
import AvatarEditor from "react-avatar-editor";

interface ImageUploadInputProps extends Partial<InputSharedProps>, Partial<InputWrapperProps>{

  value? : string,
  editorHeight: number | string,
  editorWidth: number | string,
  imageBorder: number | string,
  thumbHeight: number | string,
  thumbWidth: number | string,

  editorTitle?: string,
  inputUploadDescription?: string,
  inputReplaceDescription?: string,
  modalResizeDescription? : string,
  modalRotateDescription?: string,
  modalCancelDescription?: string,
  modalConfirmDescription?: string,

}

const defaultProps: Partial<ImageUploadInputProps> = {
  size: 'sm',
  imageBorder: 16,
  editorHeight: 480,
  editorWidth: 480,
  thumbHeight: 64,
  thumbWidth: 64
};

export function ImageUploadInput(props: ImageUploadInputProps) {

  //console.log('ImageUploadInputProps: ', props)
  const { value, inputProps, wrapperProps, ...others } = useInputProps('ImageUploadInput', defaultProps, props);


  const editor = useRef(null);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [image, setImage] = useState("");
  //const [editedImage, setEditedImage] = useState(value);


  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1.0);

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    files.map((file) => {

      setImage(URL.createObjectURL(file));
      setOpened(true);
    });

  }, [files]);

  useEffect(() => {    
    if (!others.thumbHeight) {
      others.thumbHeight = others.editorHeight;
    } 
    if (!others.thumbWidth) {
      others.thumbWidth = others.editorWidth;
    }
  }, []);

  return (<>
    <Input.Wrapper {...wrapperProps} >
      <Dropzone
        onDrop={setFiles}
        maxFiles={1}
        accept={IMAGE_MIME_TYPE}
        name={inputProps.id}
        multiple={false}

      >
        <Group position={"center"} spacing="xl" style={{ minHeight: 64, pointerEvents: "none" }}>
          <Dropzone.Idle>
            <FontAwesomeIcon icon={faImage} size={"2xl"} />
          </Dropzone.Idle>
          <div>
            <Text inline>
              {value === "" ? others.inputUploadDescription : others.inputReplaceDescription}
            </Text>
          </div>

        </Group>
        <Center>
          {
            value !== "" && <Image width={others.thumbWidth} height={others.thumbHeight} fit={"contain"} src={value} />
          }
          {/*
            inputProps.form.values[inputImageUrl] && inputProps.form.values[inputImageUrl] !== "" && editedImage == "" &&
            <Image width={inputProps.thumbWidth} height={inputProps.thumbHeight} fit={"contain"}
                   src={inputProps.form.values[inputImageUrl]} />
          */}

        </Center>

      </Dropzone>


    </Input.Wrapper>
    <Modal size={()=>(others.editorWidth + others.imageBorder)} onClose={() => {
      setFiles([]);
      setOpened(false);
    }} closeOnClickOutside={false} opened={opened} title={others.editorTitle}>

      <Center>

        {/* */}
        <AvatarEditor ref={editor} rotate={rotate} disableBoundaryChecks={false} border={16} scale={scale} height={props.editorHeight}
                      width={props.editorWidth}
                      image={image} />
      </Center>

      <SliderWithButtons label={others.modalResizeDescription} sliderLabel={(value) => value.toFixed(2)}
                         min={0.1}
                         max={1.9} step={0.01} value={scale} setValue={setScale} />

      <SliderWithButtons label={others.modalRotateDescription} sliderLabel={(value) => value.toFixed(0)}
                         min={-180}
                         max={180} step={1} value={rotate} setValue={setRotate} />

      <Group position="center" mt="md">

        <Button onClick={() => {
          setOpened(false);
        }} variant={"subtle"}>
          <Text tt={"capitalize"}>
            {others.modalCancelDescription}
          </Text>
        </Button>

        <Button onClick={() => {
          //save to preview
          if (editor) {
            const imgUrl = editor.current.getImageScaledToCanvas().toDataURL();
            //setEditedImage(imgUrl);
            others.onChange(imgUrl);
            //console.log('inputProps', inputProps);
            //console.log('wrapperProps', wrapperProps);
            //console.log('others', others);
            //console.log('value', value);
            //inputProps.setImage(imgUrl)
            //inputProps.form.setFieldValue(inputImageRaw, imgUrl);
            setOpened(false);


          }

        }}>{others.modalConfirmDescription}</Button>

      </Group>

    </Modal>
  </>);

}