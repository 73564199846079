import React from "react";
import {
  useGetApiApplicationGetApplicationQuery, useGetApiApplicationGroupGetApplicationGroupQuery,
  usePostApiApplicationGroupUpdateApplicationGroupMutation
} from "../../services/appcenterApi";
import { UpdateForm } from "../../components/UpdateForm";
import {
  ApplicationFormProvider,
  ApplicationResponseTransformation,
  useApplicationForm
} from "../../features/applications/ApplicationFormContext";
import { ApplicationInputs } from "../../features/applications/ApplicationInputs";
import { useUpdateApplicationMutation } from "../../features/applications/useUpdateApplicationMutation";
import {
  UserGroupFormProvider,
  UserGroupResponseTransformation,
  useUserGroupForm
} from "../../features/userGroups/UserGroupFormContext";
import { UserGroupInputs } from "../../features/userGroups/UserGroupInputs";


export function UpdateGroup() {
  return (
    <>
      <UpdateForm FormProvider={UserGroupFormProvider} inputs={<UserGroupInputs />} i18Prefix={"userGroup"}
                  //useUpdateMutation={usePostApiApplicationUpdateApplicationMutation}
                  transformResponse={UserGroupResponseTransformation}
                  useUpdateMutation={usePostApiApplicationGroupUpdateApplicationGroupMutation}

                  //useUpdateMutation={useUploadMutation}
                  useFormHook={useUserGroupForm}
                  commandKey={"updateApplicationGroupCommand"}
                  useLoadQuery={useGetApiApplicationGroupGetApplicationGroupQuery} />
    </>
  )
}