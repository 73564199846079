import { useTranslation } from "react-i18next";
import React from "react";
import { PasswordInput } from "@mantine/core";
import { useUsersPasswordFormContext } from "./UsersPasswordFormContext";

export function UsersPasswordInputs() {
    const form = useUsersPasswordFormContext()
    const {t} = useTranslation()

    return (
        <>
            <PasswordInput label={t("userPassword.newPassword")} disabled={false}
                           {...form.getInputProps('password')}
                            />

            <PasswordInput label={t("userPassword.confirmNewPassword")} disabled={false}
                           {...form.getInputProps('confirmPassword')}
                           />
        </>
    )
}