import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IconWithTooltipProps {
  text: any,
  icon: IconDefinition
}

export function IconWithTooltip(props: IconWithTooltipProps) {

  return (
    <>
      <Tooltip label={props.text}>
        <FontAwesomeIcon icon={props.icon} />
      </Tooltip>
    </>
  )

}