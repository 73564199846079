import * as React from "react";
import { useState } from "react";
import { useOrganizationFormContext } from "./OrganizationFormContext";
import { Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { UsersDataTable } from "../users/UsersDataTable";
import { OrganizationAdministratorDataTable } from "../organizations/organizationAdministratorDataTable";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetApiUserGetUsersQuery } from "../../services/appcenterApi";
import { useParams } from "react-router-dom";
import { useOrganizationAdministratorFormContext } from "./OrganizationAdministratorFormContext";
import { useAppSelector } from "../../hooks/reduxHelper";

export function OrganizationAdministratorInputs() {
  //Render inputs required for forms
  const form = useOrganizationAdministratorFormContext();
  const { t } = useTranslation();
  let orgId = null;  
  if (APPCENTER) {    
    orgId = useAppSelector((state) => state.user?.organization.id);
  }
  else {
    let { organizationId } = useParams();
    orgId = organizationId;
  }
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>("userFullName");
  const [isDesc, setIsDesc] = useState<boolean>(false);
  
  const query = useGetApiUserGetUsersQuery(
    orgId !== "" ?
      {
        organizationId: orgId,
        sortBy: sortBy,
        isDesc: isDesc,
        page: page,
        pageSize: pageSize,
        clientAdminOnly: true
      } : skipToken);

  return (
    <>
      <Text>{t("organization.description")}</Text>
      <OrganizationAdministratorDataTable
        tableHeight={'40vh'}
        isFetching={query.isFetching}
        sortBy={sortBy}
        setSortBy={setSortBy}
        isDesc={isDesc}
        setIsDesc={setIsDesc}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        totalRecords={query.isSuccess ? query.data?.total : 0}
        organizationId={orgId}
        //columns={columns}
        records={query.isSuccess ? query.data?.data : []}
      />

      <TextInput label={t("organization.email")}
                 withAsterisk
                 {...form.getInputProps("email")} />

    </>
  );
}