import React, { useContext, createContext } from 'react';

interface IMfeGlobalState {
    subscriptions: object;
    globalState: mfeSharedState;
    setGlobalState: (state: mfeSharedState) => void;
    getGlobalState: ()=> mfeSharedState;
    subscribeGlobalStateChanges: (id: string, callback: (state: mfeSharedState) => void) => mfeSharedState;
    unsubscribeGlobalStateChanges: (id: string) => void;
    invokeSubscriptions: () => void;
};

const MfeGlobalState: IMfeGlobalState = {
    subscriptions: {},
    globalState: {
        organizationId: null,
        token: null,
        userId: null,
        appcenterApiEndpoint:null
    },
    setGlobalState: function (state: mfeSharedState | any) {         
        console.debug("Mfe set global state called");        
        const self = this.MfeGlobalState;
        self.globalState = {...self.globalState,...state};
        self.invokeSubscriptions();
    }.bind(this),
    getGlobalState: function () {
        const self = this.MfeGlobalState;
        return self.globalState;
    }.bind(this),
    subscribeGlobalStateChanges: function (id, callback) {                 
        console.debug("Mfe subscribe global state called");
        const self = this.MfeGlobalState;              
        self.subscriptions[id] = callback;
        if (self.globalState) {
            return self.globalState;
        } else {
            return {};
        }
    }.bind(this),
    unsubscribeGlobalStateChanges: function (id) {
        const self = this.MfeGlobalState;
        delete this.subscriptions[id];
    }.bind(this),
    invokeSubscriptions: function () {
        const self = this.MfeGlobalState;        
        Object.keys(self.subscriptions).forEach((key) => {
            self.subscriptions[key](self.globalState);
        });
    }.bind(this)
};

const MfeGlobalStateContext = createContext(MfeGlobalState);

const GetMfeGlobalCtx = function () {
    return useContext(MfeGlobalStateContext);
}

const MfeGlobalStateProvider = function ({ children }) {
    return (
        <MfeGlobalStateContext.Provider value={MfeGlobalState}>
            {children}
        </MfeGlobalStateContext.Provider>
    )
}

export {MfeGlobalStateProvider, GetMfeGlobalCtx, MfeGlobalState}

