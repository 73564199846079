import React from "react";
import { ActivationForm } from "../../features/signup/ActivationForm";
import { usePostVerifyUserMutation } from "../../services/activationApi";
import { VerifyInputs } from "../../features/signup/VerifyInputs";
import { VerifyUserFormProvider, VerifyUserResponseTransformation, useVerifyUserForm } from "../../features/signup/VerifyUserFormContext";

export function Verify() {
  return (
    <>
      <ActivationForm FormProvider={VerifyUserFormProvider} inputs={<VerifyInputs />} useFormHook={useVerifyUserForm}
      transformResponse={VerifyUserResponseTransformation}
      useUpdateMutation={usePostVerifyUserMutation} i18Prefix={'verifyUser'} commandKey={'verifyUserCommand'} activationStatus={"Activation"} />
    </>
  );
}