import * as React from "react";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Anchor, Breadcrumbs, Button, Text, LoadingOverlay, List, Space } from "@mantine/core";
import { OrganizationUserAssignmentTransferList } from "../../features/organizations/OrganizationUserAssignmentTransferList";
import { useTranslation } from "react-i18next";
import { ContentShell } from "../../components/ContentShell";
import { usePostApiOrganizationAssignUsersToOrganizationMutation, usePostApiUserAssignUserMutation, usePostApiUserBatchUnassignUsersMutation, PostApiOrganizationAssignUsersToOrganizationApiArg, useGetApiOrganizationGetOrganizationQuery } from "../../services/appcenterApi";
import { OrganizationAssignUserChangeResponse, validationErrors} from "../../features/organizations/OrganizationUserAssignmentTransferList";
import { UsersAssignmentTransferList } from "../../features/users/UsersAssignmentTransferList";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../../hooks/reduxHelper";


export const OrganizationAssignUser = function () {    
    const userOrganizationId = useAppSelector((state) => state.user?.organization.id);
    const [deferFetch, setDeferFetch] = useState(true);
    const { organizationId } = useParams();    
    const [
        doUnassignUser,
        { error: unassignError,
            isLoading: unassignIsLoading,
            isSuccess: unassignIsSuccess,
            isError: unassignIsError }
    ] = usePostApiUserAssignUserMutation();

    const [
        doRequestAssignUser,
        { error: requestAssignError,
            isLoading: requestAssignIsLoading,
            isSuccess: requestAssignIsSuccess,
            isError: requestAssignIsError }
    ] = usePostApiOrganizationAssignUsersToOrganizationMutation();

    const [
        doBatchUnassignUsers,
        {
            error: batchUnassignuserError,
            isLoading: batchUnassignIsLoading,
            isSuccess: batchUnassignIsSuccess,
            isError: batchUnassignIsError
        }] = usePostApiUserBatchUnassignUsersMutation();
    // const organizationInfo = {};
    const {
        data: organizationInfo,
        error: organizationQueryError,
        isLoading: organizationInfoLoading,
        isSuccess: organizationInfoLoaded } = useGetApiOrganizationGetOrganizationQuery(
           {
                organizationId: organizationId
            }, { refetchOnMountOrArgChange: true, skip:deferFetch }
        );    
    
    
    const [isReady, setIsReady] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
    const [assignmentData, setAssignmentData] = useState<OrganizationAssignUserChangeResponse>(null);
    const [toReset, setToReset] = useState(false);
    const [didAssign, setDidAssign] = useState(false);
    const [didUnassign, setDidUnassign] = useState(false);
    const [errors, setErrors] = useState<validationErrors>();

    const validateErrors = function () {
        setErrors(null);
        if (assignmentData?.assignList.length > 0) {
            //check if selectedRole is present
            if (!assignmentData.selectedRole) {
                setErrors({ organizationRole: t("organizationAssignRequest.roleRequiredValidation") });
                return false;
            }            
        }
        return true;
    }
    const handleSubmit = async function () {
        if (!validateErrors()) { 
            return;
        }       
        setIsReady(false);
        let toAssign = assignmentData.assignList;
        let toUnassign = assignmentData.unassignList;
        let assignRole = assignmentData.selectedRole;

        let promises: Promise<any>[] = [];        

        if (toAssign.length > 0) {
            //call usual assign                        
            let payload: PostApiOrganizationAssignUsersToOrganizationApiArg = {
                createUserAssignmentApprovalCommand: {
                    organizationIds: [organizationId],
                    assignedRoleId: assignmentData.selectedRole,
                    users: toAssign.map(x => x.id)
                }
            }
            setDidAssign(true);
            promises.push(doRequestAssignUser(payload));
        }
        if (toUnassign.length > 0) {
            let payload = {
                batchUnassignUserCommand: {
                    userIds: toUnassign.map(x => x.id),
                    organizationId: organizationId
                }
            };
            setDidUnassign(true);
            promises.push(doBatchUnassignUsers(payload));
        }
        if (promises.length > 0) {
            await Promise.all(promises);
        }
        setIsReady(true);
    }

    const handleReady = function (result) {
        setIsReady(result);
    }

    const handleChange = function (result: OrganizationAssignUserChangeResponse) {
        setAssignmentData(result);
    }
    useEffect(function () {
        if (userOrganizationId) {            
            setDeferFetch(false);            
        }
    },[userOrganizationId])
    useEffect(function () {
        console.log("organization information is loaded");
    }, [organizationInfo]);

    useEffect(function () {        
        if (requestAssignIsSuccess && didAssign) {
            //notify       
            let title = t("organizationAssignRequest.notifications.requestAssignSuccessTitle", { organizationName : organizationInfo?.organizationName ?? ""});
            let body = t("organizationAssignRequest.notifications.requestAssignSuccessBody")
            let assignmentListComponents = <List>{assignmentData.assignList.map(x => <List.Item>{x.label}</List.Item>)}</List>;
            createNotify(title, body, assignmentListComponents);
            setToReset(true);                     
            setDidAssign(false);
        }
        if (batchUnassignIsSuccess && didUnassign) {
            //notify
            let title = t("organizationAssignRequest.notifications.unassignSuccessTitle", { organizationName: organizationInfo?.organizationName ?? "" });
            let body = t("organizationAssignRequest.notifications.unassignSuccessBody", { organizationName: organizationInfo?.organizationName ?? "" })
            let unassignListComponents = <List>{assignmentData.unassignList.map(x => <List.Item>{x.label}</List.Item>)}</List>;
            createNotify(title, body, unassignListComponents);
            setDidUnassign(false);
        }
        if (batchUnassignIsError && didUnassign) {
            //notify
            let title = t("organizationAssignRequest.notifications.unassignFailedTitle", { organizationName: organizationInfo?.organizationName ?? "" });
            let body = t("organizationAssignRequest.notifications.unassignFailedBody")
            let error = batchUnassignIsError;
            createNotify(title, body);
            setDidUnassign(false);
        }
        if (requestAssignError && didAssign) {
            //notify
            let title = t("organizationAssignRequest.notifications.requestAssignFailedTitle", { organizationName: organizationInfo?.organizationName ?? "" });
            let body = t("organizationAssignRequest.notifications.requestAssignFailedBody")
            let error = requestAssignIsError;
            createNotify(title, body);
            setDidAssign(false);
        }                
    }, [requestAssignIsSuccess, batchUnassignIsSuccess, batchUnassignuserError, requestAssignError]);

    useEffect(() => {
        if(errors != undefined && errors != null) {
            validateErrors();
        }
    }, [assignmentData]);

    //create notification
    const createNotify = function (title: string, message: string, extraComponents?: React.ReactNode) {
        showNotification({
            title: title,
            message:
                <>
                    <Text mt="md">{message}</Text>
                    {extraComponents}
                </>
        });
    }

    const computeButtonState = function () {
        if (!assignmentData) {
            return true;
        }        
        return !(assignmentData.assignList.length > 0 || assignmentData.unassignList.length > 0);
    }

    return (
        <>
            <ContentShell topSection={
                <>
                    <Breadcrumbs>
                        <Anchor component={Link} to={".."}>
                            {t("list.title", { context: "organization" })}
                        </Anchor>
                        <Anchor span={true} variant={"text"}>
                            {t("organizationAssignRequest.breadcrumbTitle", {organizationName: organizationInfo?.organizationName ?? ""})}
                        </Anchor>
                    </Breadcrumbs></>}

                bottomSection={<>
                    <Button onClick={() => navigate("..")} variant={"outline"} loading={!isReady}>
                        <Text tt={"capitalize"}>
                            {t("common.cancel")}
                        </Text>
                    </Button>
                    <Button disabled={computeButtonState()} type="submit" onClick={handleSubmit} loading={!isReady}>{t("common.submit.update")}</Button>
                </>
                }>
                <OrganizationUserAssignmentTransferList queryOrganizationId={organizationId} onReady={handleReady} onDataChange={handleChange} reset={toReset} errors={errors} />
                <LoadingOverlay visible={!isReady} />
            </ContentShell>
        </>
    )
}
function validateErrors() {
    throw new Error("Function not implemented.");
}

