import { createInstance } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const i18nInstance = createInstance();

i18nInstance
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    debug: false,
    ns: ["appCenter"],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    }
  });

i18nInstance.services.formatter.add('lowercase', (value, lng, options) => {
  return value.toLowerCase();
});

export default i18nInstance;

