import { createFormContext } from "@mantine/form";
import { z } from "zod";

import { useCustomZodResolver } from "shared-library/src/hooks/useCustomZodResolver";
import { SignUpUserCommand } from "shared-library/src/services/appcenterApi"


const SignUpUserFormSchema = z
  .object({
    userFullName: z.string().trim().min(1),
    email: z.string().email().optional().nullable().or(z.literal("")),
    password: z.string().trim().optional().nullable(), //.min(1),
    confirmPassword: z.string().trim().optional().nullable(),

  })

const signUpUserFormInitialValues: SignUpUserCommand = {

  userFullName: "",
  email: "",
  password: "",
  confirmPassword: ""
};

export const ApplicationResponseTransformation = (values) => {

  console.log('ApplicationResponseTransformation', values)
  let tempHolder = values.icon.url
  console.log('TempHolder', tempHolder)

  return {
    ...values,
    //icon: tempHolder
  }
}

export const SignUpUserResponseTransformation = (values) => {

  return {
    email: values.email,
    userFullName: values.userFullName
  }
}

export const [SignUpUserFormProvider, useSignUpUserFormContext, useSignUpUserNativeForm] =
  createFormContext<SignUpUserCommand>();

export function useSignUpUserForm() {
  return useSignUpUserNativeForm({
    initialValues: signUpUserFormInitialValues,
    validate: useCustomZodResolver("user", SignUpUserFormSchema),
  });
}
