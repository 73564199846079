import React from "react";
import { useTranslation } from "react-i18next";
import { useGetApiApplicationApplicationsQuery } from "shared-library/src/services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";
import { ApplicationCard } from "../../features/applications/ApplicationCard";
import { ContentShell } from "@ascentis/cx-reactjs-ui";
import { SimpleGrid, Text } from "@mantine/core";

export function ListApplications() {
  console.log("Mounting List Applications");
  const { t } = useTranslation();
  const organizationId = useAppSelector(
    // @ts-ignore
    (state) => state.user?.organization.id);

  const { data, isError, error, isLoading, isFetching } = useGetApiApplicationApplicationsQuery(
    organizationId !== "" ?
      {
        organizationId: organizationId,
        getHidden: false
      } : skipToken);

  console.log('Data')
  console.log(data)

  const items = data?.map((item) => item.enabled ? <ApplicationCard key={item.id} item={item} /> : null)
  return (
    <>
      <ContentShell bottomSection={<></>} topSection={<></>}>
        {(!isLoading && (!items || items.length < 1)) ?
            <Text >
              No Applications Found
            </Text>          :
            <SimpleGrid cols={3}>
              {!isError ? items : null}
            </SimpleGrid>
      }
      </ContentShell>
    </>
  );
}