import * as React from "react";
import { useEffect } from "react";
import { Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/reduxHelper";
import { ImageUploadInput } from "../../components/ImageUploadInput";
import { useUserOwnProfileFormContext } from "./UserOwnProfileFormContext";

export function UserOwnProfileInputs() {
  //Render inputs required for forms
  const form = useUserOwnProfileFormContext();
  const { t } = useTranslation();
  
  const orgCodeTextRef = React.useRef(null);
  const [orgCodeTextWidth, setOrgCodeTextWidth] = React.useState(0);
  const organizationCode = useAppSelector(
    (state) => state.user?.organization.code + "\\");
  React.useEffect(() => {
    setOrgCodeTextWidth(orgCodeTextRef.current.offsetWidth);
  }, [organizationCode]);

  const organizationId = useAppSelector(
    // @ts-ignore
    (state) => state.user?.organization.id);


  useEffect(() => {
    if (organizationId)
      form.setFieldValue('organizationId', organizationId)

  }, [organizationId])

  return (
    <>

      <TextInput label={t("user.userFullName")}
        withAsterisk={true}
        {...form.getInputProps("userFullName")} />

      <TextInput disabled={true} label={t("user.staffId")}
        icon={<Text ref={orgCodeTextRef} fz="sm" sx={{ paddingLeft: '12px'}}>{ organizationCode }</Text>} 
        iconWidth={orgCodeTextWidth}
        {...form.getInputProps("staffId")} />

      <TextInput label={t("user.mobileNumber")}
        {...form.getInputProps("mobileNumber")} />

      <TextInput label={t("user.email")}
        {...form.getInputProps("email")} />

      <ImageUploadInput label={t("user.image")} {...form.getInputProps("profilePicture")}
        editorHeight={480} editorWidth={480} thumbHeight={60} thumbWidth={60} />
    </>
  );
}