import {createFormContext} from '@mantine/form';
import {z} from "zod";
import {useCustomZodResolver} from "../../hooks/useCustomZodResolver";


interface OrganizationAdministratorFormValues {

  //id? : string,
  email: string,
  organizationId: string
}


export const OrganizationAdministratorFormInitialValues: OrganizationAdministratorFormValues = {
  email: '',
  organizationId: ''
}

export const OrganizationAdministratorFormSchema = z.object({
  email: z.string().trim().email(),

})

const TransformCreateOrganizationAdminRequest = (values)=> {
  return {
    ...values,
    organizationId: values.id
  }
}


export const [OrganizationAdministratorFormProvider, useOrganizationAdministratorFormContext, useOOrganizationAdministratorNativeForm] = createFormContext<OrganizationAdministratorFormValues>()
export function useOrganizationAdministratorForm () {
  return useOOrganizationAdministratorNativeForm(
    {
      initialValues: OrganizationAdministratorFormInitialValues,
      validate: useCustomZodResolver('organization', OrganizationAdministratorFormSchema),
      transformValues: TransformCreateOrganizationAdminRequest
    }
  )
}