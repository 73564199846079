import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useMemo } from "react";
import { useGetApiOrganizationAssignableUsersByOrgQuery, useGetApiRoleGetRolesQuery, useGetApiRoleGetChildRolesByNameOrIdQuery } from "../../services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { IconWithTooltip } from "../../components/IconWithTooltip";
import { faChevronLeft, faChevronRight, faFileCircleMinus, faFileCirclePlus, faFolderMinus, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../hooks/reduxHelper";
import { useNavbarHeight } from "../../hooks/useNavbarHeight";
import { faHourglassHalf, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import {
    TransferList,
    TransferListData,
    TransferListItemComponent,
    TransferListItemComponentProps,
    Group,
    Loader,
    Text,
    ThemeIcon,
    Select,
    Space,
    Box,
    Flex,
    Paper,
    List,
    MantineProvider,
    Anchor
}

    from "@mantine/core";

const ScopeTransferItemComponent: TransferListItemComponent = ({ data, selected }: TransferListItemComponentProps) => {
    return (
        <Group noWrap>
            {/* <Checkbox/> */}
            <div style={{ flex: 1 }}>
                <Text size="sm" weight={400}>
                    {data.label}
                </Text>
            </div>
        </Group>
    )
};

enum UserSource {
    Available = 1,
    Assigned,
    Pending
}

export type validationErrors = {
    organizationRole: string;
}

export type OrganizationAssignUserChangeResponse=
{
    assignList: any[],
    unassignList: any[],
    selectedRole: string
}

export function OrganizationUserAssignmentTransferList({
    queryOrganizationId,
    onReady,
    onDataChange,
    reset,
    errors
}) {
    const navHeight = useNavbarHeight();
    const organizationId = useAppSelector((state) => state.user?.organization.id);

    //assignable users by organization
    const {
        data: orgUserAssignData,
        error: assignedOrgsQueryError,
        isLoading: assignedOrgsLoading,
        isSuccess: assignedOrgsLoadSuccess } = useGetApiOrganizationAssignableUsersByOrgQuery(
            organizationId !== "" ? {
                organizationId: queryOrganizationId
            } : skipToken, { refetchOnMountOrArgChange: true }
        );

    //get roles
    const {
        data: allRoles,
        error: allRolesQueryError,
        isLoading: allRolesQueryLoading,
        isSuccess: allRolesQuerySuccess } = useGetApiRoleGetRolesQuery(organizationId !== "" ? {
            page: 1,
            pageSize: 9999,
            searchTerm: "client"
        } : skipToken, { refetchOnMountOrArgChange: true });    

    const { t } = useTranslation();
    const [transferListData, setTransferListData] = useState<TransferListData>([[], []]);
    const [pendingApprovals, setPendingApprovals] = useState([]);
    const [pendingApprovalComponents, setPendingApprovalComponents] = useState(null);
    const [toAssign, setToAssign] = useState([]);
    const [toUnAssign, setToUnAssign] = useState([]);
    const [hasAssignment, setHasAssignment] = useState(false);
    const [assignmentRoles, setAssignmentRoles] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);    

    useEffect(function () {
        if (assignedOrgsLoadSuccess) {
            //assignableUsers                    
            let assignableUsersData = orgUserAssignData.assignableUsers ?
                sortUserListByName(orgUserAssignData.assignableUsers.filter(x => !x.pendingAssignmentApproval))
                    .map(x => ({ value: x.id, label: x.fullName, source: UserSource.Available, ...x }))
                : [];

            //pending approval
            let pendingApprovalsData = orgUserAssignData.assignableUsers ?
                sortUserListByName(orgUserAssignData.assignableUsers
                    .filter(x => x.pendingAssignmentApproval))
                    .map(x => ({ value: x.id, label: x.fullName, source: UserSource.Pending, ...x })) : []

            //assignedUsers
            let assignedUsersData = orgUserAssignData.assignedUsers ?
                sortUserListByName(orgUserAssignData.assignedUsers)
                    .map(x => ({ value: x.id, label: x.fullName, source: UserSource.Assigned, ...x })) : [];

            setTransferListData([assignableUsersData, assignedUsersData]);
            setPendingApprovals(pendingApprovalsData);            

            if (onReady){
                onReady(true);
            }
        }
        if (allRolesQuerySuccess) {
            let roles = allRoles.data.filter(x=>!x.name.includes('Admin')).map(x => ({ value: x.id, label: x.name }));
            setAssignmentRoles(roles);
        }
        if (assignedOrgsQueryError || allRolesQueryError) {
            onReady(true);
        }
    }, [orgUserAssignData, assignedOrgsLoadSuccess, allRoles, allRolesQuerySuccess, assignedOrgsQueryError, allRolesQueryError]);

    const filter = (query, item) => {
        //console.log(item)        
        if (!query) return;
        return item.fullName.toLowerCase().includes(query.toLowerCase().trim())
    };

    useEffect(() => {
        setHasAssignment(false);
        //clear the other data too
        setSelectedRole(null);
        setToAssign([]);
        setToUnAssign([]);
    }, [reset])

    useMemo(() => {
        if (pendingApprovals.length > 0) {
            setPendingApprovalComponents(pendingApprovals.map((d) => <List.Item><Anchor component={Link} to={`/organizations/listApprovalRequest/${queryOrganizationId}/${d.assignmentRequestId}`} target="_blank">{d.label}</Anchor></List.Item>));
        }
    }, [pendingApprovals]);

    useMemo(() => {
        if (!onDataChange) {
            console.warn("no datachange handler found");
            return;
        }
        let dataChanged: OrganizationAssignUserChangeResponse = {
            assignList: toAssign,
            unassignList: toUnAssign,
            selectedRole: selectedRole
        }
        onDataChange(dataChanged);
    }, [toAssign, toUnAssign, selectedRole]);
    

    const handleTransferChange = function (values) {
        let available = values[0];
        let assigned = values[1];

        //Handle to unassign   
        let unassign = sortUserListByName(available.filter(x => x.source == UserSource.Assigned));

        //Handle to assign
        let assign = sortUserListByName(assigned.filter(x => x.source == UserSource.Available));

        setToUnAssign(unassign);
        setToAssign(assign);

        //Update display
        for (let i = 0; i < available.length; i++) {
            available[i].group = undefined;
            if (available[i].source == UserSource.Assigned) {
                available[i].group = t("organization.assignUser.unassignAction");
            }
        }

        for (let i = 0; i < assigned.length; i++) {
            assigned[i].group = undefined;
            if (assigned[i].source == UserSource.Available) {
                assigned[i].group = t("organization.assignUser.assignAction");
            }
        }
        setTransferListData([available, assigned]);

        if (assign.length > 0) {
            setHasAssignment(true);
        } else {
            setHasAssignment(false);
        }        
    }

    const handleRoleChange = async function (role) {        
        setSelectedRole(role);       
    }

    const sortUserListByName = function (arr) {
        if (arr.length < 2) {
            return arr;
        }
        let arrayForSort = [...arr]; //WhY?
        let sorted = arrayForSort.sort((a, b) => {
            let aCompare = a.fullName.toLowerCase();
            let bCompare = b.fullName.toLowerCase();
            if (aCompare < bCompare) {
                return -1;
            }
            if (aCompare > bCompare) {
                return 1;
            }
        });
        return sorted;
    }    

    return (
        <>
            <React.Suspense fallback={<Loader />}>
                <MantineProvider
                    inherit
                    theme={{
                        components: {
                            TransferList: {
                                styles: {
                                    transferListControl: {
                                        // display: "none"
                                    }
                                }
                            }
                        }
                    }}
                >
                    <Flex hidden={pendingApprovals.length < 1} justify="flex-end">
                        <Paper shadow="sm" p="md" withBorder>
                            <Group>
                                <ThemeIcon>
                                    <IconWithTooltip icon={faHourglassHalf} text={t('organizationAssignRequest.tooltip.pending')} />
                                </ThemeIcon>
                                <Text weight={500}>
                                    Pending Assignment Approval
                                </Text>
                            </Group>
                            <Space h="md" />
                            <List>
                                {pendingApprovalComponents}
                            </List>
                        </Paper>
                        <Space h="md" />
                    </Flex>
                    <TransferList
                        transferIcon={({ reversed }) => (!reversed ?
                            <IconWithTooltip icon={faChevronRight} text={t("users.addSelectedOrganizations")} /> :
                            <IconWithTooltip icon={faChevronLeft} text={t("users.removeSelectedOrganizations")} />)}
                        transferAllIcon={({ reversed }) => (!reversed ?
                            <IconWithTooltip icon={faChevronRight} text={t("users.addAllOrganizations")} /> :
                            <IconWithTooltip icon={faChevronLeft} text={t("users.removeAllOrganizations")} />)}
                        showTransferAll={false}
                        titles={[t("organization.assignUser.availableUserTitle"), t("organization.assignUser.assignedUserTitle")]}
                        value={transferListData}
                        onChange={handleTransferChange}
                        listHeight={320}
                    />
                    <Space h="md" />
                    {hasAssignment &&
                        <Box>
                            <Text weight={500}>Select Client Organization Role*</Text>
                            <Select
                                error={!!errors?.organizationRole ? errors.organizationRole : null}
                                placeholder="Select client organization role"
                                data={assignmentRoles}
                                onChange={handleRoleChange}></Select>
                        </Box>}

                </MantineProvider>
            </React.Suspense>
        </>
    )
}