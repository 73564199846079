import {
  Avatar,
  Button,
  Group,
  Menu,
  Modal,
  Select,
  Stack,
  Text,
  Title,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { Link, generatePath, useNavigate } from "react-router-dom";
import {
  changeOrganization,
  selectOrganizationId,
  selectOrganizations,
} from "shared-library/src/features/user/userSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "shared-library/src/hooks/reduxHelper";
import { useGetApiUserGetOwnProfileQuery } from "shared-library/src/services/appcenterApi";

const useStyles = createStyles((theme) => ({
  menu: {
    //border: "1px solid",
    borderRadius: theme.radius.md,
    padding: theme.spacing.xs,
    backgroundColor: "#CDCDCD",
  },
}));

export function UserProfileMenu() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  // @ts-ignore
  const name = useAppSelector((state) => state.user.name);
  const userId = useAppSelector((state) => state.user.id);
  const organization = useAppSelector((state) => state.user.organization);
  // @ts-ignore
  const profilePicture = useAppSelector((state) => state.user.picture);

  const organizationId = useAppSelector(selectOrganizationId);
  const organizations = useAppSelector(selectOrganizations);

  const organizationSelectData = organizations
    .filter(x=>x.id!=organizationId)
    .map((x) => ({ value: x.id, label: x.name }))
    .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

  const [selectedOrg, setSelectedOrg] = useState<string | null>(organizationId);

  const { data: profileData } = useGetApiUserGetOwnProfileQuery(
    organization.id !== "" ? { organizationId: organization.id } : skipToken
  );

  const navigate = useNavigate();

  const auth = useAuth();
  const signOut = () => {
    return auth.signoutRedirect();
  };

  const dispatch = useAppDispatch();
  const switchOrganization = () => {
    auth.signinRedirect({
      state: { location: location.pathname },
      acr_values: "organizationid:" + selectedOrg,
    });
  };

  return (
    <>
      <Menu width={280} position="bottom-end" transition="pop-top-right">
        <Menu.Target>
          <UnstyledButton>
            <Group spacing={"xs"} className={classes.menu}>
              <Title size="h5" title={organization?.name}>
                {organization?.name}
              </Title>

              <Avatar size={"md"} src={profilePicture} alt={name} radius="xl" />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component={Link} to={"/user"}>
            <Group noWrap>
              <Avatar src={profilePicture} alt={name} size={94} radius="md" />
              <div>
                <Text size="lg" weight={500}>
                  {name}
                </Text>

                <Text
                  size="xs"
                  sx={{ textTransform: "uppercase" }}
                  weight={700}
                  color="red"
                >
                  {profileData?.roles.map((x) => x.name).toString()}
                </Text>
                <Text
                  size="xs"
                  sx={{ fontStyle: "italic" }}
                  weight={500}
                  color="dimmed"
                >
                  {organization?.name}
                </Text>
              </div>
            </Group>
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item onClick={() => setOpened(true)}>
            {t("menu.userProfile.switchOrganization")}
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item
            component={Link}
            to={generatePath("user/changePassword", { id: "self" })}
          >
            {t("menu.userProfile.changePassword")}
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={generatePath("user", { userId: userId })}
          >
            {t("menu.userProfile.updateProfile")}
          </Menu.Item>
          <Menu.Item
            component={Link} to={"/user/viewLoginActivity"}>
            View my login activity
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={signOut}>
            {t("menu.userProfile.signOut")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={t("modal.switchOrganization.title")}
        centered
      >
        <Stack>
          <Select
            label={t("modal.switchOrganization.organization")}
            onChange={setSelectedOrg}
            value={selectedOrg}
            data={organizationSelectData}
            placeholder={organizationSelectData.length < 1 ? t("modal.switchOrganization.selectPlaceholderEmpty") : t("modal.switchOrganization.selectPlaceholder")}
            disabled={organizationSelectData.length<1}
          />

          <Button onClick={switchOrganization}>
            {t("modal.switchOrganization.confirm")}
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
