import * as React from "react";
import { CreateForm } from "../../components/CreateForm";
import { UserFormProvider, useUserForm } from "../../features/users/UserFormContext";
import { UserInputs } from "../../features/users/UserInputs";
import { useCreateUserMutation } from "../../features/users/useCreateUserMutation";

export function CreateUser() {

  return (
    <>
      <CreateForm FormProvider={UserFormProvider} inputs={<UserInputs />} i18Prefix={"users"}
                  useCreateMutation={useCreateUserMutation} useFormHook={useUserForm}
                  commandKey={"addUserCommand"} />
    </>
  );

}