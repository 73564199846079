//Usage in RouterProvider to return Menus in side menus

//enhance RouteObject with custom props, to generate 2 level menus.
//To promote reuse
import { UNSAFE_DataRouterContext } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";


function breadthFirst(roots) {

  const { t } = useTranslation();

  //console.log('what is t?', t)

  //Only support 2 levels menus
  const queue = [...roots];
  const visited = new Set();

  const result = {};

  while (queue.length > 0) {

    const current = queue.shift();

    if (!visited.has(current)) {
      visited.add(current);

      if (current.isMenu) {

        //console.log('menu', current.isMenu)
        const leaf = {
          label: current.isMenu.label ?? t("list.title", {context : current.isMenu.context, count: 2}),
          link: (current.parent && current.parent.length>0) ? "/" + current.parent.join("/") + "/" + current.path : "/" + current.path
        };

        if (current.isMenu.parent) {

          let parent = result[current.isMenu.parent];

          if (!parent)
            parent = {
              //label: t(current.isMenu.parent),
              label: t("menu.level1", {context : current.isMenu.parent}),
              items: []
            };

          parent.items.push(leaf);
          result[current.isMenu.parent] = parent;

        } else {
          result[current.isMenu.label] = leaf;
        }
      }

      //callback(current)
      //result.push(current.path)

      current.children?.forEach((child) => queue.push({
        ...child,
        parent: (current.parent ?? []).concat(current.path ?? [])
      }));
    }
  }

  return result;
}

export function useRouterMenuResolver() {
  //no side effect to router context, as this menu is read-only
  const ctx = React.useContext(UNSAFE_DataRouterContext);

  const routes = ctx.router.routes;

  const result = breadthFirst(routes);

  return Object.values(result);

}