import { Button, Group, NotificationProps, Stack, Text, useComponentDefaultProps } from "@mantine/core";
import * as React from "react";
import { useEffect } from "react";
import { ContextModalProps } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";

export interface ConfirmationAPIModalProps {


  confirmationText: string,

  useMutationHook: any,
  buttonConfirm: string,
  buttonCancel: string,
  recordId: any,
  customConfirmRecord?: any,
}

const defaultProps: Partial<ConfirmationAPIModalProps> = {


  buttonConfirm: "Default Confirm",
  buttonCancel: "Default Cancel"
};

export function ConfirmationContextModal({ context, id, innerProps }: ContextModalProps<{

  confirmationText: string,
  useMutationHook: any,
  buttonConfirm: string,
  buttonCancel: string,
  recordId: any,
  customConfirmRecord?: any,

  successHandler?: any
  errorHandler?: any

}>) {

  const props = useComponentDefaultProps("ConfirmationContextModal", defaultProps, innerProps);


  const [update, {
    data: updateResult,
    error,
    isLoading,
    isSuccess,
    isError
  }] = innerProps.useMutationHook();

  useEffect(() => {

    if (isSuccess) {
      //setSubmitError(false)

      if (props.successHandler)
        props.successHandler(context, id, updateResult)

      context.closeModal(id);
    }

  }, [isSuccess]);

  useEffect(() => {

    if (isError) {
      
      console.log(error);
      if (props.errorHandler)
        props.errorHandler(context, id, error)

      context.closeModal(id);
    }

  }, [isError]);


  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    //typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
    //typeof onCancel === 'function' && onCancel();
    //closeOnCancel && ctx.closeModal(id);
    context.closeModal(id);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    //typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
    //typeof onConfirm === 'function' && onConfirm();
    //closeOnConfirm && ctx.closeModal(id);

    if(!props.customConfirmRecord) {
      update({
        id: props.recordId
      });
    } else {
      update({
        ...props.customConfirmRecord
      });
    }

    //context.closeModal(id);
  };

  return <>
    <Stack>

      <Text size="md">{props.confirmationText}</Text>

      <Group position="right">
        <Button variant="subtle" onClick={handleCancel}>
          {props.buttonCancel}
        </Button>

        <Button loading={isLoading} onClick={handleConfirm}>
          {props.buttonConfirm}
        </Button>
      </Group>

    </Stack>

  </>;
}