import { faEraser, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, redirect, useNavigate } from "react-router-dom";

import { openDeleteModal } from "./openDeleteModal";

type LabelContextMenu = {
  key: string;
  label: string;
  color?: string;
};

type ActionContextMenu = {
  key?: string;
  title?: any;
  icon?: any;
  onClick?: any;
  color?: string;
  disabled?: boolean | ((record: any) => boolean);
};

type RowContextMenu = LabelContextMenu | ActionContextMenu;

interface createRowContextMenuProps {
  i18Context: string;
  record: any;  
  useDeleteMutation?: any;
  recordId?: string;
  confirmationValue?: string;
  labelOverride?: LabelContextMenu;
  editOverride?: RowContextMenu;
  deleteOverride?: RowContextMenu;
  viewUpdateHistoryOverride?: RowContextMenu;
  auditExclusion?: string;
  disabled?:boolean
}

//Helper method to create standard row actions as this one quite generic
export function createStandardRowContextMenu(props: createRowContextMenuProps) {
  const { t } = useTranslation();
  const i18Prefix = props.i18Context;
  const navigate = useNavigate();

  const recordId = props.recordId ?? props.record.id;
  const confirmationValue = props.confirmationValue ?? props.record.name;

  const labelItem = {
    ...{
      key: "label",
      label: props.record.name ?? "-",
    },    
    ...props.labelOverride,
  };

  const editAction = {
    ...{
      key: "edit",
      title: t("list.edit", { context: i18Prefix, data: props.record }),
      icon: faPenToSquare,
      onClick: () => {
        navigate(generatePath("edit/:id", { id: recordId }));
      },
    },    
    ...props.editOverride,
  };

  const deleteAction = {
    ...(props.useDeleteMutation
      ? {
          key: "delete",
          title: t("list.delete", { context: i18Prefix, data: props.record }),
          color: "red",
          icon: faEraser,          
        onClick: () => {
            openDeleteModal({
              confirmationValue: confirmationValue,
              recordId: recordId,
              data: props.record,
              useDeleteMutation: props.useDeleteMutation,
              i18prefix: i18Prefix,
            });
          },
        }
      : {}),
    ...props.deleteOverride,
  };

  const viewUpdateHistoryAction = {
    ...{
      key: "viewUpdateHistory",
      title: t("list.viewUpdateHistory", { context: i18Prefix, data: props.record }),
      icon: faPenToSquare,
      onClick: () => {
        navigate(generatePath("/audit/:context/:id/:exclusions?", { context: i18Prefix, id: recordId, exclusions: props.auditExclusion}));
      },
    },
    ...props.viewUpdateHistoryOverride,
  }

  const actions: RowContextMenu[] = [];
  if (props.disabled) {
    return actions;
  }

  if (props.labelOverride !== null) {
    actions.push(labelItem);
  }

  if (props.editOverride !== null) {
    actions.push(editAction);
  }

  if (props.viewUpdateHistoryOverride !== null) {
    //only for organization and user
    if (i18Prefix === "organization" || i18Prefix === "users")
      actions.push(viewUpdateHistoryAction);
  }

  if (props.deleteOverride !== null) {
    actions.push(deleteAction);
  }

  return actions;
}
