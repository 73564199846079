import { Badge, BadgeProps } from "@mantine/core";
import React from "react";
import { v4 as uuidv4 } from "uuid";

interface BadgeListProps {
  values: string[];
  badge?: BadgeProps;
}

export function BadgeList(props: BadgeListProps) {
  const nodes = props.values.map((each, index) => (
    <Badge
      key={`badgeList-${uuidv4()}`}
      variant={"light"}
      color={"blue"}
      size={"sm"}
      mb={"sm"}
      mr={index != props.values.length - 1 ? "sm" : 0}
      {...props.badge}
    >
      {each}
    </Badge>
  ));

  return <>{nodes}</>;
}
