import {
  fa2,
  faKey, faLock,
  faPaperPlane,
  faPenToSquare, faUnlock
} from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, Stack, Text, Tooltip } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";

import { BadgeList, HoverPopoverWithBadgeList } from "../../components";
import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import { openConfirmationModal } from "../../hooks/openConfirmationModal";
import { openDeleteModal } from "../../hooks/openDeleteModal";
import {
  useDeleteApiUserDeleteUserMutation,
  usePatchApiUserResendActiveEmailMutation,
  usePatchApiUserResendTotpEmailMutation, usePatchApiUserResendVerifyEmailMutation,
  usePatchApiUserSendResetPasswordMutation, usePatchApiUserUpdateUserInactivityLockStatusMutation,
  usePostApiUserRemoveOrganizationAdminMutation
} from "../../services/appcenterApi";

interface OrganizationAdminCxDataTableProps extends CxDataTableProps {
  organizationId: string;
}
export function OrganizationAdministratorDataTable(
  props: OrganizationAdminCxDataTableProps
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationId = props.organizationId;

  const i18Prefix = "users";

  const columns = [
    {
      accessor: "userFullName",
      title: t("user.userFullName"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "staffId",
      title: t("user.staffId"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "email",
      title: t("user.email"),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: "userRole",
      title: t("user.roles"),
      width: 300,
      render: ({ userRole }) => {
        if (!userRole) {
          return <></>;
        }

        let userRoleArr = userRole.split(",").filter((x) => x !== "");

        if (userRoleArr.length == 0) {
          return <></>;
        }

        if (userRoleArr.length <= 3) {
          return <BadgeList values={userRoleArr} />;
        }

        return (
          <HoverPopoverWithBadgeList
            display={
              <>
                <BadgeList values={userRoleArr.slice(0, 3)} /> ...
              </>
            }
            popoverDisplay={userRoleArr}
            popover={{
              position: "bottom-start",
              width: 300,
              shadow: "0 0 20px -8px",
              withArrow: true,
            }}
          />
        );
      },
    },
    {
      accessor: "status",
      title: t("user.status"),
      sortable: true,
      ellipsis: true,
      width: 200,
      render: ({ status, isUserInactivityLockOn }) => {
        if (isUserInactivityLockOn) {
          return (
            <>
              <Tooltip label={status}>
                <span style={{ marginRight: 8 }}>{status}</span>
              </Tooltip>
              <Tooltip label={t("user.inactivityLockStatus_locked")}>
                <FontAwesomeIcon icon={faLock} />
              </Tooltip>
            </>
          );
        } else {
          return (
            <>
              <Tooltip label={status}>
                <span>{status}</span>
              </Tooltip>
            </>
          );
        }
      }
    },
  ];

  const rowContextMenu = {
    items: (record) => {
      let ctxMenu = createStandardRowContextMenu({
        i18Context: i18Prefix,
        record: { ...record, name: record.userFullName },
        useDeleteMutation: usePostApiUserRemoveOrganizationAdminMutation,
        editOverride: null,
        deleteOverride: {
          title: "Remove Administrator",
          onClick: () => {
            const deleteData = {
              userId: record.id,
              organizationId: organizationId,
              name: record.userFullName,
            };
            openDeleteModal({
              title: t("organization.clientAdministrator.delete.title"),
              confirmation: t(
                "organization.clientAdministrator.delete.description",
                { data: deleteData }
              ),
              confirmationValue: t(
                "organization.clientAdministrator.delete.confirmValue"
              ),
              recordId: record.id,
              data: deleteData,
              i18prefix: i18Prefix,
              useDeleteMutation: usePostApiUserRemoveOrganizationAdminMutation,
              customDeleteRecord: {
                removeOrganizationAdminCommand: deleteData,
              },
              successHandler: function (context, id, updateResult) {
                showNotification({
                  title: t("organization.clientAdministrator.delete.title"),
                  message: t("organization.clientAdministrator.delete.success"),
                });
              },
              errorHandler: function (context, id, error) {
                showNotification({
                  icon: (
                    <FontAwesomeIcon size="lg" icon={faCircleExclamation} />
                  ),
                  color: "red",
                  title: t("organization.clientAdministrator.delete.title"),
                  message: t("organization.clientAdministrator.delete.fail", {
                    data: error.data?.message,
                  }),
                });
              },
            });
          },
        },
        viewUpdateHistoryOverride: {
          onClick: () => {
            navigate(generatePath("/audit/:context/:id/:exclusions?/:userOrganizationId?", { context: i18Prefix, id: record.id, exclusions: "LoginSession", userOrganizationId: organizationId }));
          },
        }
      }).toSpliced(
        2,
        0,

        {
          key: "unlockInactiveUser",
          title: t("list.unlockInactiveUser"),
          icon: faUnlock,
          disabled: !record.isUserInactivityLockOn,
          onClick: () => {
            openConfirmationModal({
              data: record,
              i18prefix: "unlockInactiveUser",
              useMutationHook: usePatchApiUserUpdateUserInactivityLockStatusMutation,
              customConfirmRecord:{
                userId: record.id,
                organizationId: organizationId,
                isUserInactivityLockOn: !record.isUserInactivityLockOn
              }
            });
          },
        });
      return ctxMenu;
    },
  };

  return (
    <>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
