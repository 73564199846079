import { createFormContext } from "@mantine/form";
import { z } from "zod";
import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";

interface UserGroupFormValues {
  name: string,
  description: string,
  organizationId: string,
  applicationId: string,
  type: string

}

const UserGroupFormSchema = z.object({
  name: z.string().trim().min(1),
  description: z.string().trim().min(1),
  //organizationId: z.string().trim().min(1),
  applicationId: z.string().trim().min(1)
});

const userGroupFormInitialValues: UserGroupFormValues = {
  name: "",
  description: "",
  organizationId: "",
  applicationId: "",
  type: "Custom"
};

export const [UserGroupFormProvider, useUserGroupFormContext, useUserGroupNativeForm] = createFormContext<UserGroupFormValues>();

const UserGroupFormTransformation = (values) => {

  return {
    ...values,
    //applicationId: values.appplicationId ?? '00000000-0000-0000-0000-000000000000',
    organizationId: values.organizationId ?? '00000000-0000-0000-0000-000000000000'
  }
}

export const UserGroupResponseTransformation = (values) => {

  return {
    ...values,
    applicationId: values.application?.id
  }
}
export function useUserGroupForm() {
  return useUserGroupNativeForm(
    {
      initialValues: userGroupFormInitialValues,
      //validate: translateValidationErrorMessages(zodResolver(ScopeFormSchema)),
      validate: useCustomZodResolver("userGroup", UserGroupFormSchema),
      transformValues: UserGroupFormTransformation,

    }
  );
}

