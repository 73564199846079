import { Anchor, Breadcrumbs, Code, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import * as React from "react";
import { Trans, Translation, useTranslation } from "react-i18next";

import { UpdateForm } from "../../components/UpdateForm";
import {
  UserOwnProfileFormProvider,
  UserProfileResponseTransformation,
  useUserOwnProfileForm,
} from "../../features/user/UserOwnProfileFormContext";
import { UserOwnProfileInputs } from "../../features/user/UserOwnProfileInputs";
import { useUpdateUserOwnProfileMutation } from "../../features/user/useUpdateUserOwnProfileMutation";
import { useWithCurrentOrganization } from "../../hooks/useWithCurrentOrganization";
import { useGetApiUserGetOwnProfileQuery } from "shared-library/src/services/appcenterApi";
import { useAppSelector } from "../../hooks/reduxHelper";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export function UpdateUserSelf() {
  const { t } = useTranslation();
  const access_token = useAppSelector((state) => state.user?.access_token);
  //useGetUserInfoQuery(access_token !== "" ? {} : skipToken);
  const userProfile = useWithCurrentOrganization(useGetApiUserGetOwnProfileQuery)();  
  const postCallbackFn = function () {
    userProfile.refetch();
  }
  return (
    <>
      <UpdateForm
        FormProvider={UserOwnProfileFormProvider}
        inputs={<UserOwnProfileInputs />}
        i18Prefix={"user"}
        postUpdateCallback={postCallbackFn}
        useUpdateMutation={useUpdateUserOwnProfileMutation}
        useFormHook={useUserOwnProfileForm}
        commandKey={"updateOwnProfileCommand"}
        transformResponse={UserProfileResponseTransformation}
        useLoadQuery={useWithCurrentOrganization(useGetApiUserGetOwnProfileQuery)}
        topSection={
          <Breadcrumbs>
            <Anchor span={true} variant={"text"}>
              {t("update.title", { context: "userProfile", count: 1 })}
            </Anchor>
          </Breadcrumbs>
        }
        preSubmission={(form, values, next) => {
          if (form.isDirty("email")) {
            openConfirmModal({
              title: t("common.confirmation"),
              children: (
                <Text>
                  <Trans i18nKey={"update.emailUpdateConfirmation"}>
                    We have detected changes on the <Code>email</Code>. Please
                    confirm that this changes will required email re-validation
                    before the user next login.
                  </Trans>
                </Text>
              ),
              labels: {
                confirm: t("common.confirm"),
                cancel: t("common.cancel"),
              },
              onConfirm: next,
            });
          } else {
            return true;
          }
        }}
      />
    </>
  );
}
