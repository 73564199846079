import React, { useState, useEffect, useRef, useCallback } from "react";

const useScrollHook = function(scrollContainer:any, targetRef: any, scrollThresholdPct: number)
{    
    const [scrollInitialized, setScrollInitialized] = useState(false);
    const [scrollTargetHit, setScrollTargetHit] = useState(0);
    const scrollTargetHitRef = useRef(0);
    const scrollStop = useRef(false);
    const timeoutId = useRef(-1);
    
    //assumes parent is the scroll container
    // const scrollContainer = targetRef?.current?.parentElement.parentElement;    
    let parentbb = scrollContainer?.getBoundingClientRect();

    if (scrollContainer == null) {
        console.warn(`useScrollHook: Element ${targetRef?.current} has no scroll container parent`);        
    } 

    const stopScrollEvents = function () {        
        if (scrollStop.current != null) {
            scrollStop.current = true;
            if (scrollContainer != null) {
                scrollContainer.removeEventListener('scroll', scrollHandler);                
            }
        }
    }        

    const hasScrollBar = function () {
        let parentbb = scrollContainer?.getBoundingClientRect();
        let elementbb = targetRef.current.getBoundingClientRect();
        let elementHeight = elementbb.height;
        let scrollableDist = Math.max(elementHeight - parentbb.height, 0);
        let currScrollPct = Math.min((parentbb.top - elementbb.top) / scrollableDist, 1) * 100;    
        if (isNaN(currScrollPct) && scrollableDist == 0) {
            return false;
        }
        return true;        
    }

    const scrollHandler = useCallback(
        () => {               
            if (timeoutId.current != -1) {
                return;
            }
            timeoutId.current = setTimeout(() => {
                let parentbb = scrollContainer?.getBoundingClientRect();
                let elementbb = targetRef.current.getBoundingClientRect();
                let elementHeight = elementbb.height;
                let scrollableDist = Math.max(elementHeight - parentbb.height, 0);
                let currScrollPct = Math.min((parentbb.top - elementbb.top) / scrollableDist, 1) * 100; 
                if (currScrollPct > scrollThresholdPct) {
                    scrollTargetHitRef.current += 1;
                    setScrollTargetHit(scrollTargetHitRef.current);
                }
                timeoutId.current = -1;
            }, 100);
        }
    ,[scrollContainer, targetRef]);

    useEffect(() => {
        if (scrollContainer != null && !scrollInitialized) {
            scrollContainer.addEventListener('scroll', scrollHandler);
            window.addEventListener('resize', scrollHandler);
            //run it once because user window might be very tall and scroll is not triggered.
            scrollHandler();
            setScrollInitialized(true);
        }
    });
    return { scrollTargetHit, stopScrollEvents, hasScrollBar};
}

export { useScrollHook };