import { Avatar, Text } from "@mantine/core";
import React from "react";

export interface MicroAppAvatarProps {
    color: string,
    initials: string
}

export const MicroAppAvatar = function ({ color, initials,selected=false, ...props }) {
    const getLuminance = function (rgbaIn) {
        const rgba = rgbaIn

        let r = rgba.r / 255;
        let g = rgba.g / 255;
        let b = rgba.b / 255;

        let R = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
        let G = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
        let B = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

        let luminance = 0.2126 * R + 0.7152 * G + 0.0722 * B;
        return luminance;
    }

    const hexToRgba = function (hex: string) {
        if (!hex.startsWith("#")) {
            hex = "#" + hex;
        }
        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);
        let a = hex.length === 9 ? parseInt(hex.slice(7, 9), 16) / 255 : 1;
        return { r, g, b, a };
    }

    function hslToHex({ h, s, l }) {
        h = h * 360;
        s = s * 100;
        l = l * 100;
      
        let c = (1 - Math.abs(2 * l / 100 - 1)) * s / 100,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l / 100 - c / 2,
            r = 0,
            g = 0,
            b = 0;
      
        if (0 <= h && h < 60) {
          r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
          r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
          r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
          r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
          r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
          r = c; g = 0; b = x;
        }
      
        r = Math.round((r + m) * 255).toString(16).padStart(2, '0');
        g = Math.round((g + m) * 255).toString(16).padStart(2, '0');
        b = Math.round((b + m) * 255).toString(16).padStart(2, '0');
      
        return "#" + r + g + b;
      }

    const computeBackgroundColor = function (fgColor) {
        let fgRgb = hexToRgba(fgColor);
        let hsl = rgbToHsl(fgRgb);
        let luma = getLuminance(fgRgb);
        const threshold = 0.25;
        let bgHsl = {h:hsl.h, s:hsl.s, l:hsl.l};
        if (luma > threshold) {
            //use a darker background
            bgHsl.l = 0.14;
        }
        else {            
            bgHsl.l = 0.8;            
        }
        bgHsl.s *= 0.8;
        let bgHex = hslToHex(bgHsl);
        let result = { color: fgColor, backgroundColor: bgHex };
        return result;
    }

    const rgbToHsl = function (rgbColor) {
        let r = rgbColor.r / 255;
        let g = rgbColor.g / 255;
        let b = rgbColor.b / 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return { h, s, l };
    }    
    const setSelectedLook = function () {
        if (selected) {
            return { border: `3px solid ${color}` };
        } else {
            return { border: "none" };
       }
    }    
    return (
        <>
            <Avatar radius={props?.radius ?? "xs"} size={props?.size ?? 75} styles={{
                placeholder: {
                     ...setSelectedLook(), 
                    ...computeBackgroundColor(color)                    
                }                
            }}
                sx={{
                    '&:hover': {
                        transform: 'translateZ(0) scale(1.0)'
                    },
                    '&:active': {
                        transform:'scale(0.8)'  
                    },
                    transition: '0.4s all cubic-bezier(0.19, 1, 0.22, 1)',                    
                    transform:'scale(0.92)'
                }}
            >
                <Text fw="600">{initials[0].toLowerCase()}</Text><Text fw="200">{initials.substring(1)}</Text>
            </Avatar>            
        </>
    )
}
