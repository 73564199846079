import {
  Anchor,
  Breadcrumbs,
  Button,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import * as React from "react";
import { ComponentType, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { Link, useNavigate, useParams } from "react-router-dom";

import { selectAccessToken, selectOrganizationId } from "../features/user/userSlice";
import { useAppSelector } from "../hooks/reduxHelper";
import { ContentShell } from "./ContentShell";
import { FormErrorAlert } from "./FormErrorAlert";

type EditSecurityPolicyFormProps = {
  FormProvider: ComponentType<any>;
  inputs: ReactElement<any>;
  useFormHook: any;
  useUpdateMutation: any;
  postUpdateCallback?: Function;
  useLoadQuery: any;
  //includeOrgIdInQuery: boolean;
  i18Prefix: string;
  commandKey: string;
  transformResponse?: any;
  topSection?: any;
  preSubmission?: (form: any, values: any, next: () => void) => boolean;
  hideTopSection?: boolean;
  hideBottomSection?: boolean;
};

export const UpdateSecurityPolicyForm = ({
  useFormHook,
  useUpdateMutation,
  postUpdateCallback,
  useLoadQuery,
  //includeOrgIdInQuery = false,
  i18Prefix,
  FormProvider,
  inputs,
  commandKey,
  transformResponse,
  topSection,
  preSubmission,
  hideTopSection,
  hideBottomSection,
}: EditSecurityPolicyFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();  
  const [queryData, setQueryData] = useState(null);

  const accessToken = useAppSelector(selectAccessToken);
  
  const {
    data,
    error: queryError,
    isLoading: queryIsLoading,
    isSuccess: queryIsLoaded,
  } = useLoadQuery(params && accessToken ? params : skipToken);

  const form = useFormHook();
  const formId = useId();
  //const [triggerFormResetDirty, setTriggerFormResetDirty] = React.useState(false);

  const [
    update,
    {
      //data: updateResult,
      error,
      isLoading,
      isSuccess,
      isError,      
    },
  ] = useUpdateMutation();

  const handleSubmit = (values) => {
    const submitAction = () => {
      update({ [commandKey]: { ...values, id: data.id } });
    };
    // if (preSubmission?.(form, values, submitAction) ?? true) {
    //   submitAction();
    // }
    if (preSubmission?.(form, values, submitAction) ?? true) {
      navigate("../listAffectedUsers",{ state: { ...values, id: data.id } })
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (postUpdateCallback) {
        var returnObj = postUpdateCallback();
        if(returnObj != undefined){
          return;
        }
      }
      //setSubmitError(false)
      showNotification({
        title: t("update.success_title", { context: i18Prefix }),
        message: t("update.success_message", {
          context: i18Prefix,
          data: form.values,
        }),
      });
      navigate("..");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (queryIsLoaded) {
      //console.log(queryIsLoaded);
      //console.log(transformResponse);
      const formData = transformResponse ? transformResponse(data) : data;
      setQueryData(formData); //save transformed data. might be use for other UI components like breadcrumbs
      console.log("FormData, ", formData);

      form.setValues(formData);
      form.resetDirty(formData);
      //setTriggerFormResetDirty(true);
      //console.log("EditForm!");
      //console.log(form);
    }
  }, [data, queryIsLoaded]);

  /** 
  React.useEffect(() => {
    if (triggerFormResetDirty) {
      form.resetDirty();
      setTriggerFormResetDirty(false);
    }
  }, [triggerFormResetDirty]);*/

  // @ts-ignore
  return (
    <>
      <FormProvider form={form}>
        <ContentShell
          topSection={
            topSection ?? (
              <Breadcrumbs>
                <Anchor component={Link} to={".."}>
                  {t("list.title", { context: i18Prefix, count: 2 })}
                </Anchor>
                <Anchor span={true} variant={"text"}>
                  {t("update.title", { context: i18Prefix, data: queryData })}
                </Anchor>
              </Breadcrumbs>
            )
          }
          hideTopSection={hideTopSection}
          bottomSection={
            <>
              <Button onClick={() => navigate("..")} variant={"subtle"}>
                <Text tt={"capitalize"}>{t("common.cancel")}</Text>
              </Button>
              <Button form={formId} loading={isLoading} type="submit">
                {t("common.preview")}
              </Button>

            </>
          }
          hideBottomSection={hideBottomSection}
        >
          <Text>{t("update.description", { context: i18Prefix })}</Text>

          <FormErrorAlert
            isError={isError}
            title={t("update.fail", { context: i18Prefix })}
            error={error}
          />

          <Skeleton visible={queryIsLoading}>
            <form id={formId} onSubmit={form.onSubmit(handleSubmit)}>
              <Stack justify={"flex-start"} spacing={"md"} pr={"md"}>
                {inputs}
              </Stack>
            </form>
          </Skeleton>
        </ContentShell>
      </FormProvider>
    </>
  );
};
