import { ActionIcon, Grid, Input, Slider } from "@mantine/core";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface SliderWithButtonsProps {

  label: any,
  sliderLabel: any,

  min: number,
  max: number,
  step: number,

  leftIcon?: any,
  rightIcon?: any,

  value: number,
  setValue: any
}

export  function SliderWithButtons(inputProps: SliderWithButtonsProps) {

  const rightIconClick = () => {
    //reduce by steps
    let val = inputProps.value - inputProps.step

    if (val < inputProps.min)
      val = inputProps.min

    inputProps.setValue(val)
  }

  const leftIconClick = () => {
    //reduce by steps
    let val = inputProps.value + inputProps.step

    if (val > inputProps.max)
      val = inputProps.max

    inputProps.setValue(val)
  }

  return (
    <>
      <Input.Wrapper label={inputProps.label}>

        <Grid grow>
          <Grid.Col span={1}>

            <ActionIcon onClick={rightIconClick} size={"xs"}>
              <FontAwesomeIcon icon={inputProps.rightIcon ?? faMinus} />

            </ActionIcon>

          </Grid.Col>

          <Grid.Col span={10}>
            <Slider label={inputProps.sliderLabel}
                    min={inputProps.min} max={inputProps.max}
                    step={inputProps.step} value={inputProps.value}
                    onChange={inputProps.setValue} />
          </Grid.Col>

          <Grid.Col span={1}>
            <ActionIcon size={"xs"} onClick={leftIconClick}>
              <FontAwesomeIcon icon={inputProps.leftIcon ?? faPlus} />

            </ActionIcon>
          </Grid.Col>


        </Grid>


      </Input.Wrapper>
    </>
  );
}