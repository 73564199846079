import {
  faEllipsisVertical,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  MantineNumberSize,
  Menu,
  Text,
  ThemeIcon,
  Tooltip,
  createStyles,
  useComponentDefaultProps,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import {
  DataTable,
  DataTableColumn,
  DataTableSortStatus,
} from "mantine-datatable";
import * as React from "react";
import { useEffect, useState } from "react";

const useStyles = createStyles((theme) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export interface CxDataTableProps {
  records?: Array<any>;
  columns?: DataTableColumn<any>[];
  rowActions?: any;

  searchTerm?: string;
  setSearchTerm?: any;
  searchPlaceholderText?: any;

  sortBy?: string;
  setSortBy?: any;
  isDesc?: boolean;
  setIsDesc?: any;

  page?: number;
  setPage?: any;
  totalRecords?: number;
  pageSize?: number;
  setPageSize?: any;

  recordsPerPageLabel?: any;
  paginationText?: any;

  dataTablePageSizes?: number[];
  //dataTableInitialPageSize : number,
  isFetching: boolean;
  paginationColor?: string;

  searchBarWidth?: number | string;

  tableFontSize?: MantineNumberSize;
  tableHorizontalSpacing?: MantineNumberSize;

  tableMinHeight?: number;
  tableHeight?: number | string;
  noRecordsText?: string;
}

const defaultProps: Partial<CxDataTableProps> = {
  dataTablePageSizes: [10, 25, 50, 100],
  pageSize: 10,
  //page: 1,
  paginationColor: "ascentis",
  tableFontSize: "md",
  tableHorizontalSpacing: "xs",
  tableMinHeight: 200,
  noRecordsText: "No records to show",
  tableHeight: 400,
  searchBarWidth: 600,
};

function renderActions(record: any, contextMenus: any) {
  //TODO: If more than 3 icons do something
  /*
  const actions = contextMenus.items(record).map((item: any, index: number) =>

    <Tooltip key={index} label={item.title}>
      <ThemeIcon color={item.iconColor} onClick={item.onClick}>

        <FontAwesomeIcon icon={item.icon ? item.icon : faMagnifyingGlass} size={"xs"} />
      </ThemeIcon></Tooltip>);*/

  const actions = contextMenus.items(record).map((item: any, index: number) =>
    item.label ? (
      <Menu.Label key={index}>{item.label}</Menu.Label>
    ) : (
      <Menu.Item
        disabled={
          item.disabled
            ? typeof item.disabled === "boolean"
              ? item.disabled
              : item.disabled(record)
            : false
        }
        color={item.color}
        onClick={item.onClick}
        key={index}
        icon={
          <FontAwesomeIcon
            icon={item.icon ? item.icon : faMagnifyingGlass}
            size={"xs"}
          />
        }
      >
        {item.title}
      </Menu.Item>
    )
  );

  return (
    <>
      <Menu
        //trigger="hover" openDelay={100} closeDelay={400}
        shadow="md"
        position={"bottom-start"}
        withinPortal={true}
        withArrow={true}
        disabled={actions.length <= 0}
      >
        <Menu.Target>
          {/* <Button leftIcon={<FontAwesomeIcon icon={faEllipsisVertical} />}></Button> */}
          <ActionIcon disabled={actions.length <= 0}>
            <ThemeIcon color={actions.length <= 0 ? "gray" : ""}>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </ThemeIcon>
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>{actions}</Menu.Dropdown>
      </Menu>
    </>
  );
}

export function CxDataTable(inputProps: CxDataTableProps) {
  const { classes } = useStyles();

  // Updated: This Table should just thinly wrap Mantine DataTable, search etc should be at the shell level.

  const props = useComponentDefaultProps(
    "CxDataTable",
    defaultProps,
    inputProps
  );

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: props.sortBy as string,
    direction: props.isDesc ? "desc" : "asc",
  });

  const { ref, width, height } = useElementSize();

  const updatedColumns = props.columns.slice();

  if (props.rowActions) {
    // console.log("Props.RowActions", width);
    const actionColumn: DataTableColumn<any> = {
      accessor: props.rowActions.accessor
        ? props.rowActions.accessor
        : "actions",
      title: props.rowActions.title ? props.rowActions.title : "",
      render: (record) => renderActions(record, props.rowActions),
      textAlignment: "center",
      width: 50,
      //ellipsis: true
    };
    //Add action to first column
    updatedColumns.splice(0, 0, actionColumn);
  }

  // Add tooltip for those "ellipsis" column
  updatedColumns.forEach((each) => {
    if (each.ellipsis && !each.render) {
      each.render = (item) => (
        <Tooltip label={item[each.accessor]}>
          <Text className={classes.ellipsis}>{item[each.accessor]}</Text>
        </Tooltip>
      );
    }
  });

  useEffect(() => {
    if (props.setSortBy && props.setIsDesc) {
      props.setSortBy(sortStatus.columnAccessor);
      props.setIsDesc(sortStatus.direction == "desc");
    }
  }, [sortStatus]);

  useEffect(() => {
    if (props.setPage) {
      props.setPage(1);
    }
  }, [props.pageSize]);

  return (
    <>
      {/* <Box sx={{ height: props.tableHeight }} ref={ref}> */}
      <DataTable
        withBorder
        withColumnBorders
        striped
        highlightOnHover
        fontSize={props.tableFontSize as MantineNumberSize}
        horizontalSpacing={props.tableHorizontalSpacing as MantineNumberSize}
        minHeight={props.tableMinHeight as number}
        noRecordsText={props.noRecordsText as string}
        //Pagination
        page={props.page as number}
        onPageChange={props.setPage}
        totalRecords={props.totalRecords}
        recordsPerPage={props.pageSize as number}
        //Page selector
        recordsPerPageOptions={props.dataTablePageSizes as number[]}
        onRecordsPerPageChange={props.setPageSize}
        //rowContextMenu={props.actions}
        recordsPerPageLabel={props.recordsPerPageLabel}
        paginationText={props.paginationText}
        paginationColor={props.paginationColor}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        records={props.records}
        //columns={props.columns}
        columns={updatedColumns}
        fetching={props.isFetching}
      />
      {/* </Box> */}
    </>
  );
}
