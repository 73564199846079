import React, { useState } from "react";
import { UpdateForm } from "../../components/UpdateForm";
import { UsersPasswordFormProvider, useUsersPasswordForm } from "../../features/users/UsersPasswordFormContext";
import { UsersPasswordInputs } from "../../features/users/UsersPasswordInputs";
import {
    useGetApiUserGetOwnProfileQuery,
    usePostApiUserChangePasswordMutation,
    usePostApiUserResetUserPasswordMutation,
    useGetApiUserGetUserQuery
} from "../../services/appcenterApi";
import { MantineProvider, Alert, List, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function OverrideUserPasswordInputs() {
    return <>
        <MantineProvider inherit theme={{
            components: {
                PasswordInput: {
                    defaultProps: (theme) => ({
                        disabled: true
                    })
                }
            }
        }}>
            <UsersPasswordInputs />
        </MantineProvider>
    </>
}
export function ResetPassword() {
    const { userId, organizationId } = useParams();
    const { t } = useTranslation();
    const [passwordPolicy, setPasswordPolicy] = useState([]);
    const transformQueryData = function (data) {
        let transformedData = { id: userId, userFullName: data.userFullName, organizationId: organizationId, ...data };
        let passwordPolicies = transformedData.passwordPolicy.trim().split("\r\n");
        setPasswordPolicy(passwordPolicies);
    }
    const topSection =
        <Alert radius="lg" fz="sm" title={t("resetPassword.policyTitle")} color="blue" icon={<FontAwesomeIcon size="lg" icon={faCircleInfo} />}>            
            <List fz="xs">
                {
                    passwordPolicy.map(x => <List.Item my="5px">{x}</List.Item>)
                }
            </List>
        </Alert>


    //TODO: How to handle password policy
    return (<>
        <UpdateForm FormProvider={UsersPasswordFormProvider}
            inputs={<OverrideUserPasswordInputs />}
            topSection={topSection}
            useFormHook={useUsersPasswordForm} useUpdateMutation={usePostApiUserResetUserPasswordMutation}
            useLoadQuery={useGetApiUserGetUserQuery}
            transformResponse={transformQueryData}
            i18Prefix={"userPassword"} commandKey={"resetUserPasswordCommand"} />
    </>)
}