import { IconWithTooltip } from "@ascentis/cx-reactjs-ui/dist/components/IconWithTooltip";
import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ActionIcon } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ListAffectedUsersPageShell } from "../../components/ListAffectedUsersPageShell";
import {
  useGetApiOrganizationGetAffectedUsersQuery,
  usePostApiOrganizationUpdateOrganizationPolicyMutation,
} from "../../services/appcenterApi";
import { AffectedUsersDataTable } from "../../components/AffectedUsersDataTable";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectAccessToken, selectOrganizationId } from "../../features/user/userSlice";
import { useAppSelector } from "../../hooks/reduxHelper";

export function ListAffectedUsers() {
  const { t } = useTranslation();

  const state = useLocation().state;
  const loginType = state.loginType;



  const organizationId = selectOrganizationId(useAppSelector(state => state));
  const access_token = selectAccessToken(useAppSelector(state => state));
  const webserviceUrl = () => window.env.appCenterApiUrl

  const onExportClick = () => {

    //https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
    //https://stackoverflow.com/questions/4545311/download-a-file-by-jquery-ajax/29939024#29939024
    //need to use fetch
    //download(organizationId)

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }

    fetch(webserviceUrl() + "/api/Organization/DownloadAffectedUsers?organizationId=" + organizationId+"&&loginType="+loginType, options)
      .then(res => {
        if (res.ok) {
          return res.blob();
        } else {
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .then(blob => {

        let file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      })
      .catch(err => {
        showNotification({
          title: t('error.title', { context: '403' }),
          message: t('error.message', { context: '403' }),
          color: 'red',
          icon: <FontAwesomeIcon icon={faXmark} />,
          autoFocus: true,
        })
      })
  }


  return (
    <>
      <ListAffectedUsersPageShell
        i18Prefix={"affectedUsers"}
        initialSortKey={"userFullName"}
        useQuery={useGetApiOrganizationGetAffectedUsersQuery}
        DataTable={AffectedUsersDataTable}
        queryParam={{ loginType: loginType }}
        tableData={state}
        useUpdateMutation={
          usePostApiOrganizationUpdateOrganizationPolicyMutation
        }
        topLeftTableActions={
          <>
            <ActionIcon onClick={onExportClick}>
              <IconWithTooltip
                text={t("users.list.export")}
                icon={faDownload}
              />
            </ActionIcon>
          </>
        }
      />
    </>
  );
}
