import React from "react";
import { ActivationForm } from "../../features/signup/ActivationForm";
import { usePostAddAdditionalInfoMutation, usePostVerifyUserMutation } from "../../services/activationApi";
import { VerifyInputs } from "../../features/signup/VerifyInputs";
import { VerifyUserFormProvider, VerifyUserResponseTransformation, useVerifyUserForm } from "../../features/signup/VerifyUserFormContext";
import { AddAdditionalInfoFormProvider, AddAdditionalInfoResponseTransformation, useAddAdditionalInfoForm } from "../../features/signup/AddAdditionalInfoFormContext";
import { AddAdditionalInfoInputs } from "../../features/signup/AddAdditionalInfoInputs";

export function AddAdditionalInfo() {
  return (
    <>
      <ActivationForm FormProvider={AddAdditionalInfoFormProvider} inputs={<AddAdditionalInfoInputs />} useFormHook={useAddAdditionalInfoForm}
      transformResponse={AddAdditionalInfoResponseTransformation}
      useUpdateMutation={usePostAddAdditionalInfoMutation} i18Prefix={'addAdditionalInfo'} commandKey={'addAdditionalInfoCommand'} activationStatus={"AdditionalInformation"} />
    </>
  );
}