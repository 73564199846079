import * as React from "react";
import { Select, TextInput, Textarea } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/reduxHelper";
import {
  useGetApiApplicationGetApplicationsQuery,
  useGetApiApplicationSubcribedApplicationsQuery
} from "../../services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useUserGroupFormContext } from "./UserGroupFormContext";
import { useEffect, useMemo, useState } from "react";

export function UserGroupInputs() {
  //Render inputs required for forms
  const form = useUserGroupFormContext()
  const {t} = useTranslation()
  const [applicationsSelectData, setApplicationsSelectData] = useState(null);
  const organizationId = useAppSelector(
    // @ts-ignore
    (state) => state.user?.organization.id)

  //TODO: Application paging
  const {data: applicationsData} =
    useGetApiApplicationSubcribedApplicationsQuery(
      organizationId !== "" ?
        {
          //page: 1,
          //pageSize: 20,
          organizationId: organizationId
        } : skipToken)

        //const applicationsSelectData= []

  // @ts-ignore
  const computeAppPlaceholderStatus = function () {    
    if (!applicationsSelectData) {
      return t("userGroup.applicationLoading");      
    }
    else if (applicationsSelectData.length<1) {
      return t("userGroup.applicationNoSubscribed");
    }
    else {
      return t("userGroup.applicationSelectOneOrMore");
    }
  }
  
  useEffect(() => {    
    if (applicationsData && applicationsData) {
      let t = applicationsData.map((x) => ({
        value: x.id,
        label: x.name
      })).sort((a, b) => a.label.localeCompare(b.label));
      setApplicationsSelectData(t);
    }
  },[applicationsData])

  useEffect(()=> {
    if (organizationId)
      form.setFieldValue("organizationId", organizationId)
  }, [organizationId])
  

  return (
    <>
      <TextInput label={t("userGroup.name")}
        //placeholder={t("scope.name_placeholder")}
                 withAsterisk
                 {...form.getInputProps('name')} />

      <Textarea label={t("userGroup.description")}
        //placeholder={t("scope.displayName_placeholder")}
                 withAsterisk
                 {...form.getInputProps('description')} />

      <Select data={applicationsSelectData??[]}
              label={t("userGroup.applicationId")}
              withAsterisk
              placeholder={computeAppPlaceholderStatus()}
                   {...form.getInputProps('applicationId')}
      />
    </>
  )
}