import { createFormContext } from "@mantine/form";
import { z } from "zod";
import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";
import { useTranslation } from "react-i18next";

interface UserPasswordFormValues {
    id: string,
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
}


let UserPasswordFormSchema = z.object({
    id: z.string().trim().min(1),
    currentPassword: z.string().trim().min(1),
    newPassword: z.string().trim().min(1),
    confirmNewPassword: z.string().trim().min(1)
})

const userPasswordInitialValues: UserPasswordFormValues = {
    id: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
};

export const [UserPasswordFormProvider,
    useUserPasswordFormContext,
    useUserPasswordNativeForm] = createFormContext<UserPasswordFormValues>();

export function useUserPasswordForm() {
    const { t } = useTranslation();
    UserPasswordFormSchema = UserPasswordFormSchema.refine((form) => {
        return form.newPassword === form.confirmNewPassword
    },
        {
            message: t("validation.field_equality", { field1: "userPassword.newPassword", field2: "userPassword.confirmNewPassword", interpolation: {skipOnVariables:true} }),
            path:["confirmNewPassword"]
        });
    return useUserPasswordNativeForm(
        {
            initialValues: userPasswordInitialValues,
            //validate: translateValidationErrorMessages(zodResolver(ScopeFormSchema)),
            validate: useCustomZodResolver("userPassword", UserPasswordFormSchema)
            //transformValues: ScopeFormTransformation,

        }
    );
}