import React from "react";
import { useApiClientFormContext } from "./ApiClientFormContext";
import { useTranslation } from "react-i18next";
import { TextInput } from "@mantine/core";
import { ImageUploadInput } from "../../components/ImageUploadInput";

export function PersonalizationInputs() {

  const form = useApiClientFormContext();
  const { t } = useTranslation();

  return(
    <>
      <TextInput label={t("apiClient.supportEmail")}
                 {...form.getInputProps("supportEmail")} />

      <TextInput label={t("apiClient.developerEmail")}
                 {...form.getInputProps("developerEmail")} />

      <TextInput label={t("apiClient.appHomePage")}
                 {...form.getInputProps("appHomePage")} />

      <TextInput label={t("apiClient.privacyPolicyLink")}
                 {...form.getInputProps("privacyPolicyLink")} />

      <TextInput label={t("apiClient.termOfServiceLink")}
                 {...form.getInputProps("termOfServiceLink")} />

      <ImageUploadInput
                        label={t("apiClient.backgroundImage")} {...form.getInputProps("backgroundImage")}
                        editorHeight={720} editorWidth={1280} thumbHeight={144} thumbWidth={256} />
      <ImageUploadInput  label={t("apiClient.logoUri")} {...form.getInputProps("logoUri")}
                        editorHeight={320} editorWidth={320} thumbHeight={80} thumbWidth={80} />
    </>
  )
}