import { FormErrors } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { SafeParseError, SafeParseSuccess, ZodIssueCode, ZodSchema } from "zod";




//To use i18next interpolation and nesting capabilities
export function useCustomZodResolver<T extends Record<string, any>>(i18KeyPrefix: string, schema: any) {
    const _schema: ZodSchema = schema;

    const {t} = useTranslation()

    return (values: T): FormErrors => {
        const parsed: SafeParseError<T> | SafeParseSuccess<T> = _schema.safeParse(values);

        if (parsed.success) {
            return {};
        }


        const results = {};

        if (parsed.success === false) {
            parsed.error?.issues.forEach((issue) => {
                //console.log(issue)
                const field = issue.path.join('.')
                //const fieldKey = '$t(' + i18KeyPrefix + '.' + field + ')'
                const context = i18KeyPrefix + '.' + field

                const errorKeyPrefix = "validation."

                let errorKeySuffix = issue.code + ""

                if (issue.code === ZodIssueCode.too_small && issue.type === "string" && issue.minimum === 1) {

                    errorKeySuffix = "is_required"
                }

                if (issue.code === ZodIssueCode.too_small) {

                    errorKeySuffix = "is_required"
                }

                const errorKey = errorKeyPrefix + errorKeySuffix

                console.log("Issue", issue)
                console.log("Context: ", context)

                if (issue.code === ZodIssueCode.invalid_union) {
                    if (issue.unionErrors) {

                        issue.unionErrors.forEach((unionError) => {
                            
                            unionError.issues.forEach((unionIssue) => {

                                const unionIssueField = unionIssue.path.join('.')
                                const unionErorMsg = unionIssue.code === ZodIssueCode.custom ?
                            t(unionIssue.message):
                            t("validation." + unionIssue.code, {context: i18KeyPrefix + '.' + unionIssueField, issue: unionIssue, interpolation: {skipOnVariables: false}})

                            console.log('UnionIssue', unionIssue)
                            console.log('UnionIssueField', unionIssueField)
                            console.log('Mesg', unionErorMsg)
                            results[unionIssueField] = results[unionIssueField] ?? unionErorMsg

                            })
                            

                        })
                    }
                }

                //if custom function, return the translated message instead.
                //results[field] = issue.code === ZodIssueCode.custom ?
                //    t(issue.message)
                //    : t(errorKey, {context: i18KeyPrefix, field: field, issue: issue, interpolation: {skipOnVariables: false}});

                //if function contain custom message, use custom message as translation field

                const errorMsg =  issue.code === ZodIssueCode.custom ?
                  t(issue.message, {context: context, issue: issue, interpolation: {skipOnVariables: false}}):
                  t(errorKey, {context: context, issue: issue, interpolation: {skipOnVariables: false}})

                //Only show the first error msg (current TextInput does not display multiple error msg)
                results[field] = results[field] ?? errorMsg

                /*
                results[field] = issue.code === ZodIssueCode.custom ?
                  t(issue.message):
                  t(errorKey, {context: context, issue: issue, interpolation: {skipOnVariables: false}})

                 */
            });
        }

        return results;

    };
}