import { Button, Group, NotificationProps, Stack, Textarea, TextInput, useComponentDefaultProps, Space } from "@mantine/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { ContextModalProps } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

export interface DeleteModalProps {
  confirmationText: string,
  confirmationValue: string,
  customDeleteRecord?: any,
  reasonLabel: string,
  useDeleteHook: any,
  buttonConfirm: string,
  buttonCancel: string,
  recordId: any,
  successNotificationProps: NotificationProps
}

const defaultProps: Partial<DeleteModalProps> = {
  reasonLabel: "Default Reason",
  buttonConfirm: "Default Confirm",
  buttonCancel: "Default Cancel"
};

export function DeleteContextModal({ context, id, innerProps }: ContextModalProps<{
  confirmationText: string,
  confirmationValue: string,
  customDeleteRecord?: any,
  reasonLabel: string,
  useDeleteHook: any,
  buttonConfirm: string,
  buttonCancel: string,
  recordId: any,
  successHandler?: any
  errorHandler?: any
}>) {

  const props = useComponentDefaultProps("DeleteContextModal", defaultProps, innerProps);

  const [value, setValue] = useState("");
  const [reason, setReason] = useState("");


  const [update, {
    data: updateResult,
    error,
    isLoading,
    isSuccess,
    isError
  }] = innerProps.useDeleteHook();

  useEffect(() => {
    if (isSuccess) {
      //setSubmitError(false)
      if (props.successHandler)
        props.successHandler(context, id, updateResult)
      //navigate("..");
      context.closeModal(id)
    }

  }, [isSuccess]);

  useEffect(() => {
    if (isError) {      
      //setSubmitError(false)
      if (props.errorHandler) {
        //console.log(error)
        props.errorHandler(context, id, error)

      }
        
      //navigate("..");
      context.closeModal(id)
    }
  }, [isError]);


  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    //typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
    //typeof onCancel === 'function' && onCancel();
    //closeOnCancel && ctx.closeModal(id);
    context.closeModal(id);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    //typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
    //typeof onConfirm === 'function' && onConfirm();
    //closeOnConfirm && ctx.closeModal(id);
    
    if (!props.customDeleteRecord) {
      update({
        id: props.recordId,
        name: props.confirmationValue,
        reason: reason ?? "auto-reason"
      });      
    }
    else {      
      update({
        ...props.customDeleteRecord,
        reason: reason ?? "auto-reason"
      });
    }

    //context.closeModal(id);
  };

  return <>
    <Stack>   
      <Space h="{md}"/>
      <TextInput labelProps={{mb:"lg"}} label={props.confirmationText} value={value} placeholder={props.confirmationValue}
        onChange={(event) => setValue(event.currentTarget.value)} />
      
      <Space h="{md}"/>
      <Textarea label={props.reasonLabel} value={reason}
                onChange={(event) => setReason(event.currentTarget.value)} />

      <Group position="right">

        <Button variant="subtle" onClick={handleCancel}>
          {props.buttonCancel}
        </Button>

        <Button loading={isLoading} disabled={value !== props.confirmationValue} onClick={handleConfirm}>
          {props.buttonConfirm}
        </Button>
      </Group>
    </Stack>

  </>;
}