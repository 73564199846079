import React from "react";
import { ListPageShell } from "../../components/ListPageShell";
import { useGetApiApplicationGroupFilterApplicationGroupsQuery } from "../../services/appcenterApi";
import { UserGroupsDataTable } from "../../features/userGroups/UserGroupsDataTable";


export function ListGroups() {

  return (
    <>
      <ListPageShell
        i18Prefix={"userGroup"}
        initialSortKey={"name"}
        useQuery={useGetApiApplicationGroupFilterApplicationGroupsQuery} DataTable={UserGroupsDataTable}
      />
    </>
  );
}