import { skipToken } from "@reduxjs/toolkit/dist/query";
import { selectOrganizationId } from "shared-library/src/features/user/userSlice";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";


export function useWithCurrentOrganization(fun) {
    
    //const organizationId = selectOrganizationId(useAppSelector((state) => state));
    const organizationId = useAppSelector(selectOrganizationId);
    //params will override organization id
  return (params) => fun(organizationId ? { organizationId: organizationId, ...params } : skipToken)
}