import { createSlice } from "@reduxjs/toolkit";

import { identityApi } from "../../services/identityApi";

export interface Organization {
    id : string,
    name: string,
    code: string
}

export interface UserState {
    access_token: string,
    scope: string,
    id_token: string,
    status: string,
    id: string,
    name: string,
    picture: string,
    email: string,
    organization: Organization,
    organizations: Organization[]
    profile: any
}

const initialState : UserState = {
    access_token: "", email: "", id: "", id_token: "", name: "",
    organization: {
        id: "",
        code: "",
        name: ""
    }, picture: "", scope: "",
    profile: {},
    //access_token: null,
    //id_token: null,
    status: 'idle',
    //scope: null,

    //user info
    //id: null,
    //name: null,
    //picture: null,
    //organization: null,
    organizations: []
    //groups: {},
    //email: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userAuthenticated(state, action) {
            const {access_token, scope, id_token, profile} = action.payload
            state.access_token = access_token
            state.scope = scope
            state.id_token = id_token
            state.profile = profile
        },
        changeOrganization(state, action) {
            const { id, name, code } = action.payload;
            state.organization = {
                id: id,
                name: name,
                code: code
            };
        },
        userSessionExpired(state, action) {
            state.name = ""
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(identityApi.endpoints.getUserInfo.matchFulfilled,
            (state, action) => {

                console.log(action)
                state.name = action.payload.name
                state.id = action.payload.sub
                state.picture = action.payload.picture
                state.organization = {
                    id : action.payload['https://ascentis.com.sg/organizationid'],
                    code : action.payload['https://ascentis.com.sg/organizationcode'],
                    name: action.payload['https://ascentis.com.sg/organizationname']
                }
                state.organizations = JSON.parse(action.payload["https://ascentis.com.sg/organizationlist"]);
        })
    }
})

export const selectOrganizationId = state => (state.user?.organization.id);
export const selectOrganizations = (state) => (state.user?.organizations);
export const selectAccessToken = (state) => (state.user?.access_token);

export const {userAuthenticated, changeOrganization, userSessionExpired} = userSlice.actions
export default userSlice.reducer