import * as React from "react";
import { AppShell, createStyles, LoadingOverlay } from "@mantine/core";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { userAuthenticated } from "shared-library/src/features/user/userSlice";
import { GetMfeGlobalCtx } from "shared-library/src/provider/mfeGlobalStateProvider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetUserInfoQuery } from "shared-library/src/services/identityApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector } from "shared-library/src/hooks/reduxHelper";
import { CxHeader } from "@ascentis/cx-reactjs-ui";
import { MicroAppMenu } from "shared-library/src/features/microapps/MicroAppMenu";
import { UserProfileMenu } from "../features/user/UserProfileMenu";

function CustomHeader() {

  return (
    <>
      <CxHeader productName={"Cx"}>        
        <MicroAppMenu />
        <UserProfileMenu />
      </CxHeader>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  hostAppShellMain: {
    backgroundColor: theme.other.mainBackgroundColor // normalize duplicate left padding
  }
}));

export const ApplicationLayout = () => {

  //Add Authentication && isActive
  //TODO: Move this logic to a custom hook
  const [isUserExpired, setIsUserExpired] = React.useState(false);

  const [isUserLoaded, setIsUserLoaded] = React.useState(false);

  const auth = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const mfeSharedState: mfeSharedState = { userId: null, token: null, organizationId: null };    
  //Custom Mfe State//
  const mfeGlobalStateCtx = GetMfeGlobalCtx();
    //event when user is loaded
    React.useEffect(() => {
      return auth.events.addUserLoaded(() => {
        mfeGlobalStateCtx.setGlobalState((prevState) => {
          return { ...prevState, token: auth.user.access_token };
        }
        );
        console.debug("debug: user is loaded/reloaded");
      });
    }, [auth.events]);
  
    //event when user is unloaded
    React.useEffect(() => {
      return auth.events.addUserUnloaded(() => {
        mfeGlobalStateCtx.setGlobalState((prevState) => {
          return { ...prevState, token: null, userid: null, organizationId: null };
        });
        console.debug("debug: user is unloaded");
      });
    }, [auth.events]);

  if (!hasAuthParams() && !isUserExpired &&
    !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
    auth.signinRedirect({
      state: {location: location.pathname },     
  }).catch((error) => {
    console.log("signInRedirect error", error);
    //console.log(error)
    //errorCountHandler.increment()
    //how to set a timeout?
  })
  .finally(() => {
    console.log("signInRedirect");
    console.log("isAuthenticated", auth.isAuthenticated);
    //console.log(result)
    //setIsUserExpired(false)
  });;
  }

  //Calling own profile
  // @ts-ignore
  const access_token = useAppSelector((state) => state.user?.access_token);
  useGetUserInfoQuery(access_token !== "" ? {} : skipToken);

  // @ts-ignore
  useAppSelector((state) => state.user?.organization);


  React.useEffect(() => {

    if (auth.isAuthenticated) {      
      mfeGlobalStateCtx.setGlobalState({ userId: null, token: auth.user.access_token, organizationId: null });
      dispatch(userAuthenticated({
        access_token: auth?.user?.access_token,
        scope: auth?.user?.scope,
        id_token: auth?.user?.id_token,
        profile: auth?.user?.profile
      }));

      if (auth.user) {
        //navigate at this state won't cause refresh
        if (auth.user.state) {
            // @ts-ignore
          navigate(auth.user.state?.location);
          setIsUserLoaded(true)
        } else {
          setIsUserLoaded(true)
        }
      }
    }
    
    

  }, [auth.isAuthenticated]);

  React.useEffect(() => {
    console.log('start: useEffect auth.error');
    console.log(auth.error);
    if (auth.error) {
        console.log('clean up auth')
        auth.clearStaleState();
        auth.revokeTokens();
        auth.removeUser().then(() => {
          console.log("removed user")
          sessionStorage.clear();
          //throw new Response("User session has expired", { status: 401 });
        });
        
        //history.push('/SessionExpired');
        //dispatch(userSessionExpired({}))
        console.log('dispatch')
    }
    
  }, [auth.error, auth.querySessionStatus])

  React.useEffect(() => {
    return auth.events.addUserSignedOut(() => {
        console.log("addUserSignedOut")
        //auth.clearStaleState();
        //auth.revokeTokens();        
        //auth.signoutSilent();
        //throw new Error("User Session Expired");
        //dispatch(userSessionExpired({}))        
        //throw new Response("User session has expired", { status: 401 });
        auth.removeUser().then(() => {
          console.log("User removed");
          //need to wait for the call to finish, so the user will be removed
          setIsUserExpired(true);
        });
        
    })
}, [auth.events]);

React.useEffect(() => {
  if (isUserExpired) {
    console.log("User expired");

    //auth.clearStaleState();
    auth.revokeTokens();
    
    throw new Response("User session has expired", {
      status: 401,
      statusText: "Your session has expired",
    });
  }
}, [isUserExpired]);


  return (
    <React.Fragment>
      <AppShell
        padding={"xs"}
        header={<CustomHeader />}
        classNames={{
          main: classes.hostAppShellMain
        }}
      >
        <LoadingOverlay visible={!auth.isAuthenticated && !isUserLoaded}
          //overlayBlur={2}
                        overlayOpacity={100} />
          <div id="container">
            <Outlet />
          </div>
      </AppShell>

    </React.Fragment>
  );
};