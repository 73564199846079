import { configureStore } from "@reduxjs/toolkit";
import { errorHandlerMiddleware } from "shared-library/src/features/error/errorHandlerMiddleware";
import errorReducer from "shared-library/src/features/error/errorSlice";
import userReducer from "shared-library/src/features/user/userSlice";
import { appcenterApi } from "shared-library/src/services/appcenterApi";
import { identityApi } from "shared-library/src/services/identityApi";

import { activationApi } from "../services/activationApi";

const store = configureStore({
  reducer: {
    //menus: menusReducer,
    user: userReducer,
    error: errorReducer,
    [identityApi.reducerPath]: identityApi.reducer,
    [appcenterApi.reducerPath]: appcenterApi.reducer,
    [activationApi.reducerPath]: activationApi.reducer,
  },
  middleware: (gDM) =>
    gDM()
      .concat(identityApi.middleware)
      .concat(appcenterApi.middleware)
      .concat(activationApi.middleware)
      .concat(errorHandlerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
