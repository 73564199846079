import { faEraser, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import {
  useDeleteApiApplicationGroupDeleteApplicationGroupMutation,
  useDeleteApiApplicationGroupRemoveUserFromGroupMutation,
  useDeleteApiScopeDeleteSCopeMutation,
} from "../../services/appcenterApi";

export function UserGroupAssignmentDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const i18Prefix = "user";

  const {id} = useParams();

  const columns = [
    //{accessor: 'id'},

    {
      accessor: "userFullName",
      title: t("user.userFullName"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "staffId",
      title: t("user.staffId"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "email",
      title: t("user.email"),
      sortable: true,
      ellipsis: true,
    },
  ];

  const rowContextMenu = {
    items: (record) =>
      createStandardRowContextMenu({
        i18Context: "userGroupAssignment",
        record: {name: record.userFullName, ...record},
        useDeleteMutation:
          () => {
            //wrap standard mutation, alternative to add custom delete record
            const [deleteUser, result] = useDeleteApiApplicationGroupRemoveUserFromGroupMutation();
            return [(standard) => deleteUser({
              applicationGroupId: id,
              userId: record.id
            }), result]          
          },
        editOverride: null,
        confirmationValue: record.userFullName,
        labelOverride: {
          label: record.userFullName,
          //key: ""
        }
        //deleteOverride: null
      })
  };

  return (
    <>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
