import { useUploadFileMutation } from "../../hooks/useUploadFileMutation";
import { usePostApiUserUpdateUserMutation } from "../../services/appcenterApi";

export function useUpdateUserMutation() {

  const getUrl = (values) => {
    return values.updateUserCommand.profilePicture;
  };
  const transformValues = (values, data) => {
    values.updateUserCommand.profilePicture = data[0].key;
    return values;
  };
  const transformExistingValues = (values) => {
    values.updateUserCommand.profilePicture =
      values.updateUserCommand.profilePictureKey;
    return values;
  };
  return useUploadFileMutation({
    useMutationHook: usePostApiUserUpdateUserMutation,
    transformValues,
    getUrl,
    transformExistingValues,
  });

  /*
  //TODO: How to get the error from upload file to 'main' form
  //upload files

  const [uploadFile] = usePostApiFileUploadMutation();

  //const []
  const [create, postApiObj] = usePostApiUserCreateUserMutation();

  const combineFunction = async (values) => {

    //console.log('Values:')
    //console.log(values)
    //const imgDataUri = values.addApplicationCommand.iconImageUrl.split(',')

    if (values.addUserCommand.profilePicture == "")
      return create(values)

    const data = await uploadFile({
      'body' : [{
        name: 'not-required',
        data: values.addUserCommand.profilePicture.split(',').pop(),
        type: 'image/png'
      }]
    }).unwrap()

    //console.log('uploaded')
    //console.log(data)
    //delete values.addUserCommand.iconImageUrl

    //Add icon key
    values.addUserCommand.profilePicture = data[0].key
    return create(values)
  }

  return[combineFunction, postApiObj]*/
}
