import { createFormContext } from "@mantine/form";
import { z } from "zod";

import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";
import { t } from "i18next";

interface UserFormValues {
  userFullName: string;
  staffId: string;
  email: string;
  mobileNumber: string;
  status: string;

  organizationId: string;
  enableEmailOtp: boolean;
  enableTotp: boolean;
  profilePicture: string;
}

const UserFormSchema = z
  .object({
    userFullName: z.string().trim().min(1),
    staffId: z.string().trim().optional().nullable(), //.min(1),
    email: z.string().email().optional().nullable().or(z.literal("")),
    mobileNumber: z.string().trim().optional().nullable(), //.min(1),

    //status: z.string().trim().min(1),
    //roles: z.string().trim().min(1),
    //organizationId: z.string().trim().min(1),
    //enableEmailOtp: z.string().trim().min(1),
    //enableTotp: z.string().trim().min(1),
  })
  .refine((data) => !(!data.staffId && !data.email && !data.mobileNumber), {
    path: ["email"],
    message: "validation.oneOfUserIdentifierFieldMustBeFilled" //"Either StaffId or Email or Mobile Number should be filled in.",
  });

const userFormInitialValues: UserFormValues = {
  userFullName: "",
  staffId: "",
  email: "",
  mobileNumber: "",

  organizationId: "",
  enableEmailOtp: false,
  enableTotp: false,
  status: "",
  profilePicture: "",
};

export const UserProfileResponseTransformation = (values) => {
  //console.log('values', values)
  //const loginTypes  = values.organizationPolicy?.loginType ? values.organizationPolicy.loginType.split(',') : []

  return {
    ...values,
    profilePicture: values.profilePicture?.url,
  };
};

export const [
  UserOwnProfileFormProvider,
  useUserOwnProfileFormContext,
  useUserNativeForm,
] = createFormContext<UserFormValues>();

export function useUserOwnProfileForm() {
  return useUserNativeForm({
    initialValues: userFormInitialValues,
    //validate: translateValidationErrorMessages(zodResolver(ScopeFormSchema)),
    validate: useCustomZodResolver("user", UserFormSchema),
  });
}
