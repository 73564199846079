import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiClientFormProvider, useApiClientForm } from "../../features/apiClients/ApiClientFormContext";
import { Anchor, Breadcrumbs, Button, Stack, Stepper, Text } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { ApiClientInputs } from "../../features/apiClients/ApiClientInputs";
import { ScopesInputs } from "../../features/apiClients/ScopesInputs";
import { PersonalizationInputs } from "../../features/apiClients/PersonalizationInputs";
import { Summary } from "../../features/apiClients/Summary";
import { FormErrorAlert } from "../../components/FormErrorAlert";
import { selectOrganizationId } from "../../features/user/userSlice";
import { useAppSelector } from "../../hooks/reduxHelper";
import { useCreateApiClientMutation } from "../../features/apiClients/useCreateApiClientMutation";
import { showNotification } from "@mantine/notifications";
import { useNavbarHeight } from "../../hooks/useNavbarHeight";
import { ContentShell } from "../../components/ContentShell";
import { useId } from "@mantine/hooks";

export const CreateApiClient = () => {

  const { t } = useTranslation();
  const form = useApiClientForm();
  const formId = useId();
  const organizationId = selectOrganizationId(useAppSelector(state => state))

  const barHeight = useNavbarHeight()
  //const scrollHeight = barHeight - 16 - 16 - 42 - 16 - 36 - 20
  //const scrollHeight = 'calc(100vh - var(--mantine-header-height, 0px) - var(--mantine-footer-height, 0px) - var(--mantine-Stepper-steps, 0px))'
  const scrollHeight = 'calc(100vh - var(--mantine-header-height, 0px) - 158px - 20px)'

  const maxStep = 4
  const navigate = useNavigate();

  const [active, setActive] = useState(0);
  const nextStep = (e) => {
    e.preventDefault();

    setActive((current) => {

      if (form.validate().hasErrors) {
        return current;
      }

      return (current < maxStep ? current + 1 : current)});
  }
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));


  // @ts-ignore
  const [create, {
    error,
    isLoading,
    isSuccess,
    isError
  }] = useCreateApiClientMutation();

  const handleSubmit = (values) => {
    console.log('handleSubmit called')
    //console.log(form);
    values.organizationId = organizationId
    create({ 'addClientCredentialCommand': values })
  };

  useEffect(() => {

    if (isSuccess) {
      //setSubmitError(false)
      showNotification(
        {
          title: t( "apiClient.notification.new.title"),
          message: t("apiClient.notification.new.message", { 'data': form.values })
        }
      );
      //redirect to list page
      //return redirect('/AppSubscription/apiscopes')
      navigate("..");
    }

  }, [isSuccess]);

  return (
    <>
      <ApiClientFormProvider form={form}>

        <ContentShell topSection={<Breadcrumbs>
          <Anchor component={Link} to={".."}>
            {t("apiClient.list.title")}
          </Anchor>
          <Anchor span={true} variant={"text"}>
            {t("apiClient.new.title")}
          </Anchor>
        </Breadcrumbs>} bottomSection={

          <>
            {
              active == 0 ?
                <Button variant="subtle" onClick={() => navigate("..")}>
                  <Text tt={"capitalize"}>
                    {t("common.cancel")}
                  </Text>
                </Button> :
            <Button variant="subtle" onClick={prevStep}>
              <Text tt={"capitalize"}>
                {t("common.back")}
              </Text>
            </Button>
            }

        {
          active == maxStep - 1 ?
          <Button form={formId} loading={isLoading} type="submit">{t("common.submit.new")}</Button>
        : <Button onClick={nextStep}>{t("common.next")}</Button>
        }
        </>
      }>
          <form id={formId} onSubmit={form.onSubmit(handleSubmit)}>

              <Stepper active={active} onStepClick={setActive} breakpoint="sm" allowNextStepsSelect={true}>

                <Stepper.Step label={t("apiClient.clientCredential")}>
                    <Stack justify={"flex-start"} spacing={"md"}>
                      <ApiClientInputs />
                    </Stack>


                </Stepper.Step>
                <Stepper.Step label={t("apiClient.scope")} description={t("apiClient.optional")}>

                    <Stack justify={"flex-start"}>
                      <ScopesInputs />
                    </Stack>

                </Stepper.Step>
                <Stepper.Step label={t("apiClient.personalization")} description={t("apiClient.optional")}>

                    <Stack justify={"flex-start"}>
                      <PersonalizationInputs />
                    </Stack>

                </Stepper.Step>
                <Stepper.Step label={t("apiClient.summary")} description="">
                  <Stack justify={"flex-start"}>
                    <FormErrorAlert isError={isError} title={t("new.fail", { context: "apiClient" })} error={error} />
                    <Summary />
                  </Stack>
                </Stepper.Step>
              </Stepper>
          </form>


        </ContentShell>



      </ApiClientFormProvider>
    </>
  );
};