import { openContextModal } from "@mantine/modals";
import React from "react";
import { Translation } from "react-i18next";
import { showNotification } from "@mantine/notifications";

interface openDeleteModalProps {

  useDeleteMutation: any,
  i18prefix: string,
  data: any,
  confirmationValue: string,
  recordId: string,
  title?: string,
  confirmation?: string
  errorHandler?: Function,
  customDeleteRecord? : any
}

export function openDeleteModal({
                                  useDeleteMutation,
                                  i18prefix,
                                  data,
                                  confirmationValue,
                                  recordId,
                                  title,
                                  confirmation,
                                  errorHandler,
                                  customDeleteRecord
                                }: openDeleteModalProps) {

  return openContextModal({
    modal: "deleteApi",
    title: title ?? <Translation>
      {(t) => t("delete.title", { context: i18prefix, data: data })}
    </Translation>,
    innerProps: {
      confirmationText: confirmation ??
        <Translation>
          {(t) => t("delete.confirmation", { context: i18prefix, data: data })}
        </Translation>,
      confirmationValue: confirmationValue,
      useDeleteHook: useDeleteMutation,
      recordId: recordId,
      successHandler: () => {
        showNotification({
          title: <Translation>
            {(t) => t("delete.success_title", { context: i18prefix, data: data })}
          </Translation>,
          message: <Translation>
            {(t) => t("delete.success_message", { context: i18prefix, data: data })}
          </Translation>
        })
      },
      errorHandler: errorHandler ?? ((context, id, error) => {
        showNotification({
          title: <Translation>
            {(t) => t("delete.fail_title", { context: i18prefix, data: data })}
          </Translation>,
          message: <Translation>
            {(t) => t("delete.fail_message", { context: i18prefix, data: error })}
          </Translation>,
        });
      }),
      customDeleteRecord: customDeleteRecord
    }
  });


}
