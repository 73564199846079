import * as React from "react";
import { createStyles, Paper } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { CxNavbar } from "@ascentis/cx-reactjs-ui";
import { useRouterMenuResolver } from "shared-library/src/hooks/useRouterMenuResolver";


const useStyles = createStyles((theme) => ({
  content: {
    height: `calc(100vh - var(--mantine-header-height, 0px) - ${theme.spacing.md}px - ${theme.spacing.md}px)`,
    width: `calc(100vw - var(--mantine-navbar-width, 0px) - ${theme.spacing.md}px - ${theme.spacing.md}px)`,
    overflow: "hidden",
    display: "flex", flexDirection: "column"
  }
}));

export const SideBarLayout = () => {

  const { classes } = useStyles();
  const menu = useRouterMenuResolver();

  const version = window.env.version;

  console.log(menu);

  /*
  const { classes } = useStyles(undefined, undefined)
  const links = appcenterData.map((item) => <LinksGroup
      links={item.links} icon={item.icon} label={item.label} initiallyOpened={item.initiallyOpened}  key={item.label} />)

   */
  return (
    <React.Fragment>
      <CxNavbar title={"AppCenter"} version={version} menu={menu} />
      <Paper p={"md"} id={"maincontent"} className={classes.content} withBorder={false} shadow="md" radius="lg">
        <Outlet />
        <div id="microcontent"></div>
      </Paper>
    </React.Fragment>
  );

};
