import React from "react";
import { ListPageShell } from "../../components/ListPageShell";
import { useGetApiOrganizationGetAllOrganizationsQuery } from "../../services/appcenterApi";
import { OrganizationsDataTable } from "../../features/organizations/OrganizationsDataTable";


export function ListOrganizations() {
  return (
    <>
      <ListPageShell
        i18Prefix={"organization"}
        initialSortKey={"organizationName"}
        useQuery={useGetApiOrganizationGetAllOrganizationsQuery} DataTable={OrganizationsDataTable}
      />
    </>
  );
}