import { themeAscentis } from "@ascentis/cx-reactjs-ui";
import {
  LoadingOverlay,
  MantineProvider,
  MantineThemeOverride,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import { I18nextProvider, Translation, useTranslation } from "react-i18next";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,  
  json,
  redirect
} from "react-router-dom";
import { ConfirmationContextModal } from "shared-library/src/components/ConfirmationContextModal";
import { CxError } from "shared-library/src/components/CxError";
import { DeleteContextModal } from "shared-library/src/components/DeleteContextModal";
import i18n from "./i18n";
import store from "./store";
import { WebStorageStateStore } from "oidc-client-ts";
import { registerMicroApps, start, addGlobalUncaughtErrorHandler } from "qiankun";
import { routes, MicroFeApp } from "./routes";
import { GetMfeGlobalCtx } from "shared-library/src/provider/mfeGlobalStateProvider";

const theme: MantineThemeOverride = {
  //fontFamily: 'Poppins',
  ...themeAscentis,
  components: {
    ImageUploadInput: {
      defaultProps: {
        editorTitle: (
          <Translation>{(t) => t("common.imageEditor.resize")}</Translation>
        ),
        inputUploadDescription: (
          <Translation>
            {(t) => t("common.imageUpload.description")}
          </Translation>
        ),
        inputReplaceDescription: (
          <Translation>{(t) => t("common.imageUpload.replace")}</Translation>
        ),
        modalRotateDescription: (
          <Translation>{(t) => t("common.imageUpload.rotate")}</Translation>
        ),
        modalResizeDescription: (
          <Translation>{(t) => t("common.imageUpload.resize")}</Translation>
        ),
        modalCancelDescription: (
          <Translation>{(t) => t("common.cancel")}</Translation>
        ),
        modalConfirmDescription: (
          <Translation>{(t) => t("common.confirm")}</Translation>
        ),
      },
    },
    DeleteContextModal: {
      defaultProps: {
        reasonLabel: (
          <Translation>{(t) => t("common.deleteModal.reason")}</Translation>
        ),
        buttonConfirm: <Translation>{(t) => t("common.confirm")}</Translation>,
        buttonCancel: <Translation>{(t) => t("common.cancel")}</Translation>,
      },
    },
    ConfirmationContextModal: {
      defaultProps: {
        buttonConfirm: <Translation>{(t) => t("common.confirm")}</Translation>,
        buttonCancel: <Translation>{(t) => t("common.cancel")}</Translation>,
      },
    },
  },
};

//Handling application wide error such as 404 and error
function ErrorHandler() {
  const error = useRouteError();
  const { t } = useTranslation();
  //const errorCode =
  console.error(error);
  console.error("isRouteError:" + isRouteErrorResponse(error));

  return (
    <CxError
      code={isRouteErrorResponse(error) ? error.status : error.status ?? "999"}
      description={t("common.errorDescription", {
        context: isRouteErrorResponse(error) ? error.status : error.status ?? ""
      })}
      title={t("common.errorTitle", {
        context: isRouteErrorResponse(error) ? error.status : error.status ?? ""
      })}
    />
  );
}

export function App()
{    
  const [browserRouter, setBrowserRouter] = useState(createBrowserRouter(routes));  
  const [userAccessToken, setUserAccessToken] = useState(store.getState().user?.access_token ?? "");

  store.subscribe(() => {
    let updatedOrgId = store.getState().user.organization.id;
    let updatedAcessToken = store.getState().user.access_token;    
    if (updatedAcessToken != userAccessToken) {
      setUserAccessToken(updatedAcessToken);
    }
  }); 

  useEffect(function () {
    if (userAccessToken) {
      fetchSubscribedMfe();
    }
  }, [userAccessToken]);


  const fetchSubscribedMfe = async function () {
    let getRequestUrl = `${window.env.appCenterApiUrl}/api/application/GetMfeApplications?isCc=false`;
    let res = await fetch(getRequestUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userAccessToken}`
      }
    });
    if (res.status >= 200 && res.status<300) {
      let response = await res.json();
      initMfeRoutes(response.data);
    }
  }

  const mfeGlobalCtx = GetMfeGlobalCtx();

  const initMfeRoutes = function (subscribedMfes) {
    let mfeApps = subscribedMfes.map((x) => {
      let mfeApp = new MicroFeApp();
      mfeApp.name = `apps/${x.mfePath.toLowerCase()}`;
      mfeApp.entry = x.appUrl.replace("https:", "").replace("http", "");            
      mfeApp.activeRule = `apps/${x.mfePath.toLowerCase()}`;      
      mfeApp.props = mfeGlobalCtx;
      return mfeApp;
    });
    registerMicroApps(mfeApps);

    let mfeRoutes = subscribedMfes.map((x) => {
      return {
        path: `apps/${x.mfePath.toLowerCase()}`
      }
    });  
    let newRoutes = [...routes];
    newRoutes[0].children = routes[0].children.concat(mfeRoutes);        
    setBrowserRouter(createBrowserRouter(newRoutes));    
    
    window.env.mfeLoaded = true;
  }
  
  addGlobalUncaughtErrorHandler((event) => {
    console.error(JSON.stringify(event));
    window.location.href = "/offline"
  });
  start({ sandbox: true });  

  const oidcConfig: AuthProviderProps = {
    authority: window.env.identityUrl,
    client_id: window.env.clientId,
    //redirect_uri: `${appCenterUrl}`,
    redirect_uri: window.location.origin,
    //post_logout_redirect_uri: window.location.origin,
    response_type: "code",
    scope: "openid profile ascentiscx ascentiscx_profile email",
    automaticSilentRenew: true,
    loadUserInfo: false,
    monitorSession: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: (user) => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
  };

  return (
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider {...oidcConfig}>
          <I18nextProvider i18n={i18n}>
            {/* <App title={title} /> */}
            <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
              <NotificationsProvider position={"top-center"}>
                <ModalsProvider
                  modals={{
                    deleteApi: DeleteContextModal,
                    confirmationApi: ConfirmationContextModal,
                  }}
                >
                  <RouterProvider
                    router={browserRouter}
                    fallbackElement={<LoadingOverlay visible={true} />}
                  />
                </ModalsProvider>
              </NotificationsProvider>
            </MantineProvider>
          </I18nextProvider>
        </AuthProvider>
      </Provider>
    </React.StrictMode>
  );
}
//#endregion
//#region Export
//export default App;  
//#endregion
