import * as React from "react";
import { useEffect } from "react";
import { Center, MultiSelect, PasswordInput, Text, TextInput, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faPray } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSignUpUserFormContext } from "./SignUpUserFormContext";
import { useParams } from "react-router-dom";


export function SignUpInputs() {
  //Render inputs required for forms
  const form = useSignUpUserFormContext();
  const { t } = useTranslation();

  return (
    <>
      <TextInput label={t("user.userFullName")}
        //placeholder={t("scope.name_placeholder")}
                 withAsterisk={true}
                 {...form.getInputProps("userFullName")} />

      <TextInput label={t("user.email")} disabled={true}
        //placeholder={t("scope.displayName_placeholder")}
        //withAsterisk
                     {...form.getInputProps("email")} />

      <PasswordInput label={t("user.password")}
                 {...form.getInputProps("password")} />

      <PasswordInput label={t("user.confirmPassword")}
        //placeholder={t("scope.displayName_placeholder")}
        //withAsterisk
                 {...form.getInputProps("confirmPassword")} />
    </>
  );
}