// @ts-nocheck
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { selectOrganizationId } from "../features/user/userSlice";

//const webserviceUrl = window.env.webserviceUrl

//const webserviceUrl = () => "window.env.webserviceUrl"



//TODO: To update this hack once, fixes to the APIs to use HTTP response as error and reduce nesting of data Hacking response
function normalizeResponse(response : any) : any {

    //console.log('rawResponse:')
    //console.log(response)

    //if (response && response.status )
    //

    //console.log(response)

    if (response && response.data && response.data.status == 'Success') {

        //console.log('Normalized: ')
        //console.log(response.data.data)
        return {data: response.data.data}
    }

    if (response && response.data && response.data.status == 'Failed') {

        //console.log('Normalized error ')
        //console.log(response.data)
        //return {error: response.data.data}
        return {
            error: {
                status: '403',
                statusText: 'Forbidden',
                data: response.data.message ? response.data.message : response.data.Data
            }
        }
    }


    //console.log('not formatted rawResponse:')
    
    return response

}

const dynamicBaseQuery = function (args, api, extraOptions) : any {

    const webserviceUrl = () => window.env.appCenterApiUrl

    const rawBaseQuery = fetchBaseQuery({
        baseUrl: webserviceUrl(),
        prepareHeaders: (headers: Headers, {getState} : Pick<any, any>): MaybePromise<Headers> => {

            // @ts-ignore
            const { access_token } = getState().user
            headers.set('Accept', 'application/json')
            headers.set('Content-Type', 'application/json')
            headers.set('Authorization', `Bearer ${access_token}`)
            return headers
        }
    });

    return new Promise<any> ((resolve, reject) => {
        rawBaseQuery(args, api, extraOptions).then((response) =>{

            resolve(normalizeResponse(response))
        })
    })
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
});
