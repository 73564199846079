import { createSlice } from "@reduxjs/toolkit";

export interface ErrorState {
    code: string,
    message: string,
    id: string,
    hasBeenShown: boolean,
    context: string,
    
}

const initialState : ErrorState = {
    code: "",
    message: "",
    context: "",
    id: "",
    hasBeenShown: true,

}

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        encounterApiError(state, action) {
            const {code, message, id} = action.payload
            state.code = code
            state.message = message
            state.id = id
            state.hasBeenShown = false
        },
        errorDisplayed(state, action) {
            const { id } = action.payload;

            if (id === state.id)
                state.hasBeenShown = true
            
        }
    }
})

export const getError = (state) => (state.error);

export const {encounterApiError, errorDisplayed} = errorSlice.actions
export default errorSlice.reducer