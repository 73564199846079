import { ContentShell } from "@ascentis/cx-reactjs-ui";
import { Anchor, Box, Breadcrumbs, Button, Group, Select, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ListPageShell } from "shared-library/src/components/ListPageShell";
import { selectAccessToken, selectOrganizationId } from "shared-library/src/features/user/userSlice";
import { UserGroupAssignmentDataTable } from "shared-library/src/features/userGroups/UserGroupAssignmentDataTable";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";
import {useGetAllUsers} from "shared-library/src/features/user/useGetAllUsers";
import {
  useGetApiApplicationApplicationsQuery,
  useGetApiApplicationGroupGetApplicationGroupQuery,
  useGetApiApplicationGroupGetApplicationGroupsByUserQuery,
  useGetApiApplicationGroupGetCustomGroupQuery,
  useGetApiApplicationGroupGetUsersFromGroupQuery,
  useGetApiUserGetUsersQuery,
  usePostApiApplicationGroupAddUserToApplicationGroupMutation
} from "shared-library/src/services/appcenterApi";
import {
  GroupAssignmentsByUserDataTable
} from "../../../../shared-library/src/features/userGroups/GroupAssignmentsByUserDataTable";
import { useGetAllGroups } from "../../../../shared-library/src/features/userGroups/useGetAllGroups";

export function useGetUserAssignments() {

  //TODO: How to get the error from upload file to 'main' form
  //upload files

  const { id } = useParams()
  const organizationId = useAppSelector(selectOrganizationId);

  return useGetApiApplicationGroupGetUsersFromGroupQuery(
    organizationId !== "" ?
      {
        organizationId: organizationId,
        applicationGroupId: id
      } : skipToken);

  //return result
}

export function ListUserGroupAssignment() {


  const {t} = useTranslation();
  const {userId} = useParams();
  const accessToken = useAppSelector(selectAccessToken);

  const { data, isFetching : queryIsFetching, isSuccess : queryIsSuccess } = useGetApiApplicationGroupGetApplicationGroupsByUserQuery(accessToken && userId ? { userId } : skipToken);

  const {data : users, isSuccess, isFetching} = useGetAllGroups();

  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  const i18Prefix = "userGroupAssignmentByUser"
  //const navigate = useNavigate();

  const [
    addUser,
    {
      //data: updateResult,
      error,
      isLoading,
      isSuccess : isAddSuccess,
      isError,
    },
  ] = usePostApiApplicationGroupAddUserToApplicationGroupMutation();

  const assignedUsers = queryIsSuccess ? data.map(item => item.id) : []

  // @ts-ignore
  //console.log("users", users)
  const usersSelectData =
    users == null
      ? []
      : users.data.map(item => {
        return {
          label: '[' + item.applicationName + '] ' + item.name,
          value: item.id
        }
      }).filter((item) => !assignedUsers.includes(item.value))

  const handleAdd = () => {

    if (selectedGroup != null && selectedGroup != "") {
      addUser({
        addUserToApplicationGroupCommand: {
          userId: userId,
          applicationGroupId: selectedGroup
        }
      })
      //setSelectedUser("")
    }
  }

  useEffect(() => {
    if (isAddSuccess) {
      
      //setSubmitError(false)
      showNotification({
        title: t("new.success_title", { context: i18Prefix }),
        message: t("new.success_message", {
          context: i18Prefix,
          data: {},
        }),
      });

      
      setSelectedGroup("")
      //navigate("..");
    }
  }, [isAddSuccess]);


  
  return (
    <>
      
      <ContentShell
        topSection={          
            <>
              <Breadcrumbs>
              <Anchor component={Link} to={".."}>
                  {t("list.title", { context: "userGroup", count: 2 })}
                </Anchor>
                <Anchor span={true} variant={"text"}>
                  {t("list.title", { context: i18Prefix, count: 2, data: data })}
                </Anchor>
              </Breadcrumbs>
            </>          
        }
        bottomSection={<></>}
      >
        <Stack>

          <Group grow>
            <Button fullWidth={false} onClick={handleAdd} disabled={selectedGroup == null || selectedGroup == ""}>
              {t("userGroupAssignmentByUser.add")}
            </Button>
            <Select
              disabled={isLoading}
              clearable
              value={selectedGroup}
              onChange={setSelectedGroup}
              searchable
              placeholder={t("userGroupAssignmentByUser.addPlaceholder")}
              nothingFound={t("userGroupAssignmentByUser.empty")}
            data={usersSelectData} />
          </Group>

          <Text>{t("userGroupAssignmentByUser.description")}</Text>

          <GroupAssignmentsByUserDataTable
            // tableHeight={'53vh'}
            isFetching={queryIsFetching}
            totalRecords={queryIsSuccess ? data.length : 0}
            //columns={columns}
            records={queryIsSuccess ? data : []}
          />
        </Stack>

        {/* Search Keywords & Filter */}
      </ContentShell>
    
    </>
  );
}