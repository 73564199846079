import { createFormContext } from "@mantine/form";
import { z } from "zod";
import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";
import { useTranslation } from "react-i18next";

interface UsersPasswordFormValues {
    id: string,    
    password: string,
    confirmPassword: string
}

const usersPasswordInitialValues: UsersPasswordFormValues = {
    id: "",    
    password: "",
    confirmPassword: ""
};

export const [UsersPasswordFormProvider,
    useUsersPasswordFormContext,
    useUsersPasswordNativeForm] = createFormContext<UsersPasswordFormValues>();

export function useUsersPasswordForm() {
    const { t } = useTranslation();    
    const UsersPasswordFormSchema = z.object({
        id: z.string().trim().min(1),
        password: z.string().trim(),
        confirmPassword: z.string().trim()
    });
    
    const userPasswordMin = UsersPasswordFormSchema.refine((form) => { return form.password.length >= 12 }, {
        message: t("validation.passwordMinLength"),
        path: ["password"]
    });

    const userPasswordMax = UsersPasswordFormSchema.refine((form) => { return form.password.length <= 80 }, {
        message: t("validation.passwordMaxLength"),
        path: ["password"]
    })
    
    const userPasswordConfirmPasswordequality = UsersPasswordFormSchema.refine((form) => {
        return form.password === form.confirmPassword
    },
        {
            message: t("validation.field_equality", { field1: "userPassword.newPassword", field2: "userPassword.confirmNewPassword", interpolation: {skipOnVariables:true} }),
            path:["confirmPassword"]
        });
    
    const userPasswordPolicy = z.intersection(z.intersection(userPasswordMin,userPasswordMax), userPasswordConfirmPasswordequality);    

    return useUsersPasswordNativeForm(
        {
            initialValues: usersPasswordInitialValues,            
            validate: useCustomZodResolver("userPassword", userPasswordPolicy)
            //transformValues: ScopeFormTransformation,

        }
    );
}