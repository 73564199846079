import {
  faCircleExclamation,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Anchor,
  Badge,
  Grid,
  Modal,
  Paper,
  Space,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as React from "react";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import { openDeleteModal } from "../../hooks/openDeleteModal";
import {
  useDeleteApiOrganizationDeleteOrgAssignmentRequestIdMutation,
  usePostApiOrganizationUpdateAndResetUserAssignmentMutation,
} from "../../services/appcenterApi";

dayjs.extend(utc.default);

export function OrganizationAssignRequestTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const i18Prefix = "organizationAssignRequest";

  const [opened, { open, close }] = useDisclosure(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const showOrganizationDetails = function (record) {
    setActiveRecord(record);
    open();
  };

  const [lastActionData, setLastActionData] = useState(null);

  const [
    updateResetAssignment,
    {
      error: updateResetError,
      isLoading: updateResetLoading,
      isSuccess: updateResetIsSuccess,
      isError: updateResetIsError,
    },
  ] = usePostApiOrganizationUpdateAndResetUserAssignmentMutation();

  useEffect(
    function () {
      if (!updateResetLoading) {
        if (updateResetIsSuccess) {
          showNotification({
            title: t("organizationAssignRequest.list.resendEmailTitle"),
            message: (
              <Trans
                i18nKey="organizationAssignRequest.list.resendEmailSuccess"
                values={{ id: lastActionData.id }}
              ></Trans>
            ),
          });
        } else if (updateResetIsError) {
          showNotification({
            icon: <FontAwesomeIcon size="lg" icon={faCircleExclamation} />,
            color: "red",
            title: t("organizationAssignRequest.list.resendEmailTitle"),
            message: (
              <Trans
                i18nKey="organizationAssignRequest.list.resendEmailFail"
                values={{
                  id: lastActionData.id,
                  error: updateResetError.data,
                  errorCode: updateResetError.status,
                }}
              ></Trans>
            ),
          });
        }
      }
    },
    [updateResetIsSuccess, updateResetIsError]
  );

  const computeStatus = function (
    status: String,
    expiryDateStr: string,
    isApproved: boolean
  ): string {
    if (status == "Completed") {
      return isApproved ? "approved" : "rejected";
    }
    if (status == "Terminated") {
      return "terminated";
    }

    if (expiryDateStr) {
      let expiryDate = new Date(expiryDateStr);
      let now = new Date();
      if (expiryDate < now) {
        return "expired";
      }
    }
    return "pending";
  };

  const columns = [
    {
      accessor: "workflowId",
      title: t(`${i18Prefix}.workflowId`),
      sortable: false,
      width: 330,
      ellipsis: true,
      render: (record) => (
        <Anchor
          onClick={() => {
            showOrganizationDetails(record);
          }}
        >
          {record.id}
        </Anchor>
      ),
    },
    {
      accessor: "createdDate",
      title: t(`${i18Prefix}.createdDate`),
      sortable: true,
      width: 200,
      ellipsis: false,
      render: ({ createdDate }) => {
        const utcDateTime = dayjs.default(createdDate).utc(true);
        return <>{utcDateTime.local().format("DD/MM/YYYY hh:mm A")}</>;
      },
    },
    {
      accessor: "expiryDate",
      title: t(`${i18Prefix}.expiryDate`),
      sortable: false,
      width: 200,
      ellipsis: false,
      render: ({ expiryDate }) => {
        const utcDateTime = dayjs.default(expiryDate).utc(true);
        return <>{utcDateTime.local().format("DD/MM/YYYY hh:mm A")}</>;
      },
    },
    {
      accessor: "status",
      title: t(`${i18Prefix}.status`),
      sortable: true,
      width: 150,
      ellipsis: false,
      render: ({ expiryDate, status, isApproved }) => (
        <>
          {t(`${i18Prefix}.${computeStatus(status, expiryDate, isApproved)}`)}
        </>
      ),
    },
    {
      accessor: "isApproved",
      title: t(`${i18Prefix}.isApproved`),
      sortable: false,
      minWidth: 150,
      ellipsis: false,
      render: ({ isApproved, status }) => (
        <>
          {status == "Completed"
            ? isApproved
              ? t(`${i18Prefix}.approved`)
              : t(`${i18Prefix}.rejected`)
            : t(`${i18Prefix}.pending`)}
        </>
      ),
    },
  ];

  const rowContextMenu = {
    items: (record) => {
      return createStandardRowContextMenu({
        i18Context: i18Prefix,
        disabled:
          computeStatus(record.status, record.expiryDate, record.isApproved) !=
          "pending",
        record: { ...record, name: record.workflowId },
        useDeleteMutation:
          useDeleteApiOrganizationDeleteOrgAssignmentRequestIdMutation,
        deleteOverride:
          APPCENTER == true
            ? null
            : {
                disabled: (record) => {
                  let currState = computeStatus(
                    record.status,
                    record.expiryDate,
                    record.isApproved
                  );
                  return currState != "pending";
                },
                onClick: () => {
                  openDeleteModal({
                    confirmationValue: "Confirm",
                    recordId: record.id,
                    data: { name: record.id, ...record },
                    title: t("organizationAssignRequest.list.terminationTitle"),
                    confirmation: t(
                      "organizationAssignRequest.list.terminationConfirmation",
                      { id: record.workflowId }
                    ),
                    useDeleteMutation:
                      useDeleteApiOrganizationDeleteOrgAssignmentRequestIdMutation,
                    i18prefix: i18Prefix,
                    errorHandler: function (context, id, error) {
                      showNotification({
                        icon: (
                          <FontAwesomeIcon
                            size="lg"
                            icon={faCircleExclamation}
                          />
                        ),
                        color: "red",
                        title: t(
                          "organizationAssignRequest.list.deleteErrorTitle"
                        ),
                        message: t(
                          "organizationAssignRequest.list.deleteErrorMessage",
                          { error: error }
                        ),
                      });
                    },
                  });
                },
              },
        editOverride: null,
      }).toSpliced(2, 0, {
        key: "resendAdmin",
        title: t("organizationAssignRequest.list.resend"),
        icon: faRefresh,
        disabled: (record) => {
          let currState = computeStatus(
            record.status,
            record.expiryDate,
            record.isApproved
          );
          return currState != "pending" && currState != "expired";
        },
        onClick: () => {
          setLastActionData(record);
          updateResetAssignment({
            updateResetUserAssignmentApprovalCommand: {
              userAssignmentRequestId: record.id,
            },
          });
          console.log("Resend Email");
        },
      });
    },
  };

  const statusColorMap = {
    approved: "green",
    pending: "blue",
    rejected: "red",
    expired: "gray",
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size={"md"}
        title={
          <>
            <Text size={"lg"} weight={"bold"}>
              {" "}
              Workflow Details
            </Text>
          </>
        }
      >
        {opened ? (
          <Stack>
            <Grid>
              <Grid.Col span={9}>
                <Stack spacing={0}>
                  <Text fz={"xs"}>Request Id</Text>
                  <Text size={"xs"} mb="xs" weight={"bold"}>
                    {activeRecord.id}
                  </Text>
                  <Text fz={"xs"}>Workflow Id</Text>
                  <Text size={"xs"} weight={"bold"}>
                    {activeRecord.workflowId}
                  </Text>
                </Stack>
              </Grid.Col>
              <Grid.Col span={3}>
                <Stack spacing={0}>
                  <Badge
                    variant="filled"
                    fullWidth={false}
                    color={
                      statusColorMap[
                        computeStatus(
                          activeRecord.status,
                          activeRecord.expiryDate,
                          activeRecord.isApproved
                        )
                      ]
                    }
                  >
                    {t(
                      `${i18Prefix}.${computeStatus(
                        activeRecord.status,
                        activeRecord.expiryDate,
                        activeRecord.isApproved
                      )}`
                    )}
                  </Badge>
                </Stack>
              </Grid.Col>
              <Grid.Col span={12}>
                <Space h={"xs"}></Space>
              </Grid.Col>
              <Grid.Col span={6}>
                <Stack spacing={0}>
                  <Text fz={"xs"}>Organization</Text>
                  <Text fz={"sm"} weight={"normal"}>
                    {activeRecord.clientOrganizationName}
                  </Text>
                </Stack>
              </Grid.Col>

              <Grid.Col span={6}>
                <Stack spacing="sm" align="flex-end">
                  <Stack spacing={0}>
                    <Text fz={"xs"} align="right">
                      Created
                    </Text>
                    <Text fz={"sm"} weight={"normal"}>
                      {new Date(activeRecord.createdDate).toLocaleString()}
                    </Text>
                  </Stack>
                  <Stack spacing={0}>
                    <Text fz={"xs"} align="right">
                      Expires
                    </Text>
                    <Text fz={"sm"} weight={"normal"}>
                      {new Date(activeRecord.expiryDate).toLocaleString()}
                    </Text>
                  </Stack>
                </Stack>
              </Grid.Col>
              <Grid.Col span={12}>
                <Space h={"xs"}></Space>
              </Grid.Col>
              <Grid.Col span={12}>
                <Stack>
                  <Stack spacing={0}>
                    <Paper shadow="md" p="md" withBorder>
                      <Text fz={"sm"} weight={"normal"}>
                        {activeRecord.isApproved
                          ? t(`${i18Prefix}.response`, {
                              context: "true",
                              respondent: activeRecord.approvedBy,
                              responseDate: new Date(
                                activeRecord.responseDate
                              ).toLocaleString(),
                            })
                          : t(`${i18Prefix}.response`, { context: "false" })}
                      </Text>
                    </Paper>
                  </Stack>
                </Stack>
              </Grid.Col>
              <Grid.Col span={12}>
                <Stack>
                  <Paper shadow="md" p="md" withBorder>
                    <Text fz={"sm"} weight={"normal"}>
                      {t(`${i18Prefix}.detailUserRoleDescription`, {
                        role: activeRecord.assignedRole,
                        organization: activeRecord.clientOrganizationName,
                      })}
                    </Text>
                    <Table fontSize={"sm"}>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activeRecord.users.map((x, idx) => {
                          return (
                            <tr>
                              <td>{idx + 1}</td>
                              <td>{x}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Paper>
                </Stack>
              </Grid.Col>
            </Grid>
          </Stack>
        ) : (
          <></>
        )}
      </Modal>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
