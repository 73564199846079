import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo, useState } from "react";

import { useAppSelector } from "../../hooks/reduxHelper";
import {
  useGetApiUserGetUsersQuery,
} from "../../services/appcenterApi";
import { selectOrganizationId } from "./userSlice";

export function useGetAllUsers() {
  
  //When count less 

  //console.log("params", params);
  const organizationId = useAppSelector(selectOrganizationId);

  const [page, setPage] = useState<number>(1);
  //const [pageSize, setPageSize] = useState<number>(1);
  const pageSize = 100;
  const [usersData, setUsersData] = useState([]);
  const [total, setTotal] = useState<number>(-1);
  const [currentLength, setCurrentLength] = useState<number>(0);

  const initialUsers = useGetApiUserGetUsersQuery(organizationId !== "" ? { 
    organizationId,
    page: page,
    pageSize: pageSize, } : skipToken);

   useMemo(() => {
      //return toCombineResult(initialUsers.data, obj.data);
      if (initialUsers.isSuccess) {


        setUsersData(usersData.concat(initialUsers.data?.data));
        setTotal(initialUsers.data.total);
        setCurrentLength(currentLength + initialUsers.data?.data.length);
        //setUsersData(initialUsers.data);
        

      }
    }, [initialUsers.isSuccess]);

    useMemo(() => {

      if (total >= usersData.length) {
        setPage(page + 1);
      }

    }, [currentLength])
  

    //console.log("usersData", usersData)

  //How to handle error
  return {
    ...initialUsers,
    data: {
      total: total,
      data: usersData
    }
    //data: combinedData,
  };
}
