import * as React from "react";
import {
  useGetApiOrganizationGetOrganizationQuery,
  usePostApiUserCreateOrganizationAdminMutation,
} from "../../services/appcenterApi";
import { UpdateForm } from "../../components/UpdateForm";
import {
  OrganizationAdministratorFormProvider,
  useOrganizationAdministratorForm
} from "../../features/organizations/OrganizationAdministratorFormContext";

import { OrganizationAdministratorInputs } from "../../features/organizations/OrganizationAdministratorInputs";
import { useWithCurrentOrganization } from "../../hooks/useWithCurrentOrganization";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";


export function UpdateOrganizationAdministrator() {
  const handleQueryResponseTransform = function (data) {    
    const tData = { ...data, name: data.organizationName }
    return tData;
  };
  return (
    <>
      <UpdateForm FormProvider={OrganizationAdministratorFormProvider} inputs={<OrganizationAdministratorInputs />} i18Prefix={"organization_admin"}
                  useUpdateMutation={usePostApiUserCreateOrganizationAdminMutation} useFormHook={useOrganizationAdministratorForm}
                  commandKey={"createOrganizationAdminCommand"}
        useLoadQuery={useWithCurrentOrganization(useGetApiOrganizationGetOrganizationQuery)}    
        transformResponse = {handleQueryResponseTransform}
      />
    </>
    // updateOrganizationCommand
  );

}