import {
  Alert,
  Divider,
  MultiSelect,
  Skeleton,
  Text,
  TextInput,
  Select, Switch, Checkbox
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure, useListState } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ImageUploadInput } from "../../components/ImageUploadInput";
import { useAppSelector } from "../../hooks/reduxHelper";
import {
  useGetApiOrganizationGetOrganizationQuery,
  useGetApiRoleGetAllRolesInOrgQuery,
  useGetApiOrganizationGetOrganizationsByUserIdQuery
} from "../../services/appcenterApi";
import { selectOrganizationId } from "../user/userSlice";
import { useUserFormContext } from "./UserFormContext";

export function UserInputs() {
  //Render inputs required for forms
  const form = useUserFormContext();
  const { t } = useTranslation();

  const orgCodeTextRef = React.useRef(null);
  const [orgCodeTextWidth, setOrgCodeTextWidth] = React.useState(200);
  const organizationCode = useAppSelector(
    (state) => state.user?.organization.code + "\\"
  );
  React.useEffect(() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = '14px -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji';
    const metrics = context.measureText(organizationCode);
    setOrgCodeTextWidth(metrics.width + 12);
  }, [organizationCode]);

  const organizationId = useAppSelector(selectOrganizationId);
  const {
    data: organization,
    error,
    isLoading,
  } = useGetApiOrganizationGetOrganizationQuery(
    organizationId && organizationId !== "" ? { organizationId } : skipToken
  );

  const [isEmailMandatory, emailHandler] = useDisclosure(false);
  const [isStaffIdMandatory, staffIdHandler] = useDisclosure(false);
  //const [isMobileMandatory, mobileHandler] = useDisclosure(false)

  //TODO: Application paging
  const { data: rolesData } = useGetApiRoleGetAllRolesInOrgQuery(
    organizationId !== ""
      ? {
          //page: 1,
          //pageSize: 20,
          organizationId: organizationId,
        }
      : skipToken
  );

  // @ts-ignore
  const rolesSelectData =
    rolesData == null
      ? []
      : rolesData
          ?.map((x) => ({
            value: x.id,
            label: x.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

  const userId = form.values.id;
  const { data: organizationsData } = useGetApiOrganizationGetOrganizationsByUserIdQuery(
    userId !== "" && organizationId !== ""
      ? {
          userId: userId,
        }
      : skipToken
  );
  const organizationsSelectData = 
    organizationsData == null
    ? []
    : organizationsData
        ?.map((x) => ({
          value: x.id,
          label: x.organizationName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (organizationId) form.setFieldValue("organizationId", organizationId);
  }, [organizationId]);

  useEffect(() => {
    if (organization) {
      console.log("Organization", organization);
      // @ts-ignore
      for (
        var i = 0;
        i < organization.organizationPolicy.loginType.length;
        i++
      ) {
        var loginType = organization.organizationPolicy.loginType[i];
        console.log(loginType);
        switch (loginType) {
          case "Email":
            emailHandler.open();
            form.setFieldValue("isEmailMandatory", true);
            break;

          case "Code":
            staffIdHandler.open();
            form.setFieldValue("isStaffIdMandatory", true);
            break;
        }
      }
    }
  }, [organization]);

  return (
    <>
      <TextInput
        label={t("user.userFullName")}
        withAsterisk={true}
        {...form.getInputProps("userFullName")}
      />

      <Skeleton visible={isLoading}>
        <Alert title={t("user.loginUsername") + "*"} variant="outline">
          {isEmailMandatory && (
            <TextInput
              label={t("user.email")}
              //withAsterisk={isEmailMandatory}
              //hidden={!isEmailMandatory}
              {...form.getInputProps("email")}
            />
          )}

          {isEmailMandatory && isStaffIdMandatory && (
            <Divider my="xs" label="or" labelPosition="center" />
          )}

          {isStaffIdMandatory && (
            <TextInput
              label={t("user.staffId")}
              //withAsterisk={isStaffIdMandatory}
              icon={
                <Text
                  ref={orgCodeTextRef}
                  fz="sm"
                  sx={{ paddingLeft: "12px", whiteSpace: "nowrap" }}
                >
                  {organizationCode}
                </Text>
              }
              iconWidth={orgCodeTextWidth}
              {...form.getInputProps("staffId")}
            />
          )}
        </Alert>

        <MultiSelect
          data={rolesSelectData}
          label={t("user.roles")}
          dropdownPosition="top"
          withAsterisk={true}
          {...form.getInputProps("roles")}
        />

        {!isEmailMandatory && (
          <TextInput
            label={t("user.email")}
            //withAsterisk={isEmailMandatory}
            //hidden={!isEmailMandatory}
            {...form.getInputProps("email")}
          />
        )}

        {!isStaffIdMandatory && (
          <TextInput
            label={t("user.staffId")}
            //withAsterisk={isStaffIdMandatory}
            icon={
              <Text
                ref={orgCodeTextRef}
                fz="sm"
                sx={{ paddingLeft: "12px", whiteSpace: "nowrap" }}
              >
                {organizationCode}
              </Text>
            }
            iconWidth={orgCodeTextWidth}
            {...form.getInputProps("staffId")}
          />
        )}
      </Skeleton>

      <TextInput
        label={t("user.mobileNumber")}
        //withAsterisk={isMobileMandatory}
        {...form.getInputProps("mobileNumber")}
      />

      <ImageUploadInput
        label={t("user.image")}
        {...form.getInputProps("profilePicture")}
        editorHeight={480}
        editorWidth={480}
        thumbHeight={60}
        thumbWidth={60}
      />

      <DatePicker
        label={t("user.startDate")}
        allowFreeInput={true}
        {...form.getInputProps("startDate")}
      />
      <DatePicker
        label={t("user.endDate")}
        allowFreeInput={true}
        {...form.getInputProps("endDate")}
      />

      <Switch checked={form.values.skipTwoFactorAuth} label={t("user.skipTwoFactorAuth")} {...form.getInputProps("skipTwoFactorAuth")} />

      {!APPCENTER && userId != undefined && (
        <Select
          data={organizationsSelectData}
          withAsterisk
          label={t("user.rootOrganization")}
          {...form.getInputProps("rootOrganizationId")}
        />
      )}
    </>
  );
}
