import { ActionIcon, Center, CopyButton, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";

interface TableCopyButtonProps {
  id: string,
  copyLabel: any,
  copiedLabel: any,
}

export function TableCopyButton(inputProps: TableCopyButtonProps) {

  //const { t } = useTranslation();

  return (

    <><Center>
      <CopyButton value={inputProps.id} timeout={2000}>
        {({ copied, copy }) => (
          <Tooltip label={copied ? inputProps.copiedLabel : inputProps.copyLabel} withArrow position="right">
            <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </ActionIcon>
          </Tooltip>
        )}
      </CopyButton></Center>
    </>

  );
}