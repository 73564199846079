import {
  Button,
  Group,
  MantineProvider,
  MantineThemeOverride,
  Text,
} from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CxError } from "shared-library/src/components/CxError";

import { ActivationForm } from "../../features/signup/ActivationForm";
import { VerifyInputs } from "../../features/signup/VerifyInputs";
import {
  VerifyUserFormProvider,
  VerifyUserResponseTransformation,
  useVerifyUserForm,
} from "../../features/signup/VerifyUserFormContext";
import { usePostVerifyUserMutation } from "../../services/activationApi";

export function Activated() {
  const { t } = useTranslation();
  const i18Prefix = "activated";
  const navigate = useNavigate();

  return (
    <>
      <Text fz="lg" mt="sm" fw={500}>
        {t("activation.title", { context: i18Prefix })}
      </Text>
      <Text c="dimmed" fz="sm">
        {t("activation.description", { context: i18Prefix })}
      </Text>
      <Group position={"right"}>
        <Button
          onClick={() => {
            navigate("/");
          }}
        >
          {t("activation.submit", { context: i18Prefix })}
        </Button>
      </Group>
    </>
  );
}
