import { createFormContext } from "@mantine/form";
import { z } from "zod";
import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";
import { UpdateOrganizationPolicyCommand } from "../../services/appcenterApi";


interface SecurityPolicyFormValues {

  //id? : string,
  organizationId: string,
  loginType: string[],
  enableTwoFactorAuthentication: boolean,
  twoFactorAuthenticationType: string,
  whiteListEmailDomain: string[],
  whiteListIpAddresses: string[],
  noOfInvalidOtpAttempts: number,
  enablePasswordPolicy: boolean,
  passwordMinLength: number,
  "passwordMaxLength": number,
  "passwordRequireLowerCase": boolean,
  "passwordRequireUpperCase": boolean,
  "passwordRequireNumericCase": boolean,
  "passwordRequireSpecialCase": boolean,
  "passwordMatchEmailCase": boolean,
  "invalidRetires": number,
  "passwordExpiry": number,
  "passwordHistory": number,
  "accountLockDuration": number,
  "idleTimeOut": number,
  "allowConcurrentSession": boolean,
  "enableRecaptcha": boolean,

  "identityProvider": string,
  "defaultNewUserRoles": [],
  "userInactivityDuration": number
}


export const SecurityPolicyFormInitialValues: UpdateOrganizationPolicyCommand = {
  accountLockDuration: 2,
  allowConcurrentSession: false,
  enablePasswordPolicy: false,
  enableRecaptcha: false,
  enableTwoFactorAuthentication: false,
  idleTimeOut: 0,
  invalidRetires: 0,
  loginType: [],
  noOfInvalidOtpAttempts: 3,
  //organizationId: "",
  passwordExpiry: 0,
  passwordHistory: 0,
  //passwordMatchEmailCase: false,
  passwordMaxLength: 0,
  passwordMinLength: 0,
  //passwordRequireLowerCase: false,
  //passwordRequireNumericCase: false,
  passwordRequireSpecialCase: false,
  //passwordRequireUpperCase: false,
  twoFactorAuthenticationType: "",
  whiteListEmailDomain: [],
  whiteListIpAddresses:[],
  identityProviders: [{}],
  defaultNewRoles: [],
  userInactivityDuration: 30
}



const passwordSchema = z.object({
  //loginTypeEmail: z.literal<boolean>(true)
  passwordMinLength: z.number().min(12),
  passwordMaxLength: z.number().max(80),
  passwordRequireSpecialCase: z.boolean(),
  passwordExpiry: z.number().min(180),
  passwordHistory: z.number(),
  whiteListEmailDomain: z.string().array(),  
  defaultNewRoles: z.string().uuid().array(),
})

const commonSchema = z.object({
  whiteListIpAddresses: z.string().array().refine((val) => {
    let regex = new RegExp('^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([1-2][0-9]|3[0-2]|[0-9])$');
    for (let i = 0; i < val.length; i++) {
      if (!regex.test(val[i])) {
        return false;
      }
    }    
    return true; 
  }, (val) => { return ({ message:"validation.incorrectCidrBlockFormat" }) }) ,
  twoFAWhiteListedIpAddress: z.string().array().refine((val) => {
    let regex = new RegExp('^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([1-2][0-9]|3[0-2]|[0-9])$');
    for (let i = 0; i < val.length; i++) {
      if (!regex.test(val[i])) {
        return false;
      }
    }
    return true;
  }, (val) => { return ({ message:"validation.incorrectCidrBlockFormat" }) })
})

const checkForMinMax = (val) => val.passwordMinLength <= val.passwordMaxLength

const emailSchema = passwordSchema.extend({
  loginTypeEmail: z.literal(true)
}).refine(checkForMinMax, {message: "validation.minMax_organizationPolicy", path: ["passwordMinLength"]})

const staffIdSchema = passwordSchema.extend({
  loginTypeCode: z.literal(true)
}).refine(checkForMinMax, {message: "validation.minMax_organizationPolicy", path: ["passwordMinLength"]})

const mobileSchema = passwordSchema.extend({
  loginTypeMobile: z.literal(true)
}).refine(checkForMinMax, {message: "validation.minMax_organizationPolicy", path: ["passwordMinLength"]})

const noPasswordSchema = z.object({
  loginTypeMobile: z.literal(false),
  loginTypeCode: z.literal(false),
  loginTypeEmail: z.literal(false),
  loginTypeCorporate: z.literal(true)
})

const checkIDP = (val) => val.identityProvider != null && val.identityProvider != ""
const idpSchema = z.object({
  identityProvider: z.string().array().min(1),
  whiteListEmailDomain: z.string().array().min(1),  
}).refine(checkIDP, {message: "validation.twoIDP_organizationPolicy", path: ["identityProvider"]})

const corporateSchema = z.object({
  loginTypeCorporate: z.literal(true),
 //identityProvider: z.array(idpSchema).min(1).max(1),
  //identityProvider: z.object(idpSchema)
  identityProvider: z.object({
    identityProvider: z.string().array().min(1),
    whiteListEmailDomain: z.string().array().min(1),
  })
})

const noCorporateSchema = z.object({
  loginTypeCorporate: z.literal(false)
})

const extendedCorporateSchema = z.discriminatedUnion(
  "loginTypeCorporate", [corporateSchema, noCorporateSchema]
)

const checkTwoFA = (val) => val.twoFactorAuthenticationType != null && val.twoFactorAuthenticationType != "";

const twoFASchema = z.object({
  enableTwoFactorAuthentication: z.literal(true),
  twoFactorAuthenticationType: z.string().nullable()
}).refine(checkTwoFA, {message: "validation.twoFA_organizationPolicy", path: ["twoFactorAuthenticationType"]})
const noTwoFASchema = z.object({
  enableTwoFactorAuthentication: z.literal(false),
  twoFactorAuthenticationType: z.string().nullable()
})

const checkLockout = (val) => {
  return val.accountLockDuration != undefined 
        && val.accountLockDuration != null 
        && val.accountLockDuration !== "" 
        && val.accountLockDuration >= 0;
}
const lockoutSchema = z.object({
  enableTwoFactorAuthentication: z.literal(true),
  accountLockDuration: z.number().min(0).catch(undefined)
}).refine(checkLockout, {message: "validation.accountLockDuration_organizationPolicy", path: ["accountLockDuration"]})
const noLockoutSchema = z.object({
  enableTwoFactorAuthentication: z.literal(false),
  accountLockDuration: z.number().min(0).catch(undefined)
})

const checkInvalidOtpAttempts = (val) => {
  return val.noOfInvalidOtpAttempts != undefined 
          && val.noOfInvalidOtpAttempts != null 
          && val.noOfInvalidOtpAttempts !== "" 
          && val.noOfInvalidOtpAttempts >= 0;
}
const invalidOtpAttemptsSchema = z.object({
  enableTwoFactorAuthentication: z.literal(true),
  noOfInvalidOtpAttempts: z.number().min(0).catch(undefined)
}).refine(checkInvalidOtpAttempts, {message: "validation.noOfInvalidOtpAttempts_organizationPolicy", path: ["noOfInvalidOtpAttempts"]})
const noInvalidOtpAttemptsSchema = z.object({
  enableTwoFactorAuthentication: z.literal(false),
  noOfInvalidOtpAttempts: z.number().min(0).catch(undefined)
})

const combinedTwoFASchema = z.intersection(twoFASchema, z.intersection(lockoutSchema, invalidOtpAttemptsSchema))
const combinedNoTwoFASchema = z.intersection(noTwoFASchema, z.intersection(noLockoutSchema, noInvalidOtpAttemptsSchema))

const checkUserInactivity = (val) => {
  return val.userInactivityDuration != undefined 
          && val.userInactivityDuration != null
          && val.userInactivityDuration !== ""
          && val.userInactivityDuration >= 0;
}
const userInactivitySchema = z.object({
  userInactivityDuration: z.number().min(0).max(2555).catch(undefined)
}).refine(checkUserInactivity, {message: "validation.userInactivityDuration_organizationPolicy", path: ["userInactivityDuration"]})

const emailTwoFASchema = z.intersection(emailSchema, combinedTwoFASchema);
const emailNoTwoFASchema = z.intersection(emailSchema, combinedNoTwoFASchema);
const emailCombinedSchema = z.intersection(z.union([emailTwoFASchema, emailNoTwoFASchema]), userInactivitySchema);

const staffIdTwoFASchema = z.intersection(staffIdSchema, combinedTwoFASchema);
const staffIdNoTwoFASchema = z.intersection(staffIdSchema, combinedNoTwoFASchema);
const staffIdCombinedSchema = z.intersection(z.union([staffIdTwoFASchema, staffIdNoTwoFASchema]), userInactivitySchema);

//export const SecurityPolicyFormSchema = extendedCorporateSchema
export const SecurityPolicyFormSchema =
  z.intersection(
    z.intersection(
      z.union([emailCombinedSchema, staffIdCombinedSchema, mobileSchema, noPasswordSchema]), extendedCorporateSchema
    ),
    commonSchema
  );

export const SecurityPolicyFormTransformation = (values) => {
  const loginTypes = []
  
  if (values.loginTypeEmail) {
    loginTypes.push('Email')
    delete values.loginTypeEmail
  }

  if (values.loginTypeMobile) {
    loginTypes.push('Mobile')
    delete values.loginTypeMobile
  }

  if (values.loginTypeCode) {
    loginTypes.push('Code')
    delete values.loginTypeCode
  }  

  if (!values.loginTypeCorporate) {
    //loginTypes.push('Corporate')
    //TODO: Call another API to update
    delete values.loginTypeCorporate
    delete values.identityProvider
  }
  

  return {
    ...values,
    
    loginType: loginTypes
  }
}

export const SecurityPolicyResponseTransformation = (values) => {

  console.log('values', values)
  const loginTypes  = values.organizationPolicy?.loginType ? values.organizationPolicy.loginType : []
  console.log('loginTypes', loginTypes)

  return {
    ...values.organizationPolicy,
    //name: values.organizationName,
    //code: values.organizationCode
    loginType: loginTypes,
    loginTypeEmail: loginTypes.includes('Email'),
    loginTypeMobile: false, //Disable mobile
    loginTypeCode: loginTypes.includes('Code'),
    loginTypeCorporate: values.organizationPolicy.identityProvider?.identityProvider?.length > 0,
    passwordMinLength: values.organizationPolicy.passwordMinLength ?? 12,
    passwordMaxLength: values.organizationPolicy.passwordMaxLength ?? 80,
    passwordExpiry: values.organizationPolicy.passwordExpiry ?? 180,
    passwordRequireSpecialCase: values.organizationPolicy.passwordRequireSpecialCase ?? false,

    //enableOtp: !!values.twoFactorAuthenticationType,
    organizationName: values.organizationName,
    passwordHistory: values.organizationPolicy.passwordHistory ?? 0,

    accountLockDuration: values.organizationPolicy.accountLockDuration ?? 2,
    noOfInvalidOtpAttempts: values.organizationPolicy.noOfInvalidOtpAttempts ?? 3,
    userInactivityDuration: values.organizationPolicy.userInactivityDuration ?? 30,

    organizationId: values.id
  }
}
export const [SecurityPolicyFormProvider, useSecurityPolicyFormContext, useSecurityPolicyNativeForm] = createFormContext<UpdateOrganizationPolicyCommand>()
export function useSecurityPolicyForm() {
  return useSecurityPolicyNativeForm(
    {
      initialValues: SecurityPolicyFormInitialValues,
      transformValues: SecurityPolicyFormTransformation,
      //validate: translateValidationErrorMessages(zodResolver(OrganizationFormSchema)),
      validate: useCustomZodResolver('organizationSecurityPolicy', SecurityPolicyFormSchema),

    }
  )
}