import React from "react";
import { ActivationForm } from "../../features/signup/ActivationForm";
import { SignUpUserFormProvider, SignUpUserResponseTransformation, useSignUpUserForm } from "../../features/signup/SignUpUserFormContext";
import { SignUpInputs } from "../../features/signup/SignUpInputs";
import { usePostSignUpUserMutation } from "../../services/activationApi";

export function SignUp() {
  return (
    <>
      <ActivationForm FormProvider={SignUpUserFormProvider} inputs={<SignUpInputs />} useFormHook={useSignUpUserForm}
      transformResponse={SignUpUserResponseTransformation}
      useUpdateMutation={usePostSignUpUserMutation} i18Prefix={'signUp'} commandKey={'signUpUserCommand'} activationStatus={"PendingActivation"} />
    </>
  );
}