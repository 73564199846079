import * as React from "react";
import { App } from "./app/App";
import * as Oidc from "oidc-client-ts";
import { createRoot } from "react-dom/client";
import { loadRemoteConfig } from "@ascentis/cx-remote-config";
import { LoadingOverlay } from "@mantine/core";
import { themeAscentis } from "@ascentis/cx-reactjs-ui";
import { MfeGlobalStateProvider } from "shared-library/src/provider/mfeGlobalStateProvider";

//const title = "ascentisCx";
const REACT_VERSION = React.version;
Oidc.Log.setLogger(console);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(<LoadingOverlay visible={true} loaderProps={{ color: '#B51614' }} />)

loadRemoteConfig("/config.json")  
  .then(async () => {   
    root.render(<MfeGlobalStateProvider><App/></MfeGlobalStateProvider>);
  })
  .catch(() => {
    root.render(<div>Failed to load application configuration. Please try to refresh later.</div>);
  });
console.warn(REACT_VERSION);