import { Box, ScrollArea, createStyles } from "@mantine/core";
import * as React from "react";

import { ErrorAlertListener } from "./ErrorAlertListener";

const useStyles = createStyles((theme) => ({
  topSection: {
    paddingBottom: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    //marginTop: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginLeft: -theme.spacing.md,
    marginBottom: theme.spacing.md,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  middleSection: {
    flex: 1,
  },

  bottomSection: {
    paddingTop: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gap: theme.spacing.md,
    marginTop: theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginLeft: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface ContentShellProps {
  topSection: React.ReactNode;
  bottomSection: React.ReactNode;
  children: React.ReactNode;
  hideTopSection?: boolean;
  hideBottomSection?: boolean;
}

//TODO: Should I make it a default props to be empty with minimum height?

export function ContentShell(props: ContentShellProps) {
  const { classes, cx } = useStyles();

  return (
    <>
      {(!props.hideTopSection || props.hideTopSection !== true) && (
        <Box className={classes.topSection}>{props.topSection}</Box>
      )}
      <ScrollArea
        offsetScrollbars={true}
        type={"auto"}
        className={classes.middleSection}
      >
        <ErrorAlertListener />
        {props.children}
      </ScrollArea>
      {(!props.hideBottomSection || props.hideBottomSection !== true) && (
        <Box className={classes.bottomSection}>{props.bottomSection}</Box>
      )}
    </>
  );
}
