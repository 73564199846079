import * as React from "react";
import { Box, Button, createStyles, Group, ScrollArea, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

}));

interface ErrorProps {

  code: string,
  title: string,
  description: string

}

//TODO: Should I make it a default props to be empty with minimum height?

export function CxError(props: ErrorProps) {

  const {classes, cx} = useStyles();


  return (
    <>
      <div className={classes.label}>{props.code}</div>
      <Title className={classes.title}>{props.title}</Title>
      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        {props.description}
      </Text>
      <Group position="center">
        <Button component={Link} variant="subtle" size="md" to={"/"}>
          Take me back to home page
        </Button>
      </Group>
    </>
  )
}