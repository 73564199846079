import React from "react";
import { Button, Code, Group, Modal, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { usePatchApiApiClientResetSecretKeyMutation } from "../../services/appcenterApi";
import { TableCopyButton } from "@ascentis/cx-reactjs-ui";

export interface UpdateClientSecretModal {

  apiClientId: number,
  apiClientName: string,
  opened: boolean,
  setOpened: any,
}

export function UpdateClientSecretModal({ apiClientId, apiClientName, opened, setOpened }: UpdateClientSecretModal) {

  //Wait for result of query
  const { t } = useTranslation();

  const [resetSecretKey, { reset, data, isError, isLoading, isSuccess }] = usePatchApiApiClientResetSecretKeyMutation();

  const onClick = () => {
    console.log(apiClientId);
    resetSecretKey({ id: apiClientId });
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          reset();
          setOpened(false);
        }}
        title={t("apiClient.modal.updateClientSecret.title")}
      >
        <Stack>
          {!isSuccess &&
            <Text>
              {t("apiClient.modal.updateClientSecret.description", { data: { name: apiClientName } })}
            </Text>
          }
          {
            isSuccess && <>
              <Text>
                {t("apiClient.clientSecrets")} for {apiClientName} :
              </Text>
              <Group position={"apart"}>
                <Code>{data}</Code>
                <TableCopyButton id={data}
                                 copyLabel={t("apiClient.list.copyClientSecrets_copied")}
                                 copiedLabel={t("apiClient.list.copyClientSecrets_copied")}
                />
              </Group>
            </>
          }
          <Group position={"right"}>
            <Button onClick={() => {
              reset();
              setOpened(false);
            }} variant={"subtle"}>
              <Text tt={"capitalize"}>
              {!isSuccess ? t("common.cancel") : t("common.close")}
              </Text>
            </Button>
            <Button onClick={onClick} disabled={isSuccess} loading={isLoading}>{t("common.confirm")}</Button>

          </Group>
        </Stack>
      </Modal>
    </>
  );
}