import {
  usePostApiApiClientCreateApiClientMutation,
  usePostApiApiClientEditApiClientMutation,
  usePostApiFileUploadMutation
} from "../../services/appcenterApi";
import { useUploadFileMutation } from "../../hooks/useUploadFileMutation";

function useUploadBackgroundImageMutation() {
  const getUrl = (values) => {
    return values.updateClientCredentialCommand.backgroundImage;
  };
  const transformValues = (values, data) => {
    values.updateClientCredentialCommand.backgroundImage = data[0].key;
    return values;
  };

  const transformExistingValues = (values) => {
    values.updateClientCredentialCommand.backgroundImage =
      values.updateClientCredentialCommand.backgroundImageKey;
    return values;
  };

  return useUploadFileMutation({
    useMutationHook: usePostApiApiClientEditApiClientMutation
    , transformValues, getUrl, transformExistingValues
  });
}
export function useUpdateApiClientMutation() {

  const getUrl = (values) => {
    return values.updateClientCredentialCommand.logoUri;
  };
  const transformValues = (values, data) => {
    values.updateClientCredentialCommand.logoUri = data[0].key;
    return values;
  };

  const transformExistingValues = (values) => {
    values.updateClientCredentialCommand.logoUri =
      values.updateClientCredentialCommand.logoUriKey;
    return values;
  };

  return useUploadFileMutation({
    useMutationHook: useUploadBackgroundImageMutation
    , transformValues, getUrl, transformExistingValues
  });
}