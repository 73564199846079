import { ContentShell } from "shared-library/src/components/ContentShell";
import { Anchor, Box, Breadcrumbs, Button, Group, Select, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ListPageShell } from "shared-library/src/components/ListPageShell";
import { selectOrganizationId } from "shared-library/src/features/user/userSlice";
import { UserGroupAssignmentDataTable } from "shared-library/src/features/userGroups/UserGroupAssignmentDataTable";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";
import {useGetAllUsers} from "shared-library/src/features/user/useGetAllUsers";
import { useGetApiApplicationApplicationsQuery, useGetApiApplicationGroupGetApplicationGroupQuery, useGetApiApplicationGroupGetCustomGroupQuery, useGetApiApplicationGroupGetUsersFromGroupQuery, useGetApiUserGetUsersQuery, usePostApiApplicationGroupAddUserToApplicationGroupMutation } from "shared-library/src/services/appcenterApi";

export function useGetUserAssignments() {

  //TODO: How to get the error from upload file to 'main' form
  //upload files

  const { id } = useParams()
  const organizationId = useAppSelector(selectOrganizationId);

  return useGetApiApplicationGroupGetUsersFromGroupQuery(
    organizationId !== "" ?
      {
        organizationId: organizationId,
        applicationGroupId: id
      } : skipToken);

  //return result
}

export function ListGroupAssignments() {

  //console.log('Nothing')
  const {t} = useTranslation();
  const {id} = useParams();
  const organizationId = useAppSelector(selectOrganizationId);

  const {data} = useGetApiApplicationGroupGetApplicationGroupQuery(organizationId !== "" ? { id } : skipToken);

  //const {data : users, isSuccess, isFetching} = useGetApiUserGetUsersQuery(organizationId !== "" ? { organizationId } : skipToken);

  const {data : users, isSuccess, isFetching} = useGetAllUsers();

  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const query = useGetUserAssignments();
  const i18Prefix = "userGroupAssignment"
  //const navigate = useNavigate();

  const [
    addUser,
    {
      //data: updateResult,
      error,
      isLoading,
      isSuccess : isAddSuccess,
      isError,
    },
  ] = usePostApiApplicationGroupAddUserToApplicationGroupMutation();

  const assignedUsers = query.isSuccess ? query.data.map(item => item.id) : []

  // @ts-ignore
  //console.log("users", users)
  const usersSelectData =
    users == null
      ? []
      : users.data.map(item => {
        return {
          label: item.userFullName,
          value: item.id
        }
      }).filter((item) => !assignedUsers.includes(item.value))

  const handleAdd = () => {

    if (selectedUser != null && selectedUser != "") {
      addUser({
        addUserToApplicationGroupCommand: {
          userId: selectedUser,
          applicationGroupId: id
        }
      })
      //setSelectedUser("")
    }
  }

  useEffect(() => {
    if (isAddSuccess) {
      
      //setSubmitError(false)
      showNotification({
        title: t("new.success_title", { context: i18Prefix }),
        message: t("new.success_message", {
          context: i18Prefix,
          data: {},
        }),
      });

      
      setSelectedUser("")
      //navigate("..");
    }
  }, [isAddSuccess]);
  
  return (
    <>
      
      <ContentShell
        topSection={          
            <>
              <Breadcrumbs>
              <Anchor component={Link} to={".."}>
                  {t("list.title", { context: "userGroup", count: 2 })}
                </Anchor>
                <Anchor span={true} variant={"text"}>
                  {t("list.title", { context: i18Prefix, count: 2, data: data })}
                </Anchor>
              </Breadcrumbs>
            </>          
        }
        bottomSection={<></>}
      >
        <Stack>

          <Group grow>
            <Button fullWidth={false} onClick={handleAdd} disabled={selectedUser == null || selectedUser == ""}>
              {t("userGroupAssignment.add")}
            </Button>
            <Select
              disabled={isLoading}
              clearable
              value={selectedUser}
              onChange={setSelectedUser}
              searchable
              placeholder={t("userGroupAssignment.addPlaceholder")}
              nothingFound={t("userGroupAssignment.empty")}
            data={usersSelectData} />
          </Group>

          <Text>{t("userGroupAssignment.description")}</Text>

          <UserGroupAssignmentDataTable
            // tableHeight={'53vh'}
            isFetching={query.isFetching}
            totalRecords={query.isSuccess ? query.data.length : 0}
            //columns={columns}
            records={query.isSuccess ? query.data : []}
          />
        </Stack>

        {/* Search Keywords & Filter */}
      </ContentShell>
    
    </>
  );
}