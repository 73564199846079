import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Stack,
  TextInput,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ComponentType, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../hooks/reduxHelper";
import { ContentShell } from "./ContentShell";
import { DeleteContextModal } from "./DeleteContextModal";

interface ListPageShellProps {
  useQuery: any;
  queryParam?: object;
  DataTable: ComponentType<any>;  
  //pageActions: React.ReactNode,
  initialSortKey?: string;
  topLeftTableActions?: React.ReactNode;
  topSection?: React.ReactNode;
  bottomSection?: React.ReactNode;  
  i18Prefix: string;
  sortDesc?: boolean;
  initialSearchTerm?: string;
}
export function ListPageShell({
  useQuery,
  queryParam,
  DataTable,
  initialSortKey,
  i18Prefix,
  topLeftTableActions,
  bottomSection,
  topSection,
  sortDesc,
  initialSearchTerm  
}: ListPageShellProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState<string>(
    initialSortKey ? initialSortKey : ""
  );
  const [isDesc, setIsDesc] = useState<boolean>(sortDesc ?? false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [rawSearchTerm, setRawSearchTerm] = useState<string>(initialSearchTerm ?? "");
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm ?? "");  
  const [debouncedSearchTerm] = useDebouncedValue(rawSearchTerm, 500);
  const [searchInputError, setSearchInputError] = useState<string>("")
  
  const handleSearchTermChange = function (e) {
    setRawSearchTerm(e.currentTarget.value);
  }

  useEffect(() => {        
    if (debouncedSearchTerm != null || debouncedSearchTerm != undefined) {      
      if (debouncedSearchTerm == "" || debouncedSearchTerm.length >= 3) {
        setSearchTerm(debouncedSearchTerm);        
        setSearchInputError("");
      } else if (debouncedSearchTerm.length != "" && debouncedSearchTerm.length < 3) {
        setSearchInputError(t("validation.at_least_3_characters", {context:i18Prefix}));
      }
    }     
  }, [debouncedSearchTerm]);

  const organizationId = useAppSelector((state) => state.user?.organization.id);  

  const query = useQuery(
    organizationId !== ""
      ?      
      {   
          organizationId: organizationId,
          sortBy: sortBy,
          isDesc: isDesc,
          page: page,
          pageSize: pageSize,
          searchTerm: searchTerm,
          ...queryParam          
        }
      : skipToken
  );
  //Override?  
  return (
    <>
      <ContentShell
        topSection={
          topSection ?? (
            <>
              <Breadcrumbs>
                <Anchor span={true} variant={"text"}>
                  {t("list.title", { context: i18Prefix, count: 2 })}
                </Anchor>
              </Breadcrumbs>
            </>
          )
        }
        bottomSection={          
          bottomSection ?? (
            <>
              <Button onClick={() => navigate("create")}>
                {t("list.addNew", { context: i18Prefix })}
              </Button>
            </>
          )
        }
      >
        <Stack>
          <Group position={"apart"} grow>
            <TextInput
              //placeholder={t(i18Prefix + ".list.searchPlaceholder")}
              placeholder={t("search.placeHolder", { context: i18Prefix })}
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              value={rawSearchTerm}
              onChange={handleSearchTermChange}
              error={searchInputError}
              //styles={{
              //  input: {
              //    width: '100%'
              //  }
              //}}
            />

            <Group position={"right"}>{topLeftTableActions}</Group>
          </Group>

          <DataTable
            // tableHeight={'53vh'}

            isFetching={query.isFetching}
            //sortStatus={sortStatus}
            //setSortStatus={setSortStatus}
            sortBy={sortBy}
            setSortBy={setSortBy}
            isDesc={isDesc}
            setIsDesc={setIsDesc}
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPage={setPage}
            totalRecords={query.isSuccess ? query.data?.total : 0}
            //columns={columns}
            records={query.isSuccess ? query.data?.data : []}
          />
        </Stack>

        {/* Search Keywords & Filter */}
      </ContentShell>
    </>
  );
}
