import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, FocusTrap, List } from "@mantine/core";
import { useForm } from "@mantine/form";
import React from "react";
import { useTranslation } from "react-i18next";

interface FormErrorAlertProps {
  isError: boolean;
  title: string;
  error: any;
}

export function FormErrorAlert(props: FormErrorAlertProps) {
  let errorMessage = "";
  const form = useForm();
  let errorMessages = [];
  const { t } = useTranslation();

  console.log(props.error);

  if (
    props?.error?.data instanceof String ||
    typeof props?.error?.data === "string"
  ) {
    //console.log(props.error.data);
    //console.log(props.error);

    errorMessage = props.error.data;
    errorMessages.push(<List.Item>{props.error.data}</List.Item>);
  } else {
    console.log("else", props.error);

    const error = props.error?.data ?? props.error;

    console.log("error",error);

    if (error) {
      //console.log(props.error.data.status);
      //if error is 401: show forbidden
      if (
        error.status &&
        (error.status === 401 || error.status === 403)
      ) {
        errorMessages.push(t("error.message_401"));
      } else {
        let errorFields = Object.keys(error.Data);
        errorFields.forEach((path) => {
          //console.log("Path", path);
          //console.log("Error", error[path]);
          //issue with too much re-rendering.
          //form.setFieldError(path, props?.error?.data[path])
          errorMessages.push(<List.Item>{error.Data[path]}</List.Item>);
        });
      }

      //errorMessage = 'object'
    }
  }

  return (
    <>
      {props.isError && (
        <FocusTrap active={props.isError}>
          <Alert
            data-autofocus
            icon={<FontAwesomeIcon icon={faCircleExclamation} />}
            title={props.title}
            color={"red"}
          >
            <List>{errorMessages}</List>
          </Alert>
        </FocusTrap>
      )}
    </>
  );
}
