import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CxDataTable, CxDataTableProps } from "./CxDataTable";
import { useAppSelector } from "../hooks/reduxHelper";

export function AffectedUsersDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationId = useAppSelector((state) => state.user?.organization.id);
  const i18Prefix = "affectedUsers";

  const columns = [
    {
      accessor: "userFullName",
      title: t("user.userFullName"),
      sortable: true,
      // width: 300,
      ellipsis: true,
    },
    {
      accessor: "staffId",
      title: t("user.staffId"),
      sortable: true,
      // width: 400,
      ellipsis: true,
    },
    {
      accessor: "email",
      title: t("user.email"),
      sortable: true,
      ellipsis: true,
    },
  ];

  return (
    <>
      {/*<CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />*/}
      <CxDataTable columns={columns} {...props} />
    </>
  );
}
