import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";


const identityBaseQuery = (args, api, extraOptions) => {

    const id4Url = () => window.env.identityUrl
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: id4Url(),
        prepareHeaders: (headers: Headers, {getState} : Pick<any, any>): MaybePromise<Headers> => {

            // @ts-ignore
            const { access_token } = getState().user
            headers.set('Accept', 'application/json')
            headers.set('Content-Type', 'application/json')
            headers.set('Authorization', `Bearer ${access_token}`)

            return headers
        }
    });

    return new Promise<any> ((resolve, reject) => {
        rawBaseQuery(args, api, extraOptions).then((response) =>{

            resolve(response)
        })
    })
}

export const identityApi = createApi({
    reducerPath: 'identityApi',
    baseQuery: identityBaseQuery,
    endpoints: (builder) => ({
        getUserInfo: builder.query({
            query: () => "/connect/userinfo"
        }),
    })
})

export const {useGetUserInfoQuery} = identityApi