import * as React from "react";
import { useState } from "react";
import { Center, Input, NumberInput, SegmentedControl, Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useApiClientFormContext } from "./ApiClientFormContext";
import { MultiTextInput } from "../../components/MultiTextInput";
import { TableCopyButton } from "@ascentis/cx-reactjs-ui";


function GrantType() {

}

function ClientCredentialsInputs() {

  const form = useApiClientFormContext();
  const { t } = useTranslation();

  return (
    <>
      {

      }
    <fieldset>
      <legend>{t("apiClient.group_client_credentials")}</legend>


      {/*form.values['clientSecrets'] &&
      <TextInput disabled={true} label={t("apiClient.clientSecrets")} {...form.getInputProps("clientSecrets")}

        rightSection={<TableCopyButton  copyLabel={t("apiClient.list.copyClientSecrets_copied")}
                                        copiedLabel={t("apiClient.list.copyClientSecrets_copied")}
                                        id={form.values['clientSecrets']}/>}
      ></TextInput>*/
      }
      {/*
        !form.values['clientSecrets'] &&
        <Text>{t("apiClient.new.clientSecrets")}</Text>
        */
      }

      <TextInput disabled={true} label={t("apiClient.clientSecrets")} {...form.getInputProps("clientSecrets")}
        placeholder={t("apiClient.new.clientSecrets")}
                 rightSection={!form.values['clientSecrets'] ? <></> : <TableCopyButton  copyLabel={t("apiClient.list.copyClientSecrets_copied")}
                                                 copiedLabel={t("apiClient.list.copyClientSecrets_copied")}
                                                 id={form.values['clientSecrets']}/>}
      ></TextInput>

    </fieldset>
    </>
  )
}

function AuthCodeInputs() {

  const form = useApiClientFormContext();
  const { t } = useTranslation();




  //TODO:
  return (
    <>
      <fieldset>
        <legend>{t("apiClient.group_auth_code")}</legend>
        <MultiTextInput {...form.getInputProps("redirectUris")}
                        label={t("apiClient.redirectUris")} />
        <MultiTextInput {...form.getInputProps("postLogoutRedirectUris")}
                        label={t("apiClient.postLogoutRedirectUris")} />
        <MultiTextInput {...form.getInputProps("allowedCorsOrigins")}
                        label={t("apiClient.allowedCorsOrigins")} />
        <MultiTextInput {...form.getInputProps("frontChannelLogoutUri")}
                        label={t("apiClient.frontChannelLogoutUri")} />
        <MultiTextInput {...form.getInputProps("backChannelLogoutUri")}
                        label={t("apiClient.backChannelLogoutUri")} />

      </fieldset>
    </>
  );

}

export function ApiClientInputs() {
  //Render inputs required for forms
  const form = useApiClientFormContext();

  const { t } = useTranslation();
  const [value, setValue] = useState(form.values['allowedGrantType']);


  const grantTypes = [
    {
      value: "authorization_code", label: (
        <Center>
          <div>
            <Text weight={500} mb={7} sx={{ lineHeight: 1 }}>
              Authorization Code
            </Text>
            <Text size="sm" color="dimmed">

            </Text>
          </div>
        </Center>
      )
    },
    {
      value: "client_credentials", label: (<Center>
        <div>
          <Text weight={500} mb={7} sx={{ lineHeight: 1 }}>
            Client Credentials
          </Text>
          <Text size="sm" color="dimmed">

          </Text>
        </div>
      </Center>)
    }


  ];

  const setGrantType = (value) => {
    console.log(value)
    form.setFieldValue('allowedGrantType', value)
    setValue(value)
  }

  return (
    <>

      <TextInput label={t("apiClient.clientName")}
                 required={true}
                 {...form.getInputProps("clientName")} />

      <TextInput label={t("apiClient.clientId")}
                 required={true}
                 {...form.getInputProps("clientId")} />


      <Input.Wrapper label={t("apiClient.allowedGrantType")} required={true}>
        <SegmentedControl //value={value}
                          {...form.getInputProps("allowedGrantType")}
                          //onChange={setGrantType}
                          data={grantTypes} fullWidth name={"Grant Types"} />
      </Input.Wrapper>

      {

        form.values['allowedGrantType'] == "authorization_code" && <AuthCodeInputs />
      }

      {

        form.values['allowedGrantType'] == "client_credentials" && <ClientCredentialsInputs />
      }

      <NumberInput
        defaultValue={3600}
        label={t("apiClient.accessTokenLifetime")}
        required
        {...form.getInputProps("accessTokenLifetime")}
      />


    </>
  );
}