import React from "react";

import { ActivationForm } from "../../features/signup/ActivationForm";
import {
  AddAdditionalInfoFormProvider,
  AddAdditionalInfoResponseTransformation,
  useAddAdditionalInfoForm,
} from "../../features/signup/AddAdditionalInfoFormContext";
import { AddAdditionalInfoInputs } from "../../features/signup/AddAdditionalInfoInputs";
import {
  AddTotpFormProvider,
  AddTotpResponseTransformation,
  useAddTotpForm,
} from "../../features/signup/AddTotpFormContext";
import { AddOTPInputs } from "../../features/signup/AddTotpInputs";
import { VerifyInputs } from "../../features/signup/VerifyInputs";
import {
  VerifyUserFormProvider,
  VerifyUserResponseTransformation,
  useVerifyUserForm,
} from "../../features/signup/VerifyUserFormContext";
import {
  usePostAddAdditionalInfoMutation,
  usePostAddTotpMutation,
  usePostVerifyUserMutation,
} from "../../services/activationApi";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export function AddTotp() {
  return (
    <>
      <ActivationForm
        FormProvider={AddTotpFormProvider}
        inputs={<AddOTPInputs />}
        useFormHook={useAddTotpForm}
        transformResponse={AddTotpResponseTransformation}
        useUpdateMutation={usePostAddTotpMutation}
        i18Prefix={"addTotp"}
        commandKey={"addTotpCommand"}
        activationStatus={"AddTotp"}
        descriptionTextOverride={
          <Trans 
              i18nKey={"activation.description"} 
              context={"addTotp"}
              components={{
                  googleLink: <Link to={`https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid`} />,
                  microsoftLink: <Link to={`https://www.microsoft.com/en-us/security/mobile-authenticator-app`} />
              }}
          />
        }
      />
    </>
  );
}
