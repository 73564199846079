import { CxHeader, CxLogo } from "@ascentis/cx-reactjs-ui";
import {
  AppShell,
  Center,
  Container,
  Group,
  Paper,
  Title,
  createStyles,
} from "@mantine/core";
import * as React from "react";
import { Outlet } from "react-router-dom";

import { MicroAppMenu } from "../features/microapps/MicroAppMenu";
import { UserProfileMenu } from "../features/user/UserProfileMenu";

function CustomHeader() {
  return (
    <>
      <CxHeader productName={"Cx"} children={""} />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  hostAppShellMain: {
    backgroundColor: theme.other.mainBackgroundColor, // normalize duplicate left padding
    height: "100vh",
  },
  logo: {
    width: "5em",
    height: "5em",
    paddingLeft: theme.spacing.xs,
  },

  company: {
    fontFamily: "verdana",
    color: "#B70200",
    fontWeight: "bold",
    fontSize: "3.5em",
    textTransform: "lowercase",
  },
  product: {
    fontFamily: "Poppins",
    color: "#4E4E4E",
    fontSize: "3.5em",
    fontWeight: "lighter",
  },

  paper: {
    marginTop: 2 * theme.spacing.lg,
    width: theme.breakpoints.xs
  }
}));

interface CxHeaderProps {
  //user: { name: string; image: string };
  logoElement?: React.ReactElement<any>;
  companyName?: string;
  productName: string;
}

function ProductLogo(props: CxHeaderProps) {
  const { classes, cx } = useStyles();

  // @ts-ignore
  return (
    <>
      <Group position="apart" sx={{ height: "100%" }}>
        <Group position={"left"} spacing={"xs"} align={"flex-end"}>
          {props.logoElement &&
            React.cloneElement(props.logoElement, {
              className: cx(props.logoElement?.props?.className, classes.logo),
            })}

          <Group position={"left"} spacing={0} align={"flex-end"}>
            <Title className={classes.company}>{props.companyName}</Title>
            <Title className={classes.product}>{props.productName}</Title>
          </Group>
        </Group>
      </Group>
    </>
  );
}

export const CenterLayout = (): JSX.Element => {
  const { classes } = useStyles();
  return (
    <React.Fragment>
      <Container fluid={true} className={classes.hostAppShellMain}>
        <Center>
          <Paper className={classes.paper} radius={"md"} p={"md"} shadow="md">
            <Center>
          <ProductLogo
            logoElement={<CxLogo />}
            companyName="ascentis"
            productName={"Cx"}
          />
          </Center>
            <Outlet />
          </Paper>
        </Center>
      </Container>
    </React.Fragment>
  );
};
