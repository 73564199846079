import { createFormContext } from "@mantine/form";
import { z } from "zod";

import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";

interface OrganizationFormValues {
  //id? : string,
  name: string;
  code: string;
  description: string;
}

export const OrganizationFormInitialValues: OrganizationFormValues = {
  name: "",
  code: "",
  description: "",
};

export const OrganizationFormSchema = z.object({
  name: z.string().trim().min(1).max(100),
  code: z.string().trim().min(1),
  description: z.string().trim().min(1),
});

export const OrganizationResponseTransformation = (values) => {
  return {
    ...values,
    name: values.organizationName,
    code: values.organizationCode.replace(" ", ""),
  };
};

const OrganizationFormTransformation = (values) => {
  return {
    ...values,
    organizationName: values.name,
    organizationCode: values.code,
  };
};

export const [
  OrganizationFormProvider,
  useOrganizationFormContext,
  useOrganizationNativeForm,
] = createFormContext<OrganizationFormValues>();
export function useOrganizationForm() {
  return useOrganizationNativeForm({
    initialValues: OrganizationFormInitialValues,
    //validate: translateValidationErrorMessages(zodResolver(OrganizationFormSchema)),
    validate: useCustomZodResolver("organization", OrganizationFormSchema),
    transformValues: OrganizationFormTransformation,
  });
}
