import * as React from "react";
import { useEffect } from "react";
import { Center, MultiSelect, PasswordInput, Text, TextInput, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faPray } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSignUpUserFormContext } from "./SignUpUserFormContext";
import { useParams } from "react-router-dom";
import { useGetUserByActivationIdQuery } from "../../services/activationApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useDisclosure } from "@mantine/hooks";
import { useAddAdditionalInfoFormContext } from "./AddAdditionalInfoFormContext";


export function AddAdditionalInfoInputs() {
  //Render inputs required for forms
  const form = useAddAdditionalInfoFormContext();
  const { t } = useTranslation();
  const params = useParams();

  const [showMobile, showMobileHandlers] = useDisclosure(false);
  const [showStaffId, showStaffIdHandlers] = useDisclosure(false);

  const {
    data,
    error: queryError,
    isLoading: queryIsLoading,
    isSuccess: queryIsLoaded,
  } = useGetUserByActivationIdQuery(
    params
      ? {
          ...params,
          //organizationId: organizationId
        }
      : skipToken
  );

  useEffect(() => {
    if (data) {
      if (data.loginType) {
        const loginTypes = data.loginType.split(",")
  
        if (loginTypes.includes('Mobile'))
          showMobileHandlers.open()
  
        if (loginTypes.includes('Code'))
          showStaffIdHandlers.open()
      }
    }
  }, [data])
  

  return (
    <>
      {showMobile && <TextInput label={t("user.mobileNumber")}
        //placeholder={t("scope.name_placeholder")}
                 withAsterisk={true}
                 {...form.getInputProps("mobileNumber")} /> }

      {showStaffId && <TextInput label={t("user.staffId")}
        //placeholder={t("scope.displayName_placeholder")}
        //withAsterisk
                     {...form.getInputProps("staffId")} /> }
    </>
  );
}