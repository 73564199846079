import { Anchor, Breadcrumbs, Button, Stack, Text } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import * as React from "react";
import { ComponentType, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { ContentShell } from "./ContentShell";
import { FormErrorAlert } from "./FormErrorAlert";

interface CreateFormProps {
  FormProvider: ComponentType<any>;
  inputs: ReactElement<any>;

  useFormHook: any;

  useCreateMutation: any;

  i18Prefix: string;
  commandKey: string;
}
export const CreateForm = ({
  useFormHook,
  useCreateMutation,
  i18Prefix,
  FormProvider,
  inputs,
  commandKey,
}: CreateFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useFormHook();

  //useDocumentTitle("test");

  const formId = useId();

  const [create, { error, isLoading, isSuccess, isError }] =
    useCreateMutation();

  const handleForm = () => {};

  //asdas

  const handleSubmit = (values) => {
    console.log("Form, ", values);

    create({ [commandKey]: values });
  };

  useEffect(() => {
    if (isSuccess) {
      //setSubmitError(false)
      showNotification({
        //title: t(i18Prefix + ".notification.new.title"),
        //message: t(i18Prefix + ".notification.new.message", { [i18Prefix]: form.values })
        title: t("new.success_title", { context: i18Prefix }),
        message: t("new.success_message", {
          context: i18Prefix,
          data: form.values,
        }),
      });
      //redirect to list page
      //return redirect('/AppSubscription/apiscopes')
      navigate("..");
    }
  }, [isSuccess]);

  return (
    <>
      <FormProvider form={form}>
        <ContentShell
          topSection={
            <Breadcrumbs>
              <Anchor component={Link} to={".."}>
                {t("list.title", { context: i18Prefix, count: 2 })}
              </Anchor>
              <Anchor span={true} variant={"text"}>
                {t("new.title", { context: i18Prefix })}
              </Anchor>
            </Breadcrumbs>
          }
          bottomSection={
            <>
              <Button onClick={() => navigate("..")} variant={"subtle"}>
                <Text tt={"capitalize"}>{t("common.cancel")}</Text>
              </Button>

              <Button onClick={() => form.reset()} variant={"subtle"}>
                <Text tt={"capitalize"}>{t("common.reset")}</Text>
              </Button>

              <Button form={formId} loading={isLoading} type="submit">
                {t("common.submit.new")}
              </Button>
            </>
          }
        >
          <Text>{t("new.description", { context: i18Prefix })}</Text>

          <FormErrorAlert
            isError={isError}
            title={t("new.fail", { context: i18Prefix })}
            error={error}
          />

          <form id={formId} onSubmit={form.onSubmit(handleSubmit)}>
            <Stack justify={"flex-start"} spacing={"md"} pr={"md"}>
              {inputs}
            </Stack>
          </form>
        </ContentShell>
      </FormProvider>
    </>
  );
};
