import * as React from "react";
import { useAppSelector } from "../../hooks/reduxHelper";
import { useGetApiAuditLoginByUserIdAndPageQuery } from "../../services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { Group, Stack, Text, Timeline, Flex, Skeleton } from "@mantine/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { UAParser } from 'ua-parser-js';
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useEffect, useRef, useState } from "react";
import { useScrollHook } from "../../hooks/useScrollHook";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function DisplayLineItem({ Timestamp, EventRecord, Application, EventName }) {

  const record = JSON.parse(EventRecord);
  //let description = "App: " + Application + " | " + "IP: " + record.UserSourceIp + " | " + "Method: " + record.LoginMethod + " | " + "User Agent: " + record.UserAgent;
  let title = "Successful login to " + Application;
  if (EventName == "LoginFailure") {
    title = "Unsuccessful login to " + Application;
  }
  let parser = new UAParser(record.UserAgent);

  let description = "Your IP Address was " + record.UserSourceIp + " and you were using " + parser.getBrowser().name + " on " + parser.getOS().name + " " + parser.getOS().version + " to login.";


  return (
    <Timeline.Item title={title}>
      <Text color="dimmed" size="sm">{description}</Text>
      <Text size="xs" mt={4}><Group>
        <Text>{dayjs(Timestamp).format('L LT')}</Text>
        <Text>{dayjs(Timestamp).fromNow()}</Text>
      </Group></Text>
    </Timeline.Item>
  )
}
export function UserLoginActivity() {


  const userId = useAppSelector(state => state.user.id);
  const [page, setPage] = useState(1);
  const [showData, setShowData] = useState([]);
  const [haveMoreData, setHaveMoreData] = useState(true);
  const stackRef = useRef();
  const { scrollTargetHit, stopScrollEvents, hasScrollBar } = useScrollHook(stackRef?.current?.parentElement.parentElement, stackRef, 90);
  const [checkScrollState, setCheckScrollState] = useState(false);

  let { data, isLoading, isSuccess, isError } = useGetApiAuditLoginByUserIdAndPageQuery(userId ? {
    'userId': userId,
    'page': page
  } : skipToken, { refetchOnMountOrArgChange: true });

  useEffect(() => {    
    if (scrollTargetHit && haveMoreData) {
      setTimeout(() => {
        setPage(page + 1);        
      }, 250);      
    }
  }, [scrollTargetHit]);

  useEffect(() => {
    if (isSuccess === false || isLoading === true) {
      return;
    }
    var newData = [];
    data?.Data?.forEach(element => {
      newData.push(element);
    });

    setShowData([...showData, ...newData]);
    if (data?.Data?.length === 0) {
      setHaveMoreData(false);
      stopScrollEvents();
    } else {
      setCheckScrollState(true);
    }
  }, [data]);

  useEffect(() => {
    if (checkScrollState == true) {
      if(!hasScrollBar()){
        setTimeout(() => {
          setPage(page + 1);
        }, 250);
      }
      setCheckScrollState(false);
    }
  },[checkScrollState])


  // @ts-ignore
  const timelineItems = showData?.map((item) => DisplayLineItem(item));

  return (
    <Stack ref={stackRef}>
      <Text>You should recognize each of these recent activities. If one looks unfamiliar, you should update your password.</Text>

      <Timeline active={-1} bulletSize={20} lineWidth={2}>
        {
          timelineItems
        }
      </Timeline>
      {
        haveMoreData ?
        <Flex direction="column" >
        <Flex align="center">
          <Skeleton height={24} circle mr="sm" />
          <Skeleton height={8} radius="xl" width="50%" />
        </Flex>
        <Flex direction="column">
          <Skeleton height={8} ml="36px" radius="xl" width="70%" />
          <Skeleton height={8} ml="36px" mt="xs" radius="xl" width="30%" />
          </Flex>
        </Flex>:null  
      }

    </Stack>
  );
}