import { faCircleQuestion, faPray } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Center,
  Input,
  MultiSelect,
  PasswordInput,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { QRCodeSVG } from "qrcode.react";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { generateRandomString } from "shared-library/src/hooks/generateRandomString";

import { useGetUserByActivationIdQuery } from "../../services/activationApi";
import { useAddAdditionalInfoFormContext } from "./AddAdditionalInfoFormContext";
import { useAddTotpFormContext } from "./AddTotpFormContext";
import { useSignUpUserFormContext } from "./SignUpUserFormContext";

export function AddOTPInputs() {
  //Render inputs required for forms
  const form = useAddTotpFormContext();
  const { t } = useTranslation();

  //const totpKey = generateRandomString(15);
  const [totpKey, setTotpKey] = React.useState(generateRandomString(15));

  useEffect(() => {
    form.setFieldValue("totpKey", totpKey);
  }, [totpKey]);

  return (
    <>
      <TextInput
        label={t("user.totpKey")}
        //placeholder={t("scope.name_placeholder")}
        disabled
        {...form.getInputProps("totpKey")}
      />

      <Input.Wrapper label={t("user.qrCodeKey")}>
        <Center>
          <QRCodeSVG
            value={`otpauth://totp/AscentisCx?secret=${totpKey}&algorithm=SHA1&digits=6&period=30`}
            size={200}
          />
        </Center>
      </Input.Wrapper>

      <TextInput
        label={t("user.totp")}
        //placeholder={t("scope.name_placeholder")}
        withAsterisk={true}
        {...form.getInputProps("totp")}
      />
    </>
  );
}
