import {
  usePostApiApiClientCreateApiClientMutation,
  usePostApiFileUploadMutation,
  usePostApiUserCreateUserMutation
} from "../../services/appcenterApi";
import { useUploadFileMutation } from "../../hooks/useUploadFileMutation";

function useUploadBackgroundImageMutation() {
  const getUrl = (values) => {
    return values.addClientCredentialCommand.backgroundImage;
  };
  const transformValues = (values, data) => {
    values.addClientCredentialCommand.backgroundImage = data[0].key;
    return values;
  };

  return useUploadFileMutation({
    useMutationHook: usePostApiApiClientCreateApiClientMutation
    , transformValues, getUrl
  });

}

export function useCreateApiClientMutation() {


  const getUrl = (values) => {
    return values.addClientCredentialCommand.logoUri;
  };
  const transformValues = (values, data) => {
    values.addClientCredentialCommand.logoUri = data[0].key;
    return values;
  };

  return useUploadFileMutation({
    useMutationHook: useUploadBackgroundImageMutation
    , transformValues, getUrl
  });
}