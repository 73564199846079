import { faMagnifyingGlass, faCheckCircle , faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Anchor,
  Alert,
  Breadcrumbs,
  Button,
  Group,
  Stack,
  TextInput,
  Text,
  Space  
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ComponentType, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../hooks/reduxHelper";
import { ContentShell } from "./ContentShell";
import { DeleteContextModal } from "./DeleteContextModal";
import { FormErrorAlert } from "./FormErrorAlert";

interface ListAffectedUsersPageShellProps {
  useQuery: any;
  queryParam?: object;
  DataTable: ComponentType<any>;
  //pageActions: React.ReactNode,
  initialSortKey?: string;
  topLeftTableActions?: React.ReactNode;
  topSection?: React.ReactNode;
  bottomSection?: React.ReactNode;
  i18Prefix: string;
  sortDesc?: boolean;
  initialSearchTerm?: string;
  useUpdateMutation: any;
  tableData?: any;
}
export function ListAffectedUsersPageShell({
  useQuery,
  queryParam,
  DataTable,
  initialSortKey,
  i18Prefix,
  topLeftTableActions,
  bottomSection,
  topSection,
  sortDesc,
  initialSearchTerm,
  useUpdateMutation,
  tableData,
}: ListAffectedUsersPageShellProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState<string>(
    initialSortKey ? initialSortKey : ""
  );
  const [isDesc, setIsDesc] = useState<boolean>(sortDesc ?? false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [rawSearchTerm, setRawSearchTerm] = useState<string>(
    initialSearchTerm ?? ""
  );
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm ?? "");
  const [debouncedSearchTerm] = useDebouncedValue(rawSearchTerm, 500);
  const [searchInputError, setSearchInputError] = useState<string>("");
  const [showDataTable, setShowDataTable] = useState(false);

  const handleSearchTermChange = function (e) {
    setRawSearchTerm(e.currentTarget.value);
  };

  useEffect(() => {
    if (debouncedSearchTerm != null || debouncedSearchTerm != undefined) {
      if (debouncedSearchTerm == "" || debouncedSearchTerm.length >= 3) {
        setSearchTerm(debouncedSearchTerm);
        setSearchInputError("");
      } else if (
        debouncedSearchTerm.length != "" &&
        debouncedSearchTerm.length < 3
      ) {
        setSearchInputError(
          t("validation.at_least_3_characters", { context: i18Prefix })
        );
      }
    }
  }, [debouncedSearchTerm]);

  const organizationId = useAppSelector((state) => state.user?.organization.id);

  const [
    update,
    {      
      error,
      isLoading,
      isSuccess,
      isError,
    },
  ] = useUpdateMutation();

  const submit = () => {
    update({ updateOrganizationPolicyCommand: tableData });    
  };

  useEffect(() => {
    if (isSuccess) {
      // if (postUpdateCallback) {
      //   var returnObj = postUpdateCallback();
      //   if(returnObj != undefined){
      //     return;
      //   }
      // }
      // setSubmitError(false)
      console.log("tableData", tableData);
      showNotification({
        title: t("update.success_title", { context: i18Prefix }),
        message: t("update.success_message", {
          context: i18Prefix,
          data: { name: tableData.organizationName },
        }),
      });
      window.history.back()
    }
  }, [isSuccess]);

  const query = useQuery(
    organizationId !== ""
      ? {
          organizationId: organizationId,
          sortBy: sortBy,
          isDesc: isDesc,
          page: page,
          pageSize: pageSize,
          searchTerm: searchTerm,
          ...queryParam,
        }
      : skipToken
  );

  useEffect(() => {
    if (query.isSuccess) {
      console.debug("Checkup data loaded");
      let total = query.data?.total;  
      if (total > 0) {
        setShowDataTable(true);
      }
    }
  }, [query.isSuccess]);

  var reminder = "The following users may be unable to sign in after the security policy update due to missing StaffId or Email. It is recommended to cancel and update the affected user accounts to ensure a smooth transition. Contact support if you need assistance.";
  const renderStatus = function () {
    if (query.isLoading) {
      return (
        (<Alert icon={<FontAwesomeIcon icon={faCheckCircle} size="lg" />} title={t("organizationSecurityPolicy.checkup.loadingTitle")} color="gray" variant="outline">
          <Text fw={500} color="gray">{t("organizationSecurityPolicy.checkup.loading")}</Text>
        </Alert>
        )
      );
    }
    else if (!query.isLoading && query.isSuccess && showDataTable) {
      return (
        <Alert icon={<FontAwesomeIcon icon={faExclamationTriangle} size="lg" />} title={t("organizationSecurityPolicy.checkup.warningTitle")} color="orange" variant="outline">
          <Text fw={500} color="orange">{t("organizationSecurityPolicy.checkup.warning")}</Text>
              
        </Alert>
      );
    } else if (!query.isLoading && query.isSuccess && !showDataTable) { 
      return (
        <Alert icon={<FontAwesomeIcon icon={faCheckCircle} size="lg" />} title={ t("organizationSecurityPolicy.checkup.goodTitle")} color="green" variant="outline">
          <Text fw={500} color="green">{t("organizationSecurityPolicy.checkup.good")}</Text>
                
        </Alert>
      );
    }
  }

  return (
    <>
      <ContentShell
        topSection={
          topSection ?? (
            <>
              <Breadcrumbs>
                <Anchor span={true} variant={"text"}>
                  {t("organizationSecurityPolicy.affectedUsers.list.title")}
                </Anchor>
              </Breadcrumbs>
            </>
          )
        }
        bottomSection={
          bottomSection ?? (
            <>
              <Button
                onClick={() => window.history.back()}
                variant={"subtle"}
              >
                {t("common.cancel")}
              </Button>
              <Button onClick={() => submit()}>{t("common.submit.update")}</Button>
            </>
          )
        }
      >
        <Stack>
          {renderStatus()}
        <Space/>
        </Stack>
        <Stack>
          {showDataTable ? (
            <Group position={"apart"} grow>
            <TextInput
              //placeholder={t(i18Prefix + ".list.searchPlaceholder")}
              placeholder={t("search.placeHolder", { context: i18Prefix })}
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              value={rawSearchTerm}
              onChange={handleSearchTermChange}
              error={searchInputError}
              //styles={{
              //  input: {
              //    width: '100%'
              //  }
              //}}
            />
            <Group position={"right"}>{topLeftTableActions}</Group>
          </Group>
          ):null}

          {showDataTable ? (
                      <DataTable            
                      // tableHeight={'53vh'}
                      isFetching={query.isFetching}
                      //sortStatus={sortStatus}
                      //setSortStatus={setSortStatus}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      isDesc={isDesc}
                      setIsDesc={setIsDesc}
                      page={page}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      setPage={setPage}
                      totalRecords={query.isSuccess ? query.data?.total : 0}
                      //columns={columns}
                      records={query.isSuccess ? query.data?.data : []}
                    />
          ) : null
        }

        </Stack>

        <FormErrorAlert
          isError={isError}
          title={t("update.fail", { context: i18Prefix })}
          error={error}
        />

        {/* Search Keywords & Filter */}
      </ContentShell>
    </>
  )
}
