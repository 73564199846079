import * as React from "react";
import { CreateForm } from "../../components/CreateForm";
import { usePostApiOrganizationCreateOrganizationMutation } from "../../services/appcenterApi";
import { OrganizationFormProvider, useOrganizationForm } from "../../features/organizations/OrganizationFormContext";
import { OrganizationInputs } from "../../features/organizations/OrganizationInputs";

export function CreateOrganization() {


  // @ts-ignore
  return (
    <>
      <CreateForm FormProvider={OrganizationFormProvider} inputs={<OrganizationInputs />} i18Prefix={"organization"}
                  useCreateMutation={usePostApiOrganizationCreateOrganizationMutation} useFormHook={useOrganizationForm}

                  commandKey={"addOrganizationCommand"} />
    </>
  );

}