import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "ApiClient",
  "Application",
  "MfeApplications",
  "ApplicationGroup",
  "AppSetting",
  "Audit",
  "File",
  "Organization",
  "IdentityProvider",
  "User",
  "OrgApprovalRequest",
  "AssignableOrganizations",
  "AssignableUsersByOrg",
  "Permission",
  "Role",
  "Scope",
  "Settings",
  "TermsAndConditions",
  "WellKnown",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiApiClientPingTest: build.query<
        GetApiApiClientPingTestApiResponse,
        GetApiApiClientPingTestApiArg
      >({
        query: () => ({ url: `/api/ApiClient/PingTest` }),
        providesTags: ["ApiClient"],
      }),
      getApiApiClientGetApiClient: build.query<
        GetApiApiClientGetApiClientApiResponse,
        GetApiApiClientGetApiClientApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApiClient/GetApiClient`,
          params: { id: queryArg.id },
        }),
        providesTags: ["ApiClient"],
      }),
      getApiApiClientGetApiClients: build.query<
        GetApiApiClientGetApiClientsApiResponse,
        GetApiApiClientGetApiClientsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApiClient/GetApiClients`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ["ApiClient"],
      }),
      postApiApiClientCreateApiClient: build.mutation<
        PostApiApiClientCreateApiClientApiResponse,
        PostApiApiClientCreateApiClientApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApiClient/CreateApiClient`,
          method: "POST",
          body: queryArg.addClientCredentialCommand,
        }),
        invalidatesTags: ["ApiClient"],
      }),
      postApiApiClientEditApiClient: build.mutation<
        PostApiApiClientEditApiClientApiResponse,
        PostApiApiClientEditApiClientApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApiClient/EditApiClient`,
          method: "POST",
          body: queryArg.updateClientCredentialCommand,
        }),
        invalidatesTags: ["ApiClient"],
      }),
      deleteApiApiClientDeleteApiClient: build.mutation<
        DeleteApiApiClientDeleteApiClientApiResponse,
        DeleteApiApiClientDeleteApiClientApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApiClient/DeleteApiClient`,
          method: "DELETE",
          params: { id: queryArg.id, reason: queryArg.reason },
        }),
        invalidatesTags: ["ApiClient"],
      }),
      patchApiApiClientResetSecretKey: build.mutation<
        PatchApiApiClientResetSecretKeyApiResponse,
        PatchApiApiClientResetSecretKeyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApiClient/ResetSecretKey`,
          method: "PATCH",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["ApiClient"],
      }),
      getApiApplicationPingTest: build.query<
        GetApiApplicationPingTestApiResponse,
        GetApiApplicationPingTestApiArg
      >({
        query: () => ({ url: `/api/Application/PingTest` }),
        providesTags: ["Application"],
      }),
      getApiApplicationApplications: build.query<
        GetApiApplicationApplicationsApiResponse,
        GetApiApplicationApplicationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/Applications`,
          params: {
            searchTerm: queryArg.searchTerm,
            organizationId: queryArg.organizationId,
            getHidden: queryArg.getHidden,
          },
        }),
        providesTags: ["Application"],
      }),
      getApiApplicationSubcribedApplications: build.query<
        GetApiApplicationSubcribedApplicationsApiResponse,
        GetApiApplicationSubcribedApplicationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/SubcribedApplications`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Application"],
      }),
      getApiApplicationGetEnabledApplications: build.query<
        GetApiApplicationGetEnabledApplicationsApiResponse,
        GetApiApplicationGetEnabledApplicationsApiArg
      >({
        query: () => ({ url: `/api/Application/GetEnabledApplications` }),
        providesTags: ["Application"],
      }),
      getApiApplicationGetApplication: build.query<
        GetApiApplicationGetApplicationApiResponse,
        GetApiApplicationGetApplicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/GetApplication`,
          params: { id: queryArg.id },
        }),
        providesTags: ["Application"],
      }),
      getApiApplicationGetApplications: build.query<
        GetApiApplicationGetApplicationsApiResponse,
        GetApiApplicationGetApplicationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/GetApplications`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
          },
        }),
        providesTags: ["Application"],
      }),
      postApiApplicationCreateApplication: build.mutation<
        PostApiApplicationCreateApplicationApiResponse,
        PostApiApplicationCreateApplicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/CreateApplication`,
          method: "POST",
          body: queryArg.addApplicationCommand,
        }),
        invalidatesTags: ["Application"],
      }),
      postApiApplicationUpdateApplication: build.mutation<
        PostApiApplicationUpdateApplicationApiResponse,
        PostApiApplicationUpdateApplicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/UpdateApplication`,
          method: "POST",
          body: queryArg.updateApplicationCommand,
        }),
        invalidatesTags: ["Application", "MfeApplications"],
      }),
      deleteApiApplicationDeleteApplication: build.mutation<
        DeleteApiApplicationDeleteApplicationApiResponse,
        DeleteApiApplicationDeleteApplicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/DeleteApplication`,
          method: "DELETE",
          params: { id: queryArg.id, reason: queryArg.reason },
        }),
        invalidatesTags: ["Application"],
      }),
      postApiApplicationSubcribeApplication: build.mutation<
        PostApiApplicationSubcribeApplicationApiResponse,
        PostApiApplicationSubcribeApplicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/SubcribeApplication`,
          method: "POST",
          body: queryArg.subscribeAppCommand,
        }),
        invalidatesTags: ["Application"],
      }),
      postApiApplicationUnsubcribeApplication: build.mutation<
        PostApiApplicationUnsubcribeApplicationApiResponse,
        PostApiApplicationUnsubcribeApplicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/UnsubcribeApplication`,
          method: "POST",
          body: queryArg.unsubscribeAppCommand,
        }),
        invalidatesTags: ["Application"],
      }),
      getApiApplicationWellKnown: build.query<
        GetApiApplicationWellKnownApiResponse,
        GetApiApplicationWellKnownApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/WellKnown`,
          params: { applicationId: queryArg.applicationId },
        }),
        providesTags: ["Application"],
      }),
      postApiApplicationWellKnown: build.mutation<
        PostApiApplicationWellKnownApiResponse,
        PostApiApplicationWellKnownApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/WellKnown`,
          method: "POST",
          body: queryArg.addWellknowCommand,
        }),
        invalidatesTags: ["Application"],
      }),
      getApiApplicationGetSubscribedMfe: build.query<
        GetApiApplicationGetSubscribedMfeApiResponse,
        GetApiApplicationGetSubscribedMfeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/GetSubscribedMfe`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Application"],
      }),
      getApiApplicationGetMfeApplications: build.query<
        GetApiApplicationGetMfeApplicationsApiResponse,
        GetApiApplicationGetMfeApplicationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Application/GetMfeApplications`,
          params: { IsCc: queryArg.isCc },
        }),
        providesTags: ["MfeApplications"],
      }),
      postApiApplicationGroupCreateCustomGroup: build.mutation<
        PostApiApplicationGroupCreateCustomGroupApiResponse,
        PostApiApplicationGroupCreateCustomGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/CreateCustomGroup`,
          method: "POST",
          body: queryArg.addApplicationGroupCommand,
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      postApiApplicationGroupDeleteCustomGroup: build.mutation<
        PostApiApplicationGroupDeleteCustomGroupApiResponse,
        PostApiApplicationGroupDeleteCustomGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/DeleteCustomGroup`,
          method: "POST",
          body: queryArg.deleteApplicationGroupCommand,
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      getApiApplicationGroupGetCustomGroup: build.query<
        GetApiApplicationGroupGetCustomGroupApiResponse,
        GetApiApplicationGroupGetCustomGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/GetCustomGroup`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["ApplicationGroup"],
      }),
      getApiApplicationGroupGetApplicationGroup: build.query<
        GetApiApplicationGroupGetApplicationGroupApiResponse,
        GetApiApplicationGroupGetApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/GetApplicationGroup`,
          params: { id: queryArg.id },
        }),
        providesTags: ["ApplicationGroup"],
      }),
      deleteApiApplicationGroupDeleteApplicationGroup: build.mutation<
        DeleteApiApplicationGroupDeleteApplicationGroupApiResponse,
        DeleteApiApplicationGroupDeleteApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/DeleteApplicationGroup`,
          method: "DELETE",
          params: { id: queryArg.id, reason: queryArg.reason },
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      postApiApplicationGroupAddApplicationGroup: build.mutation<
        PostApiApplicationGroupAddApplicationGroupApiResponse,
        PostApiApplicationGroupAddApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/AddApplicationGroup`,
          method: "POST",
          body: queryArg.addApplicationGroupCommand,
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      postApiApplicationGroupAddDefaultApplicationGroup: build.mutation<
        PostApiApplicationGroupAddDefaultApplicationGroupApiResponse,
        PostApiApplicationGroupAddDefaultApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/AddDefaultApplicationGroup`,
          method: "POST",
          body: queryArg.addApplicationGroupCommand,
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      postApiApplicationGroupUpdateApplicationGroup: build.mutation<
        PostApiApplicationGroupUpdateApplicationGroupApiResponse,
        PostApiApplicationGroupUpdateApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/UpdateApplicationGroup`,
          method: "POST",
          body: queryArg.updateApplicationGroupCommand,
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      getApiApplicationGroupFilterApplicationGroups: build.query<
        GetApiApplicationGroupFilterApplicationGroupsApiResponse,
        GetApiApplicationGroupFilterApplicationGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/FilterApplicationGroups`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ["ApplicationGroup"],
      }),
      getApiApplicationGroupGetAllDefaultApplicationGroup: build.query<
        GetApiApplicationGroupGetAllDefaultApplicationGroupApiResponse,
        GetApiApplicationGroupGetAllDefaultApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/GetAllDefaultApplicationGroup`,
          params: { applicationId: queryArg.applicationId },
        }),
        providesTags: ["ApplicationGroup"],
      }),
      getApiApplicationGroupGetApplicationGroupsByUser: build.query<
        GetApiApplicationGroupGetApplicationGroupsByUserApiResponse,
        GetApiApplicationGroupGetApplicationGroupsByUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/GetApplicationGroupsByUser`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["ApplicationGroup"],
      }),
      postApiApplicationGroupAddUserToApplicationGroup: build.mutation<
        PostApiApplicationGroupAddUserToApplicationGroupApiResponse,
        PostApiApplicationGroupAddUserToApplicationGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/AddUserToApplicationGroup`,
          method: "POST",
          body: queryArg.addUserToApplicationGroupCommand,
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      deleteApiApplicationGroupRemoveUserFromGroup: build.mutation<
        DeleteApiApplicationGroupRemoveUserFromGroupApiResponse,
        DeleteApiApplicationGroupRemoveUserFromGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/RemoveUserFromGroup`,
          method: "DELETE",
          params: {
            userId: queryArg.userId,
            applicationGroupId: queryArg.applicationGroupId,
          },
        }),
        invalidatesTags: ["ApplicationGroup"],
      }),
      getApiApplicationGroupGetUsersFromGroup: build.query<
        GetApiApplicationGroupGetUsersFromGroupApiResponse,
        GetApiApplicationGroupGetUsersFromGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ApplicationGroup/GetUsersFromGroup`,
          params: {
            organizationId: queryArg.organizationId,
            applicationGroupId: queryArg.applicationGroupId,
          },
        }),
        providesTags: ["ApplicationGroup"],
      }),
      getApiAppSettingPingTest: build.query<
        GetApiAppSettingPingTestApiResponse,
        GetApiAppSettingPingTestApiArg
      >({
        query: () => ({ url: `/api/AppSetting/PingTest` }),
        providesTags: ["AppSetting"],
      }),
      getApiAuditEventByOrganizationIdAndEntityId: build.query<
        GetApiAuditEventByOrganizationIdAndEntityIdApiResponse,
        GetApiAuditEventByOrganizationIdAndEntityIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/event/${queryArg.organizationId}/${queryArg.entityId}`,
        }),
        providesTags: ["Audit"],
      }),
      getApiAuditEventByOrganizationIdAndEntityIdPage: build.query<
        GetApiAuditEventByOrganizationIdAndEntityIdPageApiResponse,
        GetApiAuditEventByOrganizationIdAndEntityIdPageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/event/${queryArg.organizationId}/${queryArg.entityId}/${queryArg.page}`,
          params: { excludeEvents: queryArg.excludeEvents },
        }),
        providesTags: ["Audit"],
      }),
      getApiAuditHeadByOrganizationIdAndEntityId: build.query<
        GetApiAuditHeadByOrganizationIdAndEntityIdApiResponse,
        GetApiAuditHeadByOrganizationIdAndEntityIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/head/${queryArg.organizationId}/${queryArg.entityId}`,
        }),
        providesTags: ["Audit"],
      }),
      getApiAuditOrganizationEventByOrganizationIdAndEntityId: build.query<
        GetApiAuditOrganizationEventByOrganizationIdAndEntityIdApiResponse,
        GetApiAuditOrganizationEventByOrganizationIdAndEntityIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/organizationEvent/${queryArg.organizationId}/${queryArg.entityId}`,
        }),
        providesTags: ["Audit"],
      }),
      getApiAuditOrganizationEventByOrganizationIdAndEntityIdPage: build.query<
        GetApiAuditOrganizationEventByOrganizationIdAndEntityIdPageApiResponse,
        GetApiAuditOrganizationEventByOrganizationIdAndEntityIdPageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/organizationEvent/${queryArg.organizationId}/${queryArg.entityId}/${queryArg.page}`,
          params: { excludeEvents: queryArg.excludeEvents },
        }),
        providesTags: ["Audit"],
      }),
      getApiAuditOrganizationHeadByOrganizationIdAndEntityId: build.query<
        GetApiAuditOrganizationHeadByOrganizationIdAndEntityIdApiResponse,
        GetApiAuditOrganizationHeadByOrganizationIdAndEntityIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/organizationHead/${queryArg.organizationId}/${queryArg.entityId}`,
        }),
        providesTags: ["Audit"],
      }),
      getApiAuditLoginByUserId: build.query<
        GetApiAuditLoginByUserIdApiResponse,
        GetApiAuditLoginByUserIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/Audit/login/${queryArg.userId}` }),
        providesTags: ["Audit"],
      }),
      getApiAuditLoginByUserIdAndPage: build.query<
        GetApiAuditLoginByUserIdAndPageApiResponse,
        GetApiAuditLoginByUserIdAndPageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Audit/login/${queryArg.userId}/${queryArg.page}`,
        }),
        providesTags: ["Audit"],
      }),
      getApiFilePingTest: build.query<
        GetApiFilePingTestApiResponse,
        GetApiFilePingTestApiArg
      >({
        query: () => ({ url: `/api/File/PingTest` }),
        providesTags: ["File"],
      }),
      postApiFileUpload: build.mutation<
        PostApiFileUploadApiResponse,
        PostApiFileUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/api/File/Upload`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["File"],
      }),
      postApiFile: build.mutation<PostApiFileApiResponse, PostApiFileApiArg>({
        query: (queryArg) => ({
          url: `/api/File`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["File"],
      }),
      getApiOrganizationGetListOrganizations: build.query<
        GetApiOrganizationGetListOrganizationsApiResponse,
        GetApiOrganizationGetListOrganizationsApiArg
      >({
        query: () => ({ url: `/api/Organization/GetListOrganizations` }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationGetOrganizationById: build.query<
        GetApiOrganizationGetOrganizationByIdApiResponse,
        GetApiOrganizationGetOrganizationByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrganizationById`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationGetOrganizationByIdOrCode: build.query<
        GetApiOrganizationGetOrganizationByIdOrCodeApiResponse,
        GetApiOrganizationGetOrganizationByIdOrCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrganizationByIdOrCode`,
          params: {
            organizationId: queryArg.organizationId,
            organizationCode: queryArg.organizationCode,
          },
        }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationPingTest: build.query<
        GetApiOrganizationPingTestApiResponse,
        GetApiOrganizationPingTestApiArg
      >({
        query: () => ({ url: `/api/Organization/PingTest` }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationGetOrganizations: build.query<
        GetApiOrganizationGetOrganizationsApiResponse,
        GetApiOrganizationGetOrganizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrganizations`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
          },
        }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationGetAllOrganizations: build.query<
        GetApiOrganizationGetAllOrganizationsApiResponse,
        GetApiOrganizationGetAllOrganizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetAllOrganizations`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
          },
        }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationIdentityProvider: build.query<
        GetApiOrganizationIdentityProviderApiResponse,
        GetApiOrganizationIdentityProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/IdentityProvider`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["IdentityProvider"],
      }),
      postApiOrganizationSetIdentityProvider: build.mutation<
        PostApiOrganizationSetIdentityProviderApiResponse,
        PostApiOrganizationSetIdentityProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/SetIdentityProvider`,
          method: "POST",
          body: queryArg.setIdentityProviderCommand,
        }),
        invalidatesTags: ["IdentityProvider"],
      }),
      getApiOrganizationAvailableOrganization: build.query<
        GetApiOrganizationAvailableOrganizationApiResponse,
        GetApiOrganizationAvailableOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/AvailableOrganization`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            disableMaxChar: queryArg.disableMaxChar,
          },
        }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationGetOrganization: build.query<
        GetApiOrganizationGetOrganizationApiResponse,
        GetApiOrganizationGetOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrganization`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Organization"],
      }),
      postApiOrganizationCreateOrganization: build.mutation<
        PostApiOrganizationCreateOrganizationApiResponse,
        PostApiOrganizationCreateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/CreateOrganization`,
          method: "POST",
          body: queryArg.addOrganizationCommand,
        }),
        invalidatesTags: ["Organization"],
      }),
      postApiOrganizationSignUpOrganization: build.mutation<
        PostApiOrganizationSignUpOrganizationApiResponse,
        PostApiOrganizationSignUpOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/SignUpOrganization`,
          method: "POST",
          body: queryArg.signUpOrganizationCommand,
        }),
        invalidatesTags: ["Organization"],
      }),
      postApiOrganizationUpdateOrganization: build.mutation<
        PostApiOrganizationUpdateOrganizationApiResponse,
        PostApiOrganizationUpdateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/UpdateOrganization`,
          method: "POST",
          body: queryArg.updateOrganizationCommand,
        }),
        invalidatesTags: ["User", "Organization"],
      }),
      postApiOrganizationUpdateOrganizationLogo: build.mutation<
        PostApiOrganizationUpdateOrganizationLogoApiResponse,
        PostApiOrganizationUpdateOrganizationLogoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/UpdateOrganizationLogo`,
          method: "POST",
          body: queryArg.updateOrganizationLogoCommand,
        }),
        invalidatesTags: ["Organization"],
      }),
      deleteApiOrganizationDeleteOrganization: build.mutation<
        DeleteApiOrganizationDeleteOrganizationApiResponse,
        DeleteApiOrganizationDeleteOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/DeleteOrganization`,
          method: "DELETE",
          params: {
            id: queryArg.id,
            name: queryArg.name,
            reason: queryArg.reason,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      postApiOrganizationUpdateOrganizationPolicy: build.mutation<
        PostApiOrganizationUpdateOrganizationPolicyApiResponse,
        PostApiOrganizationUpdateOrganizationPolicyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/UpdateOrganizationPolicy`,
          method: "POST",
          body: queryArg.updateOrganizationPolicyCommand,
        }),
        invalidatesTags: ["Organization"],
      }),
      getApiOrganizationGetAffectedUsers: build.query<
        GetApiOrganizationGetAffectedUsersApiResponse,
        GetApiOrganizationGetAffectedUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetAffectedUsers`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
            organizationId: queryArg.organizationId,
            loginType: queryArg.loginType,
          },
        }),
        providesTags: ["Organization"],
      }),
      postApiOrganizationDownloadAffectedUsers: build.mutation<
        PostApiOrganizationDownloadAffectedUsersApiResponse,
        PostApiOrganizationDownloadAffectedUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/DownloadAffectedUsers`,
          method: "POST",
          params: {
            organizationId: queryArg.organizationId,
            loginType: queryArg.loginType,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      postApiOrganizationAssignUsersToOrganization: build.mutation<
        PostApiOrganizationAssignUsersToOrganizationApiResponse,
        PostApiOrganizationAssignUsersToOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/AssignUsersToOrganization`,
          method: "POST",
          body: queryArg.createUserAssignmentApprovalCommand,
        }),
        invalidatesTags: [
          "OrgApprovalRequest",
          "AssignableOrganizations",
          "AssignableUsersByOrg",
        ],
      }),
      postApiOrganizationUpdateAndResetUserAssignment: build.mutation<
        PostApiOrganizationUpdateAndResetUserAssignmentApiResponse,
        PostApiOrganizationUpdateAndResetUserAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/updateAndResetUserAssignment`,
          method: "POST",
          body: queryArg.updateResetUserAssignmentApprovalCommand,
        }),
        invalidatesTags: [
          "OrgApprovalRequest",
          "AssignableOrganizations",
          "AssignableUsersByOrg",
        ],
      }),
      postApiOrganizationSendUsrOrgAccessApprovalEmail: build.mutation<
        PostApiOrganizationSendUsrOrgAccessApprovalEmailApiResponse,
        PostApiOrganizationSendUsrOrgAccessApprovalEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/SendUsrOrgAccessApprovalEmail`,
          method: "POST",
          body: queryArg.sendUserAssignmentEmailCommand,
        }),
        invalidatesTags: ["OrgApprovalRequest"],
      }),
      postApiOrganizationUpdateUsrOrgAccessApproval: build.mutation<
        PostApiOrganizationUpdateUsrOrgAccessApprovalApiResponse,
        PostApiOrganizationUpdateUsrOrgAccessApprovalApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/UpdateUsrOrgAccessApproval`,
          method: "POST",
          body: queryArg.updateUserAssignmentApprovalCommand,
        }),
        invalidatesTags: ["OrgApprovalRequest"],
      }),
      getApiOrganizationGetOrgAssignmentRequestByWfId: build.query<
        GetApiOrganizationGetOrgAssignmentRequestByWfIdApiResponse,
        GetApiOrganizationGetOrgAssignmentRequestByWfIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrgAssignmentRequestByWfId`,
          params: {
            organizationId: queryArg.organizationId,
            workflowId: queryArg.workflowId,
          },
        }),
        providesTags: ["OrgApprovalRequest"],
      }),
      getApiOrganizationGetUserAssignmentApprovals: build.query<
        GetApiOrganizationGetUserAssignmentApprovalsApiResponse,
        GetApiOrganizationGetUserAssignmentApprovalsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetUserAssignmentApprovals`,
          params: {
            organizationId: queryArg.organizationId,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
          },
        }),
        providesTags: ["OrgApprovalRequest"],
      }),
      deleteApiOrganizationDeleteOrgAssignmentRequestId: build.mutation<
        DeleteApiOrganizationDeleteOrgAssignmentRequestIdApiResponse,
        DeleteApiOrganizationDeleteOrgAssignmentRequestIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/DeleteOrgAssignmentRequestId`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["OrgApprovalRequest"],
      }),
      getApiOrganizationAssignableUsersByOrg: build.query<
        GetApiOrganizationAssignableUsersByOrgApiResponse,
        GetApiOrganizationAssignableUsersByOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/AssignableUsersByOrg`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["AssignableUsersByOrg"],
      }),
      getApiOrganizationGetOrganizationsByUserId: build.query<
        GetApiOrganizationGetOrganizationsByUserIdApiResponse,
        GetApiOrganizationGetOrganizationsByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrganizationsByUserId`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["Organization"],
      }),
      getApiOrganizationGetOrganizationWithPolicy: build.query<
        GetApiOrganizationGetOrganizationWithPolicyApiResponse,
        GetApiOrganizationGetOrganizationWithPolicyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Organization/GetOrganizationWithPolicy`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Organization"],
      }),
      getApiPermissionPingTest: build.query<
        GetApiPermissionPingTestApiResponse,
        GetApiPermissionPingTestApiArg
      >({
        query: () => ({ url: `/api/Permission/PingTest` }),
        providesTags: ["Permission"],
      }),
      getApiPermissionGetPermissionsByRoleId: build.query<
        GetApiPermissionGetPermissionsByRoleIdApiResponse,
        GetApiPermissionGetPermissionsByRoleIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Permission/GetPermissionsByRoleId`,
          params: {
            id: queryArg.id,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
          },
        }),
        providesTags: ["Permission"],
      }),
      getApiRolePingTest: build.query<
        GetApiRolePingTestApiResponse,
        GetApiRolePingTestApiArg
      >({
        query: () => ({ url: `/api/Role/PingTest` }),
        providesTags: ["Role"],
      }),
      getApiRoleGetRole: build.query<
        GetApiRoleGetRoleApiResponse,
        GetApiRoleGetRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/GetRole`,
          params: { id: queryArg.id },
        }),
        providesTags: ["Role"],
      }),
      getApiRoleGetRoles: build.query<
        GetApiRoleGetRolesApiResponse,
        GetApiRoleGetRolesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/GetRoles`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
          },
        }),
        providesTags: ["Role"],
      }),
      postApiRoleCreateRole: build.mutation<
        PostApiRoleCreateRoleApiResponse,
        PostApiRoleCreateRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/CreateRole`,
          method: "POST",
          body: queryArg.addRoleCommand,
        }),
        invalidatesTags: ["Role"],
      }),
      postApiRoleUpdateRole: build.mutation<
        PostApiRoleUpdateRoleApiResponse,
        PostApiRoleUpdateRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/UpdateRole`,
          method: "POST",
          body: queryArg.updateRoleCommand,
        }),
        invalidatesTags: ["Role"],
      }),
      deleteApiRoleDeleteRole: build.mutation<
        DeleteApiRoleDeleteRoleApiResponse,
        DeleteApiRoleDeleteRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/DeleteRole`,
          method: "DELETE",
          params: { id: queryArg.id, reason: queryArg.reason },
        }),
        invalidatesTags: ["Role"],
      }),
      getApiRoleGetManageableRole: build.query<
        GetApiRoleGetManageableRoleApiResponse,
        GetApiRoleGetManageableRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/GetManageableRole`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Role"],
      }),
      getApiRoleGetAllRolesInOrg: build.query<
        GetApiRoleGetAllRolesInOrgApiResponse,
        GetApiRoleGetAllRolesInOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/GetAllRolesInOrg`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["Role"],
      }),
      getApiRoleGetChildRolesByNameOrId: build.query<
        GetApiRoleGetChildRolesByNameOrIdApiResponse,
        GetApiRoleGetChildRolesByNameOrIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/GetChildRolesByNameOrId`,
          params: { roleName: queryArg.roleName, roleId: queryArg.roleId },
        }),
        providesTags: ["Role"],
      }),
      getApiRoleGetRolesByOrganizationId: build.query<
        GetApiRoleGetRolesByOrganizationIdApiResponse,
        GetApiRoleGetRolesByOrganizationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Role/GetRolesByOrganizationId`,
          params: {
            organizationId: queryArg.organizationId,
            includeTopRole: queryArg.includeTopRole,
          },
        }),
        providesTags: ["Role"],
      }),
      getApiScopePingTest: build.query<
        GetApiScopePingTestApiResponse,
        GetApiScopePingTestApiArg
      >({
        query: () => ({ url: `/api/Scope/PingTest` }),
        providesTags: ["Scope"],
      }),
      getApiScopeGetScope: build.query<
        GetApiScopeGetScopeApiResponse,
        GetApiScopeGetScopeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Scope/GetScope`,
          params: { id: queryArg.id },
        }),
        providesTags: ["Scope"],
      }),
      getApiScopeGetScopes: build.query<
        GetApiScopeGetScopesApiResponse,
        GetApiScopeGetScopesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Scope/GetScopes`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ["Scope"],
      }),
      postApiScopeCreateScope: build.mutation<
        PostApiScopeCreateScopeApiResponse,
        PostApiScopeCreateScopeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Scope/CreateScope`,
          method: "POST",
          body: queryArg.addScopeCommand,
        }),
        invalidatesTags: ["Scope"],
      }),
      postApiScopeUpdateScope: build.mutation<
        PostApiScopeUpdateScopeApiResponse,
        PostApiScopeUpdateScopeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Scope/UpdateScope`,
          method: "POST",
          body: queryArg.updateScopeCommand,
        }),
        invalidatesTags: ["Scope"],
      }),
      deleteApiScopeDeleteSCope: build.mutation<
        DeleteApiScopeDeleteSCopeApiResponse,
        DeleteApiScopeDeleteSCopeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Scope/DeleteSCope`,
          method: "DELETE",
          params: { id: queryArg.id, reason: queryArg.reason },
        }),
        invalidatesTags: ["Scope"],
      }),
      getApiScopeGetAllAvailable: build.query<
        GetApiScopeGetAllAvailableApiResponse,
        GetApiScopeGetAllAvailableApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Scope/GetAllAvailable`,
          params: {
            organizationId: queryArg.organizationId,
            searchTerm: queryArg.searchTerm,
            grantType: queryArg.grantType,
          },
        }),
        providesTags: ["Scope"],
      }),
      getApiSettings: build.query<
        GetApiSettingsApiResponse,
        GetApiSettingsApiArg
      >({
        query: () => ({ url: `/api/Settings` }),
        providesTags: ["Settings"],
      }),
      getApiTermsAndConditionsPingTest: build.query<
        GetApiTermsAndConditionsPingTestApiResponse,
        GetApiTermsAndConditionsPingTestApiArg
      >({
        query: () => ({ url: `/api/TermsAndConditions/PingTest` }),
        providesTags: ["TermsAndConditions"],
      }),
      getApiTermsAndConditionsGetLatestTermsAndConditions: build.query<
        GetApiTermsAndConditionsGetLatestTermsAndConditionsApiResponse,
        GetApiTermsAndConditionsGetLatestTermsAndConditionsApiArg
      >({
        query: () => ({
          url: `/api/TermsAndConditions/GetLatestTermsAndConditions`,
        }),
        providesTags: ["TermsAndConditions"],
      }),
      getApiUserGetAllUserDetails: build.query<
        GetApiUserGetAllUserDetailsApiResponse,
        GetApiUserGetAllUserDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetAllUserDetails`,
          params: {
            organizationId: queryArg.organizationId,
            applicationId: queryArg.applicationId,
            lastPullDateTime: queryArg.lastPullDateTime,
            groupId: queryArg.groupId,
          },
        }),
        providesTags: ["User"],
      }),
      getApiUserGetUserDetails: build.query<
        GetApiUserGetUserDetailsApiResponse,
        GetApiUserGetUserDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetUserDetails`,
          params: {
            organizationId: queryArg.organizationId,
            userId: queryArg.userId,
            email: queryArg.email,
          },
        }),
        providesTags: ["User"],
      }),
      getApiUserPingTest: build.query<
        GetApiUserPingTestApiResponse,
        GetApiUserPingTestApiArg
      >({
        query: () => ({ url: `/api/User/PingTest` }),
        providesTags: ["User"],
      }),
      getApiUserGetUser: build.query<
        GetApiUserGetUserApiResponse,
        GetApiUserGetUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetUser`,
          params: {
            userId: queryArg.userId,
            organizationId: queryArg.organizationId,
            email: queryArg.email,
          },
        }),
        providesTags: ["User"],
      }),
      getApiUserGetOwnProfile: build.query<
        GetApiUserGetOwnProfileApiResponse,
        GetApiUserGetOwnProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetOwnProfile`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["User"],
      }),
      postApiUserUpdateOwnProfile: build.mutation<
        PostApiUserUpdateOwnProfileApiResponse,
        PostApiUserUpdateOwnProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UpdateOwnProfile`,
          method: "POST",
          body: queryArg.updateOwnProfileCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserLinkUser: build.mutation<
        PostApiUserLinkUserApiResponse,
        PostApiUserLinkUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/LinkUser`,
          method: "POST",
          body: queryArg.linkUserCommand,
          params: { organizationId: queryArg.organizationId },
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserUnlinkUser: build.mutation<
        PostApiUserUnlinkUserApiResponse,
        PostApiUserUnlinkUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UnlinkUser`,
          method: "POST",
          body: queryArg.unlinkUserCommand,
          params: { organizationId: queryArg.organizationId },
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserGetUserProvider: build.query<
        GetApiUserGetUserProviderApiResponse,
        GetApiUserGetUserProviderApiArg
      >({
        query: () => ({ url: `/api/User/GetUserProvider` }),
        providesTags: ["User"],
      }),
      getApiUserGetUsers: build.query<
        GetApiUserGetUsersApiResponse,
        GetApiUserGetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetUsers`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            searchTerm: queryArg.searchTerm,
            sortBy: queryArg.sortBy,
            isDesc: queryArg.isDesc,
            organizationId: queryArg.organizationId,
            clientAdminOnly: queryArg.clientAdminOnly,
            disableMaxChar: queryArg.disableMaxChar,
          },
        }),
        providesTags: ["User"],
      }),
      postApiUserDownloadUsers: build.mutation<
        PostApiUserDownloadUsersApiResponse,
        PostApiUserDownloadUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/DownloadUsers`,
          method: "POST",
          params: { organizationId: queryArg.organizationId },
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserCreateUser: build.mutation<
        PostApiUserCreateUserApiResponse,
        PostApiUserCreateUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/CreateUser`,
          method: "POST",
          body: queryArg.addUserCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserUpdateUser: build.mutation<
        PostApiUserUpdateUserApiResponse,
        PostApiUserUpdateUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UpdateUser`,
          method: "POST",
          body: queryArg.updateUserCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserResetUserPassword: build.mutation<
        PostApiUserResetUserPasswordApiResponse,
        PostApiUserResetUserPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ResetUserPassword`,
          method: "POST",
          body: queryArg.resetUserPasswordCommand,
        }),
        invalidatesTags: ["User"],
      }),
      deleteApiUserDeleteUser: build.mutation<
        DeleteApiUserDeleteUserApiResponse,
        DeleteApiUserDeleteUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/DeleteUser`,
          method: "DELETE",
          params: {
            userId: queryArg.userId,
            organizationId: queryArg.organizationId,
            reason: queryArg.reason,
          },
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserGetUserByActivationId: build.query<
        GetApiUserGetUserByActivationIdApiResponse,
        GetApiUserGetUserByActivationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetUserByActivationId`,
          params: { setPasswordId: queryArg.setPasswordId },
        }),
        providesTags: ["User"],
      }),
      postApiUserSignUpUser: build.mutation<
        PostApiUserSignUpUserApiResponse,
        PostApiUserSignUpUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/SignUpUser`,
          method: "POST",
          body: queryArg.signUpUserCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserVerifyUser: build.mutation<
        PostApiUserVerifyUserApiResponse,
        PostApiUserVerifyUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/VerifyUser`,
          method: "POST",
          body: queryArg.verifyUserCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserAddAdditionalInfo: build.mutation<
        PostApiUserAddAdditionalInfoApiResponse,
        PostApiUserAddAdditionalInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/AddAdditionalInfo`,
          method: "POST",
          body: queryArg.addAdditionalInfoCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserAddTotp: build.mutation<
        PostApiUserAddTotpApiResponse,
        PostApiUserAddTotpApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/AddTotp`,
          method: "POST",
          body: queryArg.addTotpCommand,
        }),
        invalidatesTags: ["User"],
      }),
      patchApiUserResendTotpEmail: build.mutation<
        PatchApiUserResendTotpEmailApiResponse,
        PatchApiUserResendTotpEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ResendTotpEmail`,
          method: "PATCH",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserActiveUser: build.mutation<
        PostApiUserActiveUserApiResponse,
        PostApiUserActiveUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ActiveUser`,
          method: "POST",
          body: queryArg.activeUserCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserChangePassword: build.mutation<
        PostApiUserChangePasswordApiResponse,
        PostApiUserChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ChangePassword`,
          method: "POST",
          body: queryArg.changePasswordCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserChangePasswordExternal: build.mutation<
        PostApiUserChangePasswordExternalApiResponse,
        PostApiUserChangePasswordExternalApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ChangePasswordExternal`,
          method: "POST",
          body: queryArg.changePasswordExternalCommand,
        }),
        invalidatesTags: ["User"],
      }),
      patchApiUserSendResetPassword: build.mutation<
        PatchApiUserSendResetPasswordApiResponse,
        PatchApiUserSendResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/SendResetPassword`,
          method: "PATCH",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserGetPermissions: build.query<
        GetApiUserGetPermissionsApiResponse,
        GetApiUserGetPermissionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetPermissions`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ["User"],
      }),
      patchApiUserResendActiveEmail: build.mutation<
        PatchApiUserResendActiveEmailApiResponse,
        PatchApiUserResendActiveEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ResendActiveEmail`,
          method: "PATCH",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["User"],
      }),
      patchApiUserResendVerifyEmail: build.mutation<
        PatchApiUserResendVerifyEmailApiResponse,
        PatchApiUserResendVerifyEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/ResendVerifyEmail`,
          method: "PATCH",
          params: { id: queryArg.id },
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserCreateOrganizationAdmin: build.mutation<
        PostApiUserCreateOrganizationAdminApiResponse,
        PostApiUserCreateOrganizationAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/CreateOrganizationAdmin`,
          method: "POST",
          body: queryArg.createOrganizationAdminCommand,
        }),
        invalidatesTags: ["Organization"],
      }),
      postApiUserRemoveOrganizationAdmin: build.mutation<
        PostApiUserRemoveOrganizationAdminApiResponse,
        PostApiUserRemoveOrganizationAdminApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/removeOrganizationAdmin`,
          method: "POST",
          body: queryArg.removeOrganizationAdminCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserAssignUser: build.mutation<
        PostApiUserAssignUserApiResponse,
        PostApiUserAssignUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/AssignUser`,
          method: "POST",
          body: queryArg.assignUserCommand,
        }),
        invalidatesTags: ["User", "AssignableOrganizations"],
      }),
      getApiUserAssignedOrganization: build.query<
        GetApiUserAssignedOrganizationApiResponse,
        GetApiUserAssignedOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/AssignedOrganization`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["User"],
      }),
      postApiUserVerifyUserTotpCommand: build.mutation<
        PostApiUserVerifyUserTotpCommandApiResponse,
        PostApiUserVerifyUserTotpCommandApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/VerifyUserTotpCommand`,
          method: "POST",
          body: queryArg.verifyUserTotpCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserUploadUser: build.mutation<
        PostApiUserUploadUserApiResponse,
        PostApiUserUploadUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UploadUser`,
          method: "POST",
          body: queryArg.uploadUserCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserUploadUserForUpdate: build.mutation<
        PostApiUserUploadUserForUpdateApiResponse,
        PostApiUserUploadUserForUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UploadUserForUpdate`,
          method: "POST",
          body: queryArg.uploadUserForUpdateCommand,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserSendResetPasswordEmailCommand: build.mutation<
        PostApiUserSendResetPasswordEmailCommandApiResponse,
        PostApiUserSendResetPasswordEmailCommandApiArg
      >({
        query: () => ({
          url: `/api/User/SendResetPasswordEmailCommand`,
          method: "POST",
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserAssignUserToOrgWorkflow: build.mutation<
        PostApiUserAssignUserToOrgWorkflowApiResponse,
        PostApiUserAssignUserToOrgWorkflowApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/AssignUserToOrgWorkflow`,
          method: "POST",
          body: queryArg.assignUsersByWorkflowCommand,
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserGetAssignableOrganization: build.query<
        GetApiUserGetAssignableOrganizationApiResponse,
        GetApiUserGetAssignableOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/GetAssignableOrganization`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["User", "AssignableOrganizations"],
      }),
      postApiUserBatchUnassignUsers: build.mutation<
        PostApiUserBatchUnassignUsersApiResponse,
        PostApiUserBatchUnassignUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/BatchUnassignUsers`,
          method: "POST",
          body: queryArg.batchUnassignUserCommand,
        }),
        invalidatesTags: ["User", "AssignableUsersByOrg"],
      }),
      patchApiUserUpdateUserInactivityLockStatus: build.mutation<
        PatchApiUserUpdateUserInactivityLockStatusApiResponse,
        PatchApiUserUpdateUserInactivityLockStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UpdateUserInactivityLockStatus`,
          method: "PATCH",
          params: {
            userId: queryArg.userId,
            organizationId: queryArg.organizationId,
            isUserInactivityLockOn: queryArg.isUserInactivityLockOn,
          },
        }),
        invalidatesTags: ["User"],
      }),
      patchApiUserUpdateUserInactivityLockFlag: build.mutation<
        PatchApiUserUpdateUserInactivityLockFlagApiResponse,
        PatchApiUserUpdateUserInactivityLockFlagApiArg
      >({
        query: (queryArg) => ({
          url: `/api/User/UpdateUserInactivityLockFlag`,
          method: "PATCH",
          params: {
            userId: queryArg.userId,
            organizationId: queryArg.organizationId,
            isUserInactivityLockOn: queryArg.isUserInactivityLockOn,
          },
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserLockInactiveUsers: build.query<
        GetApiUserLockInactiveUsersApiResponse,
        GetApiUserLockInactiveUsersApiArg
      >({
        query: () => ({ url: `/api/User/LockInactiveUsers` }),
        providesTags: ["User"],
      }),
      getWellKnownAscentiscx: build.query<
        GetWellKnownAscentiscxApiResponse,
        GetWellKnownAscentiscxApiArg
      >({
        query: () => ({ url: `/.well-known/ascentiscx` }),
        providesTags: ["WellKnown"],
      }),
      getWellKnownByApplicationId: build.query<
        GetWellKnownByApplicationIdApiResponse,
        GetWellKnownByApplicationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/.well-known/${queryArg.applicationId}`,
        }),
        providesTags: ["WellKnown"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as appcenterApi };
export type GetApiApiClientPingTestApiResponse = unknown;
export type GetApiApiClientPingTestApiArg = void;
export type GetApiApiClientGetApiClientApiResponse = unknown;
export type GetApiApiClientGetApiClientApiArg = {
  id?: number;
};
export type GetApiApiClientGetApiClientsApiResponse = unknown;
export type GetApiApiClientGetApiClientsApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
  organizationId?: string;
};
export type PostApiApiClientCreateApiClientApiResponse = unknown;
export type PostApiApiClientCreateApiClientApiArg = {
  addClientCredentialCommand: AddClientCredentialCommand;
};
export type PostApiApiClientEditApiClientApiResponse = unknown;
export type PostApiApiClientEditApiClientApiArg = {
  updateClientCredentialCommand: UpdateClientCredentialCommand;
};
export type DeleteApiApiClientDeleteApiClientApiResponse = unknown;
export type DeleteApiApiClientDeleteApiClientApiArg = {
  id?: number;
  reason?: string;
};
export type PatchApiApiClientResetSecretKeyApiResponse = unknown;
export type PatchApiApiClientResetSecretKeyApiArg = {
  id?: number;
};
export type GetApiApplicationPingTestApiResponse = unknown;
export type GetApiApplicationPingTestApiArg = void;
export type GetApiApplicationApplicationsApiResponse = unknown;
export type GetApiApplicationApplicationsApiArg = {
  searchTerm?: string;
  organizationId?: string;
  getHidden?: boolean;
};
export type GetApiApplicationSubcribedApplicationsApiResponse = unknown;
export type GetApiApplicationSubcribedApplicationsApiArg = {
  organizationId?: string;
};
export type GetApiApplicationGetEnabledApplicationsApiResponse = unknown;
export type GetApiApplicationGetEnabledApplicationsApiArg = void;
export type GetApiApplicationGetApplicationApiResponse = unknown;
export type GetApiApplicationGetApplicationApiArg = {
  id?: string;
};
export type GetApiApplicationGetApplicationsApiResponse = unknown;
export type GetApiApplicationGetApplicationsApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
};
export type PostApiApplicationCreateApplicationApiResponse = unknown;
export type PostApiApplicationCreateApplicationApiArg = {
  addApplicationCommand: AddApplicationCommand;
};
export type PostApiApplicationUpdateApplicationApiResponse = unknown;
export type PostApiApplicationUpdateApplicationApiArg = {
  updateApplicationCommand: UpdateApplicationCommand;
};
export type DeleteApiApplicationDeleteApplicationApiResponse = unknown;
export type DeleteApiApplicationDeleteApplicationApiArg = {
  id?: string;
  reason?: string;
};
export type PostApiApplicationSubcribeApplicationApiResponse = unknown;
export type PostApiApplicationSubcribeApplicationApiArg = {
  subscribeAppCommand: SubscribeAppCommand;
};
export type PostApiApplicationUnsubcribeApplicationApiResponse = unknown;
export type PostApiApplicationUnsubcribeApplicationApiArg = {
  unsubscribeAppCommand: UnsubscribeAppCommand;
};
export type GetApiApplicationWellKnownApiResponse = unknown;
export type GetApiApplicationWellKnownApiArg = {
  applicationId?: string;
};
export type PostApiApplicationWellKnownApiResponse = unknown;
export type PostApiApplicationWellKnownApiArg = {
  addWellknowCommand: AddWellknowCommand;
};
export type GetApiApplicationGetSubscribedMfeApiResponse = unknown;
export type GetApiApplicationGetSubscribedMfeApiArg = {
  organizationId?: string;
};
export type GetApiApplicationGetMfeApplicationsApiResponse = unknown;
export type GetApiApplicationGetMfeApplicationsApiArg = {
  isCc?: boolean;
};
export type PostApiApplicationGroupCreateCustomGroupApiResponse = unknown;
export type PostApiApplicationGroupCreateCustomGroupApiArg = {
  addApplicationGroupCommand: AddApplicationGroupCommand;
};
export type PostApiApplicationGroupDeleteCustomGroupApiResponse = unknown;
export type PostApiApplicationGroupDeleteCustomGroupApiArg = {
  deleteApplicationGroupCommand: DeleteApplicationGroupCommand;
};
export type GetApiApplicationGroupGetCustomGroupApiResponse = unknown;
export type GetApiApplicationGroupGetCustomGroupApiArg = {
  organizationId?: string;
};
export type GetApiApplicationGroupGetApplicationGroupApiResponse = unknown;
export type GetApiApplicationGroupGetApplicationGroupApiArg = {
  id?: string;
};
export type DeleteApiApplicationGroupDeleteApplicationGroupApiResponse =
  unknown;
export type DeleteApiApplicationGroupDeleteApplicationGroupApiArg = {
  id?: string;
  reason?: string;
};
export type PostApiApplicationGroupAddApplicationGroupApiResponse = unknown;
export type PostApiApplicationGroupAddApplicationGroupApiArg = {
  addApplicationGroupCommand: AddApplicationGroupCommand;
};
export type PostApiApplicationGroupAddDefaultApplicationGroupApiResponse =
  unknown;
export type PostApiApplicationGroupAddDefaultApplicationGroupApiArg = {
  addApplicationGroupCommand: AddApplicationGroupCommand;
};
export type PostApiApplicationGroupUpdateApplicationGroupApiResponse = unknown;
export type PostApiApplicationGroupUpdateApplicationGroupApiArg = {
  updateApplicationGroupCommand: UpdateApplicationGroupCommand;
};
export type GetApiApplicationGroupFilterApplicationGroupsApiResponse = unknown;
export type GetApiApplicationGroupFilterApplicationGroupsApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
  organizationId?: string;
};
export type GetApiApplicationGroupGetAllDefaultApplicationGroupApiResponse =
  unknown;
export type GetApiApplicationGroupGetAllDefaultApplicationGroupApiArg = {
  applicationId?: string;
};
export type GetApiApplicationGroupGetApplicationGroupsByUserApiResponse =
  unknown;
export type GetApiApplicationGroupGetApplicationGroupsByUserApiArg = {
  userId?: string;
};
export type PostApiApplicationGroupAddUserToApplicationGroupApiResponse =
  unknown;
export type PostApiApplicationGroupAddUserToApplicationGroupApiArg = {
  addUserToApplicationGroupCommand: AddUserToApplicationGroupCommand;
};
export type DeleteApiApplicationGroupRemoveUserFromGroupApiResponse = unknown;
export type DeleteApiApplicationGroupRemoveUserFromGroupApiArg = {
  userId?: string;
  applicationGroupId?: string;
};
export type GetApiApplicationGroupGetUsersFromGroupApiResponse = unknown;
export type GetApiApplicationGroupGetUsersFromGroupApiArg = {
  organizationId?: string;
  applicationGroupId?: string;
};
export type GetApiAppSettingPingTestApiResponse = unknown;
export type GetApiAppSettingPingTestApiArg = void;
export type GetApiAuditEventByOrganizationIdAndEntityIdApiResponse = unknown;
export type GetApiAuditEventByOrganizationIdAndEntityIdApiArg = {
  organizationId: string;
  entityId: string;
};
export type GetApiAuditEventByOrganizationIdAndEntityIdPageApiResponse =
  unknown;
export type GetApiAuditEventByOrganizationIdAndEntityIdPageApiArg = {
  organizationId: string;
  entityId: string;
  page: number;
  excludeEvents?: string;
};
export type GetApiAuditHeadByOrganizationIdAndEntityIdApiResponse = unknown;
export type GetApiAuditHeadByOrganizationIdAndEntityIdApiArg = {
  organizationId: string;
  entityId: string;
};
export type GetApiAuditOrganizationEventByOrganizationIdAndEntityIdApiResponse =
  unknown;
export type GetApiAuditOrganizationEventByOrganizationIdAndEntityIdApiArg = {
  organizationId: string;
  entityId: string;
};
export type GetApiAuditOrganizationEventByOrganizationIdAndEntityIdPageApiResponse =
  unknown;
export type GetApiAuditOrganizationEventByOrganizationIdAndEntityIdPageApiArg =
  {
    organizationId: string;
    entityId: string;
    page: number;
    excludeEvents?: string;
  };
export type GetApiAuditOrganizationHeadByOrganizationIdAndEntityIdApiResponse =
  unknown;
export type GetApiAuditOrganizationHeadByOrganizationIdAndEntityIdApiArg = {
  organizationId: string;
  entityId: string;
};
export type GetApiAuditLoginByUserIdApiResponse = unknown;
export type GetApiAuditLoginByUserIdApiArg = {
  userId: string;
};
export type GetApiAuditLoginByUserIdAndPageApiResponse = unknown;
export type GetApiAuditLoginByUserIdAndPageApiArg = {
  userId: string;
  page: number;
};
export type GetApiFilePingTestApiResponse = unknown;
export type GetApiFilePingTestApiArg = void;
export type PostApiFileUploadApiResponse = unknown;
export type PostApiFileUploadApiArg = {
  body: File[];
};
export type PostApiFileApiResponse = unknown;
export type PostApiFileApiArg = {
  body: File[];
};
export type GetApiOrganizationGetListOrganizationsApiResponse = unknown;
export type GetApiOrganizationGetListOrganizationsApiArg = void;
export type GetApiOrganizationGetOrganizationByIdApiResponse = unknown;
export type GetApiOrganizationGetOrganizationByIdApiArg = {
  organizationId?: string;
};
export type GetApiOrganizationGetOrganizationByIdOrCodeApiResponse = unknown;
export type GetApiOrganizationGetOrganizationByIdOrCodeApiArg = {
  organizationId?: string;
  organizationCode?: string;
};
export type GetApiOrganizationPingTestApiResponse = unknown;
export type GetApiOrganizationPingTestApiArg = void;
export type GetApiOrganizationGetOrganizationsApiResponse = unknown;
export type GetApiOrganizationGetOrganizationsApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
};
export type GetApiOrganizationGetAllOrganizationsApiResponse = unknown;
export type GetApiOrganizationGetAllOrganizationsApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
};
export type GetApiOrganizationIdentityProviderApiResponse = unknown;
export type GetApiOrganizationIdentityProviderApiArg = {
  organizationId?: string;
};
export type PostApiOrganizationSetIdentityProviderApiResponse = unknown;
export type PostApiOrganizationSetIdentityProviderApiArg = {
  setIdentityProviderCommand: SetIdentityProviderCommand;
};
export type GetApiOrganizationAvailableOrganizationApiResponse = unknown;
export type GetApiOrganizationAvailableOrganizationApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  disableMaxChar?: boolean;
};
export type GetApiOrganizationGetOrganizationApiResponse = unknown;
export type GetApiOrganizationGetOrganizationApiArg = {
  organizationId?: string;
};
export type PostApiOrganizationCreateOrganizationApiResponse = unknown;
export type PostApiOrganizationCreateOrganizationApiArg = {
  addOrganizationCommand: AddOrganizationCommand;
};
export type PostApiOrganizationSignUpOrganizationApiResponse = unknown;
export type PostApiOrganizationSignUpOrganizationApiArg = {
  signUpOrganizationCommand: SignUpOrganizationCommand;
};
export type PostApiOrganizationUpdateOrganizationApiResponse = unknown;
export type PostApiOrganizationUpdateOrganizationApiArg = {
  updateOrganizationCommand: UpdateOrganizationCommand;
};
export type PostApiOrganizationUpdateOrganizationLogoApiResponse = unknown;
export type PostApiOrganizationUpdateOrganizationLogoApiArg = {
  updateOrganizationLogoCommand: UpdateOrganizationLogoCommand;
};
export type DeleteApiOrganizationDeleteOrganizationApiResponse = unknown;
export type DeleteApiOrganizationDeleteOrganizationApiArg = {
  id?: string;
  name?: string;
  reason?: string;
};
export type PostApiOrganizationUpdateOrganizationPolicyApiResponse = unknown;
export type PostApiOrganizationUpdateOrganizationPolicyApiArg = {
  updateOrganizationPolicyCommand: UpdateOrganizationPolicyCommand;
};
export type GetApiOrganizationGetAffectedUsersApiResponse = unknown;
export type GetApiOrganizationGetAffectedUsersApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
  organizationId?: string;
  loginType?: string;
};
export type PostApiOrganizationDownloadAffectedUsersApiResponse = unknown;
export type PostApiOrganizationDownloadAffectedUsersApiArg = {
  organizationId?: string;
  loginType?: string;
};
export type PostApiOrganizationAssignUsersToOrganizationApiResponse = unknown;
export type PostApiOrganizationAssignUsersToOrganizationApiArg = {
  createUserAssignmentApprovalCommand: CreateUserAssignmentApprovalCommand;
};
export type PostApiOrganizationUpdateAndResetUserAssignmentApiResponse =
  unknown;
export type PostApiOrganizationUpdateAndResetUserAssignmentApiArg = {
  updateResetUserAssignmentApprovalCommand: UpdateResetUserAssignmentApprovalCommand;
};
export type PostApiOrganizationSendUsrOrgAccessApprovalEmailApiResponse =
  unknown;
export type PostApiOrganizationSendUsrOrgAccessApprovalEmailApiArg = {
  sendUserAssignmentEmailCommand: SendUserAssignmentEmailCommand;
};
export type PostApiOrganizationUpdateUsrOrgAccessApprovalApiResponse = unknown;
export type PostApiOrganizationUpdateUsrOrgAccessApprovalApiArg = {
  updateUserAssignmentApprovalCommand: UpdateUserAssignmentApprovalCommand;
};
export type GetApiOrganizationGetOrgAssignmentRequestByWfIdApiResponse =
  unknown;
export type GetApiOrganizationGetOrgAssignmentRequestByWfIdApiArg = {
  organizationId?: string;
  workflowId?: string;
};
export type GetApiOrganizationGetUserAssignmentApprovalsApiResponse = unknown;
export type GetApiOrganizationGetUserAssignmentApprovalsApiArg = {
  organizationId?: string;
  sortBy?: string;
  isDesc?: boolean;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
};
export type DeleteApiOrganizationDeleteOrgAssignmentRequestIdApiResponse =
  unknown;
export type DeleteApiOrganizationDeleteOrgAssignmentRequestIdApiArg = {
  id?: string;
};
export type GetApiOrganizationAssignableUsersByOrgApiResponse = unknown;
export type GetApiOrganizationAssignableUsersByOrgApiArg = {
  organizationId?: string;
};
export type GetApiOrganizationGetOrganizationsByUserIdApiResponse = unknown;
export type GetApiOrganizationGetOrganizationsByUserIdApiArg = {
  userId?: string;
};
export type GetApiOrganizationGetOrganizationWithPolicyApiResponse = unknown;
export type GetApiOrganizationGetOrganizationWithPolicyApiArg = {
  organizationId?: string;
};
export type GetApiPermissionPingTestApiResponse = unknown;
export type GetApiPermissionPingTestApiArg = void;
export type GetApiPermissionGetPermissionsByRoleIdApiResponse = unknown;
export type GetApiPermissionGetPermissionsByRoleIdApiArg = {
  id?: string;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
};
export type GetApiRolePingTestApiResponse = unknown;
export type GetApiRolePingTestApiArg = void;
export type GetApiRoleGetRoleApiResponse = unknown;
export type GetApiRoleGetRoleApiArg = {
  id?: string;
};
export type GetApiRoleGetRolesApiResponse = unknown;
export type GetApiRoleGetRolesApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
};
export type PostApiRoleCreateRoleApiResponse = unknown;
export type PostApiRoleCreateRoleApiArg = {
  addRoleCommand: AddRoleCommand;
};
export type PostApiRoleUpdateRoleApiResponse = unknown;
export type PostApiRoleUpdateRoleApiArg = {
  updateRoleCommand: UpdateRoleCommand;
};
export type DeleteApiRoleDeleteRoleApiResponse = unknown;
export type DeleteApiRoleDeleteRoleApiArg = {
  id?: string;
  reason?: string;
};
export type GetApiRoleGetManageableRoleApiResponse = unknown;
export type GetApiRoleGetManageableRoleApiArg = {
  organizationId?: string;
};
export type GetApiRoleGetAllRolesInOrgApiResponse = unknown;
export type GetApiRoleGetAllRolesInOrgApiArg = {
  organizationId?: string;
};
export type GetApiRoleGetChildRolesByNameOrIdApiResponse = unknown;
export type GetApiRoleGetChildRolesByNameOrIdApiArg = {
  roleName?: string;
  roleId?: string;
};
export type GetApiRoleGetRolesByOrganizationIdApiResponse = unknown;
export type GetApiRoleGetRolesByOrganizationIdApiArg = {
  organizationId?: string;
  includeTopRole?: boolean;
};
export type GetApiScopePingTestApiResponse = unknown;
export type GetApiScopePingTestApiArg = void;
export type GetApiScopeGetScopeApiResponse = unknown;
export type GetApiScopeGetScopeApiArg = {
  id?: number;
};
export type GetApiScopeGetScopesApiResponse = unknown;
export type GetApiScopeGetScopesApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
  organizationId?: string;
};
export type PostApiScopeCreateScopeApiResponse = unknown;
export type PostApiScopeCreateScopeApiArg = {
  addScopeCommand: AddScopeCommand;
};
export type PostApiScopeUpdateScopeApiResponse = unknown;
export type PostApiScopeUpdateScopeApiArg = {
  updateScopeCommand: UpdateScopeCommand;
};
export type DeleteApiScopeDeleteSCopeApiResponse = unknown;
export type DeleteApiScopeDeleteSCopeApiArg = {
  id?: number;
  reason?: string;
};
export type GetApiScopeGetAllAvailableApiResponse = unknown;
export type GetApiScopeGetAllAvailableApiArg = {
  organizationId?: string;
  searchTerm?: string;
  grantType?: string;
};
export type GetApiSettingsApiResponse = unknown;
export type GetApiSettingsApiArg = void;
export type GetApiTermsAndConditionsPingTestApiResponse = unknown;
export type GetApiTermsAndConditionsPingTestApiArg = void;
export type GetApiTermsAndConditionsGetLatestTermsAndConditionsApiResponse =
  unknown;
export type GetApiTermsAndConditionsGetLatestTermsAndConditionsApiArg = void;
export type GetApiUserGetAllUserDetailsApiResponse = unknown;
export type GetApiUserGetAllUserDetailsApiArg = {
  organizationId?: string;
  applicationId?: string;
  lastPullDateTime?: string;
  groupId?: string;
};
export type GetApiUserGetUserDetailsApiResponse = unknown;
export type GetApiUserGetUserDetailsApiArg = {
  organizationId?: string;
  userId?: string;
  email?: string;
};
export type GetApiUserPingTestApiResponse = unknown;
export type GetApiUserPingTestApiArg = void;
export type GetApiUserGetUserApiResponse = unknown;
export type GetApiUserGetUserApiArg = {
  userId?: string;
  organizationId?: string;
  email?: string;
};
export type GetApiUserGetOwnProfileApiResponse = unknown;
export type GetApiUserGetOwnProfileApiArg = {
  organizationId?: string;
};
export type PostApiUserUpdateOwnProfileApiResponse = unknown;
export type PostApiUserUpdateOwnProfileApiArg = {
  updateOwnProfileCommand: UpdateOwnProfileCommand;
};
export type PostApiUserLinkUserApiResponse = unknown;
export type PostApiUserLinkUserApiArg = {
  organizationId?: string;
  linkUserCommand: LinkUserCommand;
};
export type PostApiUserUnlinkUserApiResponse = unknown;
export type PostApiUserUnlinkUserApiArg = {
  organizationId?: string;
  unlinkUserCommand: UnlinkUserCommand;
};
export type GetApiUserGetUserProviderApiResponse = unknown;
export type GetApiUserGetUserProviderApiArg = void;
export type GetApiUserGetUsersApiResponse = unknown;
export type GetApiUserGetUsersApiArg = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortBy?: string;
  isDesc?: boolean;
  organizationId?: string;
  clientAdminOnly?: boolean;
  disableMaxChar?: boolean;
};
export type PostApiUserDownloadUsersApiResponse = unknown;
export type PostApiUserDownloadUsersApiArg = {
  organizationId?: string;
};
export type PostApiUserCreateUserApiResponse = unknown;
export type PostApiUserCreateUserApiArg = {
  addUserCommand: AddUserCommand;
};
export type PostApiUserUpdateUserApiResponse = unknown;
export type PostApiUserUpdateUserApiArg = {
  updateUserCommand: UpdateUserCommand;
};
export type PostApiUserResetUserPasswordApiResponse = unknown;
export type PostApiUserResetUserPasswordApiArg = {
  resetUserPasswordCommand: ResetUserPasswordCommand;
};
export type DeleteApiUserDeleteUserApiResponse = unknown;
export type DeleteApiUserDeleteUserApiArg = {
  userId?: string;
  organizationId?: string;
  reason?: string;
};
export type GetApiUserGetUserByActivationIdApiResponse = unknown;
export type GetApiUserGetUserByActivationIdApiArg = {
  setPasswordId?: string;
};
export type PostApiUserSignUpUserApiResponse = unknown;
export type PostApiUserSignUpUserApiArg = {
  signUpUserCommand: SignUpUserCommand;
};
export type PostApiUserVerifyUserApiResponse = unknown;
export type PostApiUserVerifyUserApiArg = {
  verifyUserCommand: VerifyUserCommand;
};
export type PostApiUserAddAdditionalInfoApiResponse = unknown;
export type PostApiUserAddAdditionalInfoApiArg = {
  addAdditionalInfoCommand: AddAdditionalInfoCommand;
};
export type PostApiUserAddTotpApiResponse = unknown;
export type PostApiUserAddTotpApiArg = {
  addTotpCommand: AddTotpCommand;
};
export type PatchApiUserResendTotpEmailApiResponse = unknown;
export type PatchApiUserResendTotpEmailApiArg = {
  id?: string;
};
export type PostApiUserActiveUserApiResponse = unknown;
export type PostApiUserActiveUserApiArg = {
  activeUserCommand: ActiveUserCommand;
};
export type PostApiUserChangePasswordApiResponse = unknown;
export type PostApiUserChangePasswordApiArg = {
  changePasswordCommand: ChangePasswordCommand;
};
export type PostApiUserChangePasswordExternalApiResponse = unknown;
export type PostApiUserChangePasswordExternalApiArg = {
  changePasswordExternalCommand: ChangePasswordExternalCommand;
};
export type PatchApiUserSendResetPasswordApiResponse = unknown;
export type PatchApiUserSendResetPasswordApiArg = {
  id?: string;
};
export type GetApiUserGetPermissionsApiResponse = unknown;
export type GetApiUserGetPermissionsApiArg = {
  organizationId?: string;
};
export type PatchApiUserResendActiveEmailApiResponse = unknown;
export type PatchApiUserResendActiveEmailApiArg = {
  id?: string;
};
export type PatchApiUserResendVerifyEmailApiResponse = unknown;
export type PatchApiUserResendVerifyEmailApiArg = {
  id?: string;
};
export type PostApiUserCreateOrganizationAdminApiResponse = unknown;
export type PostApiUserCreateOrganizationAdminApiArg = {
  createOrganizationAdminCommand: CreateOrganizationAdminCommand;
};
export type PostApiUserRemoveOrganizationAdminApiResponse = unknown;
export type PostApiUserRemoveOrganizationAdminApiArg = {
  removeOrganizationAdminCommand: RemoveOrganizationAdminCommand;
};
export type PostApiUserAssignUserApiResponse = unknown;
export type PostApiUserAssignUserApiArg = {
  assignUserCommand: AssignUserCommand;
};
export type GetApiUserAssignedOrganizationApiResponse = unknown;
export type GetApiUserAssignedOrganizationApiArg = {
  userId?: string;
};
export type PostApiUserVerifyUserTotpCommandApiResponse = unknown;
export type PostApiUserVerifyUserTotpCommandApiArg = {
  verifyUserTotpCommand: VerifyUserTotpCommand;
};
export type PostApiUserUploadUserApiResponse = unknown;
export type PostApiUserUploadUserApiArg = {
  uploadUserCommand: UploadUserCommand;
};
export type PostApiUserUploadUserForUpdateApiResponse = unknown;
export type PostApiUserUploadUserForUpdateApiArg = {
  uploadUserForUpdateCommand: UploadUserForUpdateCommand;
};
export type PostApiUserSendResetPasswordEmailCommandApiResponse = unknown;
export type PostApiUserSendResetPasswordEmailCommandApiArg = void;
export type PostApiUserAssignUserToOrgWorkflowApiResponse = unknown;
export type PostApiUserAssignUserToOrgWorkflowApiArg = {
  assignUsersByWorkflowCommand: AssignUsersByWorkflowCommand;
};
export type GetApiUserGetAssignableOrganizationApiResponse = unknown;
export type GetApiUserGetAssignableOrganizationApiArg = {
  userId?: string;
};
export type PostApiUserBatchUnassignUsersApiResponse = unknown;
export type PostApiUserBatchUnassignUsersApiArg = {
  batchUnassignUserCommand: BatchUnassignUserCommand;
};
export type PatchApiUserUpdateUserInactivityLockStatusApiResponse = unknown;
export type PatchApiUserUpdateUserInactivityLockStatusApiArg = {
  userId?: string;
  organizationId?: string;
  isUserInactivityLockOn?: boolean;
};
export type PatchApiUserUpdateUserInactivityLockFlagApiResponse = unknown;
export type PatchApiUserUpdateUserInactivityLockFlagApiArg = {
  userId?: string;
  organizationId?: string;
  isUserInactivityLockOn?: boolean;
};
export type GetApiUserLockInactiveUsersApiResponse = unknown;
export type GetApiUserLockInactiveUsersApiArg = void;
export type GetWellKnownAscentiscxApiResponse = unknown;
export type GetWellKnownAscentiscxApiArg = void;
export type GetWellKnownByApplicationIdApiResponse = unknown;
export type GetWellKnownByApplicationIdApiArg = {
  applicationId: string;
};
export type AddClientCredentialCommand = {
  organizationId?: string;
  clientName?: string | null;
  clientId?: string | null;
  clientSecrets?: string | null;
  allowedGrantType?: string | null;
  redirectUris?: string | null;
  postLogoutRedirectUris?: string | null;
  allowedScopes?: string | null;
  allowedCorsOrigins?: string | null;
  logoUri?: string | null;
  backgroundImage?: string | null;
  appHomePage?: string | null;
  privacyPolicyLink?: string | null;
  termOfServiceLink?: string | null;
  supportEmail?: string | null;
  developerEmail?: string | null;
  requirePkce?: boolean;
  frontChannelLogoutSessionRequired?: boolean;
  frontChannelLogoutUri?: string | null;
  backChannelLogoutSessionRequired?: boolean;
  backChannelLogoutUri?: string | null;
  allowOfflineAccess?: boolean;
  allowAccessTokensViaBrowser?: boolean;
  requireClientSecret?: boolean;
  accessTokenLifetime?: number | null;
};
export type UpdateClientCredentialCommand = {
  id?: number;
  clientName?: string | null;
  clientId?: string | null;
  clientSecrets?: string | null;
  allowedGrantType?: string | null;
  redirectUris?: string | null;
  postLogoutRedirectUris?: string | null;
  allowedScopes?: string | null;
  allowedCorsOrigins?: string | null;
  logoUri?: string | null;
  backgroundImage?: string | null;
  appHomePage?: string | null;
  privacyPolicyLink?: string | null;
  termOfServiceLink?: string | null;
  supportEmail?: string | null;
  developerEmail?: string | null;
  requirePkce?: boolean;
  frontChannelLogoutSessionRequired?: boolean;
  frontChannelLogoutUri?: string | null;
  backChannelLogoutSessionRequired?: boolean;
  backChannelLogoutUri?: string | null;
  allowOfflineAccess?: boolean;
  allowAccessTokensViaBrowser?: boolean;
  requireClientSecret?: boolean;
  accessTokenLifetime?: number | null;
};
export type S3BucketFile = {
  key?: string | null;
  url?: string | null;
};
export type AddApplicationCommand = {
  name?: string | null;
  description?: string | null;
  appUrl?: string | null;
  icon?: S3BucketFile;
  preview?: boolean;
  enabled?: boolean;
  hideFromClient?: boolean;
  documentUrl?: string | null;
  isMfe?: boolean;
  mfePath?: string | null;
  mfeIconColor?: string | null;
};
export type UpdateApplicationCommand = {
  id?: string;
  name?: string | null;
  description?: string | null;
  appUrl?: string | null;
  icon?: S3BucketFile;
  enabled?: boolean;
  hideFromClient?: boolean;
  documentUrl?: string | null;
  preview?: boolean;
  isMfe?: boolean;
  mfePath?: string | null;
  mfeIconColor?: string | null;
};
export type SubscribeAppCommand = {
  organizationId?: string;
  appId?: string;
};
export type UnsubscribeAppCommand = {
  organizationId?: string;
  appId?: string;
};
export type WellKnownCustomElementVm = {
  key?: string | null;
  value?: string | null;
};
export type AddWellknowCommand = {
  applicationId?: string;
  wellknownUrl?: string | null;
  apiEndpoint?: string | null;
  webHealthCheckEndPoint?: string | null;
  apiHealthCheckEndpoint?: string | null;
  apiDefinitionUrl?: string | null;
  clientLibraryVersion?: string | null;
  wellKnownCustomElements?: WellKnownCustomElementVm[] | null;
};
export type AddApplicationGroupCommand = {
  name?: string | null;
  description?: string | null;
  applicationId?: string;
  organizationId?: string;
  type?: string | null;
};
export type DeleteApplicationGroupCommand = {
  id?: string;
  name?: string | null;
  organizationId?: string;
  type?: string | null;
  reason?: string | null;
};
export type UpdateApplicationGroupCommand = {
  id?: string;
  name?: string | null;
  description?: string | null;
  applicationId?: string;
  organizationId?: string;
};
export type AddUserToApplicationGroupCommand = {
  applicationGroupId?: string;
  userId?: string;
};
export type File = {
  name?: string | null;
  data?: string | null;
  type?: string | null;
};
export type SetIdentityProviderCommand = {
  organizationId?: string;
  identityProvider?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  returnUrl?: string | null;
  defaultNewUserRole?: string | null;
};
export type AddOrganizationCommand = {
  organizationName?: string | null;
  organizationCode?: string | null;
  description?: string | null;
};
export type SignUpOrganizationCommand = {
  organizationName?: string | null;
  organizationCode?: string | null;
  description?: string | null;
};
export type UpdateOrganizationCommand = {
  id?: string;
  organizationName?: string | null;
  organizationCode?: string | null;
  description?: string | null;
};
export type UpdateOrganizationLogoCommand = {
  id?: string;
  logo?: S3BucketFile;
};
export type OrganizationIdentityProviderVm = {
  identityProvider?: string[] | null;
  whiteListEmailDomain?: string[] | null;
  defaultNewRoles?: string[] | null;
};
export type UpdateOrganizationPolicyCommand = {
  id?: string;
  whiteListEmailDomain?: string[] | null;
  whiteListIpAddresses?: string[] | null;
  twoFAWhiteListedIpAddress?: string[] | null;
  defaultNewRoles?: string[] | null;
  identityProvider?: OrganizationIdentityProviderVm;
  loginType?: string[] | null;
  enableTwoFactorAuthentication?: boolean;
  twoFactorAuthenticationType?: string | null;
  noOfInvalidOtpAttempts?: number | null;
  enablePasswordPolicy?: boolean;
  passwordMinLength?: number | null;
  passwordMaxLength?: number | null;
  passwordRequireSpecialCase?: boolean | null;
  invalidRetires?: number | null;
  passwordExpiry?: number | null;
  passwordHistory?: number | null;
  accountLockDuration?: number | null;
  idleTimeOut?: number | null;
  allowConcurrentSession?: boolean;
  enableRecaptcha?: boolean;
  enableAdminOnlyUpdateEmail?: boolean;
  enableLocalAccount?: boolean;
  userInactivityDuration?: number | null;
};
export type CreateUserAssignmentApprovalCommand = {
  organizationIds?: string[] | null;
  users?: string[] | null;
  assignedRoleId?: string;
};
export type UpdateResetUserAssignmentApprovalCommand = {
  userAssignmentRequestId?: string;
};
export type OrgAssignmentUsr = {
  fullName?: string | null;
};
export type SendUserAssignmentEmailCommand = {
  approverEmails?: string | null;
  ascentisUsers?: OrgAssignmentUsr[] | null;
  assignedRole?: string | null;
  workflowId?: string | null;
  organizationName?: string | null;
};
export type ApprovalStatus = 0 | 1;
export type UpdateUserAssignmentApprovalCommand = {
  workflowId?: string | null;
  approvalResult?: ApprovalStatus;
  approvalReason?: string | null;
};
export type Permission = {
  id?: string;
  status?: string | null;
  name?: string | null;
  description?: string | null;
  displayName?: string | null;
  group?: string | null;
  rolePermissions?: RolePermission[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type RolePermission = {
  id?: string;
  status?: string | null;
  roleId?: string;
  permissionId?: string;
  role?: Role;
  permission?: Permission;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type WellKnownCustomElement = {
  id?: string;
  status?: string | null;
  key?: string | null;
  value?: string | null;
  isDeleted?: boolean;
  wellKnownId?: string;
  deleteBy?: string;
  deletedOn?: string | null;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type WellKnown = {
  id?: string;
  status?: string | null;
  wellknownUrl?: string | null;
  apiEndpoint?: string | null;
  webHealthCheckEndPoint?: string | null;
  apiHealthCheckEndpoint?: string | null;
  apiDefinitionUrl?: string | null;
  clientLibraryVersion?: string | null;
  applicationId?: string;
  application?: Application;
  wellKnownCustomElements?: WellKnownCustomElement[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type EncryptVersion = 0;
export type UserProvider = {
  id?: string;
  status?: string | null;
  userId?: string;
  user?: User;
  provider?: string | null;
  providerEmail?: string | null;
  providerUserId?: string | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type TermsAndConditons = {
  id?: string;
  status?: string | null;
  type?: string | null;
  version?: string | null;
  url?: string | null;
  enabled?: boolean;
  publishOn?: string;
  userAcceptedTermsAndConditions?: UserAcceptedTermsAndConditions[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type UserAcceptedTermsAndConditions = {
  id?: string;
  status?: string | null;
  termsAndConditionsId?: string;
  userId?: string;
  user?: User;
  termsAndConditons?: TermsAndConditons;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type User = {
  id?: string;
  status?: string | null;
  staffId?: string | null;
  organizationIdByClientId?: string | null;
  organizationNameByClientId?: string | null;
  organizationCodeByClientId?: string | null;
  profilePicture?: string | null;
  userFullName?: string | null;
  emailAddress?: string | null;
  hashedEmail?: string | null;
  password?: string | null;
  setPasswordId?: string | null;
  setPasswordExpirationDate?: string | null;
  twoFactorSecretKey?: string | null;
  passwordHistory?: string | null;
  lastUpdatedPassword?: string | null;
  mobileNumber?: string | null;
  hashedMobileNumber?: string | null;
  otp?: string | null;
  enableEmailOtp?: boolean;
  enableTotp?: boolean;
  salt?: string | null;
  encryptVersion?: EncryptVersion;
  rootOrganizationId?: string | null;
  rootOrganization?: Organization;
  userRoles?: UserRole[] | null;
  userProviders?: UserProvider[] | null;
  userApplicationGroups?: UserApplicationGroup[] | null;
  userAcceptedTermsAndConditions?: UserAcceptedTermsAndConditions[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
  startDate?: string | null;
  endDate?: string | null;
};
export type UserApplicationGroup = {
  id?: string;
  status?: string | null;
  applicationGroupId?: string;
  applicationGroup?: ApplicationGroup;
  userId?: string;
  user?: User;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type ApplicationGroup = {
  id?: string;
  status?: string | null;
  name?: string | null;
  description?: string | null;
  type?: string | null;
  organizationId?: string | null;
  organization?: Organization;
  applicationId?: string | null;
  application?: Application;
  userApplicationGroups?: UserApplicationGroup[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type AppSetting = {
  id?: string;
  status?: string | null;
  applicationId?: string | null;
  key?: string | null;
  encrypted?: boolean;
  settings?: string | null;
  valueType?: string | null;
  salt?: string | null;
  encryptVersion?: EncryptVersion;
  application?: Application;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type Application = {
  id?: string;
  status?: string | null;
  name?: string | null;
  description?: string | null;
  appUrl?: string | null;
  icon?: string | null;
  preview?: boolean;
  enabled?: boolean;
  hideFromClient?: boolean;
  documentUrl?: string | null;
  wellKnown?: WellKnown;
  isMfe?: boolean;
  mfePath?: string | null;
  mfeIconColor?: string | null;
  organizationApplications?: OrganizationApplication[] | null;
  applicationGroups?: ApplicationGroup[] | null;
  appSettings?: AppSetting[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type OrganizationApplication = {
  id?: string;
  status?: string | null;
  organizationId?: string;
  applicationId?: string;
  organization?: Organization;
  application?: Application;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type OrganizationIdentityProvider = {
  id?: string;
  status?: string | null;
  identityProvider?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  returnUrl?: string | null;
  defaultNewRoles?: string | null;
  whiteListEmailDomain?: string | null;
  organization?: Organization;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type OrganizationPolicy = {
  id?: string;
  status?: string | null;
  organizationId?: string;
  defaultNewRoles?: string | null;
  loginType?: string | null;
  enableTwoFactorAuthentication?: boolean;
  enableLocalAccount?: boolean;
  enableAdminOnlyUpdateEmail?: boolean;
  twoFactorAuthenticationType?: string | null;
  whiteListEmailDomain?: string | null;
  whiteListIpAddresses?: string | null;
  twoFAWhiteListedIpAddress?: string | null;
  noOfInvalidOtpAttempts?: number | null;
  enablePasswordPolicy?: boolean;
  passwordMinLength?: number | null;
  passwordMaxLength?: number | null;
  passwordRequireLowerCase?: boolean;
  passwordRequireUpperCase?: boolean;
  passwordRequireNumericCase?: boolean;
  passwordRequireSpecialCase?: boolean;
  passwordMatchEmailCase?: boolean;
  invalidRetires?: number | null;
  passwordExpiry?: number | null;
  passwordHistory?: number | null;
  accountLockDuration?: number | null;
  idleTimeOut?: number | null;
  allowConcurrentSession?: boolean;
  enableRecaptcha?: boolean;
  organization?: Organization;
  identityProviders?: OrganizationIdentityProvider[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
  userInactivityDuration?: number | null;
};
export type Organization = {
  id?: string;
  status?: string | null;
  organizationName?: string | null;
  organizationCode?: string | null;
  description?: string | null;
  logo?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  returnUrl?: string | null;
  defaultNewUserRole?: string | null;
  organizationApplications?: OrganizationApplication[] | null;
  applicationGroups?: ApplicationGroup[] | null;
  userRoles?: UserRole[] | null;
  organizationPolicy?: OrganizationPolicy;
  rootUsers?: User[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
  isOwnerOrg?: boolean;
};
export type UserRole = {
  id?: string;
  status?: string | null;
  roleId?: string;
  organizationId?: string;
  userId?: string;
  staffId?: string | null;
  hashStaffId?: string | null;
  role?: Role;
  organization?: Organization;
  user?: User;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
  otpAttempts?: number | null;
  accountLockDate?: string | null;
  lastLoginDate?: string | null;
  isUserInactivityLockOn?: boolean;
  skipTwoFactorAuth?: boolean;
};
export type RoleParent = {
  id?: string;
  roleId?: string;
  parentId?: string;
  role?: Role;
  parent?: Role;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
};
export type UserPermission = {
  name?: string | null;
};
export type Role = {
  id?: string;
  status?: string | null;
  name?: string | null;
  description?: string | null;
  rolePermissions?: RolePermission[] | null;
  userRoles?: UserRole[] | null;
  roleChildren?: RoleParent[] | null;
  roleParents?: RoleParent[] | null;
  isDeleted?: boolean;
  deletedOn?: string | null;
  deleteBy?: string;
  createdDate?: string | null;
  createdBy?: string;
  updatedDate?: string | null;
  updatedBy?: string | null;
  userPermission?: UserPermission[] | null;
  parentNames?: string[] | null;
  isOwnerOrg?: boolean;
};
export type AddRoleCommand = {
  name?: string | null;
  description?: string | null;
  permissions?: string[] | null;
  parents?: Role[] | null;
};
export type UpdateRoleCommand = {
  id?: string;
  name?: string | null;
  description?: string | null;
  permissions?: string[] | null;
  parents?: Role[] | null;
};
export type ApplicationVm = {
  id?: string;
  name?: string | null;
  description?: string | null;
  appUrl?: string | null;
  icon?: S3BucketFile;
  preview?: boolean;
  status?: string | null;
  documentUrl?: string | null;
  enabled?: boolean;
  hideFromClient?: boolean;
  subcribed?: boolean;
  isMfe?: boolean;
  mfePath?: string | null;
  mfeIconColor?: string | null;
};
export type AddScopeCommand = {
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  status?: string | null;
  internalScope?: boolean;
  restrictOauthFlow?: string | null;
  apiDefinitionLink?: string | null;
  application?: ApplicationVm;
};
export type UpdateScopeCommand = {
  id?: number;
  name?: string | null;
  description?: string | null;
  displayName?: string | null;
  internalScope?: boolean;
  restrictOauthFlow?: string | null;
  apiDefinitionLink?: string | null;
  status?: string | null;
  application?: ApplicationVm;
};
export type UpdateOwnProfileCommand = {
  staffId?: string | null;
  userFullName?: string | null;
  email?: string | null;
  organizationId?: string;
  profilePicture?: string | null;
  status?: string | null;
  mobileNumber?: string | null;
  enableEmailOtp?: boolean;
  enableTotp?: boolean;
};
export type LinkUserCommand = {
  provider?: string | null;
  providerUserId?: string | null;
  providerEmail?: string | null;
  organizationId?: string;
};
export type UnlinkUserCommand = {
  provider?: string | null;
  organizationId?: string;
};
export type AddUserCommand = {
  staffId?: string | null;
  userFullName?: string | null;
  email?: string | null;
  organizationId?: string;
  mobileNumber?: string | null;
  profilePicture?: string | null;
  roles?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
};
export type UpdateUserCommand = {
  id?: string;
  staffId?: string | null;
  userFullName?: string | null;
  email?: string | null;
  organizationId?: string;
  profilePicture?: string | null;
  roles?: string[] | null;
  status?: string | null;
  mobileNumber?: string | null;
  enableEmailOtp?: boolean;
  enableTotp?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  rootOrganizationId?: string | null;
  skipTwoFactorAuth?: boolean | null;
};
export type ResetUserPasswordCommand = {
  id?: string;
  organizationId?: string;
  password?: string | null;
  confirmPassword?: string | null;
};
export type SignUpUserCommand = {
  setPasswordId?: string;
  userFullName?: string | null;
  email?: string | null;
  password?: string | null;
  confirmPassword?: string | null;
};
export type VerifyUserCommand = {
  setPasswordId?: string;
  otpCode?: string | null;
};
export type AddAdditionalInfoCommand = {
  setPasswordId?: string;
  mobileNumber?: string | null;
  staffId?: string | null;
};
export type AddTotpCommand = {
  setPasswordId?: string;
  totpKey?: string | null;
  totp?: string | null;
};
export type ActiveUserCommand = {
  setPasswordId?: string;
  tnCId?: string;
};
export type ChangePasswordCommand = {
  id?: string;
  currentPassword?: string | null;
  newPassword?: string | null;
  confirmNewPassword?: string | null;
};
export type ChangePasswordExternalCommand = {
  currentPassword?: string | null;
  newPassword?: string | null;
  confirmPassword?: string | null;
};
export type CreateOrganizationAdminCommand = {
  email?: string | null;
  organizationId?: string;
};
export type RemoveOrganizationAdminCommand = {
  userId?: string;
  organizationId?: string;
};
export type AssignUserCommand = {
  organizationIds?: string[] | null;
  userId?: string;
};
export type VerifyUserTotpCommand = {
  twoFactorSecretKey?: string | null;
  totpCode?: string | null;
};
export type UploadUserCommand = {
  organizationId?: string;
  file?: File;
};
export type UploadUserForUpdateCommand = {
  organizationId?: string;
  file?: File;
};
export type AssignUsersByWorkflowCommand = {
  workflowId?: string | null;
};
export type BatchUnassignUserCommand = {
  userIds?: string[] | null;
  organizationId?: string;
};
export const {
  useGetApiApiClientPingTestQuery,
  useGetApiApiClientGetApiClientQuery,
  useGetApiApiClientGetApiClientsQuery,
  usePostApiApiClientCreateApiClientMutation,
  usePostApiApiClientEditApiClientMutation,
  useDeleteApiApiClientDeleteApiClientMutation,
  usePatchApiApiClientResetSecretKeyMutation,
  useGetApiApplicationPingTestQuery,
  useGetApiApplicationApplicationsQuery,
  useGetApiApplicationSubcribedApplicationsQuery,
  useGetApiApplicationGetEnabledApplicationsQuery,
  useGetApiApplicationGetApplicationQuery,
  useGetApiApplicationGetApplicationsQuery,
  usePostApiApplicationCreateApplicationMutation,
  usePostApiApplicationUpdateApplicationMutation,
  useDeleteApiApplicationDeleteApplicationMutation,
  usePostApiApplicationSubcribeApplicationMutation,
  usePostApiApplicationUnsubcribeApplicationMutation,
  useGetApiApplicationWellKnownQuery,
  usePostApiApplicationWellKnownMutation,
  useGetApiApplicationGetSubscribedMfeQuery,
  useGetApiApplicationGetMfeApplicationsQuery,
  usePostApiApplicationGroupCreateCustomGroupMutation,
  usePostApiApplicationGroupDeleteCustomGroupMutation,
  useGetApiApplicationGroupGetCustomGroupQuery,
  useGetApiApplicationGroupGetApplicationGroupQuery,
  useDeleteApiApplicationGroupDeleteApplicationGroupMutation,
  usePostApiApplicationGroupAddApplicationGroupMutation,
  usePostApiApplicationGroupAddDefaultApplicationGroupMutation,
  usePostApiApplicationGroupUpdateApplicationGroupMutation,
  useGetApiApplicationGroupFilterApplicationGroupsQuery,
  useGetApiApplicationGroupGetAllDefaultApplicationGroupQuery,
  useGetApiApplicationGroupGetApplicationGroupsByUserQuery,
  usePostApiApplicationGroupAddUserToApplicationGroupMutation,
  useDeleteApiApplicationGroupRemoveUserFromGroupMutation,
  useGetApiApplicationGroupGetUsersFromGroupQuery,
  useGetApiAppSettingPingTestQuery,
  useGetApiAuditEventByOrganizationIdAndEntityIdQuery,
  useGetApiAuditEventByOrganizationIdAndEntityIdPageQuery,
  useGetApiAuditHeadByOrganizationIdAndEntityIdQuery,
  useGetApiAuditOrganizationEventByOrganizationIdAndEntityIdQuery,
  useGetApiAuditOrganizationEventByOrganizationIdAndEntityIdPageQuery,
  useGetApiAuditOrganizationHeadByOrganizationIdAndEntityIdQuery,
  useGetApiAuditLoginByUserIdQuery,
  useGetApiAuditLoginByUserIdAndPageQuery,
  useGetApiFilePingTestQuery,
  usePostApiFileUploadMutation,
  usePostApiFileMutation,
  useGetApiOrganizationGetListOrganizationsQuery,
  useGetApiOrganizationGetOrganizationByIdQuery,
  useGetApiOrganizationGetOrganizationByIdOrCodeQuery,
  useGetApiOrganizationPingTestQuery,
  useGetApiOrganizationGetOrganizationsQuery,
  useGetApiOrganizationGetAllOrganizationsQuery,
  useGetApiOrganizationIdentityProviderQuery,
  usePostApiOrganizationSetIdentityProviderMutation,
  useGetApiOrganizationAvailableOrganizationQuery,
  useGetApiOrganizationGetOrganizationQuery,
  usePostApiOrganizationCreateOrganizationMutation,
  usePostApiOrganizationSignUpOrganizationMutation,
  usePostApiOrganizationUpdateOrganizationMutation,
  usePostApiOrganizationUpdateOrganizationLogoMutation,
  useDeleteApiOrganizationDeleteOrganizationMutation,
  usePostApiOrganizationUpdateOrganizationPolicyMutation,
  useGetApiOrganizationGetAffectedUsersQuery,
  usePostApiOrganizationDownloadAffectedUsersMutation,
  usePostApiOrganizationAssignUsersToOrganizationMutation,
  usePostApiOrganizationUpdateAndResetUserAssignmentMutation,
  usePostApiOrganizationSendUsrOrgAccessApprovalEmailMutation,
  usePostApiOrganizationUpdateUsrOrgAccessApprovalMutation,
  useGetApiOrganizationGetOrgAssignmentRequestByWfIdQuery,
  useGetApiOrganizationGetUserAssignmentApprovalsQuery,
  useDeleteApiOrganizationDeleteOrgAssignmentRequestIdMutation,
  useGetApiOrganizationAssignableUsersByOrgQuery,
  useGetApiOrganizationGetOrganizationsByUserIdQuery,
  useGetApiOrganizationGetOrganizationWithPolicyQuery,
  useGetApiPermissionPingTestQuery,
  useGetApiPermissionGetPermissionsByRoleIdQuery,
  useGetApiRolePingTestQuery,
  useGetApiRoleGetRoleQuery,
  useGetApiRoleGetRolesQuery,
  usePostApiRoleCreateRoleMutation,
  usePostApiRoleUpdateRoleMutation,
  useDeleteApiRoleDeleteRoleMutation,
  useGetApiRoleGetManageableRoleQuery,
  useGetApiRoleGetAllRolesInOrgQuery,
  useGetApiRoleGetChildRolesByNameOrIdQuery,
  useGetApiRoleGetRolesByOrganizationIdQuery,
  useGetApiScopePingTestQuery,
  useGetApiScopeGetScopeQuery,
  useGetApiScopeGetScopesQuery,
  usePostApiScopeCreateScopeMutation,
  usePostApiScopeUpdateScopeMutation,
  useDeleteApiScopeDeleteSCopeMutation,
  useGetApiScopeGetAllAvailableQuery,
  useGetApiSettingsQuery,
  useGetApiTermsAndConditionsPingTestQuery,
  useGetApiTermsAndConditionsGetLatestTermsAndConditionsQuery,
  useGetApiUserGetAllUserDetailsQuery,
  useGetApiUserGetUserDetailsQuery,
  useGetApiUserPingTestQuery,
  useGetApiUserGetUserQuery,
  useGetApiUserGetOwnProfileQuery,
  usePostApiUserUpdateOwnProfileMutation,
  usePostApiUserLinkUserMutation,
  usePostApiUserUnlinkUserMutation,
  useGetApiUserGetUserProviderQuery,
  useGetApiUserGetUsersQuery,
  usePostApiUserDownloadUsersMutation,
  usePostApiUserCreateUserMutation,
  usePostApiUserUpdateUserMutation,
  usePostApiUserResetUserPasswordMutation,
  useDeleteApiUserDeleteUserMutation,
  useGetApiUserGetUserByActivationIdQuery,
  usePostApiUserSignUpUserMutation,
  usePostApiUserVerifyUserMutation,
  usePostApiUserAddAdditionalInfoMutation,
  usePostApiUserAddTotpMutation,
  usePatchApiUserResendTotpEmailMutation,
  usePostApiUserActiveUserMutation,
  usePostApiUserChangePasswordMutation,
  usePostApiUserChangePasswordExternalMutation,
  usePatchApiUserSendResetPasswordMutation,
  useGetApiUserGetPermissionsQuery,
  usePatchApiUserResendActiveEmailMutation,
  usePatchApiUserResendVerifyEmailMutation,
  usePostApiUserCreateOrganizationAdminMutation,
  usePostApiUserRemoveOrganizationAdminMutation,
  usePostApiUserAssignUserMutation,
  useGetApiUserAssignedOrganizationQuery,
  usePostApiUserVerifyUserTotpCommandMutation,
  usePostApiUserUploadUserMutation,
  usePostApiUserUploadUserForUpdateMutation,
  usePostApiUserSendResetPasswordEmailCommandMutation,
  usePostApiUserAssignUserToOrgWorkflowMutation,
  useGetApiUserGetAssignableOrganizationQuery,
  usePostApiUserBatchUnassignUsersMutation,
  usePatchApiUserUpdateUserInactivityLockStatusMutation,
  usePatchApiUserUpdateUserInactivityLockFlagMutation,
  useGetApiUserLockInactiveUsersQuery,
  useGetWellKnownAscentiscxQuery,
  useGetWellKnownByApplicationIdQuery,
} = injectedRtkApi;
