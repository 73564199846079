import * as React from "react";
import {
  useGetApiOrganizationGetOrganizationQuery,
  usePostApiOrganizationUpdateOrganizationMutation
} from "../../services/appcenterApi";
import { UpdateForm } from "../../components/UpdateForm";
import {
  OrganizationFormProvider,
  OrganizationResponseTransformation,
  useOrganizationForm
} from "../../features/organizations/OrganizationFormContext";
import { OrganizationInputs } from "../../features/organizations/OrganizationInputs";
import { useWithCurrentOrganization } from "../../hooks/useWithCurrentOrganization";
import { useAppDispatch, useAppSelector } from "shared-library/src/hooks/reduxHelper";
import { useGetUserInfoQuery } from "shared-library/src/services/identityApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";


export function UpdateOrganization() {

  const dispatch = useAppDispatch();
  const access_token = useAppSelector((state) => state.user?.access_token);
  //useGetUserInfoQuery(access_token !== "" ? {} : skipToken);
  const userProfile = useGetUserInfoQuery(access_token !== "" ? {} : skipToken);

  const postUpdateCallback = function () {    
    userProfile.refetch();
  }

  return (
    <>
      <UpdateForm FormProvider={OrganizationFormProvider} inputs={<OrganizationInputs disableCodeUpdate />} i18Prefix={"organization"}
                  useUpdateMutation={usePostApiOrganizationUpdateOrganizationMutation} useFormHook={useOrganizationForm}
                  commandKey={"updateOrganizationCommand"}
                  postUpdateCallback={postUpdateCallback}
                  transformResponse={OrganizationResponseTransformation}
                  useLoadQuery={useWithCurrentOrganization(useGetApiOrganizationGetOrganizationQuery)} />
    </>
  );

}