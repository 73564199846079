import { createFormContext } from "@mantine/form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as dayjs from "dayjs";
import { z } from "zod";

import { useAppSelector } from "../../hooks/reduxHelper";
import { useCustomZodResolver } from "../../hooks/useCustomZodResolver";
import { useGetApiOrganizationGetOrganizationQuery } from "../../services/appcenterApi";
import { selectOrganizationId } from "../user/userSlice";

interface UserFormValues {
  userFullName: string;
  staffId: string;
  email: string;
  mobileNumber: string;
  status: string;

  roles: string[];
  organizationId: string;
  enableEmailOtp: boolean;
  enableTotp: boolean;
  profilePicture: string;

  startDate: string;
  endDate: string;
  profilePictureKey: string;

  isEmailMandatory: boolean;
  isStaffIdMandatory: boolean;
  skipTwoFactorAuth: boolean;
}

const UserFormSchema = z
  .object({
    userFullName: z.string().trim().min(1),
    staffId: z.string().trim().optional().nullable(), //.min(1),
    email: z.string().email().optional().nullable().or(z.literal("")),
    mobileNumber: z.string().trim().optional().nullable(), //.min(1),
    startDate: z.date().optional().nullable(),
    endDate: z.date().optional().nullable(),
    //status: z.string().trim().min(1),
    //roles: z.string().trim().min(1),
    //organizationId: z.string().trim().min(1),
    //enableEmailOtp: z.string().trim().min(1),
    //enableTotp: z.string().trim().min(1),
    isEmailMandatory: z.boolean(),
    isStaffIdMandatory: z.boolean(),
    roles: z.string().array().min(1),
  })
  .refine(
    ({ isEmailMandatory, isStaffIdMandatory, staffId, email }) => {
      console.log("refine");
      console.log(
        isEmailMandatory &&
          isStaffIdMandatory &&
          email &&
          staffId &&
          email != "" &&
          staffId != ""
      );
      console.log("isEmail", isEmailMandatory);
      console.log("isStaffIdMandatory", isStaffIdMandatory);
      console.log("email", email != "");
      console.log("staffId", staffId != "");
      return !(
        isEmailMandatory &&
        isStaffIdMandatory &&
        email == "" &&
        staffId == ""
      );
    },
    {
      path: ["email"],
      message: "validation.oneOfUserIdentifierFieldMustBeFilled", //"Either StaffId or Email or Mobile Number should be filled in.",
    }
  )
  .refine(
    ({ email, isEmailMandatory, isStaffIdMandatory }) =>
      !(isEmailMandatory && email == "" && !isStaffIdMandatory),
    {
      path: ["email"],
      message: "validation.is_required",
    }
  )
  .refine(
    ({ staffId, isStaffIdMandatory, isEmailMandatory }) =>
      !(isStaffIdMandatory && staffId == "" && !isEmailMandatory),
    { path: ["staffId"], message: "validation.is_required" }
  );
//.refine(checkWithOrganizationPolicy, { path:["email"], message: "validation.invalid_organization_policy"})

const userFormInitialValues: UserFormValues = {
  userFullName: "",
  staffId: "",
  email: "",
  mobileNumber: "",
  roles: [],
  organizationId: "",
  enableEmailOtp: false,
  enableTotp: false,
  status: "",
  profilePicture: "",

  startDate: undefined,
  endDate: undefined,
  profilePictureKey: "",
  isEmailMandatory: false,
  isStaffIdMandatory: false,
  skipTwoFactorAuth: false,
};

export const UserResponseTransformation = (values) => {
  console.log("UserResponseTransformation", values);
  console.log("ValueRoles", values.roles);
  const roles = values.roles.map((x) => x.id);
  console.log("roles", roles);
  return {
    ...values,
    profilePicture: values.profilePicture.url ?? "",
    profilePictureKey: values.profilePicture.key ?? "",
    roles: roles,
    ...(values.startDate && {
      startDate: dayjs.default(values.startDate).toDate(),
    }),
    ...(values.endDate && { endDate: dayjs.default(values.endDate).toDate() }),
  };
};

const UserFormTransformation = (values) => {
  console.log(values)
  if (values.endDate) {    
    let newEndDate = values.endDate;
    newEndDate.setHours(0);
    newEndDate.setMinutes(0);
    newEndDate.setSeconds(0);
    newEndDate.setMilliseconds(0);
    newEndDate = new Date(newEndDate.getTime() + (24 * 60 * 60 * 1000 - 1));
    values.endDate = newEndDate;
  }

  if (values.mobileNumber == null) {
    values.mobileNumber = "";  
  }

  return {
    ///userFullName: values.userFullName,
    ...values,
  };
};
export const [UserFormProvider, useUserFormContext, useUserNativeForm] =
  createFormContext<UserFormValues>();

export function useUserForm() {
  return useUserNativeForm({
    initialValues: userFormInitialValues,
    //validate: translateValidationErrorMessages(zodResolver(ScopeFormSchema)),
    validate: useCustomZodResolver("user", UserFormSchema),
    transformValues: UserFormTransformation,
  });
}
