import { useApiClientFormContext } from "./ApiClientFormContext";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/reduxHelper";
import { useGetApiScopeGetAllAvailableQuery } from "../../services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Checkbox,
  Group,
  Loader,
  Text,
  TransferList,
  TransferListData,
  TransferListItemComponent,
  TransferListItemComponentProps
} from "@mantine/core";
import { useNavbarHeight } from "../../hooks/useNavbarHeight";
import { IconWithTooltip } from "../../components/IconWithTooltip";
import { faFileCircleMinus, faFileCirclePlus, faFolderMinus, faFolderPlus } from "@fortawesome/free-solid-svg-icons";


const ScopeTransferItemComponent: TransferListItemComponent = ({ data, selected }: TransferListItemComponentProps) => (
  <Group noWrap>

    <Checkbox checked={selected} onChange={() => {
    }} sx={{ pointerEvents: "none" }} />

    <div style={{ flex: 1 }}>
      <Text size="sm" weight={500}>
        {data.name}
      </Text>
      <Text size="xs" color="dimmed" weight={400}>
        {data.description}
      </Text>
    </div>


  </Group>
);

function ScopesTransferList() {

  const ref = useRef(null);

  const form = useApiClientFormContext();

  const navHeight = useNavbarHeight();

  useEffect(() => {
    //setHeight(ref.current.offsetHeight);
    //setWidth(ref.current.offsetWidth);

    // 👇️ if you need access to parent
    // of the element on which you set the ref

    console.log("height nav: " + navHeight);
    console.log(ref.current);
    console.log(ref.current.parentElement);
    console.log(ref.current.parentElement.offsetHeight);
    console.log(ref.current.parentElement.offsetWidth);
  }, []);

  //const { height, width } = useViewportSize();

  const organizationId = useAppSelector((state) => state.user?.organization.id);
  const { t } = useTranslation();
  const { data: scopes, isSuccess } = useGetApiScopeGetAllAvailableQuery(
    organizationId !== "" ?
      {
        organizationId: organizationId,
        grantType: form.values.allowedGrantType

      } : skipToken);

  //const transformedData = data.map()

  const filter = (query, item) => {
    //console.log(item)    
    if (!item) {
      return;
    }
    return item.displayName.toLowerCase().includes(query.toLowerCase().trim()) ||
      item.description.toLowerCase().includes(query.toLowerCase().trim()) ||
      item.group.toLowerCase().includes(query.toLowerCase().trim());
  };

  //const [data, setData] = useState<TransferListData>(initialValues);
  const [data, setData] = useState<TransferListData>([[], []]);


  //TODO: Further testing that state should
  const doSomething = (values) => {

    console.log(values)
    form.setFieldValue('allowedScopes', values[1].map(x => x.value))
    setData(values)
  }

  useEffect(() => { 
    if (form.values.allowedGrantType) {
      console.log(`======== Grant Type Changed =========== ${form.values.allowedGrantType}`);
    }
  },[form.values.allowedGrantType])

  useEffect(() => {

    if (isSuccess) {

      const selectedScopes = form.values['allowedScopes']
      // @ts-ignore
      const inputData = scopes.map(x => ({ value: x.name, group: x.appName, ...x }))

      setData([inputData.filter(x => !selectedScopes.includes(x.value)), selectedScopes.map(x => inputData.find(y => x === y.value))]);
    }
  }, [isSuccess]);




  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <TransferList ref={ref}


                      transferIcon={({ reversed }) => (!reversed ?
                        <IconWithTooltip icon={faFileCirclePlus} text={t("apiClient.addSelectedScopes")} /> :
                        <IconWithTooltip icon={faFileCircleMinus} text={t("apiClient.removeSelectedScopes")} />)}
                      transferAllIcon={({ reversed }) => (!reversed ?
                        <IconWithTooltip icon={faFolderPlus} text={t("apiClient.addAllScopes")} /> :
                        <IconWithTooltip icon={faFolderMinus} text={t("apiClient.removeAllScopes")} />)}

                      titles={[t("apiClient.availableScopes"), t("apiClient.selectedScopes")]}
                      transferAllMatchingFilter value={data}
                      //onChange={setData}
                      onChange={doSomething}
                      filter={filter}
                      itemComponent={ScopeTransferItemComponent}
                      listHeight={320}
        />
      </React.Suspense>
    </>
  );
}

export function ScopesInputs() {

  const form = useApiClientFormContext();
  const { t } = useTranslation();


  return (<>

    <ScopesTransferList />


  </>);
}