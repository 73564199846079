import React from "react";
import { ListPageShell } from "../../components/ListPageShell";
import { useGetApiApiClientGetApiClientsQuery } from "../../services/appcenterApi";
import { ApiClientsDataTable } from "../../features/apiClients/ApiClientsDataTable";


export function ListApiClients() {

  return (
    <>
      <ListPageShell
        i18Prefix={"apiClient"}
        useQuery={useGetApiApiClientGetApiClientsQuery}
        initialSortKey={"clientName"}
        DataTable={ApiClientsDataTable}
      />
    </>
  );
}