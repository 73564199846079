import { Anchor, Breadcrumbs, Code, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import * as React from "react";
import { Trans, Translation, useTranslation } from "react-i18next";

import { useAppSelector } from "../../hooks/reduxHelper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ContentShell } from "@ascentis/cx-reactjs-ui";
import { UserLoginActivity } from "../../features/audit/UserLoginActivity";
import { Link } from "react-router-dom";
import { useParams } from 'react-router';
import { EntityUpdateHistory } from "../../features/audit/EntityUpdateHistory";

export function ViewAuditEvent() {
  const { t } = useTranslation();
  //const access_token = useAppSelector((state) => state.user?.access_token);
  //useGetUserInfoQuery(access_token !== "" ? {} : skipToken);
  const params = useParams();  
  return (
    <>
      <ContentShell  bottomSection={<></>} topSection={<>
        <Breadcrumbs>
          <Anchor span={true} variant={"text"}>
            {t("list.title", { context: params.context, count: 1 })}
          </Anchor>
        </Breadcrumbs>
      </>}>
        <EntityUpdateHistory 
          context={params.context} 
          entityId={params.id} 
          key={params.id} 
          exclusions={params.exclusions} 
          userOrganizationId={params.userOrganizationId} 
          isOrganizationAudit={false}
        />
      </ContentShell>
    </>
  );
}
