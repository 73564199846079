import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, FocusTrap, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { string } from "zod";

import { errorDisplayed, getError } from "../features/error/errorSlice";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHelper";

const useStyles = createStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing.md, // normalize duplicate left padding
  },
}));

export function ErrorAlertListener() {
  //

  const error = useAppSelector((state) => state.error);
  const { classes } = useStyles();

  const { t } = useTranslation();

  const isString = (value) => typeof value === "string";

  const [showError, showErrorHandler] = useDisclosure(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("ErrorListener", error);

    if (error && !error.hasBeenShown) {
      showErrorHandler.open();
      dispatch(
        errorDisplayed({
          id: error.id,
        })
      );
    }
  }, [error]);

  return (
    <>
      {showError && (
        <FocusTrap active={showError}>
          <Alert
            className={classes.alert}
            data-autofocus
            icon={<FontAwesomeIcon icon={faCircleExclamation} />}
            title={t("error.title", { context: error.code })}
            color={"red"}
          >
            {isString(error.message)
              ? error.message
              : t("error.message", { context: error.code })}
          </Alert>
        </FocusTrap>
      )}
    </>
  );
}
