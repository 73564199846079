import React from "react";
import { ListPageShell } from "../../components/ListPageShell";
import { useGetApiOrganizationGetUserAssignmentApprovalsQuery } from "../../services/appcenterApi";
import { OrganizationAssignRequestTable } from "../../features/organizations/OrganizationAssignRequestTable";
import { useParams, Link } from 'react-router-dom';
import { Anchor, Breadcrumbs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/reduxHelper";
import { selectOrganizationId } from "../../features/user/userSlice";

export function ListOrganizationAssignRequests() {  
  let { organizationId, searchTerm } = useParams();  
  if (!organizationId) {    
      organizationId = selectOrganizationId(useAppSelector(state => state));      
  }
    const payload = { organizationId: organizationId };
    const translationPrefix = "organizationAssignRequest";
    const { t } = useTranslation();
    const topSectionRender = function () {
        return (
            <>
              <Breadcrumbs>
                <Anchor component={Link} to={".."}>
                  {t("list.title", { context: "organization", count: 2 })}
                </Anchor>
                <Anchor span={true} variant={"text"}>
                        {t("organizationAssignRequest.list.title")}
                </Anchor>
              </Breadcrumbs>
            </>
        )
    }
    return (
        <>
            <ListPageShell
                i18Prefix={translationPrefix}
                DataTable={OrganizationAssignRequestTable}
                useQuery={useGetApiOrganizationGetUserAssignmentApprovalsQuery}
                queryParam={payload}
                initialSortKey="CreatedDate"      
                sortDesc={true}
                initialSearchTerm={searchTerm}
            topSection={topSectionRender()}
              bottomSection={(<></>)}
            />
        </>
    )
}
