import { openContextModal } from "@mantine/modals";
import React from "react";
import { Translation } from "react-i18next";
import { showNotification } from "@mantine/notifications";

interface openConfirmationAPIModalProps {

  useMutationHook: any,
  i18prefix: string,
  recordId?: string,
  data: any,
  customConfirmRecord?: any

}

export function openConfirmationModal({
                                           useMutationHook,
                                  i18prefix,
                                  data,
                                  recordId,
                                  customConfirmRecord
                                }: openConfirmationAPIModalProps) {


  //const { t } = useTranslation();

  return openContextModal({
    modal: "confirmationApi",
    title: <Translation>
      {(t) => t( i18prefix + ".title", { data: data })}
    </Translation>,
    innerProps: {
      confirmationText:
        <Translation>
          {(t) => t( i18prefix + ".confirmation", {data: data })}
        </Translation>,
      useMutationHook: useMutationHook,
      recordId: recordId ?? data.id,
      customConfirmRecord: customConfirmRecord,
      successHandler: () => {

          showNotification({
            title: <Translation>
              {(t) => t( i18prefix + ".success_title", { data: data })}
            </Translation>,
            message: <Translation>
              {(t) => t( i18prefix + ".success_message", {  data: data })}
            </Translation>

          })
      },
      errorHandler: (context: any, id: any, error: any) => {
        console.log(error)
        showNotification({
          title: <Translation>
            {(t) => t( i18prefix + ".fail_title", { data: data })}
          </Translation>,
          message: <Translation>
            {(t) => t( i18prefix + ".fail_message", { data: error })}
          </Translation>,
          color: "red",
        });
      }
    }
  });


}
