import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../../shared-library/src/hooks/reduxHelper";
import { useAuth } from "react-oidc-context";
import { Transition, AppShell, Button, createStyles, Group, Paper, Text, Title, LoadingOverlay, List, Space, Card, Badge, Stack, Notification } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useGetApiUserGetOwnProfileQuery } from "../../../../shared-library/src/services/appcenterApi";
import { ListItem } from "@mantine/core/lib/List/ListItem/ListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type OrganizationRequestResponse = {
    assignedRole: string;
    clientOrganizationId: string;
    createdDate: Date;
    expiryDate: Date;
    id: string;
    isApproved?: boolean;
    users: string[];
    workflowId: string;
    status: string;
}

type apiResponse = {
    data: OrganizationRequestResponse;
    message: string;
    status: string;
}

type sendApprovalRequest = {
    workflowId: string;
    approvalResult: number;
    approvalReason?: string;
}

enum pageState {
    invalidOrUnauthorized = -1,
    loading = 0,
    loaded = 1,
    submitting = 2,
    submittedSuccess = 3,
    submittedFail = 4
};


const apiSuccess: string = "Success";
const approveSucess: string = "requestApproval.approvedSucess";
const rejectSuccess: string = "requestApproval.rejectSuccess";
const rejectFail: string = "requestApproval.rejectFailure";
const approveFail: string = "requestApproval.approveFailure";

const useStyles = createStyles((theme) => ({

    hostAppShellMain: {
        backgroundColor: theme.other.mainBackgroundColor // normalize duplicate left padding
    },

    label: {
        textAlign: "center",
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan("sm")]: {
            fontSize: 120
        }
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: "center",
        fontWeight: 900,
        fontSize: 38,

        [theme.fn.smallerThan("sm")]: {
            fontSize: 32
        }
    },

    description: {
        maxWidth: 640,
        fontSize: "1.25rem!important",
        margin: "0 auto",
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`
    },


    action: {
        maxWidth: 500,
        fontSize: "1.25rem",
        margin: "0 auto",
        marginTop: theme.spacing.xl,
    },

    button: {
        minWidth: "110px"
    },

    content: {
        height: `calc(100vh - var(--mantine-header-height, 0px) - ${theme.spacing.md}px - ${theme.spacing.md}px)`,
        width: `calc(100vw - var(--mantine-navbar-width, 0px) - ${theme.spacing.md}px - ${theme.spacing.md}px)`,
        overflow: "hidden",
        display: "flex", flexDirection: "column",
        margin: 'auto'

    }

}));

const fade = {
    in: { opacity: 1 },
    out: { opacity: 0 },
    transitionProperty: 'opacity, transform'
}


const OrganizationApprovalRequest = function () {    
    useGetApiUserGetOwnProfileQuery();
    const { classes } = useStyles();    
    let getRequestUrl = `${window.env.appCenterApiUrl}/api/organization/GetOrgAssignmentRequestByWfId`;    
    let submitUrl = `${window.env.appCenterApiUrl}/api/organization/UpdateUsrOrgAccessApproval`;
    const { wfid } = useParams();
    const auth = useAuth();
    const user = useAppSelector((state) => state.user);
    const [requestStatus, setRequestStatus] = useState(0);
    const [_requestData, setRequestData] = useState(null);
    const [approveRejectState, setApproveRejectState] = useState("");
    const [submissionErrors, setSubmissionErrors] = useState("");
    const [content, setContent] = useState('');
    const [isReady, setIsReady] = useState(pageState.loading);
    const navigate = useNavigate();

    const { t } = useTranslation();
    useEffect(() => {
        if (auth.isAuthenticated && user.access_token && user.organization?.id) {
            let requestData = fetchApproval();            
        }
    }, [user]);


    const fetchApproval = async function () {
        getRequestUrl = `${getRequestUrl}?organizationId=${user.organization.id}&workflowId=${wfid}`
        let res = await fetch(getRequestUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`
            }            
        });
        //set whatever we want to set        
        setRequestStatus(res.status);
        if (res.status == 200) {
            let resData: apiResponse = await res.json();
            if (resData.status === apiSuccess) {
                if (resData.data.status != "Active") {
                    setIsReady(pageState.invalidOrUnauthorized);
                    return;
                }
                setRequestData(resData.data);
                console.log(JSON.stringify(resData.data));
                setIsReady(pageState.loaded);
                return;
            }
        }
        setIsReady(pageState.invalidOrUnauthorized);
        return null;
    }

    const handleSubmitResponse = async function (result, target) {
        setIsReady(pageState.submitting);
        let payload: sendApprovalRequest = {
            workflowId: wfid,
            approvalResult: result,
            approvalReason: ''
        }
        submitUrl = `${submitUrl}?organizationId=${user.organization?.id}`;
        let res = await fetch(submitUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`
            },
            body: JSON.stringify(payload)
        });
        if (res.status == 200) {
            let resText = await res.text();
            if (result == 0) {
                setApproveRejectState(rejectSuccess);
            } else if (result == 1) {
                setApproveRejectState(approveSucess);
            }
            setIsReady(pageState.submittedSuccess);
            return;
        }
        else {
            let resMessage = "";
            resMessage = await res.text();
            if (result == 0) {
                setApproveRejectState(rejectFail);
            } else {
                setApproveRejectState(approveFail);
            }
            setSubmissionErrors(`Error Code ${res.status} ${resMessage ? ' - ' + resMessage : ''}`);
            setIsReady(pageState.submittedFail);
        }
    }

    return (
        <>
            <Paper p={"md"} className={classes.content} withBorder={false} shadow="md" radius="lg">
                <div className={classes.title}>{t("requestApproval.title")}</div>
                <Space h="xl" /><Space h="xl" />
                <Transition mounted={isReady == pageState.loaded || isReady == pageState.submitting} transition={fade} duration={400} timingFunction="expo">
                    {
                        (styles) => (
                            <Paper style={{ ...styles }}>
                                <Text size="lg" align="center" className={classes.description}>
                                    {t("requestApproval.joinDescription",
                                        {
                                            role: _requestData.assignedRole != null ? _requestData.assignedRole : "",
                                            date: (new Date(_requestData.createdDate)).toDateString(),
                                            expiry: (new Date(_requestData.expiryDate)).toDateString()
                                        })}
                                    <Space h="md" />
                                    <Group position="center">
                                        <Space w="sm" />
                                        <Stack align="stretch" justify="flex-start" spacing="xs" style={{ flexGrow: 1 }}>
                                            {_requestData.users.map((x) => <Badge color="gray" fullWidth radius="xs" size="xl" variant="light">{x}</Badge>)}
                                        </Stack>
                                    </Group>
                                </Text>
                                <Space h="xl" />
                                <Group position="center" className={classes.action}>
                                    <Text>{t("requestApproval.actionDescription")}</Text>
                                </Group>
                                <Group position="center" className={classes.action}>
                                    <Button loading={isReady == pageState.submitting} onClick={async (e) => await handleSubmitResponse(1, e)} className={classes.button} variant="outline" size="md">
                                        {t("requestApproval.approve")}
                                    </Button>
                                    <Button loading={isReady == pageState.submitting} onClick={async (e) => await handleSubmitResponse(0, e)} className={classes.button} variant="outline" size="md">
                                        {t("requestApproval.reject")}
                                    </Button>
                                </Group>
                            </Paper>
                        )
                    }
                </Transition>
                <LoadingOverlay visible={isReady == pageState.loading}></LoadingOverlay>
                <Transition mounted={isReady == pageState.invalidOrUnauthorized} transition={fade} duration={400} timingFunction="expo">
                    {
                        (styles) => (
                            <Paper style={{ ...styles }} className={classes.description}>
                                <Text align="center">
                                    {requestStatus == 401 ? t("requestApproval.unauthorized") : t("requestApproval.invalidRequest")}
                                </Text>
                            </Paper>
                        )
                    }
                </Transition>

                <Transition mounted={isReady == pageState.submittedSuccess} transition={fade} duration={400} timingFunction="expo">
                    {
                        (styles) => (
                            <Paper style={{ ...styles }} className={classes.description}>
                                <Text align="center">
                                    {t(approveRejectState, { workflowId: _requestData.workflowId })}
                                </Text>
                            </Paper>
                        )
                    }
                </Transition>

                <Transition mounted={isReady == pageState.submittedFail} transition={fade} duration={400} timingFunction="expo">
                    {
                        (styles) => (
                            <Paper style={{ ...styles }} className={classes.description}>
                                <Text align="center">
                                    {t(approveRejectState, { workflowId: _requestData.workflowId })}
                                    <Space h="xl" />
                                    <Notification icon={<FontAwesomeIcon color="white"
                                        icon={faExclamationCircle} />}>
                                        <Text color="red">{submissionErrors}</Text>
                                    </Notification>
                                </Text>
                            </Paper>
                        )
                    }
                </Transition>
                
                <Group align="flex-end" position="center" style={{flexGrow:1}}>
                    <Button component={Link} variant="subtle" size="md" to={"/"}>
                        Take me back to home page
                    </Button>
                </Group>
            </Paper>

        </>
    );
}

export default OrganizationApprovalRequest;