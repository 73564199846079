import { faEraser, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import { BadgeList } from "../../components/BadgeList";
import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { HoverPopoverWithBadgeList } from "../../components/HoverPopoverWithBadgeList";
import { TableCopyButton } from "../../components/TableCopyButton";
import { openDeleteModal } from "../../hooks/openDeleteModal";
import { useDeleteApiApiClientDeleteApiClientMutation } from "../../services/appcenterApi";
import { UpdateClientSecretModal } from "./UpdateClientSecretModal";

dayjs.extend(utc.default);

export function ApiClientsDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const i18Prefix = "apiClient";
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);
  const [selectedApiClient, setSelectedApiClient] = useState({});

  const columns = [
    { accessor: "id", hidden: true },
    {
      accessor: "clientName",
      title: t("apiClient.clientName"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "clientId",
      title: t("apiClient.clientId"),
      width: 90,
      render: ({ clientId }) => (
        <TableCopyButton
          id={clientId}
          copiedLabel={t("apiClient.list.copyClientId_copied")}
          copyLabel={t("apiClient.list.copyClientId_copy")}
        />
      ),
    },
    {
      accessor: "clientSecrets",
      title: t("apiClient.clientSecrets"),
      width: 90,
      render: ({ clientSecrets }) => (
        <TableCopyButton
          id={clientSecrets}
          copiedLabel={t("apiClient.list.copyClientSecrets_copied")}
          copyLabel={t("apiClient.list.copyClientSecrets_copy")}
        />
      ),
    },
    {
      accessor: "allowedScopes",
      title: t("apiClient.allowedScopes"),
      sortable: true,
      render: ({ allowedScopes }) => {
        const allowedScopesArr = allowedScopes
          .split(",")
          .sort((a, b) => a.localeCompare(b))
          .filter((x) => x !== "");

        if (allowedScopesArr.length == 0) {
          return <></>;
        }

        if (allowedScopesArr.length <= 3) {
          return <BadgeList values={allowedScopesArr} />;
        }

        return (
          <HoverPopoverWithBadgeList
            display={
              <>
                <BadgeList values={allowedScopesArr.slice(0, 3)} /> ...
              </>
            }
            popoverDisplay={allowedScopesArr}
            popover={{
              position: "bottom-start",
              width: 300,
              shadow: "0 0 20px -8px",
              withArrow: true,
            }}
          />
        );
      },
    },
    {
      accessor: "createdDate",
      title: t("apiClient.createdDate"),
      sortable: true,
      width: 180,
      render: ({ createdDate }) => {
        const utcDateTime = dayjs.default(createdDate).utc(true);
        return <>{utcDateTime.local().format("DD/MM/YYYY hh:mm A")}</>;
      },
    },
  ];

  const rowContextMenu = {
    items: (record) => [
      {
        key: "label",
        label: record.clientName,
      },
      {
        key: "edit",
        title: t("list.edit", { context: i18Prefix, data: record }),
        icon: faPenToSquare,
        onClick: () => {
          //const path =
          navigate(generatePath("edit/:id", { id: record.id }));
        },
      },
      {
        key: "rotateSecret",
        title: t("apiClient.list.rotateSecret", { data: record }),
        icon: faPenToSquare,
        onClick: () => {
          //const path =
          //navigate(generatePath("edit/:id", { id: record.id }));
          //Open Modal
          setSelectedApiClient({ id: record.id, name: record.clientName });

          setOpened(true);
        },
      },
      {
        key: "delete",
        title: t("list.delete", { context: i18Prefix, data: record }),
        color: "red",
        icon: faEraser,
        onClick: () => {
          let deleteData = { ...record, name: record.clientName };
          openDeleteModal({
            confirmationValue: deleteData.clientName,
            recordId: deleteData.id,
            data: deleteData,
            useDeleteMutation: useDeleteApiApiClientDeleteApiClientMutation,
            i18prefix: i18Prefix,
          });
        },
      },
    ],
  };

  return (
    <>
      <UpdateClientSecretModal
        apiClientId={selectedApiClient?.id}
        apiClientName={selectedApiClient?.name}
        opened={opened}
        setOpened={setOpened}
      />
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
