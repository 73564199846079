import * as React from "react";
import { usePostApiApplicationGroupAddApplicationGroupMutation } from "../../services/appcenterApi";
import { CreateForm } from "../../components/CreateForm";
import { UserGroupFormProvider, useUserGroupForm } from "../../features/userGroups/UserGroupFormContext";
import { UserGroupInputs } from "../../features/userGroups/UserGroupInputs";

export function CreateGroup() {

  return (
    <>
      <CreateForm FormProvider={UserGroupFormProvider} inputs={<UserGroupInputs />} i18Prefix={"userGroup"}
                  useCreateMutation={usePostApiApplicationGroupAddApplicationGroupMutation} useFormHook={useUserGroupForm}
                  commandKey={"addApplicationGroupCommand"} />
    </>
  );

}