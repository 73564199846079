//#region Import
import { ContentShell } from "@ascentis/cx-reactjs-ui";
import { Anchor, Breadcrumbs, createStyles, SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetApiApplicationApplicationsQuery, useGetApiApplicationSubcribedApplicationsQuery } from "shared-library/src/services/appcenterApi";
import { ApplicationCard } from "../../features/applications/ApplicationCard";
import { useAppSelector } from "shared-library/src/hooks/reduxHelper";
import { ApplicationCardDashboard } from "../../features/applications/ApplicationCardDashboard";

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
      theme.colors[theme.primaryColor][7]
    } 100%)`,
    padding: `calc(${theme.spacing.xl} * 1.5)`,
    borderRadius: theme.radius.md,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  title: {
    color: theme.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },

  count: {
    color: theme.white,
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    fontSize: theme.fontSizes.sm,
    marginTop: 5,
  },

  stat: {
    flex: 1,

    '& + &': {
      paddingLeft: theme.spacing.xl,
      marginLeft: theme.spacing.xl,
      borderLeft: `1 solid ${theme.colors[theme.primaryColor][3]}`,

      [theme.fn.smallerThan('sm')]: {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeft: 0,
        paddingTop: theme.spacing.xl,
        marginTop: theme.spacing.xl,
        borderTop: `1 solid ${theme.colors[theme.primaryColor][3]}`,
      },
    },
  },
}));

function Dashboard() {

  const { t } = useTranslation();
  const organizationId = useAppSelector(
    // @ts-ignore
    (state) => state.user?.organization.id);

  const { data, isError, error, isLoading, isFetching } = useGetApiApplicationSubcribedApplicationsQuery(
    organizationId !== "" ?
      {
        organizationId: organizationId,
      } : skipToken);

      //console.log('Data')
      //console.log(data)
    
      const items = data?.map((item) => item.enabled ? <ApplicationCardDashboard key={item.id} item={item} /> : null)

  return (

    <ContentShell topSection={<><Breadcrumbs>
      <Anchor component={Link} to={"."}>
        {t("glossary.dashboard")}
      </Anchor></Breadcrumbs></>} bottomSection={<></>}>

        <Stack>
        <Title order={5}>{t("dashboard.subscribedApplication")}</Title>

      {(!isLoading && (!items || items.length < 1)) ?
            <Text >
              {t("dashboard.subscribedApplication_empty")}
            </Text>          :
            <SimpleGrid cols={3}>
              {!isError ? items : null}
            </SimpleGrid>
      }
      </Stack>
    </ContentShell>
  );
}

//#endregion

//#region Export

export default Dashboard;

//#endregion
