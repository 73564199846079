import { faChevronCircleRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Affix,
  Anchor,
  Avatar,
  Burger,
  Code,
  Collapse,
  createStyles,
  Flex,
  Group,
  Navbar,
  ScrollArea,
  Text,
  UnstyledButton
} from "@mantine/core";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons/faChevronCircleLeft";
import { Global } from "@mantine/styles";
export interface MenuItem {
  label?: string;
  link?: string;
  items?: MenuItem[];
}

export interface CxNavbarProps {
  title: string;
  version?: string;
  menu: MenuItem[];
}

const useStyles = createStyles((theme) => ({

  appTitle: {
    width: "70%",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: "auto"
    }
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none"
    }
  },

  resize: {
    [theme.fn.smallerThan("xs")]: {
      display: "none"
    }
  },

  hide: {
    display: "none"
  },

  navbar: {
    backgroundColor: theme.other.mainBackgroundColor
    //paddingBottom: 0
  },

  navbarHeader: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black
  },

  navbarLinks: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md
  },

  navbarLinksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl
  },

  navbarItemControl: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colors[theme.primaryColor][7]
    }
  },

  navbarItemLink: {
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: `${theme.spacing.xs + theme.spacing.xs}px`,
    marginLeft: 24,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colors[theme.primaryColor][7],
      textDecoration: "none"
    }
  },

  chevron: {
    transition: "transform 200ms ease"
  },

  chevronOpened: {
    transform: `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
  },

  visible: {
    visibility: "visible"
  },

  hidden: {
    visibility: "hidden"
  },

  smallMenu: {
    width: `calc(24px + ${theme.spacing.md}px)`,
    paddingLeft: '0px'
  }
}));

function CxNavbarItem(menuItem: MenuItem) {
  //const { t } = useTranslation();
  const { classes, theme, cx } = useStyles();

  const [opened, setOpened] = useState(true);

  const ChevronIcon = theme.dir === "ltr" ? faChevronRight : faChevronLeft;
  const hasLink = menuItem.link !== undefined;

  //console.log(menuItem.items)

  const hasItems = Array.isArray(menuItem.items);
  const items = (
    hasItems && menuItem.items !== undefined ? menuItem.items : []
  ).map((item, index) => (
    <Anchor
      key={index}
      component={Link}
      className={classes.navbarItemLink}
      to={`${item.link}`}
    >
      {item.label ?? ""}
    </Anchor>
  ));

  return hasLink ? (
    <UnstyledButton
      className={classes.navbarItemControl}
      component={Link}
      to={`${menuItem.link}`}
    >
      <Group spacing={0}>
        <FontAwesomeIcon
          icon={ChevronIcon}
          fixedWidth
          className={classes.hidden}
        />
        <Text ml="xs" fw={700}>
          {menuItem.label ?? ""}
        </Text>
      </Group>
    </UnstyledButton>
  ) : (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={classes.navbarItemControl}
      >
        <Group spacing={0}>
          <FontAwesomeIcon
            icon={ChevronIcon}
            fixedWidth
            className={cx(
              classes.chevron,
              opened && classes.chevronOpened,
              hasItems ? classes.visible : classes.hidden
            )}
          />
          <Text ml="xs" fw={700}>
            {menuItem.label ?? ""}
          </Text>
        </Group>
      </UnstyledButton>
      {hasItems ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

export function CxNavbar(props: CxNavbarProps) {

  const { classes, cx } = useStyles();
  const [isSmallMenu, resizeMenuHandler] = useDisclosure(false);
  const [isSmallDisplay, smallDisplayMenuHandler] = useDisclosure(false);

  const links = props.menu.map((item, index) => <CxNavbarItem key={index} {...item} />);

  return (
    <>

      <Global styles={(theme) => {
        return ({
          ":root": {
            "--cx-navbar-height": `calc(100vh - var(--mantine-header-height, 0px) - ${theme.spacing.md}px - ${theme.spacing.md}px)`
          }
        });
      }} />

      <Affix className={classes.burger} position={{ bottom: 8, right: 8 }}>
        <Avatar radius={"xl"} size={"sm"}>
        <Burger size={"sm"}  opened={isSmallDisplay}
                onClick={() => smallDisplayMenuHandler.toggle()} />

        </Avatar>
      </Affix>

      <Navbar hidden={!isSmallDisplay || isSmallMenu} hiddenBreakpoint={"xs"} width={{ sm: isSmallMenu ? 40 : 240 }}
              p="md"
              className={classes.navbar}
              withBorder={false}>


        <Navbar.Section className={classes.navbarHeader} hidden={isSmallMenu}>
          <Group position="apart" spacing={"xs"}>
            <Text size="sm" weight={700}>
              {props.title}
            </Text>
            {props.version && <Code>v{props.version}</Code>}
          </Group>
        </Navbar.Section>

        <Navbar.Section hidden={isSmallMenu}
                        grow
                        className={classes.navbarLinks}
                        component={ScrollArea}
        >
          <div className={classes.navbarLinksInner}>{links}</div>
        </Navbar.Section>


        <Navbar.Section>

          <Flex mih={isSmallMenu ? "var(--cx-navbar-height, 0px)" : 0}
                align={"flex-end"}
                justify={isSmallMenu ? "center" : "flex-end"}>

            <ActionIcon size={"xl"} className={classes.resize} onClick={() => {
              resizeMenuHandler.toggle();
            }} variant="transparent">
              <FontAwesomeIcon size={"xl"} icon={isSmallMenu ? faChevronCircleRight : faChevronCircleLeft} />
            </ActionIcon>

          </Flex>

        </Navbar.Section>


      </Navbar>
    </>
  );
}

