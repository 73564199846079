import { faListSquares, faPenToSquare, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import { BadgeList } from "../../components/BadgeList";
import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { HoverPopoverWithBadgeList } from "../../components/HoverPopoverWithBadgeList";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import { useDeleteApiOrganizationDeleteOrganizationMutation } from "../../services/appcenterApi";

export function OrganizationsDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const i18Prefix = "organization";

  const columns = [
    {
      accessor: "organizationName",
      title: t(i18Prefix + ".organizationName"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "clientAdmins",
      title: t(i18Prefix + ".clientAdmins"),
      sortable: true,
      render: ({ clientAdmins }) => {
        if (!clientAdmins) {
          return <></>;
        }

        let clientAdminsArr = [...clientAdmins]
          .sort((a, b) => a.localeCompare(b))
          .filter((x) => x !== "");

        if (clientAdminsArr.length == 0) {
          return <></>;
        }

        if (clientAdminsArr.length <= 8) {
          
          return <BadgeList values={clientAdminsArr} />;
        }

        return (
          <HoverPopoverWithBadgeList
            display={
              <>
                <BadgeList values={clientAdminsArr.slice(0, 8)} /> ...
              </>
            }
            popoverDisplay={clientAdminsArr}
            popover={{
              position: "bottom-start",
              width: 300,
              shadow: "0 0 20px -8px",
              withArrow: true,
            }}
          />
        );
      },
    },
  ];

  const rowContextMenu = {
    items: (record) =>      
      createStandardRowContextMenu({
        i18Context: i18Prefix,
        record: { ...record, name: record.organizationName },

        //confirmationValue: record.organizationName,
        useDeleteMutation: useDeleteApiOrganizationDeleteOrganizationMutation,
        deleteOverride: {
          disabled: (record) => !record.canDelete,
        },
      })
        //Add additional menu
        .toSpliced(
          2,
          0,
          {
            key: "editPolicy",
            title: t("organization.list.editPolicy"),
            icon: faPenToSquare,
            onClick: () => {
              //const path =
              navigate(generatePath("editPolicy/:id", { id: record.id }));
            },
          },
          {
            key: "assignAdmin",
            title: t("organization.list.assignAdmin"),
            icon: faPenToSquare,
            onClick: () => {
              //const path =              
              navigate(generatePath("editAdmin/:id", { id: record.id}));
            },
          },
          {
            key: "assignUser",
            title: t("organization.assignUser.contextTitle"),
            icon: faUserFriends,   
            disabled: record.organizationName=="Ascentis",
            onClick: () => {
              navigate(generatePath("orgAssignUser/:id", { id: record.id }));
            }            
          },
          {
            key: "listapprovalrequest",
            title: t("organizationAssignRequest.list.contextMenu"),
            icon: faListSquares,
            onClick: () => {
              //const path =
              navigate(generatePath("listApprovalRequest/:id", { id: record.id }));
            }
          }
        ),
  };

  return (
    <>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
