import { Middleware, MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit";
import { encounterApiError } from "./errorSlice";

export const errorHandlerMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn('errorHandlerMiddleware', action)
      
      //If query then show to display
      if (action.meta?.arg?.type === 'query') {
        //api.dispatch()
        api.dispatch(encounterApiError({
          message: action.payload.data,
          code: action.payload.status,
          id: action.meta.requestId
        }))
      }

    }

    return next(action)
  }