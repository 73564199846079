import {
  usePostApiUserCreateUserMutation,
  usePostApiUserUpdateOwnProfileMutation,
  usePostApiUserUpdateUserMutation
} from "../../services/appcenterApi";
import { useUploadFileMutation } from "../../hooks/useUploadFileMutation";

export function useUpdateUserOwnProfileMutation() {

  const getUrl = (values) => {
    return values.updateOwnProfileCommand.profilePicture;
  };
  const transformValues = (values, data) => {
    values.updateOwnProfileCommand.profilePicture = data[0].key;
    return values;
  };

  return useUploadFileMutation({
    useMutationHook: usePostApiUserUpdateOwnProfileMutation
    , transformValues, getUrl
  });

}