import React from "react";
import { useApiClientFormContext } from "./ApiClientFormContext";
import { createStyles, List, Paper, SimpleGrid, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({

  title: {
    color: theme.colors.gray[6]
  },

  description: {
    color: theme.black
  },

  card: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.xl,
    paddingLeft: theme.spacing.xl * 2,

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.02)',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
      backgroundImage: theme.fn.linearGradient(0, theme.colors.pink[6], theme.colors.orange[6]),
    },
  },
}));


interface SummaryItem {
  form: any,
  t: any,
  accessor: string
}

function SummaryTextItem(inputProps: SummaryItem) {

  const { classes } = useStyles();

  return (
    <>

      <div>
        <Text size="xs" className={classes.title}>
          {inputProps.t("apiClient." + inputProps.accessor)}
        </Text>
        {inputProps.form.getInputProps(inputProps.accessor).value && inputProps.form.getInputProps(inputProps.accessor).value !== "" ?
          <Text className={classes.description}>{inputProps.form.getInputProps(inputProps.accessor).value}</Text> :
          <Text className={classes.description}>-</Text>}

      </div>
    </>
  );
}

function SummaryListItem(inputProps: SummaryItem) {

  const { classes } = useStyles();

  const items = inputProps.form.getInputProps(inputProps.accessor).value.map((val) => <List.Item><Text
    className={classes.description}>{val}</Text></List.Item>);

  return (
    <>
      <div>
        <Text size="xs" className={classes.title}>
          {inputProps.t("apiClient." + inputProps.accessor)}
        </Text>

        {items && items.length > 0 ? <List>
            {items}
          </List> :
          <Text className={classes.description}>-</Text>
        }
      </div>
    </>
  );

}

interface CardGradientProps {
  title: string;
  //description: string;

  children?: React.ReactNode;
}

export function CardGradient({ title, children }: CardGradientProps) {
  const { classes } = useStyles();
  return (
    <Paper withBorder radius="md" className={classes.card}>
      <Text size="xl" weight={500} mt="md">
        {title}
      </Text>
      <div>
        {children}
      </div>
    </Paper>
  );
}

function AuthCodeSummary() {

  const form = useApiClientFormContext();
  const { t } = useTranslation();

  return (
    <>
      <SummaryListItem form={form} t={t} accessor={"redirectUris"} />
      <SummaryListItem form={form} t={t} accessor={"postLogoutRedirectUris"} />
      <SummaryListItem form={form} t={t} accessor={"allowedCorsOrigins"} />
      <SummaryListItem form={form} t={t} accessor={"frontChannelLogoutUri"} />
      <SummaryListItem form={form} t={t} accessor={"backChannelLogoutUri"} />
    </>
  );
}

export function Summary() {

  const form = useApiClientFormContext();
  const { t } = useTranslation();

  //form.values.


  return (
    <>


      <SimpleGrid cols={2}>

        <CardGradient title={t('apiClient.clientCredential')}>

          <SummaryTextItem form={form} t={t} accessor={"clientName"} />
          <SummaryTextItem form={form} t={t} accessor={"clientId"} />
          <SummaryTextItem form={form} t={t} accessor={"allowedGrantType"} />

          {
            form.getInputProps("allowedGrantType").value === "authorization_code" &&
            <AuthCodeSummary />
          }

          <SummaryTextItem form={form} t={t} accessor={"accessTokenLifetime"} />

        </CardGradient>

        <CardGradient title={t('apiClient.scope')}>

          <SummaryListItem form={form} t={t} accessor={"allowedScopes"} />

        </CardGradient>

        <CardGradient title={t('apiClient.personalization')}>

          <SummaryTextItem form={form} t={t} accessor={"supportEmail"} />
          <SummaryTextItem form={form} t={t} accessor={"developerEmail"} />
          <SummaryTextItem form={form} t={t} accessor={"appHomePage"} />
          <SummaryTextItem form={form} t={t} accessor={"privacyPolicyLink"} />
          <SummaryTextItem form={form} t={t} accessor={"termOfServiceLink"} />

        </CardGradient>

      </SimpleGrid>


    </>
  );
}