import { useTranslation, Trans } from "react-i18next";
import React from "react";
import { PasswordInput, Text, Alert, createStyles } from "@mantine/core";
import { useUserPasswordFormContext } from "./UserPasswordFormContext";

export function UserPasswordInputs() {
    const form = useUserPasswordFormContext()
    const { t } = useTranslation()
    const useStyles = createStyles((theme) => ({
        alert: {
            color: "#000000",
            backgroundColor: "#e6e6e6"
        },
    }));

    const { classes } = useStyles();
    const renderPasswordPolicy = function () {
        let formVals = form.values;
        if (!formVals.passwordPolicy) {
            return (<></>);
        }
        let splitPolicies = formVals.passwordPolicy.split("\r\n");
        if (!splitPolicies[splitPolicies.length - 1]?.trim()) {
            splitPolicies.splice(splitPolicies.length - 1, 1);    
        }        
        let policyLineItems = [];
        for (let i = 0; i < splitPolicies.length; i++){
            policyLineItems.push(<li>{splitPolicies[i]}</li>);
        }
        return (
            <ul>
                {policyLineItems}
            </ul>
        );
    }

    return (
        <>
            <Alert my="md" mr={"-16px"} variant="light" className={classes.alert} title={t("userPassword.alert.title")}>
                <Text>                    
                    <Trans i18nKey={"userPassword.alert.body"}/>
                </Text>
                {
                    renderPasswordPolicy()
                }
            </Alert>
            
            <PasswordInput label={t("userPassword.currentPassword")}
                {...form.getInputProps('currentPassword')}                
            />

            <PasswordInput label={t("userPassword.newPassword")}
                           {...form.getInputProps('newPassword')}
                            />

            <PasswordInput label={t("userPassword.confirmNewPassword")}
                           {...form.getInputProps('confirmNewPassword')}
                           />
        </>
    )
}