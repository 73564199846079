import { MantineProvider, Paper } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdateForm } from "shared-library/src/components/UpdateForm";
import {
  UserPasswordFormProvider,
  useUserPasswordForm,
} from "shared-library/src/features/user/UserPasswordFormContext";
import { UserPasswordInputs } from "shared-library/src/features/user/UserPasswordInputs";
import { userAuthenticated } from "shared-library/src/features/user/userSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "shared-library/src/hooks/reduxHelper";
import { useWithCurrentOrganization } from "shared-library/src/hooks/useWithCurrentOrganization";
import {
  useGetApiUserGetOwnProfileQuery,
  usePostApiUserChangePasswordExternalMutation,
  usePostApiUserChangePasswordMutation,
} from "shared-library/src/services/appcenterApi";
import { useGetUserInfoQuery } from "shared-library/src/services/identityApi";

export function ChangePasswordExternal() {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  if (
    !hasAuthParams() &&
    !auth.isAuthenticated &&
    !auth.activeNavigator &&
    !auth.isLoading
  ) {
    auth.signinRedirect({
      state: { location: location.pathname },
    });
  }

  const [isUserExpired, setIsUserExpired] = React.useState(false);

  const [isUserLoaded, setIsUserLoaded] = React.useState(false);

  //Calling own profile
  // @ts-ignore
  const access_token = useAppSelector((state) => state.user?.access_token);

  useGetUserInfoQuery(access_token !== "" ? {} : skipToken);

  // @ts-ignore
  useAppSelector((state) => state.user?.organization);

  React.useEffect(() => {

    if (auth.isAuthenticated) {
      //console.log('auth.isAuthenticated?')
      console.log("authenticatedUser", auth.user)


      dispatch(userAuthenticated({
        access_token: auth?.user?.access_token,
        scope: auth?.user?.scope,
        id_token: auth?.user?.id_token,
        profile: auth?.user?.profile
      }));

      if (auth.user) {
        //navigate at this state won't cause refresh

        if (auth.user.state) {
          navigate(auth.user.state?.location);
          setIsUserLoaded(true)
        } else {
          setIsUserLoaded(true)
        }
      }
    }
    
    

  }, [auth]);

  React.useEffect(() => {
    console.log('start: useEffect auth.error');
    console.log(auth.error);
    if (auth.error) {
        console.log('clean up auth')
        auth.clearStaleState();
        auth.revokeTokens();
        auth.removeUser().then(() => {
          console.log("removed user")
          sessionStorage.clear();
          //throw new Response("User session has expired", { status: 401 });
        });
        
        //history.push('/SessionExpired');
        //dispatch(userSessionExpired({}))
        console.log('dispatch')
    }
    
  }, [auth.error, auth.querySessionStatus])

  React.useEffect(() => {
    return auth.events.addUserSignedOut(() => {
        console.log("addUserSignedOut")
        //auth.clearStaleState();
        //auth.revokeTokens();
        
        //auth.signoutSilent();
        //throw new Error("User Session Expired");
        //dispatch(userSessionExpired({}))
        
        //throw new Response("User session has expired", { status: 401 });
        auth.removeUser().then(() => {
          console.log("User removed");
          //need to wait for the call to finish, so the user will be removed
          setIsUserExpired(true);
        });
        
    })
}, [auth.events]);

React.useEffect(() => {
  if (isUserExpired) {
    console.log("User expired");

    //auth.clearStaleState();
    auth.revokeTokens();
    
    throw new Response("User session has expired", {
      status: 401,
      statusText: "Your session has expired",
    });
  }
}, [isUserExpired]);

  //TODO: How to handle password policy

  const handleTransformLoadResponse = function (data) {
    let d = { ...data, name: data.userFullName };
    return d;
  };

  return (
    <Paper m={"md"} p={"md"} withBorder={false} shadow="md" radius="lg">
      <UpdateForm
        topSection={<></>}
        hideTopSection={true}
        FormProvider={UserPasswordFormProvider}
        inputs={<UserPasswordInputs />}
        useFormHook={useUserPasswordForm}
        useUpdateMutation={usePostApiUserChangePasswordMutation}
        useLoadQuery={useWithCurrentOrganization(
          useGetApiUserGetOwnProfileQuery
        )}
        i18Prefix={"userPassword"}
        commandKey={"changePasswordCommand"}
        transformResponse={handleTransformLoadResponse}
      />
    </Paper>
  );
}
