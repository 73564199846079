import React from "react";
import { UpdateForm } from "../../components/UpdateForm";
import { UserPasswordFormProvider, useUserPasswordForm } from "../../features/user/UserPasswordFormContext";
import { UserPasswordInputs } from "../../features/user/UserPasswordInputs";
import { useGetApiUserGetOwnProfileQuery, usePostApiUserChangePasswordMutation } from "../../services/appcenterApi";
import { MantineProvider } from "@mantine/core";
import { useWithCurrentOrganization } from "../../hooks/useWithCurrentOrganization";
import { useAuth } from "react-oidc-context";

// function OverrideUserPasswordInputs() {
//     return <>
//         <MantineProvider inherit theme={{
//             components: {
//                 PasswordInput: {
//                     defaultProps: (theme) => ({
//                         disabled: false
//                     })
//                 }
//             }
//         }}>
//             <UserPasswordInputs />
//         </MantineProvider>

//     </>
// }

export function ChangePassword() {
    const auth = useAuth();

    //TODO: How to handle password policy

    const handleTransformLoadResponse = function (data) {
        let d = { ...data, name: data.userFullName };
        return d;
    }
    const postUpdateCallback = function () {
        return auth.signoutRedirect();
    }
    return (<>
        <UpdateForm FormProvider={UserPasswordFormProvider}
                    inputs={<UserPasswordInputs/>}
                    useFormHook={useUserPasswordForm} useUpdateMutation={usePostApiUserChangePasswordMutation}
                    useLoadQuery={useWithCurrentOrganization(useGetApiUserGetOwnProfileQuery)}
                    postUpdateCallback={postUpdateCallback}
                    i18Prefix={"userPassword"} commandKey={"changePasswordCommand"} transformResponse={handleTransformLoadResponse}/>
    </>)
}