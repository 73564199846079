import { BadgeProps, Popover, PopoverBaseProps, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";

import { BadgeList } from "./BadgeList";

interface HoverPopoverWithBadgeListProps {
  display: any | React.ReactElement;
  popoverDisplay: string[];
  popover?: PopoverBaseProps;
  badge?: BadgeProps;
}

export function HoverPopoverWithBadgeList(
  props: HoverPopoverWithBadgeListProps
) {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover {...props.popover} opened={opened}>
      <Popover.Target>
        <Text onMouseEnter={open} onMouseLeave={close}>
          {props.display}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <BadgeList values={props.popoverDisplay} {...props.badge} />
      </Popover.Dropdown>
    </Popover>
  );
}
