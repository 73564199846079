import * as React from "react";
import { useEffect, useState } from "react";
import { ActionIcon, Center, createStyles, Group, Popover, Text, Grid, Paper, Button, UnstyledButton, Avatar, Flex } from "@mantine/core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useGetApiApplicationGetMfeApplicationsQuery } from "../../../../shared-library/src/services/appcenterApi";
import { useAppSelector } from "../../../../shared-library/src/hooks/reduxHelper";
import { skipToken } from "@reduxjs/toolkit/query";
import { MicroAppAvatar } from "../../components/MicroAppAvatar";
import { useLocation } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  appSwitcher: {
    borderRadius: '50%',
    cursor: 'pointer',
    padding: theme.spacing.xs,
    '&:hover': {
      backgroundColor: 'rgba(60,64,67,.08)'
    }
  }
}));

export function MicroAppMenu() {
  const { classes } = useStyles();
  const organizationId = useAppSelector(
    // @ts-ignore
    (state) => state.user?.organization.id);  
  let isCc = false;
  let appName = "App Center";  
  if (!APPCENTER) {
    isCc = true;
    appName = "Command Center";
  }
  let appInitials = appName.split(' ').map((word) => {
    return word[0];
  }).join('');
  const { data, isLoading, isFetching, isError, isSuccess } = useGetApiApplicationGetMfeApplicationsQuery(organizationId != "" ? { isCc:isCc} : skipToken);
  const [subscribedMfe, setSubscribedMfe] = useState([]);
  const location = useLocation();
  const [appMenuOpen, setAppMenuOpen] = useState(false);

  useEffect(function () {
    if (!isLoading && isSuccess) {
      setSubscribedMfe([...data]);
    }
  }, [data, isLoading, isFetching, isError, isSuccess])

  const removeLeadingSlash = function (str) {
    if (str.startsWith("/")) {
      return str.substring(1);
    }
    return str;
  }

  const renderApps = function () {
    if (subscribedMfe != null && subscribedMfe.length > 0) {
      let appsArray = [];
      for (let i = 0; i < subscribedMfe.length; i++) {
        let appInitials = subscribedMfe[i].name.split(' ').map((word) => {
                            return word[0];
                          }).join('');
        appsArray.push((<Grid.Col span={4}>
          {/* <UnstyledButton component={Link} to={`/apps/${subscribedMfe[i].name.replaceAll(" ", "_").toLowerCase()}`}> */}
          <UnstyledButton component={Link} to={`apps/${subscribedMfe[i].mfePath.toLowerCase()}`} onClick={() => { togglePopover(false) }}>
            <Group spacing="xs">
              <Flex align="center" direction="column" wrap="wrap">
                
                <MicroAppAvatar
                  color={subscribedMfe[i].mfeIconColor}
                  initials={appInitials}
                  selected={removeLeadingSlash(location.pathname.toLowerCase()) == `apps/${subscribedMfe[i].mfePath.toLowerCase()}`} />
                
                <div style={{ width: '110px' }}>
                  <Text size="xs" fw="600" align="center">{subscribedMfe[i].name}</Text>
                </div>
              </Flex>
            </Group>
          </UnstyledButton>          
        </Grid.Col>));
      }
      return appsArray
    }
  }
  const togglePopover= function (state) {
    setAppMenuOpen(state);
  }  

  return (
    <Center style={{ alignSelf: 'center' }} mx="lg">
      <Popover width={400} position="bottom-end" withArrow shadow="md" radius="md" arrowSize={12} arrowOffset={25} opened={appMenuOpen}
        onClose={() => togglePopover(false)}  
        exitTransitionDuration={1500}
      >
        <Popover.Target>
          <ActionIcon onClick={() => { togglePopover(true) }}>
            <Group spacing={8} className={classes.appSwitcher}>
              <span className="fa-layers fa-xl">
                <FontAwesomeIcon icon={faEllipsisV} transform="left-5" />
                <FontAwesomeIcon icon={faEllipsisV} />
                <FontAwesomeIcon icon={faEllipsisV} transform="right-5" />
              </span>
            </Group>
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Paper>
            <Text fw={600}>Cx Applications</Text>
            <Paper my="md">
              <Grid>
                <Grid.Col span={4}>
                  <UnstyledButton component={Link} to="/" onClick={() => { togglePopover(false); }}>
                    <Group spacing="xs">
                      <Flex align="center" direction="column" wrap="wrap">
                        <MicroAppAvatar color="#FF4660" initials={ appInitials } selected={ location.pathname == "/"} />                        
                        <div style={{ width: '110px' }}>
                          <Text size="xs" fw="600" align="center">{appName}</Text>
                        </div>
                      </Flex>
                    </Group>
                  </UnstyledButton>
                </Grid.Col>
                {
                  renderApps()
                }
                {/* <Grid.Col span={4}>
                  <Button component={ Link } to="/apps/support_center" variant="gradient" gradient={{from: "red.9", to: "black"}}>
                    Help Center
                  </Button>
                </Grid.Col> */}
              </Grid>
            </Paper>
          </Paper>
        </Popover.Dropdown>
      </Popover>
    </Center>);
}