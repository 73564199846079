import { faEraser, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import {
  useDeleteApiApplicationGroupDeleteApplicationGroupMutation,
  useDeleteApiScopeDeleteSCopeMutation,
} from "../../services/appcenterApi";

export function UserGroupsDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const i18Prefix = "userGroup";

  const columns = [
    //{accessor: 'id'},

    {
      accessor: "applicationName",
      title: t("userGroup.applicationName"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "name",
      title: t("userGroup.name"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "description",
      title: t("userGroup.description"),
      sortable: true,
      ellipsis: true,
      //width: 200
    },
    {
      accessor: "type",
      title: t("userGroup.type"),
      sortable: true,
      ellipsis: true,
      width: 150,
    },
  ];

  const rowContextMenu = {
    items: (record) =>
      createStandardRowContextMenu({
        i18Context: i18Prefix,
        record: record,
        useDeleteMutation:
          useDeleteApiApplicationGroupDeleteApplicationGroupMutation,
        //editOverride: null,
        //deleteOverride: null
      }).toSpliced(
        2,
        0,
        {
          key: "assignUsersToGroup",
          title: t("userGroup.assignUsersToGroup"),
          //icon: faPaperPlane,
          //disabled: record.status != "PendingActivation",
          onClick: () => {
            //const path =
            //navigate(generatePath("edit/:id", { id: record.id }));
            //confirmSendVerificationEmail(record);
            navigate(generatePath("edit/:id/userAssignment", { id: record.id }));
          },
        }
        ),
  };

  return (
    <>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
