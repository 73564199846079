import { CxHeader, CxLogo } from "@ascentis/cx-reactjs-ui";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Paper,
  Skeleton,
  Space,
  Stack,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import * as React from "react";
import { ComponentType, ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormErrorAlert } from "shared-library/src/components/FormErrorAlert";

import { useGetUserByActivationIdQuery } from "../../services/activationApi";


type EditFormProps = {
  FormProvider: ComponentType<any>;
  inputs: ReactElement<any>;
  useFormHook: any;
  useUpdateMutation: any;
  i18Prefix: string;
  commandKey: string;
  transformResponse?: any;
  activationStatus: string;
  descriptionTextOverride?: React.ReactNode;
};

export const ActivationForm = ({
  useFormHook,
  useUpdateMutation,
  //useLoadQuery,
  i18Prefix,
  FormProvider,
  inputs,
  commandKey,
  transformResponse,
  activationStatus,
  descriptionTextOverride,
}: EditFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //const { id } = useParams();
  const params = useParams();
    const {
    data,
    error: queryError,
    isLoading: queryIsLoading,
    isSuccess: queryIsLoaded,
  } = useGetUserByActivationIdQuery(
    params
      ? {
          ...params,
          //organizationId: organizationId
        }
      : skipToken
  );

  //Check for status, if valid
  if (queryError) {        
    if (queryError != null && queryError.status != null && queryError.status === "400") {
      navigate('/expired');      
    }          
  }

  const form = useFormHook();
  const formId = useId();

  const [
    update,
    {
      //data: updateResult,
      error,
      isLoading,
      isSuccess,
      isError,
    },
  ] = useUpdateMutation();

  const handleSubmit = (values) => {
    update({ [commandKey]: { ...values, id: data.id } });
  };

  useEffect(() => {
    if (isSuccess) {
      //setSubmitError(false)
      navigate(".");
    }
  }, [isSuccess]);

  useEffect(() => {
    console.log("UpdateForm useEffect");

    if (queryIsLoaded) {
      console.log(queryIsLoaded);
      console.log(transformResponse);
      console.log(data);

      //Check status
      if (data.status === activationStatus) {
        form.setValues(transformResponse ? transformResponse(data) : data);
        form.setFieldValue("setPasswordId", params.setPasswordId);
      } else {
        switch (data.status) {
          case "Active":
            navigate("../../activated/" + params.setPasswordId);
            break;

          case "Activation":
            navigate("../../verify/" + params.setPasswordId);
            break;

          case "AdditionalInformation":
            navigate("../../addAdditionalInfo/" + params.setPasswordId);
            break;

          case "AddTotp":
            navigate("../../addTotp/" + params.setPasswordId);
            break;

          //default:
          //  navigate("../../activated/" + params.setPasswordId);
        }

      }

    }
  }, [data, queryIsLoaded]);

  // @ts-ignore
  return (
    <>
      
        <FormProvider form={form}>
          
          <Text fz="lg" mt="sm" fw={500}>
            {t("activation.title", { context: i18Prefix })}
          </Text>
          <Text c="dimmed" fz="sm">
            {descriptionTextOverride ??
              t("activation.description", { context: i18Prefix })}
          </Text>

          <FormErrorAlert
            isError={isError}
            title={t("activation.verify_fail")}
            error={error}
          />

          <Skeleton visible={queryIsLoading}>
            <form id={formId} onSubmit={form.onSubmit(handleSubmit)}>
              <Stack justify={"flex-start"} spacing={"md"} pr={"md"}>
                {inputs}

                <Group position={"right"}>
                  <Button form={formId} loading={isLoading} type="submit">
                    {t("activation.submit", { context: i18Prefix })}
                  </Button>
                </Group>
              </Stack>
            </form>
          </Skeleton>
        </FormProvider>
      
    </>
  );
};
