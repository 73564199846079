import { createFormContext } from "@mantine/form";
import { z } from "zod";

import { useCustomZodResolver } from "shared-library/src/hooks/useCustomZodResolver";
import { AddAdditionalInfoCommand, AddTotpCommand } from "shared-library/src/services/appcenterApi"


const AddTotpFormSchema = z
  .object({
    totp: z.string().trim().min(1)  

  })

const addTotpInitialValues: AddTotpCommand = {
  totp: "",
  totpKey: ""
};

2
export const AddTotpResponseTransformation = (values) => {

  return {
    
  }
}

export const [AddTotpFormProvider, useAddTotpFormContext, useAddTotpNativeForm] =
  createFormContext<AddTotpCommand>();

export function useAddTotpForm() {
  return useAddTotpNativeForm({
    initialValues: addTotpInitialValues,
    validate: useCustomZodResolver("user", AddTotpFormSchema),
  });
}
