import { Code, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

import { UpdateForm } from "../../components/UpdateForm";
import {
  UserFormProvider,
  UserResponseTransformation,
  useUserForm,
} from "../../features/users/UserFormContext";
import { UserInputs } from "../../features/users/UserInputs";
import { useUpdateUserMutation } from "../../features/users/useUpdateUserMutation";
import {
  useGetApiUserGetUserQuery
} from "../../services/appcenterApi";
import { useWithCurrentOrganization } from "../../hooks/useWithCurrentOrganization";

export function UpdateUser() {
  const { t } = useTranslation();

  return (
    <>
      <UpdateForm
        FormProvider={UserFormProvider}
        inputs={<UserInputs />}
        i18Prefix={"users"}
        useUpdateMutation={useUpdateUserMutation}
        useFormHook={useUserForm}
        commandKey={"updateUserCommand"}
        transformResponse={UserResponseTransformation}
        useLoadQuery={useWithCurrentOrganization(useGetApiUserGetUserQuery)}
        //includeOrgIdInQuery={true}
        preSubmission={(form, values, next) => {
          if (form.isDirty("email")) {
            openConfirmModal({
              title: t("common.confirmation"),
              children: (
                <Text>
                  <Trans i18nKey={"update.emailUpdateConfirmation"}>
                    We have detected changes on the <Code>email</Code>. Please
                    confirm that this changes will required email re-validation
                    before the user next login.
                  </Trans>
                </Text>
              ),
              labels: {
                confirm: t("common.confirm"),
                cancel: t("common.cancel"),
              },
              onConfirm: next,
            });
            return false;
          } else {
            return true;
          }
        }}
      />
    </>
  );
}
