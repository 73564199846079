import { TextInput, Textarea } from "@mantine/core";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { useOrganizationFormContext } from "./OrganizationFormContext";

export function OrganizationInputs({
  disableCodeUpdate = false,
}: {
  disableCodeUpdate?: boolean;
}) {
  //Render inputs required for forms
  const form = useOrganizationFormContext();
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        label={t("organization.name")}
        //placeholder={t("scope.name_placeholder")}

        withAsterisk
        {...form.getInputProps("name")}
      />

      <TextInput
        label={t("organization.code")}
        //placeholder={t("scope.displayName_placeholder")}
        withAsterisk
        disabled={disableCodeUpdate}
        {...form.getInputProps("code")}
      />

      <Textarea
        label={t("organization.description")}
        //placeholder={t("scope.description_placeholder")}
        withAsterisk
        autosize
        minRows={3}
        maxRows={5}
        {...form.getInputProps("description")}
      />
    </>
  );
}
