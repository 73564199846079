import { faEraser, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { CxDataTable, CxDataTableProps } from "../../components/CxDataTable";
import { createStandardRowContextMenu } from "../../hooks/createStandardRowContextMenu";
import {
  useDeleteApiApplicationGroupDeleteApplicationGroupMutation,
  useDeleteApiApplicationGroupRemoveUserFromGroupMutation,
  useDeleteApiScopeDeleteSCopeMutation,
} from "../../services/appcenterApi";

export function GroupAssignmentsByUserDataTable(props: CxDataTableProps) {
  const { t } = useTranslation();
  //const navigate = useNavigate();
  //const i18Prefix = "user";

  const {userId} = useParams();

  const columns = [
    //{accessor: 'id'},

    {
      accessor: "name",
      title: t("userGroup.name"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "description",
      title: t("userGroup.description"),
      sortable: true,
      width: 200,
      ellipsis: true,
    },
    {
      accessor: "applicationName",
      title: t("userGroup.applicationName"),
      sortable: true,
      ellipsis: true,
    },
  ];

  const rowContextMenu = {
    items: (record) =>
      createStandardRowContextMenu({
        i18Context: "userGroupAssignmentByUser",
        record: {name: record.name, ...record},
        useDeleteMutation:
          () => {
            //wrap standard mutation, alternative to add custom delete record
            const [deleteUser, result] = useDeleteApiApplicationGroupRemoveUserFromGroupMutation();
            return [(standard) => deleteUser({
              applicationGroupId: record.id,
              userId: userId
            }), result]          
          },
        editOverride: null,
        confirmationValue: record.name,
        labelOverride: {
          label: record.name,
          //key: ""
        }
        //deleteOverride: null
      })
  };

  return (
    <>
      <CxDataTable columns={columns} rowActions={rowContextMenu} {...props} />
    </>
  );
}
