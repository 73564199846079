import * as React from "react";
import { useEffect } from "react";
import { Center, MultiSelect, PasswordInput, Text, TextInput, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faPray } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSignUpUserFormContext } from "./SignUpUserFormContext";
import { useParams } from "react-router-dom";
import { useVerifyUserFormContext } from "./VerifyUserFormContext";


export function VerifyInputs() {
  //Render inputs required for forms
  const form = useVerifyUserFormContext();
  const { t } = useTranslation();

  return (
    <>
      <TextInput label={t("user.otpCode")}
        //placeholder={t("scope.name_placeholder")}
                 withAsterisk={true}
                 {...form.getInputProps("otpCode")} />

    </>
  );
}